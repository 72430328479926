import React from 'react';

import { KeypadTag } from 'api/tagging-tool/types';

import LiveStreamTagButton from './LiveStreamTagButton';
import RecordingTagButton from './RecordingTagButton';
import { TagButtonProps } from './TagButtonBase';

export const TagButton = (
  props: TagButtonProps & { videoStartRecordingTime?: number; onPress: (time: number) => (tag: KeypadTag) => void },
) => {
  if (props.videoStartRecordingTime) return <LiveStreamTagButton {...props} />;

  return <RecordingTagButton {...props} />;
};
