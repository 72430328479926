import { Typography } from '@mui/material';
import React from 'react';

import styles from './FiltersSummaryItem.module.scss';

interface Props {
  title?: string;
  children: React.ReactNode;
}

const FiltersSummaryItem = ({ title, children }: Props) => {
  return (
    <section className={styles.container}>
      {title && (
        <Typography className={styles.title} variant={'subtitle1'}>
          {title}
        </Typography>
      )}
      {children}
    </section>
  );
};

export default FiltersSummaryItem;
