import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonPadding, ButtonSizes, ButtonTypes } from 'shared/components/button';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';

import styles from './SortBy.module.scss';
import { SortDirection } from '../../types/filters/types';
import IconSort from '../icons/icon-sort';

type SortByProps = {
  onSort: (order: SortDirection) => void;
};

const SortBy = ({ onSort }: SortByProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSort = (order: SortDirection) => {
    handleCloseMenu();
    onSort(order);
  };

  return (
    <>
      <Button
        id='sort-by-button'
        aria-controls='sort-by-menu'
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        type={ButtonTypes.Clear}
        btnSize={ButtonSizes.S}
        padding={ButtonPadding.P0}
      >
        <div className={styles.ButtonContent}>
          <IconSort size={IconSizes.small} color={IconColors.night} />
          {t('common:actions.sort-by.sort-by').toUpperCase()}
        </div>
      </Button>
      <Menu
        id='sort-by-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{ 'aria-labelledby': 'sort-by-button' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={() => handleSort(SortDirection.ASC)}>
          <div className={styles.menuItem}>{t('common:actions.sort-by.asc')}</div>
        </MenuItem>
        <MenuItem onClick={() => handleSort(SortDirection.DESC)}>
          <div className={styles.menuItem}>{t('common:actions.sort-by.desc')}</div>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SortBy;
