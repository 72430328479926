import { Clip } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows';
import { ClipTimeline } from '../clip-timeline';

export const ClipsList = ({ recordingId, clips }: { recordingId: string; clips: Clip[] }) => {
  return (
    <>
      {clips.map((clip) => (
        <ClipTimeline recordingId={recordingId} key={clip.id} clip={clip} />
      ))}
    </>
  );
};
