import { UseQueryResult } from '@tanstack/react-query';

import { queryClient } from 'api/config';
import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { transformRecording } from 'api/recording/transformers';
import { taggingRecordingsBaseUrl } from 'api/routes';
import { APIRecording } from 'pages/recordings-list/api/types';
import { Recording } from 'shared/types/recording/types';

const generateQueryRef = () => ['fetchTaggingRecording'];

interface useFetchTaggingRecordingsInterface {
  (): UseQueryResult<Recording[]>;
}

export const useFetchTaggingRecordings: useFetchTaggingRecordingsInterface = () => {
  const onSuccess = (data: APIRecording[]) => data.map((recording) => transformRecording(recording));
  const fetchQueryRef = generateQueryRef();
  const invalidateQuery = () => queryClient.invalidateQueries(fetchQueryRef);
  const fetchRequest = useFetchRequest<Recording[]>({
    queryRef: fetchQueryRef,
    url: taggingRecordingsBaseUrl,
    options: { retry: false, refetchOnWindowFocus: true },
    transformer: onSuccess,
  });

  return { ...fetchRequest, invalidateQuery };
};
