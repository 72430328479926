import { Slider, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import * as React from 'react';

const TrimSliderTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#ffad0d',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 11,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: -2,
    width: 32,
    left: -26,
    height: 20,
    padding: 2,
  },
}))(Tooltip);

export const ValueLabelComponent = (props: { children: React.ReactElement; value: number }) => {
  const { children, value } = props;

  return (
    <TrimSliderTooltip open={true} placement={'top'} title={value}>
      {children}
    </TrimSliderTooltip>
  );
};

const TrimSlider = withStyles({
  root: {
    color: '#ffad0d',
    height: 40,
    padding: '0',
    top: 0,
    marginLeft: 16,
    width: 'calc(100% - 16px)',
  },
  thumb: {
    height: 38,
    width: 16,
    backgroundColor: '#ffad0d',
    border: '1px solid currentColor',
    marginTop: 0,
    borderRadius: 0,

    '&::before': {
      boxShadow: 'none',
      width: 2,
      height: 24,
      marginLeft: 1,
      marginRight: 1,
      backgroundColor: '#171928',
    },
  },
  active: {},
  track: {
    height: 38,
    borderTop: '4px solid #ffad0d',
    borderBottom: '4px solid #ffad0d',
    backgroundColor: '#fffbf3',
  },
  rail: {
    color: '#f5f5f7',
    opacity: 1,
    height: 40,
  },
})(Slider);

export default TrimSlider;
