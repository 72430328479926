import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

import { playlistTimelineHeaderHeight } from '../playlist-detail-page/styled';

const PLAYLIST_ITEM_WIDTH = 152;
export const PLAYLIST_ITEM_GAP = 4;
export const PLAYLIST_ITEM_SIZE = PLAYLIST_ITEM_WIDTH + PLAYLIST_ITEM_GAP * 2;

export const PlaylistItemsContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2, 0, 1, 2),
}));

export const PlaylistItemsListBulk = styled(Box)(({ theme }) => ({
  height: playlistTimelineHeaderHeight,
  backgroundColor: Colors.shark,
  color: Colors.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0.5, 1),
  position: 'absolute',
  top: 0,
  width: '100%',
}));
