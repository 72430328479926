import { useRecoilValue, useSetRecoilState } from 'recoil';

import { timelineMatchAtoms } from '../../atoms';

export const useSetTacticalAnalysisSelectedTactics = (recordingId: string) => {
  return useSetRecoilState(timelineMatchAtoms.selectedTactics(recordingId));
};

export const useTacticalAnalysisSelectedTactics = (recordingId: string) => {
  return useRecoilValue(timelineMatchAtoms.selectedTactics(recordingId));
};
