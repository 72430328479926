import { Box, Button, Stack, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { useCallback, useState } from 'react';

import { useSeasonClients } from 'pages/backoffice/api/season-clients/use-season-clients';
import IconPlus from 'shared/components/icons/icon-plus';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import { ListHeader } from 'shared/components/list/list-header';
import NotFound from 'shared/components/not-found';
import Pagination from 'shared/components/pagination';

import { SeasonClientsList } from './components/season-clients-list';
import { SeasonClientsModal } from './components/season-clients-modal';
import { SeasonClientsListHeaderGrid } from './styled';
import { BackofficeContentContainer } from '../../components/backoffice-content-container';
import { ListSkeleton } from '../../components/list-skeleton';

export const SeasonClients = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { items, page, fetchNextPage, isFetching } = useSeasonClients();

  const handleCreateModalOpen = useCallback(() => setIsCreateModalOpen(true), []);
  const handleCreateModalClose = useCallback(() => setIsCreateModalOpen(false), []);

  return (
    <BackofficeContentContainer>
      <Stack direction='row' alignItems='center' justifyContent='space-between' marginBottom={2}>
        <Typography variant='h4'>Associations season clients</Typography>
        <Button
          variant='contained'
          size='large'
          startIcon={<IconPlus color={IconColors.white} size={IconSizes.small} />}
          onClick={handleCreateModalOpen}
        >
          New association season clients
        </Button>
      </Stack>
      <ListHeader>
        <SeasonClientsListHeaderGrid>
          <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
            Original client
          </Typography>
          <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
            Competition
          </Typography>
          <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
            Clients
          </Typography>
          <div>&nbsp;</div>
        </SeasonClientsListHeaderGrid>
      </ListHeader>
      {!isFetching && items.length === 0 ? (
        <Box marginTop={4}>
          <NotFound header={'No results'} />
        </Box>
      ) : null}
      {isFetching ? <ListSkeleton /> : <SeasonClientsList seasonClientsList={items} />}
      {page ? (
        <Pagination
          total={page.totalElements}
          displayed={items.length}
          onShowMore={fetchNextPage}
          getStatsText={(displayed, total) => `${displayed} of ${total} venues`}
        />
      ) : null}
      <SeasonClientsModal open={isCreateModalOpen} onClose={handleCreateModalClose} />
    </BackofficeContentContainer>
  );
};
