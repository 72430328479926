import { useQueryClient } from '@tanstack/react-query';

import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { SortDirection } from 'shared/types/filters/types';

import { transformCompetitions } from './transformer';
import { CompetitionsApiResponse, CompetitionsFilters } from './types';
import { Competition } from '../../../types/competitions';
import { getCompetitionsUrl } from '../../routes';
import { useAllowedBackofficeOrigin } from '../../use-allowed-backoffice-origin';

const QUERY_KEY = 'competitions';

export const useCompetitions = (): InfinityQueryResult<Competition, CompetitionsFilters> => {
  const { isAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();

  return useInfinityQuery<Competition, CompetitionsFilters, CompetitionsApiResponse>({
    generateUrl: getCompetitionsUrl,
    key: QUERY_KEY,
    options: {
      size: 10,
      sort: 'name',
      sortDirection: SortDirection.DESC,
    },
    transformer: transformCompetitions,
    queryOptions: {
      enabled: isAllowedBackofficeOrigin,
    },
  });
};

export const useInvalidateCompetitions = () => {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
};
