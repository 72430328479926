import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { extractClipsFromTimelineTableData } from '../../api/use-tactical-analysis-data';
import { Row, RowGroup, TimelineTableData } from '../../api/use-tactical-analysis-data/generate-timeline-rows';
import {
  useAddClipsToSelectionList,
  useAddRowsToSelectionList,
  useAddRowToSelectionList,
} from '../../api/use-tactical-analysis-data/generate-timeline-rows/atoms';
import { timelineMatchAtoms } from '../../atoms';
import { useTimelineTeamIdFocus } from '../use-timeline-team-id-focus';

function extractRowsFromGroup(rowGroup: RowGroup): Row[] {
  let rows: Row[] = [...rowGroup.rows];

  if (rowGroup.rowGroups) {
    for (const rg of rowGroup.rowGroups) {
      rows = rows.concat(extractRowsFromGroup(rg));
    }
  }

  return rows;
}

function extractAllRows(rowGroups: RowGroup[]): Row[] {
  let allRows: Row[] = [];

  for (const rg of rowGroups) {
    allRows = allRows.concat(extractRowsFromGroup(rg));
  }

  return allRows;
}

export const useSetTimelineTableData = (recordingId: string) => {
  const addRowsForSelection = useAddRowsToSelectionList();
  const addRowForSelection = useAddRowToSelectionList();
  const { teamIdFocus } = useTimelineTeamIdFocus(recordingId);
  const addClipsForSelection = useAddClipsToSelectionList();
  const setTimelineData = useSetRecoilState(timelineMatchAtoms.timelineTableData(recordingId));

  return useCallback(
    (data: TimelineTableData) => {
      const rows = [...(data.scenariosRowGroup ? [data.scenariosRowGroup] : []), ...data.rowGroups];

      addRowsForSelection(extractAllRows(rows), teamIdFocus);
      if (data.filtersRow) {
        addRowForSelection(data.filtersRow);
      }

      const filterClips = data.filtersRow ? extractClipsFromTimelineTableData([data.filtersRow]) : [];
      addClipsForSelection([
        ...data.episodesRow.clips.filter((clip) => clip.type !== 'not-effective-time'),
        ...filterClips,
        ...extractClipsFromTimelineTableData(extractAllRows(rows)),
      ]);

      setTimelineData(data);
    },
    [addRowsForSelection, teamIdFocus, addClipsForSelection, setTimelineData, addRowForSelection],
  );
};

export const useTimelineTableData = (recordingId: string) => {
  return useRecoilValue(timelineMatchAtoms.timelineTableData(recordingId));
};
