import { array, object, string, TypeOf } from 'zod';

import { KogniaUser, kogniaUserSchema } from 'api/backoffice/user/use-kognia-users/types';

export enum AddUsersToClientsFormFieldsNames {
  CLIENT_IDS = 'clientIds',
  KOGNIA_USERS = 'kogniaUsers',
}

export interface AddUsersToClientsFormFields {
  [AddUsersToClientsFormFieldsNames.CLIENT_IDS]: string[];
  [AddUsersToClientsFormFieldsNames.KOGNIA_USERS]: KogniaUser[];
}

export interface AddUsersToClientsForm {
  [AddUsersToClientsFormFieldsNames.CLIENT_IDS]: AddUsersToClientsFormFields[AddUsersToClientsFormFieldsNames.CLIENT_IDS];
  [AddUsersToClientsFormFieldsNames.KOGNIA_USERS]: AddUsersToClientsFormFields[AddUsersToClientsFormFieldsNames.KOGNIA_USERS];
}

export const addUsersToClientsFormSchema = object({
  [AddUsersToClientsFormFieldsNames.CLIENT_IDS]: array(string()).min(1),
  [AddUsersToClientsFormFieldsNames.KOGNIA_USERS]: array(kogniaUserSchema).min(1),
});

export type AddUsersToClientsFormSchema = TypeOf<typeof addUsersToClientsFormSchema>;
