import { useUser } from 'shared/contexts/app-state';
import { Client } from 'shared/types/user/types';

interface UseSourceClientReturn {
  (clientIds: string[]): Client[];
}
export const useCommonResourceClients = (): UseSourceClientReturn => {
  const user = useUser();

  return (clientIds: string[]) =>
    user.clients.reduce((acc: Client[], client: Client) => {
      if (!clientIds.includes(client.id)) return acc;

      return [...acc, client];
    }, []);
};
