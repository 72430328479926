import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';

import { getReprocessSyncUrl } from '../../routes';
import { ReprocessSyncApiParams } from '../../types';
import { useAllowedBackofficeOrigin } from '../../use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from '../../utils';

interface ReprocessSyncParams {
  data: ReprocessSyncApiParams;
}

export const useReprocessSync = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Reprocess sync success',
    errorMessage: (data) => metadataApiErrorParser(data, 'Reprocess sync error'),
  });

  const reprocessSync = ({ data }: ReprocessSyncParams) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate({ url: getReprocessSyncUrl(data.recordingId) });
  };

  return { ...mutationRequest, reprocessSync };
};
