import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';

import { annotationApiUrls } from '../../routes';
import { useAllowedBackofficeOrigin } from '../../use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from '../../utils';
import { useInvalidateMetadataGames } from '../use-metadata-games';

interface DeleteMetadataGameOptions {
  id: string;
  onSuccess?: () => void;
}

type Options = {
  successMessage?: string;
};

export const useDeleteMetadataGame = ({ successMessage }: Options = { successMessage: 'Game deleted' }) => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateMetadataGames = useInvalidateMetadataGames();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.DELETE,
    successMessage: successMessage,
    errorMessage: (data) => metadataApiErrorParser(data, 'Game delete error'),
    onSuccess: invalidateMetadataGames,
  });

  const deleteMetadataGame = ({ id, onSuccess }: DeleteMetadataGameOptions) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: `${annotationApiUrls.GAMES}/${id}`,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, deleteMetadataGame };
};
