import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { DialogNew } from 'shared/components/dialog-new';
import { IconUser } from 'shared/components/icons/icon-user';
import { IconSizes } from 'shared/components/icons/svg-icon';

import { useInvalidateTeams } from '../../api/teams/use-teams';
import { useCreateBackofficeTeam } from '../../api/use-create-backoffice-team';
import { TeamForm } from '../../pages/teams/components/team-form';
import { createTeamFormSchema, TeamTypes } from '../../pages/teams/components/team-form/form';
import {
  CreateTeamForm,
  CreateTeamFormSchema,
  TeamFormFieldsNames,
} from '../../pages/teams/components/team-form/types';
import { MetadataTeam } from '../../types/games';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess?: (team: MetadataTeam) => void;
}

export const CreateTeamModal = ({ open, onClose, onSuccess }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const invalidateQuery = useInvalidateTeams();
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    trigger,
    control,
  } = useForm<CreateTeamFormSchema>({
    resolver: zodResolver(createTeamFormSchema),
    defaultValues: {
      [TeamFormFieldsNames.teamType]: TeamTypes.club,
      [TeamFormFieldsNames.countryCode]: '',
    },
  });

  const { createTeam } = useCreateBackofficeTeam();

  const handleCreateTeam = useCallback(
    (team: CreateTeamForm) => {
      createTeam({
        data: {
          name: team[TeamFormFieldsNames.name],
          country_code: team[TeamFormFieldsNames.countryCode],
          team_type: team[TeamFormFieldsNames.teamType],
          abbreviation: team[TeamFormFieldsNames.abbreviation],
          image: team[TeamFormFieldsNames.teamImage] ? team[TeamFormFieldsNames.teamImage][0] : undefined,
        },
        onSuccess: (team: MetadataTeam) => {
          onSuccess && onSuccess(team);
          invalidateQuery().then(() => onClose());
        },
      });
    },
    [onSuccess, createTeam, invalidateQuery, onClose],
  );

  const handleModalSubmit = useCallback(() => {
    formRef.current?.requestSubmit();
  }, []);

  return (
    <DialogNew
      maxWidth='sm'
      fullWidth
      title='New team'
      icon={<IconUser size={IconSizes.small} />}
      onCancel={onClose}
      onClose={onClose}
      onSubmit={handleModalSubmit}
      buttonSubmitText={'Create team'}
      open={open}
    >
      <form ref={formRef} onSubmit={handleSubmit(handleCreateTeam)}>
        <TeamForm<CreateTeamFormSchema>
          errors={errors}
          register={register}
          watch={watch}
          trigger={trigger}
          control={control}
        />
      </form>
    </DialogNew>
  );
};
