import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { GameForm } from '../game-form';
import { useResetGameFormData } from '../game-form/config';
import { GameFormRouteParams } from '../game-form/types';

export const NewGame = () => {
  const history = useHistory();
  const { id } = useParams<GameFormRouteParams>();
  const resetNewGameData = useResetGameFormData();

  useEffect(() => {
    if (!id) resetNewGameData();
  }, [id, history, resetNewGameData]);

  return <GameForm />;
};
