import { useQueryClient } from '@tanstack/react-query';

import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { SortDirection } from 'shared/types/filters/types';

import { transformMetadataRecordingsFromApiResponse } from './transformer';
import { MetadataRecordingsApiFilters, MetadataRecordingsApiResponse } from './types';
import { MetadataRecording } from '../../../types/recordings';
import { getRecordingsUrl } from '../../routes';
import { useAllowedBackofficeOrigin } from '../../use-allowed-backoffice-origin';

const QUERY_KEY = 'metadata-recordings';

export const useMetadataRecordings = (): InfinityQueryResult<MetadataRecording, MetadataRecordingsApiFilters> => {
  const { isAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();

  return useInfinityQuery<MetadataRecording, MetadataRecordingsApiFilters, MetadataRecordingsApiResponse>({
    generateUrl: getRecordingsUrl,
    key: QUERY_KEY,
    options: {
      size: 10,
      sort: 'name',
      sortDirection: SortDirection.DESC,
    },
    transformer: transformMetadataRecordingsFromApiResponse,
    queryOptions: {
      enabled: isAllowedBackofficeOrigin,
    },
  });
};

export const useInvalidateMetadataRecordings = () => {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
};
