import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Container, Grid, Paper, Stack, Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { FormInputField } from 'shared/components/form/form-input-field';
import { FormSelectField } from 'shared/components/form/form-select-field';
import { FormSwitchField } from 'shared/components/form/form-switch-field';
import { getTypeOptions } from 'shared/components/form/get-type-options';

import { BundesligaForm } from './bundesliga-form';
import { ChyronhegoForm } from './chyronhego-form';
import {
  eventsFilesFieldsNames,
  ProcessGameAnnotationTool,
  processGameDefaultValues,
  ProcessGameEventsProvider,
  ProcessGameFieldsNames,
  ProcessGameForm,
  processGameFormSchema,
  ProcessGameFormSchema,
  ProcessGamePipelineType,
  ProcessGameTrackingProvider,
  trackingFilesFieldsNames,
} from './form';
import { HawkeyeForm } from './hawkeye-form';
import { OptaForm } from './opta-form';
import { SecondSpectrumForm } from './second-spectrum-form';
import { SignalityForm } from './signality-form';
import { ProcessGameParamsData, useProcessGame } from '../../api/operations/use-process-game';
import { BackofficeContentContainer } from '../../components/backoffice-content-container';

export const ProcessGamePage = () => {
  const { processGame } = useProcessGame();
  const {
    register,
    formState: { errors },
    reset,
    unregister,
    handleSubmit,
    watch,
  } = useForm<ProcessGameFormSchema>({
    resolver: zodResolver(processGameFormSchema),
    defaultValues: processGameDefaultValues,
  });

  const isTrackingFile = (value: string) => {
    const trackingFileNames: string[] = Object.values(trackingFilesFieldsNames);
    return trackingFileNames.includes(value);
  };

  const isEventsFile = (value: string) => {
    const eventsFileNames: string[] = Object.values(eventsFilesFieldsNames);
    return eventsFileNames.includes(value);
  };

  const handleFormSubmit = useCallback(
    (formData: ProcessGameForm) => {
      const {
        [ProcessGameFieldsNames.recordingId]: recordingId,
        [ProcessGameFieldsNames.videoPath]: videoPath,
        [ProcessGameFieldsNames.bucket]: bucket,
        [ProcessGameFieldsNames.pipelineType]: pipelineType,
        [ProcessGameFieldsNames.trackingProvider]: trackingProvider,
        [ProcessGameFieldsNames.eventsProvider]: eventsProvider,
        [ProcessGameFieldsNames.pitchWidth]: pitchWidth,
        [ProcessGameFieldsNames.pitchLength]: pitchLength,
        [ProcessGameFieldsNames.annotationTool]: annotationTool,
        [ProcessGameFieldsNames.runProcessing]: runProcessing,
        ...restData
      } = formData;

      const filesNonEmptyData = Object.entries(restData).reduce(
        (acc, [key, value]) => {
          if (!isEmpty(value)) {
            if (isTrackingFile(key)) {
              return { ...acc, tracking: { ...acc.tracking, [key]: value } };
            } else if (isEventsFile(key)) {
              return { ...acc, events: { ...acc.events, [key]: value } };
            }
          }

          return acc;
        },
        { tracking: {}, events: {} },
      );

      const data: ProcessGameParamsData = {
        recording_id: recordingId,
        video_path: videoPath,
        bucket,
        pipeline_type: pipelineType,
        settings: {
          pitch: {
            width: pitchWidth,
            length: pitchLength,
          },
          provider: {
            video: 'third-party-tactical',
            tracking: trackingProvider,
            events: eventsProvider,
          },
          annotation_tool: annotationTool,
          run_processing: runProcessing,
        },
        ...(!isEmpty(filesNonEmptyData.tracking) && {
          tracking_files: filesNonEmptyData.tracking,
        }),
        ...(!isEmpty(filesNonEmptyData.events) && {
          events_files: filesNonEmptyData.events,
        }),
      };

      processGame({ data });
    },
    [processGame],
  );

  const showProviderField =
    watch(ProcessGameFieldsNames.pipelineType) === ProcessGamePipelineType.THIRD_PARTY_TACTICAL_TRACKING;
  const showTrackingProviderFields = (trackingProvider: ProcessGameTrackingProvider) =>
    showProviderField && watch(ProcessGameFieldsNames.trackingProvider) === trackingProvider;
  const showEventsProviderFields = (eventsProvider: ProcessGameEventsProvider) =>
    showProviderField && watch(ProcessGameFieldsNames.eventsProvider) === eventsProvider;

  return (
    <BackofficeContentContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h4'>Process game</Typography>
        </Grid>
        <Grid item xs={12}>
          <Container maxWidth='md'>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <Paper sx={{ padding: 4 }}>
                <FormInputField
                  id={ProcessGameFieldsNames.recordingId}
                  label='Recording ID'
                  error={!!errors[ProcessGameFieldsNames.recordingId]}
                  fullWidth
                  helperText={
                    errors[ProcessGameFieldsNames.recordingId] && (
                      <>{errors[ProcessGameFieldsNames.recordingId]?.message}</>
                    )
                  }
                  {...register(ProcessGameFieldsNames.recordingId)}
                />

                <FormInputField
                  id={ProcessGameFieldsNames.videoPath}
                  label='Video path'
                  error={!!errors[ProcessGameFieldsNames.videoPath]}
                  fullWidth
                  helperText={
                    errors[ProcessGameFieldsNames.videoPath] && <>{errors[ProcessGameFieldsNames.videoPath]?.message}</>
                  }
                  {...register(ProcessGameFieldsNames.videoPath)}
                />

                <FormInputField
                  id={ProcessGameFieldsNames.bucket}
                  label='Bucket'
                  error={!!errors[ProcessGameFieldsNames.bucket]}
                  fullWidth
                  helperText={
                    errors[ProcessGameFieldsNames.bucket] && <>{errors[ProcessGameFieldsNames.bucket]?.message}</>
                  }
                  {...register(ProcessGameFieldsNames.bucket)}
                />

                <FormInputField
                  id={ProcessGameFieldsNames.pitchLength}
                  label='Pitch length'
                  error={!!errors[ProcessGameFieldsNames.pitchLength]}
                  fullWidth
                  helperText={
                    errors[ProcessGameFieldsNames.pitchLength] && (
                      <>{errors[ProcessGameFieldsNames.pitchLength]?.message}</>
                    )
                  }
                  {...register(ProcessGameFieldsNames.pitchLength)}
                />

                <FormInputField
                  id={ProcessGameFieldsNames.pitchWidth}
                  label='Pitch width'
                  error={!!errors[ProcessGameFieldsNames.pitchWidth]}
                  fullWidth
                  helperText={
                    errors[ProcessGameFieldsNames.pitchWidth] && (
                      <>{errors[ProcessGameFieldsNames.pitchWidth]?.message}</>
                    )
                  }
                  {...register(ProcessGameFieldsNames.pitchWidth)}
                />

                <FormSelectField
                  id={ProcessGameFieldsNames.annotationTool}
                  label='Annotation tool'
                  error={!!errors[ProcessGameFieldsNames.annotationTool]}
                  fullWidth
                  options={getTypeOptions({
                    options: Object.values(ProcessGameAnnotationTool).map((value) => ({ value })),
                  })}
                  defaultValue={ProcessGameAnnotationTool.CVAT}
                  helperText={
                    errors[ProcessGameFieldsNames.annotationTool] && (
                      <>{errors[ProcessGameFieldsNames.annotationTool]?.message}</>
                    )
                  }
                  {...register(ProcessGameFieldsNames.annotationTool)}
                />

                <FormSelectField
                  id={ProcessGameFieldsNames.pipelineType}
                  label='Pipeline type'
                  error={!!errors[ProcessGameFieldsNames.pipelineType]}
                  fullWidth
                  options={getTypeOptions({
                    options: Object.values(ProcessGamePipelineType).map((value) => ({
                      value,
                    })),
                  })}
                  helperText={
                    errors[ProcessGameFieldsNames.pipelineType] && (
                      <>{errors[ProcessGameFieldsNames.pipelineType]?.message}</>
                    )
                  }
                  {...register(ProcessGameFieldsNames.pipelineType)}
                  onChange={(event) => {
                    Object.values(trackingFilesFieldsNames).forEach((name) => unregister(name));
                    Object.values(eventsFilesFieldsNames).forEach((name) => unregister(name));
                    return register(ProcessGameFieldsNames.pipelineType).onChange(event);
                  }}
                />

                {showProviderField ? (
                  <FormSelectField
                    id={ProcessGameFieldsNames.trackingProvider}
                    label='Tracking provider'
                    error={!!errors[ProcessGameFieldsNames.trackingProvider]}
                    fullWidth
                    options={getTypeOptions({
                      options: Object.values(ProcessGameTrackingProvider).map((value) => ({
                        value,
                      })),
                    })}
                    helperText={
                      errors[ProcessGameFieldsNames.trackingProvider] && (
                        <>{errors[ProcessGameFieldsNames.trackingProvider]?.message}</>
                      )
                    }
                    {...register(ProcessGameFieldsNames.trackingProvider)}
                    onChange={(event) => {
                      Object.values(trackingFilesFieldsNames).forEach((name) => unregister(name));
                      return register(ProcessGameFieldsNames.trackingProvider).onChange(event);
                    }}
                  />
                ) : null}

                {showTrackingProviderFields(ProcessGameTrackingProvider.HAWKEYE) && (
                  <HawkeyeForm register={register} errors={errors} />
                )}

                {showTrackingProviderFields(ProcessGameTrackingProvider.CHYRONHEGO) && (
                  <ChyronhegoForm register={register} errors={errors} />
                )}

                {showTrackingProviderFields(ProcessGameTrackingProvider.SIGNALITY) && (
                  <SignalityForm register={register} errors={errors} />
                )}

                {showTrackingProviderFields(ProcessGameTrackingProvider.SECOND_SPECTRUM) && (
                  <SecondSpectrumForm register={register} errors={errors} />
                )}

                {showProviderField ? (
                  <FormSelectField
                    id={ProcessGameFieldsNames.eventsProvider}
                    label='Events provider'
                    error={!!errors[ProcessGameFieldsNames.eventsProvider]}
                    fullWidth
                    options={getTypeOptions({
                      options: Object.values(ProcessGameEventsProvider).map((value) => ({
                        value,
                      })),
                    })}
                    helperText={
                      errors[ProcessGameFieldsNames.eventsProvider] && (
                        <>{errors[ProcessGameFieldsNames.eventsProvider]?.message}</>
                      )
                    }
                    {...register(ProcessGameFieldsNames.eventsProvider)}
                    onChange={(event) => {
                      Object.values(eventsFilesFieldsNames).forEach((name) => unregister(name));
                      return register(ProcessGameFieldsNames.eventsProvider).onChange(event);
                    }}
                  />
                ) : null}

                {showEventsProviderFields(ProcessGameEventsProvider.OPTA) && (
                  <OptaForm register={register} errors={errors} />
                )}

                {showEventsProviderFields(ProcessGameEventsProvider.BUNDESLIGA) && (
                  <BundesligaForm register={register} errors={errors} />
                )}

                <FormSwitchField
                  id={ProcessGameFieldsNames.runProcessing}
                  label='Run processing'
                  defaultChecked
                  {...register(ProcessGameFieldsNames.runProcessing)}
                />

                <Stack direction='row' justifyContent='flex-end' spacing={1}>
                  <Button type='button' onClick={() => reset(processGameDefaultValues)}>
                    Reset
                  </Button>
                  <Button variant='contained' type='submit'>
                    Submit
                  </Button>
                </Stack>
              </Paper>
            </form>
          </Container>
        </Grid>
      </Grid>
    </BackofficeContentContainer>
  );
};
