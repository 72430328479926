import { Alert, Stack, Tab } from '@mui/material';
import { Colors } from 'kognia-ui';
import { DefensiveTacticId, defensiveTactics, OffensiveTacticId, offensiveTactics, TacticId } from 'overlay-generator';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxWithColor } from 'shared/components/checkbox-with-color';
import { IconColors } from 'shared/components/icons/svg-icon';
import { Tabs } from 'shared/components/tabs';
import { TabPanel, TabsPanel } from 'shared/components/tabs/components/tab-panel';

import { TacticCheckBox } from '../../tactic-checkbox';
import {
  useOverlaySelectedTab,
  useSetOverlaySelectedTab,
} from '../../video-player/video-player-component/hooks/overlay-panel';
import { PanelContent, PanelSectionTitle } from '../styled';

type Props = {
  id: string;
  width: number;
  isOpen: boolean;
  availableTactics: TacticId[];
  initialSelectedTactics: TacticId[];
  frameTactics: TacticId[];
  onTacticsChange: (tactic: TacticId[]) => void;
};

export const OverlaySelectorContent = ({
  id,
  width,
  isOpen,
  availableTactics,
  initialSelectedTactics,
  frameTactics,
  onTacticsChange,
}: Props) => {
  const { t } = useTranslation();
  const selectedTab = useOverlaySelectedTab(id);
  const setSelectedTab = useSetOverlaySelectedTab(id);

  const selectedTactics = useMemo(() => new Set(initialSelectedTactics), [initialSelectedTactics]);

  const availableOffensiveTactics = useMemo(
    () =>
      availableTactics.filter((tactic) => offensiveTactics.includes(tactic as OffensiveTacticId), [availableTactics]),
    [availableTactics],
  );

  const availableDefensiveTactics = useMemo(
    () =>
      availableTactics.filter((tactic) => defensiveTactics.includes(tactic as DefensiveTacticId), [availableTactics]),
    [availableTactics],
  );

  const selectedOffensiveTactics = useMemo(
    () =>
      availableTactics.filter(
        (tactic) => offensiveTactics.includes(tactic as OffensiveTacticId) && selectedTactics.has(tactic),
        [selectedTactics],
      ),
    [availableTactics, selectedTactics],
  );

  const selectedDefensiveTactics = useMemo(
    () =>
      availableTactics.filter(
        (tactic) => defensiveTactics.includes(tactic as DefensiveTacticId) && selectedTactics.has(tactic),
        [selectedTactics],
      ),
    [availableTactics, selectedTactics],
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleTacticToggle = useCallback(
    (tacticId: TacticId) => {
      if (selectedTactics.has(tacticId)) {
        selectedTactics.delete(tacticId);
      } else {
        selectedTactics.add(tacticId);
      }
      onTacticsChange(Array.from(selectedTactics));
    },
    [onTacticsChange, selectedTactics],
  );

  const areAllOffensiveTacticsSelected = useMemo(() => {
    return (
      availableOffensiveTactics.filter((tactic) => selectedTactics.has(tactic)).length ===
      availableOffensiveTactics.length
    );
  }, [availableOffensiveTactics, selectedTactics]);

  const areAllDefensiveTacticsSelected = useMemo(
    () =>
      availableDefensiveTactics.filter((tactic) => selectedTactics.has(tactic)).length ===
      availableDefensiveTactics.length,
    [availableDefensiveTactics, selectedTactics],
  );

  const handleToggleAllOffensiveTactics = useCallback(() => {
    if (areAllOffensiveTacticsSelected) {
      availableOffensiveTactics.forEach((tactic) => {
        selectedTactics.delete(tactic);
      });
    } else {
      availableOffensiveTactics.forEach((tactic) => {
        selectedTactics.add(tactic);
      });
    }
    onTacticsChange(Array.from(selectedTactics));
  }, [availableOffensiveTactics, onTacticsChange, areAllOffensiveTacticsSelected, selectedTactics]);

  const handleToggleAllDefensiveTactics = useCallback(() => {
    if (areAllDefensiveTacticsSelected) {
      availableDefensiveTactics.forEach((tactic) => {
        selectedTactics.delete(tactic);
      });
    } else {
      availableDefensiveTactics.forEach((tactic) => {
        selectedTactics.add(tactic);
      });
    }
    onTacticsChange(Array.from(selectedTactics));
  }, [areAllDefensiveTacticsSelected, availableDefensiveTactics, onTacticsChange, selectedTactics]);

  if (availableTactics.length === 0) return <Alert severity='info'>No tactics available</Alert>;

  return (
    <PanelContent width={width} isOpen={isOpen}>
      <Stack gap={1}>
        <PanelSectionTitle>{t('video-player:overlays.tactics')}</PanelSectionTitle>
      </Stack>
      <Tabs color={Colors.night} value={selectedTab} onChange={handleTabChange} variant={'fullWidth'}>
        <Tab label={t('video-player:overlays.offensive')} />
        <Tab label={t('video-player:overlays.defensive')} />
      </Tabs>
      <TabsPanel>
        <TabPanel value={selectedTab} index={0}>
          <Stack gap={2}>
            <Stack gap={1} direction={'row'} alignItems={'center'}>
              <CheckboxWithColor
                customColor={IconColors.night}
                checked={areAllOffensiveTacticsSelected}
                onChange={handleToggleAllOffensiveTactics}
                indeterminate={selectedOffensiveTactics.length > 0 && !areAllOffensiveTacticsSelected}
              />
              {t('video-player:overlays.all-tactics')} ({selectedOffensiveTactics.length} /{' '}
              {availableOffensiveTactics.length})
            </Stack>
            <Stack gap={0.5} direction={'column'}>
              {availableOffensiveTactics.map((tactic) => (
                <TacticCheckBox
                  key={tactic}
                  isAvailableInFrame={frameTactics.includes(tactic)}
                  tactic={tactic}
                  selected={selectedTactics.has(tactic)}
                  onChange={handleTacticToggle}
                />
              ))}
            </Stack>
          </Stack>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Stack gap={2}>
            <Stack gap={1} direction={'row'} sx={{ alignItems: 'center' }}>
              <CheckboxWithColor
                customColor={IconColors.night}
                checked={areAllDefensiveTacticsSelected}
                onChange={handleToggleAllDefensiveTactics}
                indeterminate={selectedDefensiveTactics.length > 0 && !areAllDefensiveTacticsSelected}
              />{' '}
              {t('video-player:overlays.all-tactics')} ({selectedDefensiveTactics.length} /{' '}
              {availableDefensiveTactics.length})
            </Stack>
            <Stack gap={0.5} direction={'column'}>
              {availableDefensiveTactics.map((tactic) => (
                <TacticCheckBox
                  key={tactic}
                  isAvailableInFrame={frameTactics.includes(tactic)}
                  tactic={tactic}
                  selected={selectedTactics.has(tactic)}
                  onChange={handleTacticToggle}
                />
              ))}
            </Stack>
          </Stack>
        </TabPanel>
      </TabsPanel>
    </PanelContent>
  );
};
