import { Card } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './PlaylistResults.module.scss';

interface ResultsWrapperProps {
  children?: React.ReactNode;
  showSearchResults?: boolean;
  open: boolean;
  title?: string;
}

export const SearchPlaylistResults = (props: ResultsWrapperProps) => {
  const { t } = useTranslation();

  if (!open) return null;

  return (
    <div>
      {props.showSearchResults && (
        <div className={styles.playlistsTitle}>{props.title ? props.title : t('timeline:latest-playlists')}</div>
      )}
      <div>{props.children}</div>
    </div>
  );
};

export const SearchPlaylistResultsPopOver = (props: ResultsWrapperProps) => {
  const { t } = useTranslation();

  if (!open) return null;

  return (
    <Card sx={{ padding: 2 }}>
      {props.showSearchResults && (
        <div className={styles.playlistsTitle}>{props.title ? props.title : t('timeline:latest-playlists')}</div>
      )}
      <div>{props.children}</div>
    </Card>
  );
};
