import { zodResolver } from '@hookform/resolvers/zod';
import { AccordionActions, AccordionDetails } from '@mui/material';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useAnnotationTask } from '../../../../api/operations/use-annotation-task';
import { annotationsTaskFormSchema } from '../../utils/form-fields-validation';
import { AnnotationsForm, AnnotationsTaskFormSchema } from '../../utils/form-fields-validation/types';
import { AnnotationFormActions } from '../annotation-form-actions';
import { AnnotationToolField } from '../form-fields/annotation-tool-field';
import { IndexTypeGroup } from '../form-fields/index-type-group';
import { PipelineTypeField } from '../form-fields/pipeline-type-field';
import { RecordingIdField } from '../form-fields/recording-id-field';
import { TaskTypeField } from '../form-fields/task-type-field';

export const AnnotationsTask = () => {
  const { annotateTask } = useAnnotationTask();

  const {
    register,
    formState: { errors },
    reset,
    resetField,
    handleSubmit: handleFormSubmit,
  } = useForm<AnnotationsTaskFormSchema>({
    resolver: zodResolver(annotationsTaskFormSchema),
  });

  const handleSubmit = useCallback(
    (data: AnnotationsForm) => {
      annotateTask({ data });
    },
    [annotateTask],
  );

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)}>
      <AccordionDetails>
        <RecordingIdField errors={errors} register={register} />
        <TaskTypeField errors={errors} register={register} />
        <IndexTypeGroup errors={errors} register={register} resetField={resetField} />
        <PipelineTypeField errors={errors} register={register} />
        <AnnotationToolField errors={errors} register={register} />
      </AccordionDetails>
      <AccordionActions>
        <AnnotationFormActions reset={() => reset()} />
      </AccordionActions>
    </form>
  );
};
