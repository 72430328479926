import { useRecoilValue, useSetRecoilState } from 'recoil';

import { timelineMatchAtoms } from '../../atoms';

export const useSetTacticalAnalysisMatchSegments = (recordingId: string) => {
  return useSetRecoilState(timelineMatchAtoms.matchSegments(recordingId));
};

export const useTacticalAnalysisMatchSegments = (recordingId: string) => {
  return useRecoilValue(timelineMatchAtoms.matchSegments(recordingId));
};
