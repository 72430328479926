import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { queryClient } from 'api/config';
import { useBackendApi } from 'api/hooks/useBackendApi';
import { playlistsWithFiltersUrl } from 'api/routes';
import { fetchQueryResponse, HTTPMethod } from 'api/types';
import { useDates } from 'shared/hooks/use-dates';
import { FiltersList, Playlist, SortDirection } from 'shared/types';
import { guid } from 'shared/utils/guid';

import { INITIAL_PLAYLISTS_FILTERS, usePlaylistsFilters, useSetPlaylistsFilters } from './atoms';
import {
  PlaylistFiltersNames,
  PlaylistsFilters,
  PlaylistSortOptions,
  PlaylistsWithFiltersPage,
  PlaylistsWithFiltersPageQueryData,
} from './types';
import { getFilters, getPlaylistItems, getTimelinePlaylistPageSize, getTotalElementsFromPage } from './util';
import { transformPlaylists } from '../transformers';

export type PlaylistsData = {
  fetchNextPage: () => void;
  filters: FiltersList;
  playlists: Playlist[];
  totalElements: number;
};

export interface PlaylistsFilterActions {
  setCompetitions: (competition: string[]) => void;
  setDateRange: (from: Date | null, to: Date | null) => void;
  setName: (name: string) => void;
  setTypes: (name: string[]) => void;
  setSort: (sort: PlaylistSortOptions, sortDirection: SortDirection) => void;
}

interface useFetchPlaylistInterface extends fetchQueryResponse<PlaylistsData> {
  fetchNextPage: () => void;
  filterActions: PlaylistsFilterActions;
  setPageSizeParam: (pageSize: number) => void;
  appliedFilters: PlaylistsFilters;
}

const PAGE_SIZE = 8;

const NAME_SEARCH_LENGTH_LIMIT = 2;
export const FETCH_PLAYLIST_QUERY_KEY = 'fetchPlaylists';

export const invalidatePlaylistsQuery = () => queryClient.invalidateQueries([FETCH_PLAYLIST_QUERY_KEY]);

interface Options {
  enabled?: boolean;
  initialFilters?: PlaylistsFilters;
  isAutocomplete?: boolean;
  refetchInterval?: number | false;
  playlistId?: string;
}

const usePlaylists = ({
  initialFilters = INITIAL_PLAYLISTS_FILTERS,
  refetchInterval = 60000,
  enabled = true,
  isAutocomplete = false,
  playlistId = guid(),
}: Options): useFetchPlaylistInterface => {
  const { parseDateForApi } = useDates();
  const appliedFilters = usePlaylistsFilters(playlistId);
  const setFilters = useSetPlaylistsFilters(playlistId);

  useEffect(() => {
    setFilters(initialFilters);
  }, []);

  const [pageSizeParam, setPageSizeParam] = useState(
    isAutocomplete ? getTimelinePlaylistPageSize(appliedFilters['name']) : PAGE_SIZE,
  );

  const queryRef = useMemo(
    () => [FETCH_PLAYLIST_QUERY_KEY, pageSizeParam, appliedFilters],
    [pageSizeParam, appliedFilters],
  );

  const fetchRequest = useInfiniteQuery<PlaylistsWithFiltersPage>(
    queryRef,
    (options) => {
      return useBackendApi(
        playlistsWithFiltersUrl({
          size: pageSizeParam,
          sort: `${appliedFilters.sort},${appliedFilters.sortDirection}`,
          page: options.pageParam,
          name: appliedFilters[PlaylistFiltersNames.name],
          competition: appliedFilters[PlaylistFiltersNames.competition],
          from: appliedFilters[PlaylistFiltersNames.from] ?? undefined,
          to: appliedFilters[PlaylistFiltersNames.to] ?? undefined,
          type: appliedFilters[PlaylistFiltersNames.type] ?? undefined,
        }),
        HTTPMethod.GET,
        transformPlaylists,
      );
    },
    {
      getNextPageParam: (lastPage: PlaylistsWithFiltersPage) => {
        return lastPage.nextCursor;
      },
      refetchInterval,
      enabled,
      staleTime: 200,
    },
  );

  const setQueryData = (data: any) => queryClient.setQueryData(queryRef, data);
  const invalidateQuery = () => queryClient.invalidateQueries(queryRef);

  const setName = useCallback(
    (name: PlaylistsFilters[PlaylistFiltersNames.name]) => {
      name.length >= NAME_SEARCH_LENGTH_LIMIT
        ? setFilters({ ...appliedFilters, name })
        : setFilters({ ...appliedFilters, name: '' });
    },
    [setFilters, appliedFilters],
  );

  const setTypes = useCallback(
    (type: PlaylistsFilters[PlaylistFiltersNames.type]) => {
      setFilters({ ...appliedFilters, type });
    },
    [setFilters, appliedFilters],
  );

  const setCompetitions = useCallback(
    (competition: PlaylistsFilters[PlaylistFiltersNames.competition]) => {
      setFilters({ ...appliedFilters, competition });
    },
    [setFilters, appliedFilters],
  );

  const setDateRange = useCallback(
    (from: Date | null, to: Date | null) => {
      setFilters({ ...appliedFilters, from: from ? parseDateForApi(from) : null, to: to ? parseDateForApi(to) : null });
    },
    [parseDateForApi, setFilters, appliedFilters],
  );

  const setSort = useCallback(
    (sort: PlaylistSortOptions, sortDirection: SortDirection) => {
      setFilters({ ...appliedFilters, sort, sortDirection });
    },
    [setFilters, appliedFilters],
  );

  const filterActions = useMemo(
    () => ({ setCompetitions, setDateRange, setName, setTypes, setSort }),
    [setCompetitions, setDateRange, setName, setTypes, setSort],
  );

  const data = useMemo(
    () => ({
      playlists: getPlaylistItems(fetchRequest?.data?.pages),
      totalElements: getTotalElementsFromPage(fetchRequest?.data?.pages),
      fetchNextPage: fetchRequest?.fetchNextPage,
      filters: getFilters(fetchRequest?.data?.pages),
    }),
    [fetchRequest?.data?.pages, fetchRequest?.fetchNextPage],
  );

  return {
    ...fetchRequest,
    data,
    setQueryData,
    appliedFilters,
    filterActions,
    invalidateQuery,
    setPageSizeParam,
  };
};

const removePlaylistItemFromList = async (playlistId: string) => {
  queryClient.setQueryData<PlaylistsWithFiltersPageQueryData | undefined>([FETCH_PLAYLIST_QUERY_KEY], (data) => {
    if (!data) return;

    return {
      pages: data.pages.map((page: PlaylistsWithFiltersPage) => {
        return {
          ...page,
          data: {
            ...page.data,
            playlists: page.data.playlists.filter((playlist: Playlist) => playlist.id !== playlistId),
          },
        };
      }),
      pageParams: data.pageParams,
    };
  });

  await queryClient.invalidateQueries([FETCH_PLAYLIST_QUERY_KEY]);
};

export { usePlaylists, removePlaylistItemFromList };
