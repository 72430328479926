import { UseQueryResult } from '@tanstack/react-query';
import { TacticId } from 'overlay-generator';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { tacticsInRange } from 'api/routes';
import { HTTPMethod } from 'api/types';

import { queryClient } from '../../config';

type TacticsInRangeApiResponse = { [key in TacticId]: { playlistItemIds: string[]; teamIds: string[] } };
type TacticsInRange = { tactics: TacticId[]; playlistItemsTactics: { [key in string]: TacticId[] } };

const transformTactics = (response: TacticsInRangeApiResponse) => {
  const tacticsList = Object.keys(response) as TacticId[];

  return {
    tactics: tacticsList,
    playlistItemsTactics: tacticsList.reduce(
      (acc, tacticId) => {
        response[tacticId].playlistItemIds.forEach((playlistItemId: string) => {
          if (!acc[playlistItemId]) acc[playlistItemId] = [];
          acc[playlistItemId].push(tacticId);
        });
        return acc;
      },
      {} as { [key in string]: TacticId[] },
    ),
  };
};

const tacticsInRangeQueryRef = {
  all: ['tacticsInRange'],
  playlist: (playlistId: string) => [...tacticsInRangeQueryRef.all, `tacticsInRange-${playlistId}`],
  playlistItems: (playlistId: string, itemIds: string[]) => [
    ...tacticsInRangeQueryRef.playlist(playlistId),
    itemIds.join('-'),
  ],
};

export const invalidatePlaylistItemTacticsInRange = (playlistId: string, itemId: string) =>
  queryClient.invalidateQueries({ queryKey: tacticsInRangeQueryRef.playlistItems(playlistId, [itemId]) });

export const useTacticsInRange = (playlistId: string, data: string[]): UseQueryResult<TacticsInRange> => {
  return useFetchRequest<TacticsInRange>({
    queryRef: tacticsInRangeQueryRef.playlistItems(playlistId, data),
    method: HTTPMethod.POST,
    transformer: transformTactics,
    url: tacticsInRange(playlistId),
    data,
    options: {
      enabled: !!playlistId && !!data.length,
    },
  });
};
