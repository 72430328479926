import SvgIcon, { SvgIconProps } from '../svg-icon';

const IconShare = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M18.116 17.804c0 1.211-.961 2.196-2.141 2.196s-2.141-.985-2.141-2.196a2.24 2.24 0 0 1 .113-.698l-4.462-3.662c-.383.368-.896.595-1.461.595-1.18 0-2.141-.985-2.141-2.196s.96-2.196 2.141-2.196c.565 0 1.078.227 1.461.595l4.393-3.605c-.028-.142-.043-.29-.043-.441 0-1.211.961-2.196 2.141-2.196s2.141.985 2.141 2.196-.961 2.196-2.141 2.196c-.806 0-1.508-.459-1.873-1.135l-4.234 3.475a2.22 2.22 0 0 1 0 2.222l4.363 3.581c.388-.56 1.024-.927 1.744-.927 1.18 0 2.141.986 2.141 2.196z' />
    </SvgIcon>
  );
};

export default IconShare;
