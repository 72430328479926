import { Grid } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonBorderRadius, ButtonSizes, ButtonTypes } from 'shared/components/button';

interface Props {
  children: React.ReactNode;
  onApply: () => void;
  onResetRecordings: () => void;
}

export const ResultsWrapper = ({ children, onApply, onResetRecordings }: Props) => {
  const { t } = useTranslation();

  const handleOnSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      onApply();
    },
    [onApply],
  );

  return (
    <form onSubmit={handleOnSubmit}>
      <Grid container direction={'column'} spacing={1}>
        <Grid item container justifyContent={'flex-end'}>
          <Grid item>
            <Button
              underline
              buttonType={'button'}
              onClick={onResetRecordings}
              radius={ButtonBorderRadius.R4}
              type={ButtonTypes.Reset}
              btnSize={ButtonSizes.S}
            >
              {t('common:actions.reset-filter').toUpperCase()}
            </Button>
          </Grid>
        </Grid>
        <Grid item>{children}</Grid>
        <Grid item container justifyContent={'flex-end'} spacing={1}>
          <Grid item>
            <Button radius={ButtonBorderRadius.R4} type={ButtonTypes.WhiteIronOutlined}>
              {t('common:actions.apply')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
