import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Grid, Autocomplete, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { generatePath } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import Container from 'shared/components/container';
import { FormFormLabel } from 'shared/components/form/form-form-label';
import { PageTitle } from 'shared/components/page-title';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import Spinner from 'shared/components/spinner';

import {
  UploadAutocompleteTextField,
  UploadColumn,
  UploadInput,
  UploadLabelWithLink,
  UploadOutlinedInput,
} from './styled';
import {
  AutocompleteTeamsValue,
  uploadVideoStepOneDefaultValues,
  UploadVideoStepOneFormFields,
  uploadVideoStepOneFormSchema,
  UploadVideoStepOneFormSchema,
  UploadVideoStepOneType,
} from './utils/upload-video-step-one-form';
import { fetchCreateUploadRecording } from '../../tagging-tool/service/taggingRecording.service';
import { fetchTeams } from '../../tagging-tool/service/teams.service';
import { sortArray } from '../../tagging-tool/utility/arrays';

export const UploadVideoStep1Screen = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [fetching, setFetching] = useState<boolean>(false);

  const [teams, setTeams] = useState<Array<AutocompleteTeamsValue>>([]);

  const {
    register,
    control,
    watch,
    setValue,
    handleSubmit: handleFormSubmit,
  } = useForm<UploadVideoStepOneFormSchema>({
    resolver: zodResolver(uploadVideoStepOneFormSchema),
    defaultValues: uploadVideoStepOneDefaultValues,
  });

  const formTypeValue = watch(UploadVideoStepOneFormFields.type);

  const refreshTeamsData = () => {
    fetchTeams().then((res) => {
      if (res.error) {
        return;
      }
      setTeams(
        sortArray({
          data: res.data,
          key: 'name',
          asc: true,
        }).map(({ id: value, name: label }) => ({ value, label })),
      );
    });
  };

  useEffect(() => {
    refreshTeamsData();
  }, []);

  const handleNext = useCallback(
    (formValues: UploadVideoStepOneFormSchema) => {
      setFetching(true);
      // send only the values that are needed
      // (homeTeam and awayTeam are objects used in Autocomplete which have label and value properties,
      // but we only need the value for the request - this value is set in other fields - homeTeamId and awayTeamId)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { homeTeam, awayTeam, videoSource, ...requiredValues } = formValues;

      const uploadRecordingBody = {
        ...requiredValues,
        ...(videoSource && { videoSource }), // don't include empty value
      };

      fetchCreateUploadRecording(uploadRecordingBody).then((res) => {
        setFetching(false);

        if (res.error) {
          return;
        }

        const { id: recordingId } = res.data;
        history.replace(generatePath(routes.TAGGING_TOOL_UPLOAD_STEP_TWO, { recordingId }));
      });
    },
    [history],
  );

  const handleCancel = useCallback(() => history.replace(routes.TAGGING_TOOL), [history]);

  return (
    <SidebarLayout>
      <Container>
        {teams === undefined && <Spinner isFullPage />}

        <form onSubmit={handleFormSubmit(handleNext)}>
          {teams !== undefined && (
            <Grid container justifyContent={'center'}>
              <Grid xs={10}>
                <PageTitle>{t('tagging-tool:upload-video.form-title')}</PageTitle>

                <FormControl required margin='normal' fullWidth>
                  <FormFormLabel>{t('tagging-tool:upload-video.recording-title')}</FormFormLabel>
                  <UploadInput
                    fullWidth
                    size='small'
                    variant='outlined'
                    required
                    {...register(UploadVideoStepOneFormFields.recordingTitle)}
                  />
                </FormControl>

                <FormControl required margin='dense' fullWidth>
                  <Controller
                    name={UploadVideoStepOneFormFields.type}
                    control={control}
                    render={({ field }) => (
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value={UploadVideoStepOneType.game}
                          control={<Radio />}
                          label={t('tagging-tool:upload-video.type-match')}
                        />
                        <FormControlLabel
                          value={UploadVideoStepOneType.training}
                          control={<Radio />}
                          label={t('tagging-tool:upload-video.type-training')}
                        />
                      </RadioGroup>
                    )}
                  ></Controller>
                </FormControl>

                <UploadColumn>
                  <FormControl required margin='normal' fullWidth>
                    <FormFormLabel>{t('tagging-tool:upload-video.country')}</FormFormLabel>
                    <UploadInput required size='small' {...register(UploadVideoStepOneFormFields.country)} />
                  </FormControl>
                  <FormControl required margin='normal' fullWidth>
                    <FormFormLabel>{t('tagging-tool:upload-video.city')}</FormFormLabel>
                    <UploadInput required size='small' {...register(UploadVideoStepOneFormFields.city)} />
                  </FormControl>
                </UploadColumn>
                <FormControl required margin='normal' fullWidth>
                  <FormFormLabel>{t('tagging-tool:upload-video.stadium')}</FormFormLabel>
                  <UploadInput required size='small' {...register(UploadVideoStepOneFormFields.stadium)} />
                </FormControl>

                <FormControl required fullWidth margin='normal'>
                  <FormFormLabel>{t('tagging-tool:upload-video.date')}</FormFormLabel>
                  <UploadOutlinedInput
                    required
                    size='small'
                    type='date'
                    {...register(UploadVideoStepOneFormFields.date)}
                  />
                </FormControl>

                {formTypeValue === UploadVideoStepOneType.game && (
                  <>
                    <FormControl required margin='normal' fullWidth>
                      <FormFormLabel>{t('tagging-tool:upload-video.competition')}</FormFormLabel>
                      <UploadInput
                        fullWidth
                        size='small'
                        variant='outlined'
                        required
                        {...register(UploadVideoStepOneFormFields.competition)}
                      />
                    </FormControl>

                    <FormControl required margin='normal' fullWidth>
                      <UploadLabelWithLink>
                        <FormFormLabel>{t('tagging-tool:upload-video.home-team')}</FormFormLabel>
                      </UploadLabelWithLink>
                      <Controller
                        name={UploadVideoStepOneFormFields.homeTeam}
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            disablePortal
                            id={UploadVideoStepOneFormFields.homeTeam}
                            options={teams}
                            loadingText={t('common:pagination.loading')}
                            {...field}
                            onChange={(event, value) => {
                              if (!value) return;
                              setValue(UploadVideoStepOneFormFields.homeTeamId, value.value);
                              return setValue(UploadVideoStepOneFormFields.homeTeam, value);
                            }}
                            renderInput={(params) => (
                              <UploadAutocompleteTextField
                                {...params}
                                size='small'
                                required
                                placeholder={t('tagging-tool:upload-video.autocomplete-placeholder')}
                              />
                            )}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl required margin='normal' fullWidth>
                      <UploadLabelWithLink>
                        <FormFormLabel>{t('tagging-tool:upload-video.away-team')}</FormFormLabel>
                      </UploadLabelWithLink>
                      <Controller
                        name={UploadVideoStepOneFormFields.awayTeam}
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            disablePortal
                            id={UploadVideoStepOneFormFields.awayTeam}
                            options={teams}
                            loadingText={t('common:pagination.loading')}
                            {...field}
                            onChange={(event, value) => {
                              if (!value) return;
                              setValue(UploadVideoStepOneFormFields.awayTeamId, value.value);
                              return setValue(UploadVideoStepOneFormFields.awayTeam, value);
                            }}
                            renderInput={(params) => (
                              <UploadAutocompleteTextField
                                {...params}
                                size='small'
                                required
                                placeholder={t('tagging-tool:upload-video.autocomplete-placeholder')}
                              />
                            )}
                          />
                        )}
                      />
                    </FormControl>
                  </>
                )}

                <FormControl margin='normal' fullWidth>
                  <FormFormLabel>{t('tagging-tool:upload-video.video-source')}</FormFormLabel>
                  <UploadInput size='small' {...register(UploadVideoStepOneFormFields.videoSource)} />
                </FormControl>
              </Grid>
            </Grid>
          )}
          <Grid display='flex' gap={2} justifyContent='center' marginTop={2}>
            <Button size='large' color='secondary' variant='outlined' onClick={handleCancel} disabled={fetching}>
              {t('common:actions.cancel')}
            </Button>
            <Button size='large' variant='contained' type='submit' disabled={fetching}>
              {t('common:actions.next')}
            </Button>
          </Grid>
        </form>
      </Container>
    </SidebarLayout>
  );
};
