import { atomFamily, useRecoilValue, useSetRecoilState } from 'recoil';

import { SortDirection } from 'shared/types/filters/types';

import { PlaylistsFilters, PlaylistSortOptions } from './types';

export const INITIAL_PLAYLISTS_FILTERS: PlaylistsFilters = {
  competition: [],
  from: '',
  name: '',
  to: '',
  type: [],
  sort: PlaylistSortOptions.UPDATED_AT,
  sortDirection: SortDirection.DESC,
};

const playlistFilters = atomFamily<PlaylistsFilters, string>({
  key: 'playlists-filters',
  default: INITIAL_PLAYLISTS_FILTERS,
});

export const usePlaylistsFilters = (id: string) => {
  return useRecoilValue(playlistFilters(id));
};

export const useSetPlaylistsFilters = (id: string) => {
  return useSetRecoilState(playlistFilters(id));
};
