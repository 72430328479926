import { atomFamily, useRecoilValue, useSetRecoilState } from 'recoil';

const bulkMode = atomFamily<boolean, string>({
  key: 'bulk-mode',
  default: false,
});

const bulkSelectedItems = atomFamily<string[], string>({
  key: 'selected-items-bulk-mode',
  default: [],
});

export const useIsBulkModeActive = (id: string) => {
  return useRecoilValue(bulkMode(id));
};

export const useSetIsBulkModeActive = (id: string) => {
  return useSetRecoilState(bulkMode(id));
};

export const useBulkSelectedItems = (id: string) => {
  return useRecoilValue(bulkSelectedItems(id));
};

export const useSetBulkSelectedItems = (id: string) => {
  return useSetRecoilState(bulkSelectedItems(id));
};
