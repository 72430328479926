import { useCallback } from 'react';

import { useAddPlayersToGame } from '../../../../../../api/games/use-add-players-to-game';
import { useDeleteMetadataGamePlayers } from '../../../../../../api/games/use-delete-metadata-game-players';
import { useInvalidateMetadataGame } from '../../../../../../api/games/use-metadata-game';
import { useUpdateMetadataGame } from '../../../../../../api/games/use-update-metadata-game';
import { MetadataGame } from '../../../../../../types/games';
import { MatchEndType } from '../../../../../../types/shared';
import { GameInfoSchema } from '../../../forms';
import { NewGameTeam } from '../../../types';

export const useUpdateNewGameWithPlayers = (onSuccess: (game: MetadataGame) => void) => {
  const { update } = useUpdateMetadataGame();
  const { create: addPlayersToGame } = useAddPlayersToGame();
  const { deleteMetadataGamePlayers } = useDeleteMetadataGamePlayers();
  const invalidateMetadataGame = useInvalidateMetadataGame();

  const handleAddNewPlayerToGame = useCallback(
    (gameId: string, homeTeam: NewGameTeam, awayTeam: NewGameTeam, onSuccess: (data: any) => void) =>
      addPlayersToGame({
        gameId,
        players: [
          ...homeTeam.players.map((p) => ({
            jersey_number: p.jerseyNumber ?? 0,
            side: p.side,
            position: p.position,
            player_uuid: p.id,
            team_uuid: homeTeam.team.id,
          })),
          ...awayTeam.players.map((p) => ({
            jersey_number: p.jerseyNumber ?? 0,
            side: p.side,
            position: p.position,
            player_uuid: p.id,
            team_uuid: awayTeam.team.id,
          })),
        ],
        onSuccess: (data: MetadataGame) => {
          onSuccess(data);
        },
      }),
    [addPlayersToGame],
  );

  return useCallback(
    (gameId: string, gameInfo: GameInfoSchema, homeTeam: NewGameTeam, awayTeam: NewGameTeam) => {
      update({
        id: gameId,
        data: {
          name: gameInfo.name,
          duration: gameInfo.duration,
          home_team_score: gameInfo.scoreHomeTeam,
          away_team_score: gameInfo.scoreOpponentTeam,
          pitch_length: gameInfo.pitchLength,
          pitch_width: gameInfo.pitchWidth,
          home_penalty_score: 0,
          away_penalty_score: 0,
          match_end_type: MatchEndType.NORMAL_TIME,
          home_team_uuid: homeTeam?.team.id ?? '',
          away_team_uuid: awayTeam?.team.id ?? '',
          stage_uuid: gameInfo.stageId,
          ...(gameInfo?.homeCoach?.id ? { home_coach_uuid: gameInfo.homeCoach.id } : {}),
          ...(gameInfo?.awayCoach?.id ? { away_coach_uuid: gameInfo.awayCoach.id } : {}),
          ...(gameInfo.date ? { date: gameInfo.date } : {}),
          ...(gameInfo.venue ? { venue_uuid: gameInfo.venue.id } : {}),
        },
        onSuccess: () => {
          invalidateMetadataGame(gameId);
        },
      });

      deleteMetadataGamePlayers({
        data: { gameId: gameId },
        onSuccess: () => handleAddNewPlayerToGame(gameId, homeTeam, awayTeam, onSuccess),
      });
    },
    [invalidateMetadataGame, handleAddNewPlayerToGame, deleteMetadataGamePlayers, update, onSuccess],
  );
};
