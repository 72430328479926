import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import Container from 'shared/components/container';
import NotFound from 'shared/components/not-found';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import { SwitchEnvironment } from 'shared/components/switch-environment';
import { VideoPlayerStateProvider } from 'shared/components/video-player';
import usePageStateMachine from 'shared/hooks/use-page-state-machine';
import { groupTagsByType } from 'shared/utils/group-tags-by-type';

import { UseTimelineMatchTaggingData } from './api/use-tactical-analysis-data/types';
import { TacticalAnalysis } from './components/tactical-analysis';
import { AddToPlaylistMenuStateProvider } from './components/tactical-analysis/add-to-playlist-menu/add-to-playlist-menu-state';
import { TacticalAnalysisPageSkeleton } from './components/tactical-analysis/tactical-analysis-page-skeleton';
import { useTacticalAnalysisPage } from './hooks/use-tactical-analysis-page';
import { useTimelineInitialTime } from './hooks/use-timeline-initial-time';

interface StaticContext {
  statusCode?: number | undefined;
}

interface State {
  initialStartTimeFromClip?: number;
}

interface RouteParams {
  id: string;
}

const NotFoundContainer = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: Colors.background,
}));

export const TacticalAnalysisContainer = (props: RouteComponentProps<RouteParams, StaticContext, State>) => {
  const {
    match: {
      params: { id },
    },
    location: { state },
  } = props;
  const { t } = useTranslation();
  const initialTimePreset = useTimelineInitialTime(id);

  const { current, data, PAGE_STATES, isInvalidClient } = usePageStateMachine<UseTimelineMatchTaggingData>(() =>
    useTacticalAnalysisPage(id),
  );

  const initialStartTimeFromClip = useMemo(
    () => state?.initialStartTimeFromClip || initialTimePreset,
    [initialTimePreset, state?.initialStartTimeFromClip],
  );

  if (isInvalidClient && data?.matchWithEpisodes?.match.clientIds) {
    return <SwitchEnvironment resourceClientsIds={data.matchWithEpisodes.match.clientIds} />;
  }

  return (
    <SidebarLayout>
      <Container fullScreen>
        {current.matches(PAGE_STATES.LOADING) && <TacticalAnalysisPageSkeleton />}
        {current.matches(PAGE_STATES.MISSING_DATA) ||
          (current.matches(PAGE_STATES.ERROR) && (
            <NotFoundContainer>
              <NotFound header={t('timeline:not-found')} showContactLink />
            </NotFoundContainer>
          ))}
        {current.matches(PAGE_STATES.READY) &&
          data?.matchWithEpisodes &&
          data?.taggingEvents &&
          data?.playlistItems && (
            <VideoPlayerStateProvider
              playerId={`timeline-${data.matchWithEpisodes.match.id}`}
              playlistItems={data.playlistItems}
              playingMode={data.initialPlayingMode}
              initialStartTime={initialStartTimeFromClip}
            >
              <AddToPlaylistMenuStateProvider id={'timeline-playlist-menu'}>
                <TacticalAnalysis
                  episodes={data.matchWithEpisodes.episodes}
                  match={data.matchWithEpisodes.match}
                  recordingId={id}
                  groupedTags={groupTagsByType(data.taggingEvents.tags)}
                />
              </AddToPlaylistMenuStateProvider>
            </VideoPlayerStateProvider>
          )}
      </Container>
    </SidebarLayout>
  );
};
