import classNames from 'classnames';

import Button, { ButtonTypes } from 'shared/components/button';
import IconPlus from 'shared/components/icons/icon-plus';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';

import styles from './FilterAddButton.module.scss';

interface Props {
  disabled?: boolean;
  label: string;
  onClick: () => void;
}

const FilterAddButton = ({ label, onClick, disabled = false }: Props) => {
  return (
    <Button
      disabled={disabled}
      type={ButtonTypes.Simple}
      className={classNames(styles.addButton, { [styles.disabled]: disabled })}
      onClick={onClick}
    >
      <>
        <div className={styles.addIcon}>
          <IconPlus color={IconColors.blue} size={IconSizes.medium} />
        </div>
        <span>{label}</span>
      </>
    </Button>
  );
};

export default FilterAddButton;
