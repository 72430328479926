import { QueryFunctionContext, useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { queryClient } from 'api/config';
import { useBackendApi } from 'api/hooks/useBackendApi';
import { liveSessionsWithFiltersUrl } from 'api/routes';
import {
  LiveSessionsFilters,
  useLiveSessionsFilters,
} from 'api/tagging-tool/use-live-sessions-with-filters/hooks/use-live-sessions-filters';
import { HTTPMethod } from 'api/types';
import { Recording } from 'shared/types/recording/types';

import { transformLiveSessions } from './transformer';
import { LiveSessionsWithFiltersPage } from './types';

const liveSessionsWithFiltersQueryKey = 'liveSessionsWithFilters';

const getLiveSessionsItems = (pages: LiveSessionsWithFiltersPage[] | undefined): Recording[] => {
  return pages
    ? pages.reduce((acc: Recording[], page: any) => {
        return acc.concat(page.data.liveSessions);
      }, [])
    : [];
};

const getTotalElementsFromPage = (pages: LiveSessionsWithFiltersPage[] | undefined): number => {
  if (!pages || pages.length === 0) return 0;

  const lastPage = pages[pages.length - 1];

  return lastPage.data.page.totalElements;
};

export const invalidateLiveSessionsWithFiltersQuery = () => {
  return queryClient.invalidateQueries([liveSessionsWithFiltersQueryKey]);
};

export const useLiveSessionsWithFilters = () => {
  const { filters, updateFilters } = useLiveSessionsFilters();
  const url = (options: QueryFunctionContext<(string | LiveSessionsFilters)[], any>) =>
    liveSessionsWithFiltersUrl({ ...filters, page: options.pageParam });
  const fetchRequest = useInfiniteQuery(
    ['taggingRecordings', filters],
    (options) => {
      return useBackendApi(url(options), HTTPMethod.GET, transformLiveSessions);
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
    },
  );
  const data = useMemo(
    () => ({
      liveSessions: getLiveSessionsItems(fetchRequest?.data?.pages),
      totalElements: getTotalElementsFromPage(fetchRequest?.data?.pages),
      fetchNextPage: fetchRequest?.fetchNextPage,
    }),
    [fetchRequest?.data?.pages, fetchRequest?.fetchNextPage],
  );

  return { ...fetchRequest, data, updateFilters, filters };
};
