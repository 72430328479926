import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { clientBaseUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { Client } from 'shared/types';

import { transformClient } from '../transformer';
import { ClientApiResponse } from '../types';

export const useCreateClient = () => {
  const { mutate, isLoading, isError, isSuccess } = useMutationRequest<ClientApiResponse, Client>({
    type: HTTPMethod.POST,
    errorMessage: 'Error creating a new client',
    successMessage: 'New client created',
    transformer: transformClient,
  });

  const createClient = ({ data, onSuccess = () => {} }: { data: Client; onSuccess?: (res: Client) => void }) => {
    mutate({ url: clientBaseUrl, data }, { onSuccess: (res) => onSuccess && onSuccess(res) });
  };

  return { createClient, isLoading, isError, isSuccess };
};
