import SvgIcon, { SvgIconProps } from '../svg-icon';

const IconSearch = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M 15.129 13.879 h -0.658 l -0.233 -0.225 c 0.817 -0.95 1.308 -2.183 1.308 -3.525 a 5.42 5.42 0 0 0 -5.417 -5.417 a 5.42 5.42 0 0 0 -5.417 5.417 a 5.42 5.42 0 0 0 5.417 5.417 c 1.342 0 2.575 -0.492 3.525 -1.308 l 0.225 0.233 v 0.658 l 4.167 4.158 l 1.242 -1.242 l -4.158 -4.167 z m -5 0 c -2.075 0 -3.75 -1.675 -3.75 -3.75 s 1.675 -3.75 3.75 -3.75 s 3.75 1.675 3.75 3.75 s -1.675 3.75 -3.75 3.75 z' />
    </SvgIcon>
  );
};
export default IconSearch;
