import { Stack, Typography } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import { Colors, fontSizes } from 'kognia-ui';
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { useDeletePlaylistItem } from 'api/playlist/useDeletePlaylistItem';
import { useDuplicatePlaylistItems } from 'api/playlist/useDuplicatePlaylistItems';
import { useUpdatePlaylistItem } from 'api/playlist/useUpdatePlaylistItem';
import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { routes } from 'kognia/router/routes';
import { CheckboxWithColor } from 'shared/components/checkbox-with-color';
import ConfirmPopoverDialog from 'shared/components/confirm-popover-dialog';
import { IconTimeline } from 'shared/components/icons';
import IconCopy from 'shared/components/icons/icon-copy';
import { IconCopyTo } from 'shared/components/icons/icon-copy-to';
import IconDelete from 'shared/components/icons/icon-delete';
import { IconDownload } from 'shared/components/icons/icon-download';
import IconEdit from 'shared/components/icons/icon-edit';
import { IconOverlays } from 'shared/components/icons/icon-overlays';
import IconPlay from 'shared/components/icons/icon-play';
import IconTime from 'shared/components/icons/icon-time';
import IconTrim from 'shared/components/icons/icon-trim';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';
import { useIsCurrentPlaylistItem, useVideoPlayerActions } from 'shared/components/video-player';
import { useVideoPlayerId } from 'shared/components/video-player/hooks';
import { isFullMatchVideo } from 'shared/components/video-player/is-full-match-video';
import { useSetCurrentTime } from 'shared/components/video-player/state/atoms/hooks';
import { PlaylistItemType } from 'shared/components/video-player/types';
import { useSetIsOverlayPanelOpen } from 'shared/components/video-player/video-player-component/hooks/overlay-panel';
import { useFeatureFlag } from 'shared/contexts/app-state';
import { Playlist } from 'shared/types';
import { secondsAsDuration } from 'shared/utils/seconds-as-duration';

import { CopyTo, CopyToPlaylistItemDetails } from './copy-to';
import { PlayButton } from './play-button';
import ProgressBar from './progress-bar';
import RenameDialog from './rename-dialog';
import {
  CounterBadge,
  PlaylistItemContent,
  PlaylistItemContentBottomRow,
  PlaylistItemContentTopRow,
  PlaylistItemContentWrapper,
  PlaylistItemWrapper,
} from './styled';
import { useMapVideos } from '../../../util/map-videos';
import { DownloadModal } from '../../download-modal';

interface Props {
  isDisabled?: boolean;
  isEditing?: boolean;
  playlist: Playlist;
  playlistItem: PlaylistItemType;
  setTrimmingPlaylistItem: (id: string) => void;
  videoIndex: number;
  enabledBulkMode?: boolean;
  onSelect?: (value: string) => void;
  isChecked?: boolean;
  onPlaylistItemDelete?: (id: string) => void;
  onPlaylistItemDuplicate?: () => void;
}

const PlaylistItemElement = ({
  isDisabled = false,
  isEditing = false,
  playlist,
  playlistItem,
  setTrimmingPlaylistItem,
  videoIndex,
  enabledBulkMode = false,
  onSelect = () => {},
  isChecked = false,
  onPlaylistItemDelete,
  onPlaylistItemDuplicate,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const mapVideos = useMapVideos();
  const playerId = useVideoPlayerId();
  const setCurrentTime = useSetCurrentTime(playerId);
  const isCurrentPlaylistItem = useIsCurrentPlaylistItem(playlistItem.id);
  const actions = useVideoPlayerActions();

  const [isDeleted, setIsDeleted] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isOpenRenameDialog, setIsOpenRenameDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [copyToAnchor, setCopyToAnchor] = useState<HTMLElement | null>(null);
  const setIsOverlayPanelOpenState = useSetIsOverlayPanelOpen(playerId);

  const { duplicatePlaylistItems } = useDuplicatePlaylistItems(playlist.id);
  const { deletePlaylistItem, isLoading, isError } = useDeletePlaylistItem(playlist.id);
  const downloadPlaylistFeatureFlag = useFeatureFlag(FEATURE_FLAG.DOWNLOAD_PLAYLIST);
  const customOverlaysFeatureFlag = useFeatureFlag(FEATURE_FLAG.CUSTOM_OVERLAYS);
  const kebabRef = useRef<HTMLDivElement | null>(null);
  const isEditingMode = Boolean(isOpenDeleteDialog || isOpenRenameDialog || isEditing);

  const { updatePlaylistItem } = useUpdatePlaylistItem(playlist.id);

  const handleUpdatePlaylistItem = useCallback(
    (playlistItemId: string, data: { [key: string]: any }) => {
      updatePlaylistItem(playlistItemId, data, (playlist: Playlist) => {
        actions.updatePlaylistItems(mapVideos(playlist));
      });
    },
    [actions, mapVideos, updatePlaylistItem],
  );

  const handleRenameDialogClose = useCallback(() => {
    actions.resumeStandBy();
  }, [actions]);

  const playlistItemVideoSource = playlistItem.videoTypes.find((videoType) =>
    isFullMatchVideo(videoType.playingMode.mode),
  )?.videoSources[0];

  const playlistItemCopyToDetails: CopyToPlaylistItemDetails = {
    startTime: playlistItemVideoSource?.startTime ?? 0,
    endTime: playlistItemVideoSource?.endTime ?? 0,
    name: playlistItem.name ?? '',
    recordingId: playlistItem.recordingId ?? '',
    fundamentalsSelected: playlistItem.fundamentalsSelected,
  };

  const handleDuplicate = useCallback(
    () =>
      duplicatePlaylistItems({
        playlistItemIds: [playlistItem.id],
        options: {
          onSuccess: (data: Playlist) => {
            onPlaylistItemDuplicate && onPlaylistItemDuplicate();
            actions.replacePlaylistItems(mapVideos(data));
            actions.resumeStandBy();
          },
        },
      }),
    [duplicatePlaylistItems, playlistItem.id, onPlaylistItemDuplicate, actions, mapVideos],
  );

  const handleCloseCopyToDialog = useCallback(() => {
    actions.resumeStandBy();
    setCopyToAnchor(null);
  }, [actions]);

  const handleOpenCopyToDialog = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setCopyToAnchor(event.currentTarget);
  }, []);

  const handleCloseDownloadModal = useCallback(() => {
    setIsDownloadModalOpen(false);
    actions.resumeStandBy();
  }, [actions]);

  const controls = useAnimation();

  const handleConfirmDelete = useCallback(async () => {
    setIsDeleted(true);
    await controls.start({ opacity: 0 });
    deletePlaylistItem(playlistItem.id, () => {
      actions.resumeStandBy();
      actions.removePlaylistItem(playlistItem.id);

      if (onPlaylistItemDelete) {
        onPlaylistItemDelete(playlistItem.id);
      }
    });

    if (isError) {
      setIsDeleted(false);
    }
  }, [actions, controls, deletePlaylistItem, isError, onPlaylistItemDelete, playlistItem.id]);

  const handleGoToTimeline = useCallback(
    (event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      event && event.stopPropagation();
      const path = generatePath(routes.PERFORMANCE_REPORT_TIMELINE, { id: playlistItem.recordingId });

      history.push(path, { initialStartTimeFromClip: playlistItemVideoSource?.startTime, playlist });
    },
    [history, playlist, playlistItem.recordingId, playlistItemVideoSource?.startTime],
  );

  const handleSetPlaylistItem = useCallback(
    (autoPlay = true) => {
      setCurrentTime(0);
      actions.setPlaylistItem(playlistItem.id, autoPlay);
    },
    [setCurrentTime, actions, playlistItem.id],
  );

  const handleItemClick = useCallback(
    (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event && event.stopPropagation();
      if (enabledBulkMode) {
        return onSelect(playlistItem.id);
      }

      return handleSetPlaylistItem(true);
    },
    [enabledBulkMode, handleSetPlaylistItem, onSelect, playlistItem.id],
  );

  const menuOptions: MenuListOption[] = useMemo(() => {
    const overlayOption = {
      displayText: t('video-player:overlays.edit-overlays'),
      icon: <IconOverlays size={IconSizes.small} />,
      onClick: () => {
        handleSetPlaylistItem(false);
        setIsOverlayPanelOpenState(true);
      },
    };
    const options = [
      {
        displayText: t('common:actions.rename'),
        icon: <IconEdit size={IconSizes.small} />,
        onClick: () => {
          setIsOpenRenameDialog(true);
          actions.handleStandBy();
        },
      },
      {
        displayText: t('common:actions.trim'),
        icon: <IconTrim size={IconSizes.small} />,
        onClick: () => {
          setTrimmingPlaylistItem(playlistItem.id);
        },
      },
      {
        displayText: t('common:actions.download'),
        icon: <IconDownload size={IconSizes.small} />,
        onClick: () => {
          setIsDownloadModalOpen(true);
          actions.handleStandBy();
        },
        isHidden: !downloadPlaylistFeatureFlag,
      },
      {
        displayText: t('common:actions.duplicate'),
        icon: <IconCopy size={IconSizes.small} />,
        onClick: () => handleDuplicate(),
      },
      {
        displayText: t('common:actions.copy-to'),
        icon: <IconCopyTo size={IconSizes.small} />,
        onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
          handleOpenCopyToDialog(event);
          actions.handleStandBy();
        },
        avoidCloseOnClick: true,
      },
      {
        displayText: t('common:actions.remove'),
        icon: <IconDelete size={IconSizes.small} />,
        onClick: () => {
          setIsOpenDeleteDialog(true);
          actions.handleStandBy();
        },
      },
      {
        displayText: '',
      },
      {
        displayText: t('playlist-detail:go-to-timeline'),
        icon: <IconTimeline size={IconSizes.small} />,
        onClick: handleGoToTimeline,
      },
    ];
    return customOverlaysFeatureFlag ? [overlayOption, ...options] : options;
  }, [
    t,
    customOverlaysFeatureFlag,
    handleSetPlaylistItem,
    setIsOverlayPanelOpenState,
    downloadPlaylistFeatureFlag,
    handleGoToTimeline,
    actions,
    setTrimmingPlaylistItem,
    playlistItem.id,
    handleDuplicate,
    handleOpenCopyToDialog,
  ]);

  const durationIconColor = isCurrentPlaylistItem ? IconColors.white : IconColors.storm;
  const durationColor = isCurrentPlaylistItem ? Colors.white : Colors.storm;

  return (
    <motion.div
      layout
      animate={controls}
      initial={{ opacity: 1 }}
      transition={{ delay: 0.2, duration: 0.5, type: 'tween' }}
    >
      <PlaylistItemWrapper
        isCurrent={isCurrentPlaylistItem && !isLoading}
        isDeleting={isDeleted}
        isEditing={isEditingMode}
        isDisabled={isDisabled}
        onClick={handleItemClick}
        direction='row'
        justifyContent='space-between'
        title={`${playlistItem.name} | ${playlistItem.recordingName} (${playlistItem.recordingMatchday})`}
      >
        <PlaylistItemContentWrapper>
          {isCurrentPlaylistItem && !isDisabled && !isDeleted && <ProgressBar isEditing={Boolean(isEditingMode)} />}
          <PlaylistItemContent>
            <PlaylistItemContentTopRow>
              {enabledBulkMode ? (
                <CheckboxWithColor
                  sx={{ padding: '0 !important' }}
                  checked={isChecked}
                  customColor={IconColors.primary}
                />
              ) : null}
              {isCurrentPlaylistItem && !enabledBulkMode ? (
                <PlayButton playlistItem={playlistItem} />
              ) : enabledBulkMode ? null : (
                <IconPlay
                  onClick={() => handleSetPlaylistItem(true)}
                  isButton
                  size={IconSizes.small}
                  color={IconColors.primary}
                />
              )}
              <CounterBadge data-element-name='counter'>{(videoIndex + 1).toString().padStart(3, '0')}</CounterBadge>
              <Typography
                data-element-name='duration'
                fontSize={fontSizes.xxSmall}
                color={durationColor}
                display='flex'
                alignItems='center'
              >
                <IconTime size={IconSizes.small} color={durationIconColor} />
                {secondsAsDuration(playlistItem.duration, false)}
              </Typography>
            </PlaylistItemContentTopRow>
            <PlaylistItemContentBottomRow>
              <Typography
                color={isCurrentPlaylistItem ? Colors.white : Colors.night}
                fontSize={fontSizes.xxSmall}
                whiteSpace='nowrap'
                textOverflow='ellipsis'
                overflow='hidden'
                data-element-name='name-text'
              >
                {playlistItem.name}
              </Typography>
              <Typography
                color={isCurrentPlaylistItem ? Colors.white : Colors.storm}
                fontSize={fontSizes.xxSmall}
                whiteSpace='nowrap'
                textOverflow='ellipsis'
                overflow='hidden'
                data-element-name='name-text'
              >
                {`${playlistItem.recordingName} (${playlistItem.recordingMatchday})`}
              </Typography>
            </PlaylistItemContentBottomRow>
          </PlaylistItemContent>
          {!enabledBulkMode ? (
            <Stack>
              <KebabMenu
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                ref={kebabRef}
                options={menuOptions}
                disableButtonPadding
              />
            </Stack>
          ) : null}
        </PlaylistItemContentWrapper>
      </PlaylistItemWrapper>

      <ConfirmPopoverDialog
        anchorEl={kebabRef.current}
        cancelLabel={t('common:actions.cancel')}
        confirmLabel={t('common:actions.remove')}
        description={t('playlist-detail:confirm-permanently-remove-clip')}
        isOpen={isOpenDeleteDialog}
        onConfirm={handleConfirmDelete}
        setIsOpen={setIsOpenDeleteDialog}
        type={'warning'}
      />
      <RenameDialog
        onClose={handleRenameDialogClose}
        videoIndex={videoIndex + 1}
        updatePlaylistItem={handleUpdatePlaylistItem}
        setIsOpen={setIsOpenRenameDialog}
        playlistItem={playlistItem}
        isOpen={isOpenRenameDialog}
      />
      {isDownloadModalOpen ? (
        <DownloadModal
          playlistId={playlist.id}
          isPlaylistDownload={false}
          itemsToDownload={[playlistItem.id]}
          onClose={handleCloseDownloadModal}
        />
      ) : null}

      <CopyTo playlistItems={[playlistItemCopyToDetails]} onClose={handleCloseCopyToDialog} anchorEl={copyToAnchor} />
    </motion.div>
  );
};

export const PlaylistItem = memo(PlaylistItemElement);
