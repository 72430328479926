import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { usePlaylistMenuId } from '../';
import {
  useCleanSelection,
  useRemoveClipFromSelection,
} from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows/atoms';
import { playlistMenuAtoms, PlaylistMenuItem } from '../atoms';

export const generatePlaylistMenuItemId = (playlistMenuId: string, clipId: string) => {
  return `${playlistMenuId}-${clipId}`;
};

export const useRemovePlaylistMenuItem = (clipId: string) => {
  const removeClipFromSelection = useRemoveClipFromSelection();

  return useCallback(() => {
    removeClipFromSelection(clipId);
  }, []);
};

export const useRemoveMultiplePlaylistMenuItem = () => {
  const playlistMenuId = usePlaylistMenuId();
  const resetSelection = useCleanSelection();
  const setPlaylistMenuItemIds = useSetRecoilState(playlistMenuAtoms.playlistItemsSelectorIds(playlistMenuId));

  return useCallback((clipsIdsToRemove: string[]) => {
    resetSelection();
    setPlaylistMenuItemIds(clipsIdsToRemove);
  }, []);
};

export const useAddMultiplePlaylistItems = () => {
  const playlistMenuId = usePlaylistMenuId();
  const setPlaylistMenuItemIds = useSetRecoilState(playlistMenuAtoms.playlistItemsSelector(playlistMenuId));

  return useCallback((clips: PlaylistMenuItem[]) => {
    setPlaylistMenuItemIds(clips);
  }, []);
};

export const usePlaylistMenuItems = (): PlaylistMenuItem[] => {
  const playlistMenuId = usePlaylistMenuId();

  return useRecoilValue(playlistMenuAtoms.playlistItemsSelector(playlistMenuId));
};
