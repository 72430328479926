import { Chip, Grid } from '@mui/material';
import { usePlayerContext } from '@vime/react';
import React, { useMemo } from 'react';

import { round } from 'shared/utils/round';

import {
  useCurrentTime,
  useCurrentVideoSourceTime,
  useDuration,
  usePlayerCurrentSource,
  usePlaylistCurrentPlaylistItemId,
  usePlaylistItems,
  useVideoPlayerPlayingMode,
  useVideoPlayerRef,
  useVideoPlayerState,
} from '../../../../../hooks';

export const VideoPlayerTab = () => {
  const videoPlayerRef = useVideoPlayerRef();
  const [seeking] = usePlayerContext(videoPlayerRef, 'seeking', false);
  const [buffering] = usePlayerContext(videoPlayerRef, 'buffering', false);
  const videoSource = usePlayerCurrentSource();
  const playlistItemId = usePlaylistCurrentPlaylistItemId();
  const playlistItems = usePlaylistItems();
  const playingMode = useVideoPlayerPlayingMode();
  const duration = useDuration();
  const currentTime = useCurrentTime();
  const currentVideoSourceTime = useCurrentVideoSourceTime();
  const { state, readyState } = useVideoPlayerState();

  const videoSourceStartTime = videoSource?.startTime ?? 0;
  const videoSourceEndTime = videoSource?.endTime ?? 0;

  const shortcuts = useMemo(
    () => [
      { title: 'SPACE', value: 'play/pause' },
      { title: 'Arrow Right', value: '+5s' },
      { title: 'Arrow Left', value: '-5s' },
      { title: 'ctrl + Arrow Right', value: 'Next playlist item' },
      { title: 'ctrl + Arrow Left', value: 'Previous playlist item' },
      { title: 'alt + d', value: 'Hide and show debug (only admins)' },
      { title: 'alt + o', value: 'Hide and show custom overlays (only admins)' },
    ],
    [],
  );

  const values = useMemo(
    () => [
      { title: 'Current Time', value: currentTime ?? 0 },
      { title: 'currentVideoSourceTime', value: currentVideoSourceTime ?? 0 },
      { title: 'Percent', value: duration > 0 ? round((100 * currentTime) / duration) : 0 },
      {
        title: 'VideoSource Percent',
        value: round((100 * currentTime) / (videoSourceEndTime - videoSourceStartTime)),
      },
      { title: 'Start time', value: videoSourceStartTime },
      { title: 'End time', value: videoSourceEndTime },
      { title: 'Seeking', value: seeking ? 'true' : 'false' },
      { title: 'Buffering', value: buffering ? 'true' : 'false' },
      { title: 'Source', value: videoSource?.src },
      { title: 'Source Download', value: videoSource?.srcDownload },
      { title: 'Playing mode', value: playingMode.mode },
      { title: 'Playlist Id', value: playlistItemId },
      {
        title: 'Playlist index playing',
        value: playlistItems.findIndex((playlistItem) => playlistItem.id === playlistItemId),
      },
      { title: 'State', value: <Chip size='small' label={`${state}`} color='default' /> },
      { title: 'Ready state', value: <Chip size='small' label={readyState} color='default' /> },
    ],
    [
      buffering,
      currentTime,
      currentVideoSourceTime,
      duration,
      playingMode,
      playlistItemId,
      playlistItems,
      readyState,
      seeking,
      state,
      videoSource?.src,
      videoSource?.srcDownload,
      videoSourceEndTime,
      videoSourceStartTime,
    ],
  );

  if (!videoSource) return null;

  return (
    <>
      <h4>Player info</h4>
      <Grid container spacing={1}>
        {values.map((value) => (
          <React.Fragment key={value.title}>
            <Grid item xs={4}>
              <strong>{value.title}</strong>
            </Grid>
            <Grid item xs={8}>
              {value.value}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <br />
      <h4>Shortcuts</h4>
      <Grid container spacing={1}>
        {shortcuts.map((value) => (
          <React.Fragment key={value.title}>
            <Grid item xs={4}>
              <strong>{value.title}</strong>
            </Grid>
            <Grid item xs={8}>
              {value.value}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </>
  );
};
