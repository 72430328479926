import SvgIcon, { SvgIconProps } from '../svg-icon';

export const IconCopyTo = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <g fillRule='evenodd'>
        <path d='M5.986 5.055h5.117a.63.63 0 0 1 .446.179l.001.001 1.583 1.575h6.27c.73 0 1.326.58 1.326 1.278v7.798c0 .698-.597 1.278-1.326 1.278h-.068v-.001h-7.95v-1.191h8.018c.055 0 .089-.03.089-.086V8.088c0-.056-.034-.086-.089-.086h-6.535a.63.63 0 0 1-.445-.187l-1.569-1.567H5.986c-.055 0-.089.03-.089.086v2.584H4.662V6.247c.047-.659.624-1.191 1.323-1.191z' />
        <path d='M8.572 8.342H4.449c-.648 0-1.172.53-1.172 1.178l-.006 8.246c0 .648.524 1.178 1.172 1.178h6.485a1.18 1.18 0 0 0 1.178-1.178v-5.89L8.572 8.342zm-4.123 9.425V9.52h3.534v2.945h2.945v5.301H4.449z' />
      </g>
      <path d='M7.215 13.431h.946v3.393h-.946v-3.393z' />
      <path d='M5.992 15.6v-.943h3.393v.943H5.992z' />
    </SvgIcon>
  );
};
