import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';

import { annotationApiUrls } from '../../routes';
import { useAllowedBackofficeOrigin } from '../../use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from '../../utils';
import { useInvalidateCompetitions } from '../use-competitions';

interface DeleteCompetitionParams {
  competitionId: string;
  onSuccess?: () => void;
}

export const useDeleteCompetition = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateCompetitions = useInvalidateCompetitions();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.DELETE,
    successMessage: 'Delete competition success',
    errorMessage: (data) => metadataApiErrorParser(data, 'Delete competition error'),
    onSuccess: invalidateCompetitions,
  });

  const deleteCompetition = ({ competitionId, onSuccess }: DeleteCompetitionParams) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: `${annotationApiUrls.COMPETITIONS}/${competitionId}`,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, deleteCompetition };
};
