import { useCallback } from 'react';

import { Clip } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows';
import { useRowTeam } from '../../hooks/use-row-team';
import { useRow, useRowIsHighlighted, useSetRowIsHighlighted } from '../../store/atoms';
import { ClipsList } from '../clips-list';
import { RowContentContainer } from '../row-content-container';

type Props = {
  clips: Clip[];
  recordingId: string;
  rowId: string;
};

export const RowContent = ({ clips, recordingId, rowId }: Props) => {
  const row = useRow(rowId);
  const team = useRowTeam(recordingId, row.teamId);
  const isRowHighlighted = useRowIsHighlighted(rowId);
  const setRowHighlighted = useSetRowIsHighlighted(rowId);

  const handleMouseEnter = useCallback(() => {
    setRowHighlighted(true);
  }, [setRowHighlighted]);

  const handleMouseLeave = useCallback(() => {
    setRowHighlighted(false);
  }, [setRowHighlighted]);

  return (
    <RowContentContainer
      team={team}
      borderTop={rowId === `${recordingId}-filters`}
      isHighlighted={isRowHighlighted}
      isSelected={row.isSelected}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ClipsList recordingId={recordingId} clips={clips} />
    </RowContentContainer>
  );
};
