import { fontSizes } from 'kognia-ui';
import { matchPath } from 'react-router-dom';

import { routes, routesSections } from 'kognia/router/routes';

const routesValues = Object.values(routes);

const findRouteByLink = (link: string) => {
  return routesValues.find((route) => matchPath(link, { path: route, exact: true }));
};

const isMatchingRoute = (currentLocation: string, link?: string) => {
  if (!link) return false;

  const currentMatchedRoute = findRouteByLink(currentLocation);
  const currentMatchedLink = findRouteByLink(link);

  return currentMatchedRoute === currentMatchedLink;
};

const isMatchingAnyMenuItemLinkRoute = (currentLocation: string, itemLinks: string[]) => {
  return itemLinks.some((link) => isMatchingRoute(currentLocation, link));
};

const isMatchingSection = (currentLocation: string, link?: string) => {
  if (!link) return false;

  const currentRouteSection = routesSections[findRouteByLink(currentLocation) ?? ''];
  const currentMatchedLink = routesSections[findRouteByLink(link) ?? ''];

  return Boolean(currentRouteSection && currentRouteSection && currentRouteSection === currentMatchedLink);
};

export const getMenuItemSelected = (currentLocation: string, itemLinks: string[], link?: string) => {
  if (isMatchingRoute(currentLocation, link)) return true;
  if (isMatchingAnyMenuItemLinkRoute(currentLocation, itemLinks)) return true;
  if (isMatchingSection(currentLocation, link)) return true;

  return false;
};

export const ListItemTextStyles = {
  fontSize: fontSizes.default,
  lineHeight: '24px',
};
