import React from 'react';

import { applyResizeLimits } from './apply-resize-limits';
import { ResizeInterface } from '../types';

const resizerTouch: ResizeInterface<React.TouchEvent<HTMLDivElement>> = (
  e,
  mainPanelElement,
  secondaryPanelElement,
  mainPanelMinSize,
  secondaryPanelMinSize,
  direction,
  callBack,
  start,
  end,
) => {
  const directionChangeValue = direction === 'horizontal' ? 'pageX' : 'pageY';
  const updateProperty = direction === 'horizontal' ? 'width' : 'height';

  const previousPosition = e.changedTouches[0][directionChangeValue];
  const mainPanel = mainPanelElement.getBoundingClientRect();
  const secondaryPanel = secondaryPanelElement.getBoundingClientRect();
  start();

  function touchmove(e: TouchEvent) {
    const newPosition =
      previousPosition - e.changedTouches[0][directionChangeValue] * (direction === 'horizontal' ? 1 : -1);

    mainPanelElement.style[updateProperty] =
      applyResizeLimits(
        mainPanel[updateProperty] - (direction === 'vertical' ? -newPosition : newPosition),
        secondaryPanel[updateProperty] + (direction === 'vertical' ? -newPosition : newPosition),
        mainPanelMinSize,
        secondaryPanelMinSize,
      ) + 'px';
  }

  function touchend() {
    end();
    const values = mainPanelElement.getBoundingClientRect();
    callBack(values[updateProperty]);

    window.removeEventListener('touchmove', touchmove);
    window.removeEventListener('touchend', touchend);
    window.removeEventListener('resize', touchend);
  }

  window.addEventListener('touchmove', touchmove);
  window.addEventListener('touchend', touchend);
  window.addEventListener('resize', touchend);
};

export default resizerTouch;
