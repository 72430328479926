import { TacticalAnalysis } from 'shared/types/recording/types';

import { TacticalAnalysisApiResponse } from '../../types';

export const transformTacticalAnalysis = (data: TacticalAnalysisApiResponse): TacticalAnalysis => {
  return {
    id: data.id,
    recordingId: data.recordingId,
    tacticalAnalysisGitCommit: data.tacticalAnalysisGitCommit,
    numberOfEpisodes: data.numberOfEpisodes,
    startTime: data.startTime,
    state: data.state,
  };
};
