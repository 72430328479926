import { ErrorBoundary } from '@sentry/react';
import { ReactNode } from 'react';

import { ContainerWrapper, ContainerWrapperProps, Content } from './index.styled';
import { ErrorContainer } from '../error-boundary/error-container';

interface Props extends ContainerWrapperProps {
  children: ReactNode;
  fullScreen?: boolean;
}

const Container = ({ children, fullScreen = false, backgroundImage, backgroundSize, backgroundColor }: Props) => {
  return (
    <ErrorBoundary fallback={<ErrorContainer />}>
      <ContainerWrapper
        backgroundImage={backgroundImage}
        backgroundSize={backgroundSize}
        backgroundColor={backgroundColor}
      >
        <Content fullScreen={fullScreen}>{children}</Content>
      </ContainerWrapper>
    </ErrorBoundary>
  );
};

export default Container;
