import { Button } from '@mui/material';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PopoverProps } from '@mui/material/Popover';
import { PopoverOrigin } from '@mui/material/Popover/Popover';
import React, { useCallback } from 'react';

import IconClose from '../icons/icon-close';
import { IconColors, IconSizes } from '../icons/svg-icon';
import { MenuListOption } from '../kebab-menu';
import styles from '../kebab-menu/KebabMenu.module.scss';

interface Props {
  isOpen: boolean;
  id?: string;
  onClose: (event: React.SyntheticEvent | React.MouseEvent<Document, MouseEvent> | MouseEvent | TouchEvent) => void;
  anchorEl: PopoverProps['anchorEl'];
  options: MenuListOption[];
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}
export const MenuList = ({
  id = '',
  anchorEl,
  isOpen,
  onClose,
  options,
  anchorOrigin = { vertical: 'top', horizontal: 'left' },
  transformOrigin = { vertical: 'top', horizontal: 'left' },
}: Props) => {
  const handleOnClick = useCallback(
    (event: React.SyntheticEvent | React.MouseEvent<Document, MouseEvent> | MouseEvent | TouchEvent) => {
      event.stopPropagation();
    },
    [],
  );
  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      onClick={handleOnClick}
      TransitionComponent={Fade}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <div className={styles.close}>
        <Button aria-controls={id} aria-haspopup='true' className={styles.button} onClick={onClose}>
          <IconClose size={IconSizes.small} color={IconColors.iron} />
        </Button>
      </div>
      <div className={styles.popupContent}>
        {options.map((option, idx) =>
          !option.displayText ? (
            <div key={idx} className={styles.separator} />
          ) : (
            <MenuItem
              className={styles.popupMenuItem}
              key={`${option.displayText}-${idx}`}
              selected={option.selected}
              onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                if (!option.avoidCloseOnClick) onClose(event);
                option.onClick && option.onClick(event);
              }}
            >
              {option.icon}
              {option.displayText}
            </MenuItem>
          ),
        )}
      </div>
    </Menu>
  );
};
