import { useCallback } from 'react';
import { atomFamily, useRecoilValue, useSetRecoilState } from 'recoil';

import { usePlaylistItems, useVideoPlayerActions, useVideoPlayerPlayingMode } from 'shared/components/video-player';
import { PlaylistItemType } from 'shared/components/video-player/types';

import { Clip } from '../../api/use-tactical-analysis-data/generate-timeline-rows';
import {
  useCleanSelection,
  useClipsIdsSelection,
  useGetClipsList,
  useRowClips,
  useSelectPlayingSelectionClips,
  useSelectRow,
  useUnselectPlayingSelectionClips,
  useUnselectRows,
} from '../../api/use-tactical-analysis-data/generate-timeline-rows/atoms';
import { TacticalAnalysisPlayingMode } from '../../types/tactical-analysis-playing-mode';
import { createVideoSourcesFromClips } from '../../utils/create-video-sources-from-clips';
import { useGenerateTimelinePlaylist } from '../use-generate-timeline-playlist';
import { useSetTacticalAnalysisMode, useTacticalAnalysisMode } from '../use-tactical-analysis-mode';
import { useTeamUtils } from '../use-timeline-team-id-focus';

interface PlaySelection {
  rowId?: string;
  team?: 'home' | 'opponent';
}

const selectedPlayingRow = atomFamily<{ rowId: string; team?: 'home' | 'opponent' } | undefined, string>({
  key: 'selected-playing-row',
  default: undefined,
});

export const useSelectedPlayingRow = (recordingId: string) => {
  return useRecoilValue(selectedPlayingRow(recordingId));
};

export const useSetSelectedPlayingRow = (recordingId: string) => {
  return useSetRecoilState(selectedPlayingRow(recordingId));
};

export const useSelectionPlaying = (recordingId: string) => {
  const getRowClips = useRowClips();
  const getClipsList = useGetClipsList();
  const actions = useVideoPlayerActions();
  const playlistItems = usePlaylistItems();
  const cleanSelection = useCleanSelection();
  const selectRow = useSelectRow();
  const clipIdsSelection = useClipsIdsSelection();
  const playingMode = useVideoPlayerPlayingMode();
  const { isHomeTeam } = useTeamUtils(recordingId);
  const selectClipsList = useSelectPlayingSelectionClips();
  const unselectRows = useUnselectRows();
  const unselectPlayingSelectionClips = useUnselectPlayingSelectionClips();
  const tacticalAnalysisMode = useTacticalAnalysisMode(recordingId);
  const setTacticalAnalysisMode = useSetTacticalAnalysisMode(recordingId);
  const generateTimelinePlaylist = useGenerateTimelinePlaylist(recordingId);
  const setSelectedPlayingRow = useSetSelectedPlayingRow(recordingId);

  const createPlaylistItemsFromClips = useCallback(
    (clipsList: Clip[]): PlaylistItemType[] => {
      const { videoSources, duration } = createVideoSourcesFromClips(
        clipsList,
        playlistItems[0].videoTypes[0].videoSources,
        playingMode,
      );

      return [
        {
          ...playlistItems[0],
          id: 'timeline-playlist-item-selection',
          duration,
          videoTypes: [
            {
              ...playlistItems[0].videoTypes[0],
              videoSources,
            },
          ],
        },
      ];
    },
    [playingMode, playlistItems],
  );

  const playAll = useCallback(() => {
    unselectPlayingSelectionClips();
    unselectRows();
    setTacticalAnalysisMode(TacticalAnalysisPlayingMode.default);
    actions.setPlaylist(generateTimelinePlaylist(playingMode), playingMode, true, true);
  }, [
    unselectPlayingSelectionClips,
    actions,
    playingMode,
    unselectRows,
    setTacticalAnalysisMode,
    generateTimelinePlaylist,
  ]);

  const playSelection = useCallback(
    ({ rowId, team }: PlaySelection = {}) => {
      if (tacticalAnalysisMode === TacticalAnalysisPlayingMode.selection) playAll();

      const getClips = ({ rowId, team }: PlaySelection) => {
        if (!rowId) return [];

        setSelectedPlayingRow({ rowId, team });

        if (team === 'home') {
          return getRowClips(rowId).filter((clip) => clip.teamId && isHomeTeam(clip.teamId));
        }

        if (team === 'opponent') {
          return getRowClips(rowId).filter((clip) => clip.teamId && !isHomeTeam(clip.teamId));
        }

        return getRowClips(rowId);
      };

      const clipsList = rowId ? getClips({ rowId, team }) : getClipsList(clipIdsSelection);

      const newPlaylistItems: PlaylistItemType[] = createPlaylistItemsFromClips(clipsList);

      selectClipsList(clipsList);

      if (rowId) {
        cleanSelection();
        selectRow(rowId, team);
      } else {
        unselectRows();
        selectRow();
      }

      setTacticalAnalysisMode(TacticalAnalysisPlayingMode.selection);
      actions.setPlaylist(newPlaylistItems, playingMode, true);
      actions.play();
    },
    [
      unselectRows,
      setSelectedPlayingRow,
      tacticalAnalysisMode,
      playAll,
      getClipsList,
      clipIdsSelection,
      createPlaylistItemsFromClips,
      cleanSelection,
      setTacticalAnalysisMode,
      actions,
      playingMode,
      getRowClips,
      isHomeTeam,
      selectClipsList,
      selectRow,
    ],
  );

  return {
    playSelection,
    playAll,
  };
};
