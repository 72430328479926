import classNames from 'classnames';
import React from 'react';

import styles from './ListItem.module.scss';

interface Props {
  children?: React.ReactNode;
  onClick?: (...args: any) => any;
  className?: string;
  component?: string;
}

export const ListItem = ({ children, onClick, className, component = 'div', ...rest }: Props) => {
  return React.createElement(
    component,
    { onClick: onClick, className: classNames(styles.item, className), ...rest },
    children,
  );
};
