import { nativeEnum, object, string, TypeOf, union } from 'zod';

export type AutocompleteTeamsValue = { label: string; value: string };

export enum UploadVideoStepOneFormFields {
  recordingTitle = 'name',
  type = 'type',
  country = 'country',
  city = 'city',
  date = 'date',
  stadium = 'stadium',
  competition = 'competitionName',
  homeTeam = 'homeTeam',
  awayTeam = 'awayTeam',
  homeTeamId = 'homeTeamId',
  awayTeamId = 'awayTeamId',
  videoSource = 'videoSource',
}

export enum UploadVideoStepOneType {
  game = 'game',
  training = 'training',
}

export const uploadVideoStepOneDefaultValues = {
  [UploadVideoStepOneFormFields.type]: UploadVideoStepOneType.game,
};

const typeSchema = nativeEnum(UploadVideoStepOneType);
const teamSchema = object({ label: string(), value: string() }).optional();
const dateSchema = string().transform((value) => new Date(value).toISOString());
const stringSchema = string();

const recordingTitleSchema = string().min(2);

const uploadVideoStepOneTrainingSchema = object({
  [UploadVideoStepOneFormFields.city]: stringSchema,
  [UploadVideoStepOneFormFields.country]: stringSchema,
  [UploadVideoStepOneFormFields.date]: dateSchema,
  [UploadVideoStepOneFormFields.recordingTitle]: recordingTitleSchema,
  [UploadVideoStepOneFormFields.stadium]: stringSchema,
  [UploadVideoStepOneFormFields.type]: typeSchema,
  [UploadVideoStepOneFormFields.videoSource]: stringSchema,
  [UploadVideoStepOneFormFields.awayTeam]: teamSchema,
  [UploadVideoStepOneFormFields.homeTeam]: teamSchema,
});

const uploadVideoStepOneMatchSchema = object({
  ...uploadVideoStepOneTrainingSchema.shape,
  [UploadVideoStepOneFormFields.competition]: stringSchema,
  [UploadVideoStepOneFormFields.homeTeamId]: stringSchema,
  [UploadVideoStepOneFormFields.awayTeamId]: stringSchema,
});

export const uploadVideoStepOneFormSchema = union([uploadVideoStepOneMatchSchema, uploadVideoStepOneTrainingSchema]);
export type UploadVideoStepOneFormSchema = TypeOf<typeof uploadVideoStepOneFormSchema>;
