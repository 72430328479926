import isEqual from 'lodash/isEqual';

import {
  defaultRecordingFiltersScenariosOrTacticsInside,
  defaultRecordingsFiltersEvents,
} from 'api/recording/useEpisodesWithFilters/useTacticalAnalysesPlaylistItems';
import { RecordingsFilters } from 'shared/types/recording/types';

export const getFilterStatus = (filters: RecordingsFilters) => {
  const areFiltersStartingApplied =
    Boolean(filters.eventsStarting) && !isEqual(filters.eventsStarting, defaultRecordingsFiltersEvents);
  const areFiltersEndingApplied =
    Boolean(filters.eventsEnding) && !isEqual(filters.eventsEnding, defaultRecordingsFiltersEvents);
  const areFiltersInsideApplied =
    Boolean(filters.scenariosOrTacticsInside) &&
    !isEqual(filters.scenariosOrTacticsInside, defaultRecordingFiltersScenariosOrTacticsInside);

  return {
    areFiltersEndingApplied,
    areFiltersStartingApplied,
    areFiltersInsideApplied,
  };
};
