import { useQuery } from '@tanstack/react-query';

import {
  DEFAULT_TACTICAL_CAMERA_PLAYING_MODE,
  EPISODES_PLAYING_MODE,
  TACTICAL_CAMERA_WITH_OVERLAYS_PLAYING_MODE,
  TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE,
} from 'shared/components/video-player/defaultPlayingModes';
import { PlayingMode, PlayingModes, PlaylistItemType } from 'shared/components/video-player/types';
import { MatchWithEpisodes } from 'shared/types/match/types';
import { TacticIdOrAll } from 'shared/types/playlist/types';

import { generatePlaylistItemFromEpisodes } from '../../components/tactical-analysis/utils/generate-timeline-playlist';

interface Params {
  recordingId: string;
  matchWithEpisodes: MatchWithEpisodes | undefined;
  selectedTactics: TacticIdOrAll[];
  selectedPlayingMode: PlayingMode | undefined;
  areTimelineOverlaysEnabled: boolean;
}

const getValidPlayingMode = (playingMode: PlayingMode | undefined, hasHomographies: boolean) => {
  // Validate playing mode preset. EPISODE mode is not valid for match with hasHomographies
  if (!playingMode) return;
  if (playingMode.mode === PlayingModes.EPISODES && hasHomographies) {
    return TACTICAL_CAMERA_WITH_OVERLAYS_PLAYING_MODE;
  }

  if (playingMode.mode === PlayingModes.EPISODES && !hasHomographies) {
    return playingMode;
  }

  return playingMode;
};

export const generateTacticalAnalysisPlaylistItems = ({
  recordingId,
  matchWithEpisodes,
  selectedTactics,
  selectedPlayingMode,
  areTimelineOverlaysEnabled,
}: Params) => {
  return new Promise<PlaylistItemType[]>((resolve) => {
    const recordingEpisodes = matchWithEpisodes?.episodes;
    const recordingMatch = matchWithEpisodes?.match;
    const defaultVideoSource = matchWithEpisodes?.match.defaultVideoSource;
    const duration = matchWithEpisodes?.match.defaultVideoSource.duration;

    const shouldUseEpisodesMode =
      recordingEpisodes && recordingEpisodes.length > 0 && recordingMatch && !recordingMatch.hasHomographies;
    const shouldUseTacticalCameraMode =
      recordingMatch && !recordingMatch.hasHomographies && recordingEpisodes && recordingEpisodes.length === 0;
    const shouldUseTacticalCameraModeWithEpisodes =
      recordingMatch && recordingMatch.hasHomographies && recordingEpisodes && recordingEpisodes.length > 0;

    const playingMode: PlayingMode = areTimelineOverlaysEnabled
      ? {
          ...DEFAULT_TACTICAL_CAMERA_PLAYING_MODE,
          ...(shouldUseEpisodesMode && EPISODES_PLAYING_MODE),
          ...(shouldUseTacticalCameraMode && TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE),
          ...(shouldUseTacticalCameraModeWithEpisodes && TACTICAL_CAMERA_WITH_OVERLAYS_PLAYING_MODE),
        }
      : {
          ...DEFAULT_TACTICAL_CAMERA_PLAYING_MODE,
          ...(recordingEpisodes && recordingEpisodes?.length > 0 && EPISODES_PLAYING_MODE),
        };

    const hasHomographies =
      areTimelineOverlaysEnabled && recordingMatch?.hasHomographies !== undefined
        ? recordingMatch?.hasHomographies
        : false;

    const validateSelectedPlayingMode = getValidPlayingMode(selectedPlayingMode, hasHomographies);

    if (!recordingMatch || !recordingEpisodes || !defaultVideoSource) {
      return;
    }

    resolve(
      generatePlaylistItemFromEpisodes({
        episodes: recordingEpisodes,
        fullVideoSourceDuration: duration,
        tacticalCameraVideo: defaultVideoSource,
        playingMode: validateSelectedPlayingMode || playingMode,
        recordingId: recordingId,
        fundamentalsSelected: selectedTactics,
        hasHomographies,
      }),
    );
  });
};

export const useGenerateTacticalAnalysisPlaylistItems = (params: Params) => {
  return useQuery<PlaylistItemType[]>(
    [`generate-playlist-tactical-analysis-${params.recordingId}`],
    () => generateTacticalAnalysisPlaylistItems(params),
    {
      enabled: params.matchWithEpisodes !== undefined,
    },
  );
};
