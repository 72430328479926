import { useCallback } from 'react';

import { useTacticalAnalysisEpisodes } from 'pages/tactical-analysis/hooks/use-tactical-analysis-episodes';
import { SegmentConfig } from 'shared/types/segment/types';

import { useTimelineZoomLevel } from '../../../../hooks/use-timeline-zoom-level';
import { formattedTime } from '../../../../utils/formatted-time';
import {
  adjustTimeSpaceByZoomLevel,
  getBlockSizeByZoomLevel,
  getTimeZoomLevelAdjustment,
} from '../../../tactical-analysis/utils/adjust-time-by-zoom-level';
import { useSetPlayHeadShadowTime, useSetPlayHeadShadowVisibility } from '../../play-head/play-head-shadow/atoms';
import { CSS_CLIP_ZOOM_WIDTH } from '../../timeline-table/components/timeline-css-variables';
import { useJumpToTimeInMatch } from '../../timeline-table/hooks/use-jump-to-time-in-match';
import styles from '../TimeRuler.module.scss';

const shouldRenderTime = (blockNumber: number, zoomLevel: number) => {
  return blockNumber % 2 === 0 || zoomLevel > 1;
};

const MAX_TEXT_WIDTH = 40;

export const TimeRulerBlockContainer = ({
  startTime,
  endTime,
  recordingId,
  matchSegments,
  fullWidth,
}: {
  startTime: number;
  endTime: number;
  recordingId: string;
  matchSegments: SegmentConfig[];
  fullWidth?: boolean;
}) => {
  const episodes = useTacticalAnalysisEpisodes(recordingId);
  const jumpToTimeInMatch = useJumpToTimeInMatch(recordingId);
  const { zoomLevel } = useTimelineZoomLevel();
  const setPlayHeadShadowVisibility = useSetPlayHeadShadowVisibility(recordingId);
  const zoomScaleAdjustment = getTimeZoomLevelAdjustment(zoomLevel);
  const blockSizeByZoomLevel = getBlockSizeByZoomLevel(zoomLevel);
  const setPlayHeadShadowTime = useSetPlayHeadShadowTime(recordingId);

  const showPlayHeadShadow = useCallback(() => {
    setPlayHeadShadowVisibility(true);
  }, [setPlayHeadShadowVisibility]);

  const hidePlayHeadShadow = useCallback(() => {
    setPlayHeadShadowVisibility(false);
  }, [setPlayHeadShadowVisibility]);

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const { left } = event.currentTarget.getBoundingClientRect();
      const x = event.clientX - left;
      const playHeadShadowTime = x / zoomScaleAdjustment + startTime;
      const isValidTime = playHeadShadowTime >= startTime && playHeadShadowTime <= endTime;

      setPlayHeadShadowVisibility(isValidTime);
      isValidTime && setPlayHeadShadowTime(playHeadShadowTime);
    },
    [endTime, setPlayHeadShadowTime, setPlayHeadShadowVisibility, startTime, zoomScaleAdjustment],
  );

  const handleOnClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const { left } = event.currentTarget.getBoundingClientRect();
      const x = event.clientX - left;

      jumpToTimeInMatch(x / zoomScaleAdjustment + startTime);
    },
    [startTime, jumpToTimeInMatch, zoomScaleAdjustment],
  );

  const time = endTime - startTime;
  const numberOfBlocks = fullWidth
    ? Math.ceil(adjustTimeSpaceByZoomLevel(time, zoomLevel) / blockSizeByZoomLevel)
    : Math.ceil((zoomScaleAdjustment * time) / blockSizeByZoomLevel);
  const timePerBlock = blockSizeByZoomLevel / zoomScaleAdjustment;
  const containerWidth = zoomScaleAdjustment * time;
  const blockWidth = blockSizeByZoomLevel;

  return (
    <div
      onClick={handleOnClick}
      onMouseOver={showPlayHeadShadow}
      onMouseMove={handleMouseMove}
      onMouseOut={hidePlayHeadShadow}
      style={{
        display: 'flex',
        width: fullWidth ? '100%' : `calc(var(${CSS_CLIP_ZOOM_WIDTH}) * ${time})`,
      }}
    >
      {Array(numberOfBlocks)
        .fill('')
        .map((_, index) => {
          return (
            <div
              className={styles.block}
              key={index}
              style={{
                width: `${blockWidth}px`,
                minWidth: `${blockWidth}px`,
              }}
            >
              {shouldRenderTime(index, zoomLevel) &&
              blockWidth * index + MAX_TEXT_WIDTH <
                (fullWidth ? adjustTimeSpaceByZoomLevel(time, zoomLevel) : containerWidth)
                ? formattedTime(startTime + Math.ceil(index * timePerBlock), episodes.length === 0 ? [] : matchSegments)
                : null}
            </div>
          );
        })}
    </div>
  );
};
