import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';

import { annotationApiUrls } from '../../routes';
import { useAllowedBackofficeOrigin } from '../../use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from '../../utils';
import { useInvalidateSeasons } from '../use-seasons';

interface DeleteSeasonApiParams {
  id: string;
  onSuccess?: () => void;
}

export const useDeleteSeason = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateSeasons = useInvalidateSeasons();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.DELETE,
    successMessage: 'Season deleted',
    errorMessage: (data) => metadataApiErrorParser(data, 'Season delete error'),
    onSuccess: invalidateSeasons,
  });

  const deleteSeason = ({ id, onSuccess }: DeleteSeasonApiParams) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: `${annotationApiUrls.SEASONS}/${id}`,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, deleteSeason };
};
