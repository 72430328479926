import { object } from 'zod';

import {
  episodeIndex,
  gitCommitSchema,
  recordingIdSchema,
  tacticalAnalysisIdSchema,
} from '../../utils/form-fields-validation';
import { AnnotationFormFieldsNames } from '../../utils/form-fields-validation/types';

const requireEpisodeWhenVisible = (input: any) => !(input[AnnotationFormFieldsNames.EPISODE_INDEX] === '');
const requireAnalysisIdWhenVisible = (input: any) => !(input[AnnotationFormFieldsNames.TACTICAL_ANALYSIS_ID] === '');
const requireGitCommitWhenVisible = (input: any) => !(input[AnnotationFormFieldsNames.GIT_COMMIT] === '');
const episodeIndexError = {
  message: 'Episode index is required',
  path: [AnnotationFormFieldsNames.EPISODE_INDEX],
};
const tacticalAnalysisIdError = {
  message: 'Tactical analysis ID is required',
  path: [AnnotationFormFieldsNames.TACTICAL_ANALYSIS_ID],
};
const gitCommitError = {
  message: 'Git commit is required',
  path: [AnnotationFormFieldsNames.GIT_COMMIT],
};

export const processEpisodeFormSchema = object({
  [AnnotationFormFieldsNames.RECORDING_ID]: recordingIdSchema,
  [AnnotationFormFieldsNames.EPISODE_INDEX]: episodeIndex,
  [AnnotationFormFieldsNames.TACTICAL_ANALYSIS_ID]: tacticalAnalysisIdSchema,
  [AnnotationFormFieldsNames.GIT_COMMIT]: gitCommitSchema,
})
  .refine(requireEpisodeWhenVisible, episodeIndexError)
  .refine(requireAnalysisIdWhenVisible, tacticalAnalysisIdError)
  .refine(requireGitCommitWhenVisible, gitCommitError);
