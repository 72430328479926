import { styled } from '@mui/material';
import { Colors } from 'kognia-ui';
import React from 'react';

import Button, { ButtonTypes } from '../../../button';
import { Chevron } from '../../../chevron';
import { AutocompleteGroup, AutocompleteGroupContent } from '../autocomplete-group';

interface Props {
  isOpen: boolean;
  items: unknown[];
  label: string;
  fullWidth?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
}

interface DropdownButtonProps {
  isOpen: boolean;
  isSelected: boolean;
  minWidth: number;
  fullWidth?: boolean;
}

const DropdownButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isOpen' && prop !== 'isSelected' && prop !== 'minWidth',
})<DropdownButtonProps>(({ theme, isOpen, isSelected, minWidth, fullWidth = false }) => ({
  margin: 0,
  minWidth: fullWidth ? '100%' : `${minWidth ? minWidth : 120}px`,
  maxWidth: fullWidth ? '100%' : `${minWidth < 300 ? 300 : minWidth}px`,
  ['&.btn-white']: {
    height: theme.spacing(6),
    color: isSelected ? Colors.blue : Colors.storm,
    borderRadius: 4,
    border: `1px solid ${isOpen ? Colors.primary : Colors.iron}`,
    '&:hover': {
      color: isSelected ? Colors.primary : Colors.storm,
    },
  },
}));

export const AutocompleteDropdownButton = ({
  items,
  label,
  onClick,
  isOpen,
  disabled = false,
  fullWidth = false,
}: Props) => {
  return (
    <DropdownButton
      buttonType={'button'}
      isOpen={isOpen}
      isSelected={items.length > 0}
      fullWidth={fullWidth}
      minWidth={250}
      onClick={onClick}
      type={ButtonTypes.White}
      disabled={disabled}
    >
      <AutocompleteGroup>
        <AutocompleteGroupContent>{label}</AutocompleteGroupContent>
        <Chevron isOpen={isOpen} />
      </AutocompleteGroup>
    </DropdownButton>
  );
};
