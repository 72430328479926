import { useEffect, useMemo, useState } from 'react';

import { useSaveUserPreset } from 'api/user-presets/use-save-user-preset';
import {
  useCurrentMatchTime,
  useCurrentVideoSourceTime,
  usePlayerCurrentSource,
  useVideoPlayerPlayingMode,
} from 'shared/components/video-player/hooks';
import { PlayingModes } from 'shared/components/video-player/types';
import { TimelineTimePreset } from 'shared/types/user-preset/types';

import { useSetTimelineInitialTime, useTimelineInitialTime } from '../use-timeline-initial-time';

export const useTimelineCurrentVideoSourceTime = (recordingId: string) => {
  const playingMode = useVideoPlayerPlayingMode();
  const currentTimeMatch = useCurrentMatchTime();
  const currentSource = usePlayerCurrentSource();
  const currentTime = useCurrentVideoSourceTime();
  const [initialTime] = useState(currentTimeMatch);
  const { savePreset } = useSaveUserPreset();
  const setTimelineInitialTime = useSetTimelineInitialTime(recordingId);
  const timelineInitialTime = useTimelineInitialTime(recordingId);

  const shouldSaveTimePreset = useMemo(
    () =>
      Math.round(currentTimeMatch) % 10 === 0 &&
      Math.round(timelineInitialTime) !== Math.round(currentTimeMatch) &&
      Math.round(initialTime) !== Math.round(currentTimeMatch),
    [currentTimeMatch, initialTime, timelineInitialTime],
  );

  const time = useMemo(() => {
    if (playingMode.mode === PlayingModes.EPISODES) {
      return currentTime + (currentSource?.startTimeInMatch ?? 0);
    }

    return currentTime;
  }, [currentSource?.startTimeInMatch, currentTime, playingMode]);

  const visibleTime = useMemo(() => {
    if (playingMode.mode === PlayingModes.EPISODES) {
      return currentTime + (currentSource?.startTimeInMatch ?? 0);
    }

    return currentTime;
  }, [currentSource?.startTimeInMatch, currentTime, playingMode.mode]);

  useEffect(() => {
    if (shouldSaveTimePreset) {
      const timePreset = new TimelineTimePreset(time, recordingId);
      setTimelineInitialTime(time);
      savePreset({ data: timePreset });
    }
  }, [time, recordingId, savePreset, setTimelineInitialTime, shouldSaveTimePreset]);

  return { currentTime: time, visibleTime };
};
