import { useTacticalAnalysisMatchSegments } from '../../../../hooks/use-tactical-analysis-match-segments';
import { TimeRulerBlockContainer } from '../time-ruler-block-container';

type Props = {
  recordingId: string;
  endTime: number;
};

export const TimeRulerFullTime = ({ endTime, recordingId }: Props) => {
  const matchSegments = useTacticalAnalysisMatchSegments(recordingId);
  return (
    <TimeRulerBlockContainer
      recordingId={recordingId}
      matchSegments={matchSegments}
      fullWidth
      startTime={0}
      endTime={endTime}
    />
  );
};
