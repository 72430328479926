import { useTranslation } from 'react-i18next';

import Button, { ButtonSizes, ButtonTypes } from 'shared/components/button';

import styles from './PlaylistItemsSelectorMenu.module.scss';
import SelectedPlaylistItem from './selected-playslist-item';
import { usePlaylistMenuItems } from '../add-to-playlist-menu-state/hooks';

interface Props {
  onSave: () => void;
  playlistId?: string;
}

export const PlaylistItemsSelectorMenu = ({ onSave, playlistId }: Props) => {
  const { t } = useTranslation();
  const selectedPlaylistItems = usePlaylistMenuItems();

  return (
    <div className={styles.menuContainer}>
      {selectedPlaylistItems.length > 0 && (
        <>
          <div className={styles.titleBar}>
            <span className={styles.title}>{`${t('timeline:playlist-items-selector.title')} (${
              selectedPlaylistItems.length
            })`}</span>
          </div>
          <div className={styles.menuContent}>
            {selectedPlaylistItems.map((item) => (
              <SelectedPlaylistItem key={item.id} item={item} />
            ))}
          </div>
          <div className={styles.button}>
            <Button btnSize={ButtonSizes.S} type={ButtonTypes.SecondaryNight} onClick={onSave} disabled={!playlistId}>
              {t('timeline:playlist-items-selector.button')}
            </Button>
          </div>
        </>
      )}
      {selectedPlaylistItems.length === 0 && playlistId && (
        <div className={styles.noItemsText}>{t('timeline:playlist-items-selector.no-items')}</div>
      )}
      {selectedPlaylistItems.length === 0 && !playlistId && (
        <div className={styles.noItemsText}>{t('timeline:playlist-items-selector.select-playlist')}</div>
      )}
    </div>
  );
};
