import classNames from 'classnames';
import React from 'react';

import { TeamTypes } from 'pages/tactical-analysis/api/types';

import styles from './FilterBlockStatus.module.scss';

export enum FilterBlockVariants {
  DEFAULT = 'default',
  NO_TEAM = 'noTeam',
}

interface Props {
  variant?: TeamTypes | FilterBlockVariants;
  active?: boolean;
}

const FilterBlockStatus = ({ active = false, variant = FilterBlockVariants.DEFAULT }: Props) => {
  return (
    <section
      className={classNames(styles.container, { [styles[variant]]: active || variant === FilterBlockVariants.DEFAULT })}
    />
  );
};

export default FilterBlockStatus;
