import { Button, Stack, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { invalidatePlaylistQuery } from 'api/playlist/usePlaylist';
import { useUpdatePlaylist } from 'api/playlist/useUpdatePlaylist';
import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { IconDownload } from 'shared/components/icons/icon-download';
import IconShare from 'shared/components/icons/icon-share';
import IconTime from 'shared/components/icons/icon-time';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import SharePlaylistModal from 'shared/components/share-playlist-modal';
import { UpdateText } from 'shared/components/update-text';
import { VerticalIconButton } from 'shared/components/vertical-icon-button';
import { useCurrentPlaylistItem, usePlaylistItems, useVideoPlayerActions } from 'shared/components/video-player';
import { PlaylistItemType } from 'shared/components/video-player/types';
import VideoPlayerComponent from 'shared/components/video-player/video-player-component';
import { PlaylistOverlaysSelectorPanel } from 'shared/components/video-player/video-player-component/components/playlist-overlays-selector-panel';
import { useFeatureFlag, useUser } from 'shared/contexts/app-state';
import { Playlist } from 'shared/types';
import { secondsAsDuration } from 'shared/utils/seconds-as-duration';

import { DownloadCurrentPlaylistItem } from './components/download-current-playlist-item';
import { useIsBulkModeActive, useSetIsBulkModeActive } from './state';
import {
  BadgeText,
  PlaylistDetailPageContainer,
  PlaylistDetailPageHeaderContainer,
  PlaylistDetailPageMainContainer,
  PlaylistDetailPageTimelineContainer,
  PlaylistDetailPageTimelineHeader,
} from './styled';
import { AddMultipleClips } from '../add-multiple-clips';
import { DownloadModal } from '../download-modal';
import { PlaylistItemTrimModal } from '../playlist-item-trim';
import { PlaylistItemsList } from '../playlist-items-list';

interface Props {
  playlist: Playlist;
}

const findVideoById = (videos: PlaylistItemType[], id: string) => {
  return videos.find((video) => video.id === id);
};

export const PlaylistDetailPage = ({ playlist }: Props) => {
  const { t } = useTranslation();
  const enabledBulkMode = useIsBulkModeActive(playlist.id);
  const setEnabledBulkMode = useSetIsBulkModeActive(playlist.id);

  const actions = useVideoPlayerActions();
  const playingItems = usePlaylistItems();
  const playlistItem = useCurrentPlaylistItem();
  const { updatePlaylistName, isLoading: isPlaylistUpdating } = useUpdatePlaylist(playlist.id);
  const downloadPlaylistFeatureFlag = useFeatureFlag(FEATURE_FLAG.DOWNLOAD_PLAYLIST);
  const customOverlaysFeatureFlag = useFeatureFlag(FEATURE_FLAG.CUSTOM_OVERLAYS);

  const [trimmingPlaylistItem, setTrimmingPlaylistItem] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isCurrentItemDownloadModalOpen, setIsCurrentItemDownloadModalOpen] = useState(false);
  const user = useUser();

  const handleOnPlaylistOrderChange = useCallback(
    (currentVideoIndex: number, newVideoIndex: number) => {
      actions.reorder(currentVideoIndex, newVideoIndex);

      actions.resumeStandBy();
    },
    [actions],
  );

  const handleOrderChangeStart = useCallback(() => actions.handleStandBy(), [actions]);
  const handleOrderChangeEnd = useCallback(() => actions.resumeStandBy(), [actions]);
  const closeModal = useCallback(() => setIsModalOpen(false), []);
  const openModal = useCallback(() => setIsModalOpen(true), []);

  const onUpdateName = useCallback(
    (newText: string) => {
      if (newText === playlist.name || trim(newText).length === 0) return;

      updatePlaylistName({ name: newText, onSuccess: invalidatePlaylistQuery });
    },
    [playlist.name, updatePlaylistName],
  );

  const canEditName = user.id === playlist.user.userId;

  const trimmingPlaylistItemItem =
    trimmingPlaylistItem !== '' ? findVideoById(playingItems, trimmingPlaylistItem) : null;

  const handleTrimmingPlaylistItem = useCallback(
    (trimmingPlaylistItem: string) => {
      setTrimmingPlaylistItem(trimmingPlaylistItem);

      trimmingPlaylistItem ? actions.handleStandBy() : actions.resumeStandBy();
    },
    [actions],
  );

  const handleDownloadModalOpen = useCallback(() => setIsDownloadModalOpen(true), []);
  const handleDownloadModalClose = useCallback(() => setIsDownloadModalOpen(false), []);

  const handleSelectClick = useCallback(() => {
    setEnabledBulkMode(!enabledBulkMode);

    actions.pause();
  }, [actions, enabledBulkMode]);

  const handleOpenDownloadCurrentItemModal = useCallback(() => {
    actions.handleStandBy();
    setIsCurrentItemDownloadModalOpen(true);
  }, [actions]);

  const handleCloseDownloadCurrentItemModal = useCallback(() => {
    actions.resumeStandBy();
    setIsCurrentItemDownloadModalOpen(false);
  }, [actions]);

  return (
    <PlaylistDetailPageContainer container>
      <PlaylistDetailPageHeaderContainer xs={12} item>
        <UpdateText
          canEdit={canEditName}
          originalText={playlist.name}
          onSubmit={onUpdateName}
          isSubmitting={isPlaylistUpdating}
        />
        {!enabledBulkMode ? (
          <Stack direction='row' spacing={2}>
            <VerticalIconButton Icon={IconShare} onClick={openModal}>
              {t('common:actions.share')}
            </VerticalIconButton>

            {downloadPlaylistFeatureFlag && !isEmpty(playingItems) ? (
              <VerticalIconButton Icon={IconDownload} onClick={handleDownloadModalOpen}>
                {t('common:actions.download')}
              </VerticalIconButton>
            ) : null}
          </Stack>
        ) : null}
      </PlaylistDetailPageHeaderContainer>
      <PlaylistDetailPageMainContainer display='flex' item xs={12}>
        <VideoPlayerComponent onDownloadSource={handleOpenDownloadCurrentItemModal} showVideoPlayerBar />
        {customOverlaysFeatureFlag && playlistItem.hasHomographies ? (
          <PlaylistOverlaysSelectorPanel key={playlist.id} playlistId={playlist.id} />
        ) : null}

        {trimmingPlaylistItemItem && (
          <PlaylistItemTrimModal
            open={Boolean(trimmingPlaylistItemItem)}
            playlistId={playlist.id}
            playlistItem={trimmingPlaylistItemItem}
            setTrimmingPlaylistItem={handleTrimmingPlaylistItem}
          />
        )}
      </PlaylistDetailPageMainContainer>
      <PlaylistDetailPageTimelineContainer item xs={12}>
        <PlaylistDetailPageTimelineHeader
          direction='row'
          alignItems='center'
          paddingX={2}
          justifyContent='space-between'
        >
          <Stack direction='row' spacing={1} alignItems={'center'}>
            <Typography fontSize={fontSizes.small}>{t('playlist-detail:all-playlist')}</Typography>
            <BadgeText>{t('playlist-detail:clips', { count: playlist.playlistItems.length })}</BadgeText>
            <BadgeText sx={{ paddingLeft: 0 }}>
              <IconTime size={IconSizes.small} color={IconColors.storm} /> {secondsAsDuration(playlist.duration, false)}
            </BadgeText>
          </Stack>
          <Stack direction='row' spacing={1}>
            {!isEmpty(playingItems) ? (
              <Button size='small' variant='outlined' color='secondary' onClick={handleSelectClick}>
                {t('common:actions.select')}
              </Button>
            ) : null}

            <AddMultipleClips key={playlist.id} playlistId={playlist.id} />
          </Stack>
        </PlaylistDetailPageTimelineHeader>
        <PlaylistItemsList
          onOrderChangeEnd={handleOrderChangeEnd}
          onOrderChangeStart={handleOrderChangeStart}
          onPlaylistOrderChange={handleOnPlaylistOrderChange}
          playlist={playlist}
          setTrimmingPlaylistItem={handleTrimmingPlaylistItem}
          trimmingPlaylistItemId={trimmingPlaylistItem}
          enabledBulkMode={enabledBulkMode}
          setEnabledBulkMode={setEnabledBulkMode}
        />
      </PlaylistDetailPageTimelineContainer>

      {isModalOpen && <SharePlaylistModal onCloseModal={closeModal} playlist={playlist} />}
      {isDownloadModalOpen ? <DownloadModal onClose={handleDownloadModalClose} playlistId={playlist.id} /> : null}
      <DownloadCurrentPlaylistItem
        playlistId={playlist.id}
        isOpen={isCurrentItemDownloadModalOpen}
        onClose={handleCloseDownloadCurrentItemModal}
      />
    </PlaylistDetailPageContainer>
  );
};
