import i18n, { Resource } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import deDE from './locales/de-DE';
import enUS from './locales/en-US';
import esES from './locales/es-ES';
import { Locales, Namespaces } from './types';

export const namespaces = Object.values(Namespaces);

export const resources: Resource = {
  [Locales['en-US']]: enUS,
  [Locales['es-ES']]: esES,
  [Locales['de-DE']]: deDE,
};

export const initConfig = {
  resources,
  ns: namespaces,
  fallbackLng: Locales['en-US'],
  supportedLngs: [Locales['en-US'], Locales['es-ES'], Locales['de-DE']],
  defaultNS: 'common',
  interpolation: {
    escapeValue: false,
  },
};

i18n.use(initReactI18next).use(LanguageDetector).init(initConfig);

export default i18n;
