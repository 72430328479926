import { useParams } from 'react-router-dom';

import { useFetchRecordingPlaylists } from 'api/playlist/useFetchRecordingPlaylists';
import Container from 'shared/components/container';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import usePageStateMachine from 'shared/hooks/use-page-state-machine';
import { Playlist } from 'shared/types';

import RecordingPlaylistsPage from './components/recording-playlists-page';
import RecordingPlaylistsSkeleton from './components/recording-playlists-skeleton';

type RouteParams = {
  id: string;
};

const RecordingPlaylistsContainer = () => {
  const { id } = useParams<RouteParams>();

  const { data, isPageReady, isPageLoading } = usePageStateMachine<Playlist[]>(() =>
    useFetchRecordingPlaylists(id, false),
  );

  return (
    <SidebarLayout>
      <Container>
        {isPageLoading && <RecordingPlaylistsSkeleton />}
        {isPageReady && data && <RecordingPlaylistsPage playlists={data} matchId={id} />}
      </Container>
    </SidebarLayout>
  );
};

export default RecordingPlaylistsContainer;
