import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import IconDelete from 'shared/components/icons/icon-delete';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import { Playlist } from 'shared/types';

import styles from './CurrentPlaylistMenu.module.scss';
import { NoClipText, PlaylistItem, PlaylistItemsList, PlaylistItemsRow, Title, TitleBar } from './styled';

interface Props {
  selectedPlaylist: Playlist;
  onDeletePlaylistItem: (playlistItemId: string) => void;
}

export const CurrentPlaylistMenu = ({ selectedPlaylist, onDeletePlaylistItem }: Props) => {
  const { t } = useTranslation();

  const playlistItems = selectedPlaylist.playlistItems;

  return (
    <Box sx={{ flex: '1 1 50%' }}>
      <TitleBar>
        <Title>{`${t('timeline:current-playlist-menu.title')} (${playlistItems.length})`}</Title>
      </TitleBar>
      <PlaylistItemsList>
        {playlistItems.length > 0 ? (
          <>
            {playlistItems.map((item) => (
              <PlaylistItemsRow key={`current-playlist-${selectedPlaylist.id}-${item.id}`}>
                <PlaylistItem>{item.name}</PlaylistItem>
                <IconDelete
                  className={styles.deleteIcon}
                  size={IconSizes.small}
                  color={IconColors.storm}
                  onClick={() => onDeletePlaylistItem(item.id)}
                />
              </PlaylistItemsRow>
            ))}
          </>
        ) : (
          <NoClipText>{t('timeline:current-playlist-menu.no-clips')}</NoClipText>
        )}
      </PlaylistItemsList>
    </Box>
  );
};
