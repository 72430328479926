import React from 'react';

import { useTacticalAnalysisAppliedFilters } from '../../../../../hooks/use-tactical-analysis-applied-filters';
import { useTimelineTableData } from '../../../../../hooks/use-timeline-table-data';
import { RowHeader } from '../row-header';

export const FiltersHeaderRow = ({ recordingId }: { recordingId: string }) => {
  const timelineTableData = useTimelineTableData(recordingId);
  const { areFiltersApplied } = useTacticalAnalysisAppliedFilters(recordingId);

  if (!areFiltersApplied || !timelineTableData.filtersRow) return null;

  return <RowHeader recordingId={recordingId} row={timelineTableData.filtersRow} />;
};
