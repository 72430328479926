import classnames from 'classnames';
import React from 'react';

import { CBChecked, CBDisabled, CBIndetermine, CBUnchecked } from '../atomize-icons';
import './index.scss';

type CheckboxProps = React.HTMLProps<HTMLInputElement> & {
  children: JSX.Element | JSX.Element[] | string;
  id: string | number;
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  onChange?: Function;
  labelClassName?: string;
  isControlled?: boolean;
};

const Checkbox = (props: CheckboxProps) => {
  const {
    children,
    disabled,
    indeterminate,
    id,
    checked = false,
    labelClassName,
    isControlled = false,
    ...rest
  } = props;
  const className = classnames(
    'checkbox-label',
    {
      'checkbox-label--checked': checked,
      'checkbox-label--disabled': disabled,
      'checkbox-label--indeterminate': indeterminate,
    },
    labelClassName,
  );
  const checkedProps = isControlled ? { checked } : { defaultChecked: checked };
  const idString = id.toString();
  return (
    <label className={className} htmlFor={idString}>
      <input id={idString} type='checkbox' className='checkbox' disabled={disabled} {...checkedProps} {...rest} />
      {indeterminate ? (
        <CBIndetermine className='checkbox__icon checkbox__icon--indeterminate' />
      ) : disabled ? (
        <CBDisabled className='checkbox__icon checkbox__icon--disabled' />
      ) : checked ? (
        <CBChecked className='checkbox__icon checkbox__icon--checked' />
      ) : (
        <CBUnchecked className='checkbox__icon' />
      )}
      <span className='checkbox__content'>{children}</span>
    </label>
  );
};

export default Checkbox;
