import { TypeOf } from 'zod';

import { createPlayerFormSchema, editPlayerFormSchema } from './form';

export enum PlayerFormFieldsNames {
  name = 'name',
  playerImage = 'player_image',
}

export interface CreatePlayerForm {
  [PlayerFormFieldsNames.name]: string;
  [PlayerFormFieldsNames.playerImage]?: FileList;
}

export type CreatePlayerFormSchema = TypeOf<typeof createPlayerFormSchema>;

export interface EditPlayerForm {
  [PlayerFormFieldsNames.name]: string;
  [PlayerFormFieldsNames.playerImage]?: FileList;
}

export type EditPlayerFormSchema = TypeOf<typeof editPlayerFormSchema>;
