import { FormSwitchField } from 'shared/components/form/form-switch-field';

import { AnnotationFormLabels } from '../../../utils/form-fields-validation';
import { AnnotationFormFieldsNames } from '../../../utils/form-fields-validation/types';
import { FormFieldProps } from '../types';

export const LoadAnnotationsField = ({ register }: FormFieldProps) => {
  return (
    <FormSwitchField
      id={AnnotationFormFieldsNames.LOAD_ALL}
      defaultChecked
      label={AnnotationFormLabels[AnnotationFormFieldsNames.LOAD_ALL]}
      {...register(AnnotationFormFieldsNames.LOAD_ALL)}
    />
  );
};
