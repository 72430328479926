import { useQueryClient, UseQueryResult } from '@tanstack/react-query';

import { useFetchRequest } from 'api/hooks/useFetchRequest';

import { MetadataGame } from '../../../types/games';
import { annotationApiUrls } from '../../routes';
import { transformMetadataGame } from '../transformers';

type Params = {
  gameId?: string;
  onSuccess?: (data: MetadataGame) => void;
};

export const getMetadataGameQueryKey = (gameId?: string) => ['metadata-game', gameId ?? ''];

export const useMetadataGame = ({ gameId }: Params): UseQueryResult<MetadataGame> => {
  return useFetchRequest<MetadataGame>({
    queryRef: getMetadataGameQueryKey(gameId),
    url: `${annotationApiUrls.GAMES}/${gameId ?? ''}`,
    transformer: transformMetadataGame,
    options: {
      enabled: gameId !== undefined,
      keepPreviousData: true,
    },
  });
};

export const useInvalidateMetadataGame = () => {
  const queryClient = useQueryClient();

  return (gameId: string) => queryClient.invalidateQueries({ queryKey: getMetadataGameQueryKey(gameId) });
};
