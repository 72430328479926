import find from 'lodash/find';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

import { Episode, isEventStarting, isValidScenario, TacticalScenario } from 'shared/types';
import { RecordingsFilters } from 'shared/types/recording/types';
import { MatchSegmentTypes } from 'shared/types/segment/types';
import { GroupedTags } from 'shared/types/tagging-events/types';

import {
  generateBlockContainerClip,
  generateEpisodeClips,
  sortByTitle,
  sortScenarios,
  TimelineTableBlock,
} from './utils';
import { defensiveTactics, isOffensiveTactic, offensiveTactics } from '../../../config';
import { TacticActionType } from '../../../types';

export type RowType = 'episodes' | 'scenarios' | 'tactics' | 'events' | 'manual-tags' | 'filters';
export type ClipType =
  | 'episode'
  | 'scenario'
  | 'tactic'
  | 'filter'
  | 'event'
  | 'manual-tag'
  | 'not-effective-time'
  | 'block-container';

export type Row = {
  id: string;
  type: RowType;
  isHidden: boolean;
  clips: Clip[];
  title: string;
  entityId?: string;
  teamId?: string;
  isSelected?: boolean;
  scenarioSubRowDisabled?: 'home' | 'opponent';
};

type RowList = { [key in string]: Row };

export type RowGroup = {
  id: string;
  type: RowType;
  isSelected: boolean;
  isOpen: boolean;
  title: string;
  rows: Row[];
  totalClips: number;
  rowGroups?: RowGroup[];
};

export interface Clip {
  clips?: Clip[];
  endTime: number;
  id: string;
  startTime: number;
  teamId?: string;
  title: string;
  name?: string;
  rowId: string;
  type: ClipType;
  elementId: string;
  action?: TacticActionType;
  isSelected?: boolean;
}

const generateEpisodesRows = (timelineTableBlocks: TimelineTableBlock[], recordingId: string): Row => {
  return {
    id: `${recordingId}-episodes`,
    type: 'episodes',
    title: 'episode',
    isHidden: false,
    clips: generateEpisodeClips({ timelineTableBlocks, clipType: 'episode', rowId: 'episodes' }),
  };
};

const generateScenariosRows = (
  episodes: Episode[],
  recordingId: string,
): {
  scenariosRowGroup: RowGroup;
  scenariosIdsList: Set<string>;
} => {
  const rows: RowList = {};
  const scenariosIdsList: Set<string> = new Set();

  episodes.map((episode) => {
    episode.tacticalScenarios
      .filter((scenario) => isValidScenario(scenario.tacticalScenarioType))
      .map((scenario) => {
        const title = `fundamentals:tactical-scenario-types.${scenario.tacticalScenarioType}`;
        if (!rows[scenario.tacticalScenarioType]) {
          rows[scenario.tacticalScenarioType] = generateBlockContainerClip({
            id: `${recordingId}-${scenario.tacticalScenarioType}`,
            rowType: 'scenarios',
            timelineTableBlocks: episodes,
            title,
            rowId: `${recordingId}-${scenario.tacticalScenarioType}`,
            clipIdPrefix: scenario.tacticalScenarioType,
          });
        }

        const episodeClip = find(
          rows[scenario.tacticalScenarioType].clips,
          (row) => row.id === `${scenario.tacticalScenarioType}-${episode.id}`,
        );

        scenariosIdsList.add(scenario.tacticalScenarioType);
        episodeClip?.clips?.push({
          id: `${recordingId}-${scenario.tacticalScenarioType}-${scenario.teamId}-${scenario.startTime}-${scenario.endTime}`,
          startTime: scenario.startTime,
          endTime: scenario.endTime,
          type: 'scenario',
          elementId: scenario.tacticalScenarioType,
          rowId: `${recordingId}-${scenario.tacticalScenarioType}`,
          teamId: scenario.teamId,
          title,
        });
      });
  });

  const extractedRows = map(rows, (row) => row).sort(sortScenarios(`${recordingId}-`));
  const scenariosRowGroup: RowGroup = {
    id: `${recordingId}-scenarios`,
    isOpen: true,
    isSelected: false,
    title: 'timeline:scenarios',
    rows: extractedRows,
    totalClips: reduce(extractedRows, (acc, row) => acc + row.clips.length, 0),
    type: 'scenarios',
  };

  return { scenariosRowGroup, scenariosIdsList };
};

export const generateFiltersRow = (
  episodes: Episode[],
  filterEpisodes: Episode[],
  appliedFilters: RecordingsFilters,
  recordingId: string,
): Row => {
  const rowId = `${recordingId}-filters`;
  const row: Row = generateBlockContainerClip({
    id: rowId,
    rowType: 'filters',
    timelineTableBlocks: episodes,
    title: 'timeline:filter-results',
    rowId: rowId,
    clipIdPrefix: rowId,
  });

  const filterUniqueId = Date.now();
  const title = `timeline:filter-result`;

  const totalOffensiveTactics = appliedFilters.scenariosOrTacticsInside?.tactics.offensive.filter(
    (t) => t.tacticalFundamentalType,
  ).length;
  const totalDefensiveTactics = appliedFilters.scenariosOrTacticsInside?.tactics.defensive.filter(
    (t) => t.tacticalFundamentalType,
  ).length;

  const areFundamentalsFiltered = Boolean((totalOffensiveTactics ?? 0) + (totalDefensiveTactics ?? 0));

  filterEpisodes.forEach((episode) => {
    const unfilteredEpisode = episodes.find((anEpisode) => anEpisode.id === episode.id);

    if (areFundamentalsFiltered && episode.tacticalFundamentals) {
      const episodeClip =
        unfilteredEpisode &&
        find(
          row.clips,
          (clip) => unfilteredEpisode.startTime < clip.endTime && unfilteredEpisode.endTime > clip.startTime,
        );

      episodeClip?.clips?.push(
        ...episode.tacticalFundamentals?.map((fundamental) => ({
          id: `tactic-${fundamental.startTime}-${fundamental.endTime}`,
          startTime: fundamental.startTime,
          endTime: fundamental.endTime,
          type: 'filter' as ClipType,
          elementId: '',
          rowId: rowId,
          title,
        })),
      );
    }

    if (!areFundamentalsFiltered && episode.tacticalScenarios) {
      const startTime = Math.min(...episode.tacticalScenarios.map((scenario) => scenario.startTime));
      const endTime = Math.max(...episode.tacticalScenarios.map((scenario) => scenario.endTime));

      const episodeClip = find(row.clips, (clip) => {
        return clip.type !== 'not-effective-time' && startTime >= clip.startTime && startTime <= clip.endTime;
      });

      episodeClip?.clips?.push({
        id: `scenario-${startTime}-${endTime}-${filterUniqueId}`,
        startTime,
        endTime,
        type: 'filter',
        elementId: '',
        rowId: rowId,
        title,
      });
    }
  });

  return row;
};

const findLastValidScenarioInEpisode = (episode: Episode): TacticalScenario | undefined => {
  let highest = Number.NEGATIVE_INFINITY;
  let lastScenarioIndex = -1;
  let tmp;
  for (let i = episode.tacticalScenarios.length - 1; i >= 0; i--) {
    tmp = episode.tacticalScenarios[i].endTime;
    if (tmp > highest && !isEventStarting(episode.tacticalScenarios[i].tacticalScenarioType)) {
      highest = tmp;
      lastScenarioIndex = i;
    }
  }

  return lastScenarioIndex !== -1 ? episode.tacticalScenarios[lastScenarioIndex] : undefined;
};

const generateEventsRows = (episodes: Episode[], recordingId: string): RowGroup => {
  const endingEventsRowList: RowList = {};
  const startingEventsRowList: RowList = {};

  episodes.forEach((episode) => {
    const firstValidScenario = episode.tacticalScenarios[0];

    if (firstValidScenario) {
      const scenario = firstValidScenario;
      const id = `${recordingId}-${episode.startingState}`;

      if (!startingEventsRowList[episode.startingState]) {
        startingEventsRowList[episode.startingState] = generateBlockContainerClip({
          id: id,
          rowType: 'events',
          timelineTableBlocks: episodes,
          title: episode.startAction.name,
          rowId: id,
          clipIdPrefix: id,
        });
      }

      const episodeClip = find(
        startingEventsRowList[episode.startingState].clips,
        (row) => row.id === `${id}-${episode.id}`,
      );

      episodeClip?.clips?.push({
        id: `${episode.id}-${scenario.teamId}-${scenario.startTime}-${scenario.endTime}`,
        startTime: scenario.startTime,
        endTime: scenario.endTime,
        type: 'event',
        elementId: scenario.tacticalScenarioType,
        rowId: id,
        teamId: scenario.teamId,
        title: episode.startAction.name,
      });
    }

    const latestValidScenario = findLastValidScenarioInEpisode(episode);

    if (latestValidScenario) {
      const scenario = latestValidScenario;
      const id = `${recordingId}-${episode.endingState}`;

      if (!endingEventsRowList[episode.endingState]) {
        endingEventsRowList[episode.endingState] = generateBlockContainerClip({
          id: id,
          rowType: 'events',
          timelineTableBlocks: episodes,
          title: episode.endAction.name,
          rowId: id,
          clipIdPrefix: id,
        });
      }

      const episodeClip = find(
        endingEventsRowList[episode.endingState].clips,
        (row) => row.id === `${id}-${episode.id}`,
      );

      episodeClip?.clips?.push({
        id: `${episode.id}-${scenario.teamId}-${scenario.startTime}-${scenario.endTime}`,
        startTime: scenario.startTime,
        endTime: scenario.endTime,
        type: 'event',
        elementId: scenario.tacticalScenarioType,
        rowId: id,
        teamId: scenario.teamId,
        title: episode.endAction.name,
      });
    }
  });

  const startingEventsRows: Row[] = reduce(
    startingEventsRowList,
    (acc, row) => {
      acc.push(row);
      return acc;
    },
    [] as Row[],
  );

  const startingEventsRowGroup: RowGroup = {
    id: `${recordingId}-starting-events`,
    isOpen: true,
    isSelected: false,
    title: 'timeline:events-starting',
    rows: startingEventsRows.sort(sortByTitle),
    totalClips: reduce(startingEventsRows, (acc, row) => acc + row.clips.length, 0),
    type: 'events',
  };

  const endingEventsRows: Row[] = reduce(
    endingEventsRowList,
    (acc, row) => {
      acc.push(row);
      return acc;
    },
    [] as Row[],
  );

  const endingEventsRowsGroup: RowGroup = {
    id: `${recordingId}-ending-events`,
    isOpen: true,
    isSelected: false,
    title: 'timeline:events-ending',
    totalClips: reduce(endingEventsRows, (acc, row) => acc + row.clips.length, 0),
    rows: endingEventsRows.sort(sortByTitle),
    type: 'events',
  };

  const eventsRowGroups = [];
  startingEventsRowGroup.totalClips > 0 && eventsRowGroups.push(startingEventsRowGroup);
  endingEventsRowsGroup.totalClips > 0 && eventsRowGroups.push(endingEventsRowsGroup);

  return {
    id: `${recordingId}-events`,
    isOpen: true,
    isSelected: false,
    title: 'timeline:events',
    rows: [],
    totalClips: startingEventsRowGroup.totalClips + endingEventsRowsGroup.totalClips,
    rowGroups: eventsRowGroups,
    type: 'events',
  };
};

const generateTacticsRows = (
  episodes: Episode[],
  recordingId: string,
): {
  tacticsRowGroup: RowGroup;
  tacticsIdsList: Set<string>;
} => {
  const tacticsIdsList: Set<string> = new Set();

  const rows: RowList = {};
  episodes.forEach((episode) => {
    episode.tacticalFundamentals?.forEach((tactic) => {
      const rowId = `${recordingId}-${tactic.tacticalFundamentalType}-${tactic.teamId}`;
      if (!rows[rowId]) {
        rows[rowId] = generateBlockContainerClip({
          id: rowId,
          rowType: 'tactics',
          timelineTableBlocks: episodes,
          title: tactic.name,
          rowId: rowId,
          teamId: tactic.teamId,
          clipIdPrefix: rowId,
          entityId: tactic.tacticalFundamentalType,
        });
      }

      const episodeClip = find(rows[rowId].clips, (row) => row.id === `${rowId}-${episode.id}`);

      tacticsIdsList.add(tactic.tacticalFundamentalType);
      episodeClip?.clips?.push({
        id: `${rowId}-${tactic.startTime}-${tactic.endTime}`,
        startTime: tactic.startTime,
        endTime: tactic.endTime,
        type: 'tactic',
        rowId: rowId,
        teamId: tactic.teamId,
        elementId: tactic.tacticalFundamentalType,
        action: isOffensiveTactic(tactic.tacticalFundamentalType)
          ? TacticActionType.OFFENSIVE
          : TacticActionType.DEFENSIVE,
        title: tactic.name,
      });
    });
  });

  const offensiveRows = reduce(
    rows,
    (acc, row) => {
      if (row.entityId && offensiveTactics.includes(row.entityId)) {
        acc.push(row);
      }
      return acc;
    },
    [] as Row[],
  );

  const offensiveRowGroup: RowGroup = {
    id: 'offensive-tactics',
    isOpen: true,
    isSelected: false,
    title: 'timeline:offensive',
    rows: offensiveRows.sort(sortByTitle),
    totalClips: reduce(offensiveRows, (acc, row) => acc + row.clips.length, 0),
    type: 'tactics',
  };

  const defensiveRows = reduce(
    rows,
    (acc, row) => {
      if (row.entityId && defensiveTactics.includes(row.entityId)) {
        acc.push(row);
      }
      return acc;
    },
    [] as Row[],
  );

  const defensiveRowGroup: RowGroup = {
    id: 'defensive-tactics',
    isOpen: true,
    isSelected: false,
    title: 'timeline:defensive',
    totalClips: reduce(defensiveRows, (acc, row) => acc + row.clips.length, 0),
    rows: defensiveRows.sort(sortByTitle),
    type: 'tactics',
  };

  const tacticsRowGroup: RowGroup = {
    id: 'tactics',
    isOpen: true,
    isSelected: false,
    title: 'timeline:tactics',
    rows: [],
    totalClips: offensiveRowGroup.totalClips + defensiveRowGroup.totalClips,
    rowGroups: [offensiveRowGroup, defensiveRowGroup],
    type: 'tactics',
  };

  return { tacticsRowGroup: tacticsRowGroup, tacticsIdsList };
};

export const generateManualTagsRows = (
  timelineTableBlocks: TimelineTableBlock[],
  groupedTags: GroupedTags,
  recordingId: string,
): RowGroup => {
  const rows: RowList = {};

  forEach(groupedTags, (tags, tagGroup) => {
    tags.forEach((tag) => {
      const id = `${recordingId}-${tag.id}`;
      const rowId = `${recordingId}-${tagGroup}`;

      if (!rows[rowId]) {
        rows[rowId] = generateBlockContainerClip({
          id: rowId,
          rowType: 'manual-tags',
          timelineTableBlocks: timelineTableBlocks,
          title: `timeline:${tagGroup}`,
          rowId: rowId,
          clipIdPrefix: tagGroup,
        });
      }

      const startTime = tag.time - tag.timeBefore > 0 ? tag.time - tag.timeBefore : 0;
      //TODO limit with duration of the match
      const endTime = tag.time + tag.timeAfter;

      const episodeClip = find(
        rows[rowId].clips,
        (row) =>
          (startTime >= row.startTime && startTime <= row.endTime) ||
          (endTime >= row.startTime && endTime <= row.endTime),
      );

      if (episodeClip) {
        episodeClip?.clips?.push({
          id,
          startTime: startTime,
          endTime: endTime,
          type: 'manual-tag',
          elementId: tag.id,
          rowId: rowId,
          title: tag.name,
        });
      }
    });
  });

  return {
    id: 'manual-tags',
    isOpen: true,
    isSelected: false,
    title: 'timeline:manual-tags',
    rows: map(rows, (row) => row),
    totalClips: reduce(rows, (acc, row) => acc + row.clips.length, 0),
    type: 'manual-tags',
  };
};

export type TimelineTableData = {
  episodesRow: Row;
  scenariosRowGroup?: RowGroup;
  filtersRow?: Row;
  rowGroups: RowGroup[];
  scenariosIdsList: Set<string>;
  tacticsIdsList: Set<string>;
};

export const DEFAULT_TIMELINE_DATA: TimelineTableData = {
  episodesRow: {
    id: 'episodes',
    type: 'episodes',
    isHidden: false,
    title: 'episode',
    clips: [],
  },
  filtersRow: undefined,
  rowGroups: [],
  scenariosRowGroup: undefined,
  scenariosIdsList: new Set(),
  tacticsIdsList: new Set(),
};

const transformEpisodeToTimelineTableBlock = (episode: Episode): TimelineTableBlock => ({
  id: episode.id,
  name: episode.name,
  startTime: episode.startTime,
  endTime: episode.endTime,
  matchSegment: episode.matchSegment,
});

export const generateFullMatchTimelineBlockFromDuration = (duration: number): TimelineTableBlock => ({
  id: 'full-match',
  name: 'full-match-block',
  startTime: 0,
  endTime: duration,
  matchSegment: MatchSegmentTypes.FIRST,
});

export const generateTimelineRows = (
  episodes: Episode[],
  groupedTags: GroupedTags,
  duration: number,
  appliedFilters: RecordingsFilters,
  filteredEpisodes: Episode[],
  recordingId: string,
): TimelineTableData => {
  const timelineBlocks: TimelineTableBlock[] =
    episodes.length > 0
      ? episodes.map(transformEpisodeToTimelineTableBlock)
      : [generateFullMatchTimelineBlockFromDuration(duration)];

  const episodesRow = generateEpisodesRows(timelineBlocks, recordingId);
  const { scenariosRowGroup, scenariosIdsList } = generateScenariosRows(episodes, recordingId);
  const eventsRowGroup = generateEventsRows(episodes, recordingId);
  const { tacticsRowGroup, tacticsIdsList } = generateTacticsRows(episodes, recordingId);
  const manualTagsRowGroup = generateManualTagsRows(timelineBlocks, groupedTags, recordingId);
  const filtersRow = generateFiltersRow(episodes, filteredEpisodes, appliedFilters, recordingId);
  const rowGroups: RowGroup[] = [tacticsRowGroup, eventsRowGroup, manualTagsRowGroup];

  return {
    filtersRow,
    scenariosRowGroup,
    scenariosIdsList,
    tacticsIdsList,
    rowGroups,
    episodesRow,
  };
};
