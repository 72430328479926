import { createStore, StoreApi } from 'zustand/vanilla';
import { Homographies, INITIAL_QUALITY, PlayersPositions, QUALITY_TO_SCALE_FACTOR, TacticId } from '../main';
import { OverlayElementDebugInfo } from '../overlay-canvas-renderer';
import { Teams } from '../overlay-canvas-renderer/types';
import { PitchSize } from '../types';
import { OverlayTactic } from '../utils/loaders';
import { validateChunkData } from './utils/validateChunkData';

const DEFAULT_CHUNK_SIZE = 3750;

export const DEFAULT_DATA: OverlayGeneratorChunkData = {};

export type FrameInfo = {
  frameNumber: number;
  frameTactics: TacticId[];
  overlayElementsDebugInfo: OverlayElementDebugInfo[];
};

export type MetaData = {
  pitch: { size: PitchSize; originalSize: PitchSize };
  video: { width: number; height: number; frameRate: number; duration: number; frameCount: number };
  teams: Teams;
  chunkSize: number;
};

export const DEFAULT_META_DATA: MetaData = {
  pitch: {
    size: {
      width: 0,
      length: 0,
    },
    originalSize: { width: 0, length: 0 },
  },
  video: { width: 0, height: 0, frameRate: 25, duration: 0, frameCount: 0 },
  teams: { homeTeam: { id: '', playerIds: [] }, awayTeam: { id: '', playerIds: [] } },
  chunkSize: DEFAULT_CHUNK_SIZE,
};

const INITIAL_STATUS = {
  isLoadingData: false,
  isLoadingAsyncData: false,
};

export const INITIAL_FRAME_INFO = {
  frameNumber: 0,
  frameTactics: [],
  overlayElementsDebugInfo: [],
};

type OverlayGeneratorData = {
  homographies: Homographies;
  playersPositions: PlayersPositions;
  overlayTactics: OverlayTactic[];
};

export type OverlayGeneratorChunkData = {
  [key in number]:
    | {
        status: 'success';
        data: OverlayGeneratorData;
      }
    | {
        status: 'error';
        data: undefined;
      };
};

type Status = {
  isLoadingData: boolean;
  isLoadingAsyncData: boolean;
};

export type OverlayGeneratorStore = {
  chunkData: OverlayGeneratorChunkData;
  renderScale: number;
  tacticalAnalysisId: string;
  recordingId: string;
  metaData: MetaData;
  status: Status;
  frameInfo: FrameInfo;
};

enum ActionTypes {
  SET_SUCCESS_CHUNK_DATA = 'SET_SUCCESS_CHUNK_DATA',
  SET_ERROR_CHUNK_DATA = 'SET_ERROR_CHUNK_DATA',
  SET_TACTICAL_ANALYSIS_ID = 'SET_TACTICAL_ANALYSIS_ID',
  SET_RECORDING_ID = 'SET_RECORDING_ID',
  SET_META_DATA = 'SET_META_DATA',
  RESET = 'RESET',
  SET_RENDER_SCALE = 'SET_RENDER_SCALE',
  START_LOADING_DATA = 'START_LOADING_DATA',
  START_LOADING_ASYNC_DATA = 'START_LOADING_ASYNC_DATA',
  FINISH_LOADING_DATA = 'FINISH_LOADING_DATA',
  FINISH_LOADING_ASYNC_DATA = 'FINISH_LOADING_ASYNC_DATA',
  SET_FRAME_INFO = 'SET_FRAME_INFO',
  VALIDATE_CHUNK_DATA_MEMORY = 'VALIDATE_CHUNK_DATA_MEMORY',
}

type Actions =
  | { type: ActionTypes.SET_SUCCESS_CHUNK_DATA; chunkNumber: number; payload: OverlayGeneratorData }
  | { type: ActionTypes.SET_ERROR_CHUNK_DATA; chunkNumber: number }
  | { type: ActionTypes.SET_TACTICAL_ANALYSIS_ID; tacticalAnalysisId: string }
  | { type: ActionTypes.SET_RECORDING_ID; recordingId: string }
  | { type: ActionTypes.SET_META_DATA; payload: MetaData }
  | { type: ActionTypes.RESET }
  | { type: ActionTypes.START_LOADING_DATA }
  | { type: ActionTypes.START_LOADING_ASYNC_DATA }
  | { type: ActionTypes.FINISH_LOADING_DATA }
  | { type: ActionTypes.FINISH_LOADING_ASYNC_DATA }
  | { type: ActionTypes.SET_FRAME_INFO; frameInfo: FrameInfo }
  | { type: ActionTypes.SET_RENDER_SCALE; renderScale: number }
  | { type: ActionTypes.VALIDATE_CHUNK_DATA_MEMORY; chunkNumber: number };

const reducer = (state: OverlayGeneratorStore, action: Actions): OverlayGeneratorStore => {
  switch (action.type) {
    case ActionTypes.SET_SUCCESS_CHUNK_DATA:
      return {
        ...state,
        chunkData: { ...state.chunkData, [action.chunkNumber]: { status: 'success', data: action.payload } },
      };
    case ActionTypes.SET_ERROR_CHUNK_DATA:
      return {
        ...state,
        chunkData: { ...state.chunkData, [action.chunkNumber]: { status: 'error', data: undefined } },
      };
    case ActionTypes.SET_TACTICAL_ANALYSIS_ID:
      return { ...state, tacticalAnalysisId: action.tacticalAnalysisId, chunkData: DEFAULT_DATA };
    case ActionTypes.SET_RECORDING_ID:
      return { ...state, recordingId: action.recordingId, chunkData: DEFAULT_DATA };
    case ActionTypes.SET_META_DATA:
      return { ...state, metaData: action.payload };
    case ActionTypes.SET_RENDER_SCALE:
      return { ...state, renderScale: action.renderScale };
    case ActionTypes.START_LOADING_DATA:
      return { ...state, status: { ...state.status, isLoadingData: true } };
    case ActionTypes.START_LOADING_ASYNC_DATA:
      return { ...state, status: { ...state.status, isLoadingAsyncData: true } };
    case ActionTypes.FINISH_LOADING_DATA:
      return { ...state, status: { ...state.status, isLoadingData: false } };
    case ActionTypes.FINISH_LOADING_ASYNC_DATA:
      return { ...state, status: { ...state.status, isLoadingAsyncData: false } };
    case ActionTypes.SET_FRAME_INFO:
      return { ...state, frameInfo: action.frameInfo };
    case ActionTypes.VALIDATE_CHUNK_DATA_MEMORY:
      return validateChunkData(state, action.chunkNumber);
    case ActionTypes.RESET:
      return { ...state, chunkData: DEFAULT_DATA, metaData: DEFAULT_META_DATA, status: INITIAL_STATUS };
    default:
      return state;
  }
};

type StoreActions = {
  setChunkData: (chunkNumber: number, payload: OverlayGeneratorData) => void;
  setErrorChunkData: (chunkNumber: number) => void;
  setTacticalAnalysisId: (tacticalAnalysisId: string) => void;
  setRecordingId: (recordingId: string) => void;
  setMetaData: (payload: MetaData) => void;
  reset: () => void;
  setRenderScale: (renderScale: number) => void;
  setFrameInfo: (frameInfo: FrameInfo) => void;
  startLoadingData: () => void;
  startLoadingAsyncData: () => void;
  finishLoadingData: () => void;
  finishLoadingAsyncData: () => void;
  validateChunkDataMemory: (chunkNumber: number) => void;
};

const initStore = (): { store: StoreApi<OverlayGeneratorStore>; actions: StoreActions } => {
  const store = createStore<OverlayGeneratorStore>(() => ({
    chunkData: DEFAULT_DATA,
    metaData: DEFAULT_META_DATA,
    tacticalAnalysisId: '',
    recordingId: '',
    renderScale: QUALITY_TO_SCALE_FACTOR[INITIAL_QUALITY],
    status: INITIAL_STATUS,
    frameInfo: INITIAL_FRAME_INFO,
  }));

  const { getState, setState } = store;

  const actions = {
    setChunkData: (chunkNumber: number, payload: OverlayGeneratorData) =>
      setState(
        reducer(getState(), {
          type: ActionTypes.SET_SUCCESS_CHUNK_DATA,
          chunkNumber: chunkNumber,
          payload,
        }),
      ),
    setErrorChunkData: (chunkNumber: number) =>
      setState(
        reducer(getState(), {
          type: ActionTypes.SET_ERROR_CHUNK_DATA,
          chunkNumber: chunkNumber,
        }),
      ),
    setTacticalAnalysisId: (tacticalAnalysisId: string) =>
      setState(reducer(getState(), { type: ActionTypes.SET_TACTICAL_ANALYSIS_ID, tacticalAnalysisId })),
    setRecordingId: (recordingId: string) =>
      setState(reducer(getState(), { type: ActionTypes.SET_RECORDING_ID, recordingId })),
    setMetaData: (payload: MetaData) => setState(reducer(getState(), { type: ActionTypes.SET_META_DATA, payload })),
    reset: () => setState(reducer(getState(), { type: ActionTypes.RESET })),
    setRenderScale: (renderScale: number) =>
      setState(reducer(getState(), { type: ActionTypes.SET_RENDER_SCALE, renderScale })),
    startLoadingData: () => setState(reducer(getState(), { type: ActionTypes.START_LOADING_DATA })),
    finishLoadingData: () => setState(reducer(getState(), { type: ActionTypes.FINISH_LOADING_DATA })),
    startLoadingAsyncData: () => setState(reducer(getState(), { type: ActionTypes.START_LOADING_ASYNC_DATA })),
    finishLoadingAsyncData: () => setState(reducer(getState(), { type: ActionTypes.FINISH_LOADING_ASYNC_DATA })),
    setFrameInfo: (frameInfo: FrameInfo) =>
      setState(reducer(getState(), { type: ActionTypes.SET_FRAME_INFO, frameInfo })),
    validateChunkDataMemory: (chunkNumber: number) =>
      setState(reducer(getState(), { type: ActionTypes.VALIDATE_CHUNK_DATA_MEMORY, chunkNumber: chunkNumber })),
  };

  return { actions, store };
};

export { initStore };
