import React, { forwardRef, PropsWithChildren } from 'react';

import styles from './EpisodesRow.module.scss';
import { useTacticalAnalysisEpisodes } from '../../../../../hooks/use-tactical-analysis-episodes';
import { useTimelineInnerWidth } from '../../hooks/use-generate-timeline-width';

export const EpisodesRow = forwardRef(
  (
    {
      children,
      recordingId,
    }: PropsWithChildren<{
      recordingId: string;
    }>,
    ref: any,
  ) => {
    const episodes = useTacticalAnalysisEpisodes(recordingId);
    const width = useTimelineInnerWidth(recordingId);

    if (episodes.length === 0) return null;

    return (
      <div ref={ref} className={styles.episodesRow} style={{ width: `${width}px` }}>
        {children}
      </div>
    );
  },
);

EpisodesRow.displayName = 'EpisodesRow';
