export type MatchSegment = 'first' | 'second' | 'firstextra' | 'secondextra' | 'penalty';

export interface Episode {
  dashUrl: string;
  endTime: number;
  endTimeInSegment: number;
  endingState: string;
  endAction: EpisodeAction;
  id: string;
  videoSourceId: string;
  matchSegment: MatchSegment;
  name: string;
  recordingId: string;
  tacticalAnalysisId: string;
  s3Url: string;
  startTime: number;
  startTimeInSegment: number;
  startingState: string;
  startAction: EpisodeAction;
  tacticalScenarios: TacticalScenario[];
  tacticalFundamentals?: TacticalFundamental[];
}

export type EpisodeAction = {
  state: string;
  teamId: string;
  zone: number;
  time: number;
  timeInSegment: number;
  name: string;
};

export type TacticalScenario = {
  endTime: number;
  endTimeInSegment: number;
  matchSegment: MatchSegment;
  startTime: number;
  startZone: number;
  tacticalFundamentals: TacticalFundamental[];
  tacticalScenarioType: TacticalScenarioType;
  teamId: string;
  name: string;
};

export type StartPlayTacticalScenarioType =
  | 'in_play|pass|kickoff'
  | 'in_play|pass|goal_kick'
  | 'in_play|pass|throw_in'
  | 'in_play|pass|corner_kick'
  | 'in_play|pass|free_kick'
  | 'in_play|pass|drop_ball'
  | 'in_play|shot|free_kick'
  | 'in_play|shot|penalty_kick'
  | 'in_play|shot|corner_kick';

export type EndPlayTacticalScenarioType =
  | 'out_of_play|out_of_bounds|touch_line'
  | 'out_of_play|out_of_bounds|attacking_goal_line'
  | 'out_of_play|out_of_bounds|defending_goal_line'
  | 'out_of_play|goal'
  | 'out_of_play|stopped_by_referee|foul|offside'
  | 'out_of_play|stopped_by_referee|foul'
  | 'out_of_play|stopped_by_referee|foul|in_penalty_area'
  | 'out_of_play|stopped_by_referee|other_stop'
  | 'out_of_play|end_of_play';

export type ListedTacticalScenarioType =
  | 'build-up'
  | 'build-up-ab'
  | 'build-up-cd'
  | 'change-of-possession'
  | 'finishing'
  | 'long-game'
  | 'possession-a'
  | 'possession-bc'
  | 'possession-d'
  | 'recovery-ball'
  | 'change-of-possession-no-possession'
  | 'possession-a-no-possession'
  | 'possession-bc-no-possession'
  | 'possession-d-no-possession';

export type NotListedTacticalScenarioType =
  | 'corner-kick'
  | 'drop-ball'
  | 'free-kick'
  | 'goal-kick'
  | 'kickoff'
  | 'out-of-play'
  | 'penalty-kick'
  | 'throw-in';

export type TacticalScenarioType = ListedTacticalScenarioType | NotListedTacticalScenarioType;

export enum Scenarios {
  BUILD_UP = 'build-up',
  BUILD_UP_AB = 'build-up-ab',
  BUILD_UP_CD = 'build-up-cd',
  POSSESION_A = 'possession-a',
  POSSESION_BC = 'possession-bc',
  POSSESION_D = 'possession-d',
  CHANGE_OF_POSSESSION = 'change-of-possession',
}

export enum EventsStarting {
  CORNER_KICK = 'corner-kick',
  DROP_BALL = 'drop-ball',
  FREE_KICK = 'free-kick',
  KICKOFF = 'kickoff',
  THROW_IN = 'throw-in',
  GOAL_KICK = 'goal-kick',
  LONG_GAME = 'long-game',
  PENALTY_KICK = 'penalty-kick',
}

export const isEventStarting = (event: string) => {
  return Object.values(EventsStarting).includes(event as EventsStarting);
};

export const isValidScenario = (scenario: string) => {
  return Object.values(Scenarios).includes(scenario as Scenarios);
};

export type TacticalFundamental = {
  tacticalFundamentalType: string;
  teamId: string;
  players: string[];
  startTime: number;
  endTime: number;
  name: string;
};

export type StartingState = 'in_play' | 'pass' | 'kickoff';
export type EndingState = 'in_play' | 'uncontrolled';
