import classNames from 'classnames';

import { RowGroup } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows';
import { useRowGroupIsCollapsed } from '../../store/atoms';
import { RowGroupRows } from '../row-group-rows';
import styles from '../Rows.module.scss';

type Props = {
  rowGroup: RowGroup;
  recordingId: string;
  level: number;
};

export const RowsContentGroup = ({ rowGroup, recordingId, level = 0 }: Props) => {
  const isCollapsed = useRowGroupIsCollapsed(rowGroup.id);

  return (
    <>
      <div className={classNames(styles.rowGroupHeaderContainer, styles[`level-${level}`])} />
      <div style={{ display: isCollapsed ? 'none' : 'block' }}>
        {rowGroup.rowGroups ? (
          rowGroup.rowGroups.map((rowGroup) => (
            <RowsContentGroup level={1} key={rowGroup.id} rowGroup={rowGroup} recordingId={recordingId} />
          ))
        ) : (
          <RowGroupRows rowsGroup={rowGroup} recordingId={recordingId} />
        )}
      </div>
    </>
  );
};
