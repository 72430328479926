import { z } from 'zod';

import { RecordingTeam } from 'pages/recordings-list/types';
import { Zones } from 'pages/tactical-analysis/components/tactical-analysis/filters/types';

import {
  RecordingFiltersScenariosOrTacticsInsideSchema,
  RecordingFiltersTacticSchema,
  RecordingFiltersTacticsSchema,
  RecordingsFiltersEventsSchema,
  RecordingsFiltersSchema,
} from './schemas';

export enum RecordingTypes {
  ALL = 'all',
  TRAINING = 'training',
  GAME = 'game',
}

export enum TacticalAnalysisStates {
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
  VALIDATED = 'VALIDATED',
}

export interface TacticalAnalysis {
  id: string;
  recordingId: string;
  tacticalAnalysisGitCommit: string;
  numberOfEpisodes: number;
  startTime: Date;
  state: TacticalAnalysisStates;
}

export enum VideoSourceStates {
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
}

export interface VideoSourceState {
  playBackType: PlayBackTypes;
  viewType: ViewTypes;
  state: VideoSourceStates;
}

export type Recording = {
  competitionName: string;
  date: Date;
  duration: number | null;
  extraTime: number | null;
  hasTaggingEvents: boolean;
  id: string;
  isLive: boolean;
  isProcessingVideo: boolean;
  matchDay: string;
  name: string;
  tacticalAnalysis: TacticalAnalysis | null;
  teams: RecordingTeam[];
  type: RecordingTypes;
  videoSourcesStates: VideoSourceState[];
  matchReportDownloadUrl: string | null;
};

export type EditFormRecording = {
  homeTeamScore: number;
  awayTeamScore: number;
  competitionName: string;
  date: Date;
  duration: number | null;
  id: string;
  matchDay: string;
  name: string;
  type: RecordingTypes;
};

export interface RecordingsGroup {
  matchDay: string;
  recordings: Recording[];
}

export interface VideoSourceBase {
  id: string;
  recordingId: string;
  playBackType: PlayBackTypes;
  poster?: string | null;
  src: string;
  srcDownload?: string;
  videoView: VideoView;
}

export interface VideoSource extends VideoSourceBase {
  duration: number;
}

export interface VideoSourceAPI extends VideoSourceBase {
  duration: string;
}

export interface VideoView {
  type: ViewTypes;
  isEffectiveTime: boolean;
}

export enum ViewTypes {
  TACTICAL = 'tactical',
  PANORAMA = 'panorama',
  UPLOAD = 'upload',
}

export enum PlayBackTypes {
  VOD = 'vod',
  LIVE = 'live',
}

export type RecordingByName = {
  awayTeamScore: number | null;
  competitionName?: string;
  date: Date;
  duration: number | null;
  homeTeamScore: number | null;
  id: string;
  matchDay?: string;
  name: string;
  videoSources: VideoSource[];
  type: RecordingTypes;
  hasTacticalAnalysis?: boolean;
  hasHomographies: boolean;
};

export type ZoneValues = {
  [Zones.zone1]: boolean;
  [Zones.zone2]: boolean;
  [Zones.zone3]: boolean;
  [Zones.zone4]: boolean;
};

export type RecordingFiltersTactic = z.TypeOf<typeof RecordingFiltersTacticSchema>;
export type RecordingFiltersTactics = z.TypeOf<typeof RecordingFiltersTacticsSchema>;
export type RecordingsFiltersEvents = z.TypeOf<typeof RecordingsFiltersEventsSchema>;
export type RecordingFiltersScenariosOrTacticsInside = z.TypeOf<typeof RecordingFiltersScenariosOrTacticsInsideSchema>;
export type RecordingsFilters = z.TypeOf<typeof RecordingsFiltersSchema>;
