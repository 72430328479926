import { useCallback } from 'react';

import { useSaveUserPreset } from 'api/user-presets/use-save-user-preset';
import { useVideoPlayerActions } from 'shared/components/video-player';
import { TimelineTimePreset } from 'shared/types/user-preset/types';

import { useSetTimelineInitialTime } from '../../../../../hooks/use-timeline-initial-time';

export const useJumpToTimeInMatch = (recordingId: string) => {
  const actions = useVideoPlayerActions();
  const { savePreset } = useSaveUserPreset();
  const setTimelineInitialTime = useSetTimelineInitialTime(recordingId);

  return useCallback(
    (time: number) => {
      const timePreset = new TimelineTimePreset(time, recordingId);
      savePreset({ data: timePreset });

      setTimelineInitialTime(time);
      actions.jumpToTimeInMatch(time);
    },
    [actions, recordingId, savePreset, setTimelineInitialTime],
  );
};
