import { FormControl, FormHelperText, MenuItem, Select, SelectProps } from '@mui/material';
import React from 'react';

import { FormField } from '../form-field';

interface Props extends SelectProps {
  id: string;
  options: any;
  showNoneOption?: boolean;
  helperText?: React.ReactNode;
  fullWidth?: boolean;
}

export const FormSelectField = React.forwardRef(
  (
    { id, label, helperText, options, required, showNoneOption = false, fullWidth = true, ...rest }: Props,
    ref: any,
  ) => {
    const labelId = `${id}-label`;

    return (
      <FormField id={id} label={label} required={required}>
        <FormControl fullWidth={fullWidth} size='small' required={required}>
          <Select ref={ref} labelId={labelId} id={id} name={id} {...rest}>
            {rest?.defaultValue === '' || showNoneOption ? (
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
            ) : null}
            {options}
          </Select>
          {helperText ? <FormHelperText error>{helperText}</FormHelperText> : null}
        </FormControl>
      </FormField>
    );
  },
);

FormSelectField.displayName = 'AnnotationSelectField';
