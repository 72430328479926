import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import KogniaLogoSmall from 'shared/assets/kognia-sports-logo-small.svg';
import KogniaLogotype from 'shared/assets/kognia-sports-logotype.svg';

interface Props {
  isSmall: boolean;
}

export const SidebarLogo = ({ isSmall }: Props) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      {isSmall ? (
        <img src={KogniaLogoSmall} alt={t('common:navigation.logo.alt')} />
      ) : (
        <img src={KogniaLogotype} alt={t('common:navigation.logo.alt')} />
      )}
    </Box>
  );
};
