import classNames from 'classnames';

import styles from './AppLoading.module.scss';
import KogniaGIF from './assets/animated-kognia-sports-logo.gif';

interface Props {
  isLoaded: boolean;
}

const AppLoading = ({ isLoaded }: Props) => {
  return (
    <div
      data-testid='app-loading'
      className={classNames(styles.appLoadingContainer, {
        [styles.loaded]: isLoaded,
      })}
    >
      <img src={KogniaGIF} alt='Kognia Sports Intelligence' />
    </div>
  );
};

export default AppLoading;
