import { useMutation } from '@tanstack/react-query';

import { useBackendApi } from 'api/hooks/useBackendApi';
import { specificRecordingUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { RecordingUpdate } from 'pages/recording-edit/types';
import { EditFormRecording, Recording } from 'shared/types/recording/types';
import { toISODateString } from 'tagging-tool/utility/date';

const extractRecordingUpdateData = (recording: EditFormRecording): RecordingUpdate => ({
  name: recording.name,
  competitionName: recording.competitionName,
  matchday: recording.matchDay,
  date: toISODateString(recording.date),
  type: recording.type,
  home_team_score: recording.homeTeamScore,
  away_team_score: recording.awayTeamScore,
});

export const useUpdateRecording = (
  recordingId: string,
  onSuccess?: () => void,
  onError?: () => void,
  onSettled?: () => void,
) => {
  const onPatchSuccess = (data: Recording) => data;
  const patchUrl = specificRecordingUrl(recordingId);
  const updateRecording = useMutation(
    (params: EditFormRecording) =>
      useBackendApi(patchUrl, HTTPMethod.PATCH, onPatchSuccess, extractRecordingUpdateData(params)),
    {
      onMutate: async (params: EditFormRecording) => {},
      onError: () => {
        if (onError) onError();
      },
      onSuccess: () => {
        if (onSuccess) onSuccess();
      },
      onSettled: () => {
        if (onSettled) onSettled();
      },
    },
  );

  const sendUpdate = (updateParams: EditFormRecording) => {
    updateRecording.mutate(updateParams);
  };

  const isUpdating = updateRecording.isLoading;

  return { sendUpdate, isUpdating };
};
