import { Button, TextField } from '@mui/material';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog } from 'shared/components/dialog';
import { DialogActions } from 'shared/components/dialog/dialog-actions';
import { DialogButtonContainer } from 'shared/components/dialog/dialog-button-container';
import { DialogContent } from 'shared/components/dialog/dialog-content';
import { DialogHeader } from 'shared/components/dialog/dialog-header';
import IconKeypad from 'shared/components/icons/icon-keypad';
import { IconSizes } from 'shared/components/icons/svg-icon';

interface Props {
  open: boolean;
  name?: string;
  onSave: (name: string) => void;
  onCancel: () => void;
}

export const KeypadNameModal = ({ open, name = '', onSave, onCancel }: Props) => {
  const [nameValue, setNameValue] = useState(name);
  const { t } = useTranslation();

  const isSubmitDisabled = useMemo(() => !nameValue, [nameValue]);

  const handleSubmit = useCallback(() => {
    if (isSubmitDisabled) return false;
    return onSave(nameValue);
  }, [isSubmitDisabled, nameValue, onSave]);

  const handleChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setNameValue(e.target.value);
  }, []);

  return (
    <Dialog open={open} maxWidth='sm' fullWidth onClose={onCancel}>
      <DialogHeader icon={<IconKeypad size={IconSizes.small} />}>
        {t('tagging-tool:keypad.change-name-modal-title')}
      </DialogHeader>
      <DialogContent>
        <TextField fullWidth size='small' value={nameValue} onChange={handleChangeName} />
      </DialogContent>
      <DialogActions>
        <DialogButtonContainer>
          <Button variant='contained' color='secondary' onClick={onCancel}>
            {t('common:actions.cancel')}
          </Button>
        </DialogButtonContainer>
        <DialogButtonContainer>
          <Button variant='contained' color='primary' onClick={handleSubmit} disabled={isSubmitDisabled}>
            {t('common:actions.save')}
          </Button>
        </DialogButtonContainer>
      </DialogActions>
    </Dialog>
  );
};
