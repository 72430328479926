import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { DialogNew } from 'shared/components/dialog-new';
import { IconUser } from 'shared/components/icons/icon-user';
import { IconSizes } from 'shared/components/icons/svg-icon';

import { useCreateVenue } from '../../../../api/venues/use-create-venue';
import { useUpdateVenue } from '../../../../api/venues/use-update-venue';
import { VenuesForm } from '../venue-form';
import { venueFormSchema } from '../venue-form/form';
import { VenueForm, VenueFormSchema } from '../venue-form/types';

interface Props {
  open: boolean;
  onClose: () => void;
  defaultValues?: VenueForm;
}

const venuesFormId = 'venues-form';

export const VenueModal = ({ open, onClose, defaultValues }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm<VenueFormSchema>({
    resolver: zodResolver(venueFormSchema),
    defaultValues,
  });

  const { create } = useCreateVenue();
  const { update } = useUpdateVenue();

  const handleCreate = useCallback(
    (data: VenueForm) => {
      create({
        data,
        onSuccess: () => {
          onClose();
          reset();
        },
      });
    },
    [create, onClose, reset],
  );
  const handleUpdate = useCallback(
    (data: VenueForm) => {
      if (!data?.id) return;
      const { id, ...rest } = data;
      update({
        data: rest,
        id,
        onSuccess: onClose,
      });
    },
    [onClose, update],
  );

  const handleCreateOrUpdate = useCallback(
    (data: VenueForm) => {
      if (defaultValues) {
        handleUpdate(data);
      } else {
        handleCreate(data);
      }
    },
    [defaultValues, handleCreate, handleUpdate],
  );

  return (
    <DialogNew
      maxWidth='sm'
      fullWidth
      title={defaultValues ? 'Edit venue' : 'New venue'}
      icon={<IconUser size={IconSizes.small} />}
      onCancel={onClose}
      onClose={onClose}
      buttonSubmitText={defaultValues ? 'Save' : 'Create'}
      open={open}
      buttonFormId={venuesFormId}
    >
      <form id={venuesFormId} ref={formRef} onSubmit={handleSubmit(handleCreateOrUpdate)}>
        <VenuesForm<VenueFormSchema> register={register} errors={errors} control={control} />
      </form>
    </DialogNew>
  );
};
