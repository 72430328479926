interface MakeFetchParams {
  url: string;
  fetchInterface: any;
  headers?: HeadersInit;
}

export const makeFetch = async <TData>({ url, headers, fetchInterface }: MakeFetchParams): Promise<TData> => {
  // @ts-ignore
  const response = await fetch(url, { headers });

  if (response.status !== 200) {
    throw new Error(`Error fetching ${url}, status: ${response.status}, statusText: ${response.statusText}`);
  }

  return response.json();
};
