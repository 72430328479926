import { useTranslation } from 'react-i18next';

import Button from 'shared/components/button';

import styles from './NotFound.module.scss';
import RecordingCardLoading from '../../vod-recording-card/loading';

interface RecordingsListNotFoundProps {
  onNotFoundClick?: () => void;
}

const NotFound = ({ onNotFoundClick }: RecordingsListNotFoundProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles['recordings-list--not-found']}>
      <RecordingCardLoading isAnimated={false} />
      <RecordingCardLoading isAnimated={false} />
      <RecordingCardLoading isAnimated={false} />
      <RecordingCardLoading isAnimated={false} />
      <div className={styles['recordings-list__not-found-text']}>
        <img src={'/assets/recordings-list/tactics-not-found.svg'} alt='' />
        <h5 className={styles['recordings-list__not-found-title']}>{t('recordings-list:not-found.header')}</h5>
        <p>{t('recordings-list:not-found.description')}</p>
        <Button
          className={styles['recordings-list__try-another-button']}
          onClick={() => {
            onNotFoundClick && onNotFoundClick();
          }}
        >
          {t('recordings-list:not-found.try-another-combination')}
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
