import { List } from '@mui/material';

import { MetadataPlayerSummary } from '../../../../types/players';
import { PlayerItem } from '../player-item';

interface Props {
  players: MetadataPlayerSummary[];
}

export const PlayersList = ({ players }: Props) => {
  const playersItems = players.map((player) => {
    return <PlayerItem key={player.id} player={player} />;
  });

  return <List>{playersItems}</List>;
};
