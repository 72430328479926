import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const videoPlayerSpeedAtom = atom({
  key: 'video-player-speed',
  default: 1,
});

export const useVideoPlayerSpeed = () => {
  return useRecoilValue(videoPlayerSpeedAtom);
};

export const useSetVideoPlayerSpeed = () => {
  return useSetRecoilState(videoPlayerSpeedAtom);
};
