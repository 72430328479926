import { Box, styled } from '@mui/material';

interface PlayerImageProps {
  fullSize?: boolean;
}

export const PlayerImageContainer = styled(Box)(() => ({
  borderRadius: '50%',
  overflow: 'hidden',
}));

export const PlayerImageElement = styled('img', { shouldForwardProp: (prop) => prop !== 'fullSize' })<PlayerImageProps>(
  ({ fullSize }) => ({
    width: fullSize ? '100%' : '36px',
    height: fullSize ? '100%' : '36px',
    objectFit: 'contain',
  }),
);
