import { Keypad, KeypadApiResponse, KeypadsApiResponse, KeypadSimple } from '../../types';

export const transformKeypads = (keypads: KeypadsApiResponse): KeypadSimple[] => {
  return keypads.map((keypad) => ({
    id: keypad.id,
    name: keypad.name,
    isDefault: keypad.isDefault,
    numberOfTags: keypad.nTags,
  }));
};

export const transformKeypad = (keypad: KeypadApiResponse): Keypad => {
  return {
    id: keypad.id,
    name: keypad.name,
    tags: keypad.tags.map((tag) => ({
      keypadId: tag.keypadId,
      keypadTagId: tag.keypadTagId,
      name: tag.name,
      typeOfPlay: tag.typeOfPlay,
      timeBefore: tag.timeBefore,
      timeAfter: tag.timeAfter,
      hotKey: tag.hotKey,
      description: tag.description,
      typeOfPlaySource: tag.typeOfPlaySource,
      isEnabled: tag.isEnabled,
      isCustom: tag.isCustom,
    })),
  };
};
