import { UseQueryResult } from '@tanstack/react-query';

import { queryClient } from 'api/config';
import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { userPresetsWithFiltersUrl } from 'api/routes';
import { UserPreset, UserPresetScope } from 'shared/types/user-preset/types';

import { transformUserPreset } from '../transformers';

type Result<T> = UseQueryResult<UserPreset<T>[]>;

interface Parameters<T> {
  scope: UserPresetScope;
  onSuccess?: (data: UserPreset<T>[]) => void;
  key?: string;
  ref?: string;
  prefix?: string;
}

const queryRef = 'user-presets';

export const invalidateUserPresetsQuery = () => queryClient.invalidateQueries({ queryKey: [queryRef] });

export const useUserPresets = <T>({ scope, onSuccess, ref, key, prefix = '' }: Parameters<T>): Result<T> => {
  return useFetchRequest<UserPreset<T>[]>({
    queryRef: [queryRef, `${queryRef}-${scope}-${key ?? ''}-${ref ?? ''}-${prefix}`],
    url: userPresetsWithFiltersUrl({ scope, ref, key }),
    transformer: transformUserPreset,
    onSuccess,
    options: {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  });
};
