import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';

import { MetadataTeamApiResponseWithPlayers, MetadataTeamsApiResponse } from './use-teams/types';
import { MetadataTeamWithFixtures, TeamWithPlayers } from '../../types/teams';

export const transformTeam = (team: MetadataTeamApiResponseWithPlayers): TeamWithPlayers => {
  return {
    id: team.uuid,
    name: team.name,
    abbreviation: team.abbreviation,
    logoUrl: team.logo_url,
    teamType: team.team_type,
    countryCode: team.country_code,
    fixtures: team.fixtures.map((fixture) => ({
      id: fixture.uuid,
      date: fixture.date,
      homeTeamScore: fixture.home_team_score,
      awayTeamScore: fixture.away_team_score,
      pitchLength: fixture.pitch_length,
      pitchWidth: fixture.pitch_width,
      name: fixture.name,
      duration: fixture.duration,
    })),
    players: team.players.map((player) => ({
      jerseyNumber: player.jersey_number,
      side: player.side,
      position: player.position,
      name: player.player_name,
      id: player.player_uuid,
      photoUrl: player.player_photo_url,
      teamId: player.team_uuid,
    })),
  };
};

export const transformTeams = (
  response: MetadataTeamsApiResponse,
): InfinityQueryDataResult<MetadataTeamWithFixtures> => {
  return {
    data: {
      items: response.data.map((team) => ({
        id: team.uuid,
        name: team.name,
        abbreviation: team.abbreviation,
        logoUrl: team.logo_url,
        teamType: team.team_type,
        countryCode: team.country_code,
        fixtures: team.fixtures.map((fixture) => ({
          id: fixture.uuid,
          date: fixture.date,
          homeTeamScore: fixture.home_team_score,
          awayTeamScore: fixture.away_team_score,
          pitchLength: fixture.pitch_length,
          pitchWidth: fixture.pitch_width,
          name: fixture.name,
          duration: fixture.duration,
        })),
      })),
      page: response.page,
    },
    nextCursor: response.page.totalPages > response.page.number ? response.page.number + 1 : undefined,
  };
};
