import React from 'react';

import { List } from 'shared/components/list';
import { ListHeader } from 'shared/components/list/list-header';
import { ListItem } from 'shared/components/list/list-item';

import styles from '../PlaylistsDetailSkeleton.module.scss';

const dataTestId = 'playlist-card-skeleton';
export const PlaylistListSkeleton = () => {
  return (
    <>
      <ListHeader className={styles.header} />
      <List>
        <ListItem data-testid={dataTestId} component={'li'} className={styles.item} />
        <ListItem data-testid={dataTestId} component={'li'} className={styles.item} />
        <ListItem data-testid={dataTestId} component={'li'} className={styles.item} />
        <ListItem data-testid={dataTestId} component={'li'} className={styles.item} />
        <ListItem data-testid={dataTestId} component={'li'} className={styles.item} />
        <ListItem data-testid={dataTestId} component={'li'} className={styles.item} />
        <ListItem data-testid={dataTestId} component={'li'} className={styles.item} />
        <ListItem data-testid={dataTestId} component={'li'} className={styles.item} />
      </List>
    </>
  );
};
