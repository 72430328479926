import { Client } from 'shared/types';

import { ClientApiResponse, ClientsApiResponse } from './types';
import { InfinityQueryDataResult } from '../../hooks/useInfinityQuery/types';

export const transformClient = (client: ClientApiResponse): Client => ({
  id: client.id,
  name: client.name,
  city: client.city,
  country: client.country,
});

export const transformClients = (response: ClientsApiResponse): InfinityQueryDataResult<Client> => {
  return {
    data: {
      items: response.content.map(transformClient),
      page: response.page,
    },
    nextCursor: response.page.totalPages > response.page.number ? response.page.number + 1 : undefined,
  };
};
