import { useCallback, useRef } from 'react';

import { KeypadTag, TaggingEvent } from 'api/tagging-tool/types';
import { useCreateTaggingEvent } from 'api/tagging-tool/use-create-tagging-event';
import { useCurrentVideoSource } from 'shared/components/video-player/hooks';
import { useUser } from 'shared/contexts/app-state';

interface Options {
  onSuccess: (event: TaggingEvent) => void;
  recordingId: string;
  isLive: boolean;
}

export const useCreateTaggingEventFromCurrentVideo = ({ onSuccess, recordingId, isLive }: Options) => {
  const { id } = useUser();
  const { createTaggingEvent } = useCreateTaggingEvent();
  const recordingIdRef = useRef<string>(recordingId);
  const recordingIsLiveRef = useRef<boolean>(isLive);
  const videoSource = useCurrentVideoSource();

  const createTaggingEventFromCurrentVideo = useCallback(
    (time: number) => (keypadTag: KeypadTag) => {
      if (!id) return;

      const userId = id;
      const recordingId = recordingIdRef.current;
      const isLive = recordingIsLiveRef.current;

      const { name, description, timeBefore, timeAfter, typeOfPlay, typeOfPlaySource } = keypadTag;

      const taggingEvent: TaggingEvent = {
        id: '',
        recordingId,
        time,
        name,
        description,
        timeBefore,
        timeAfter,
        typeOfPlay,
        typeOfPlaySource,
        userId,
        videoSourceRef: {
          type: 'vod',
          id: videoSource.id,
        },
      };

      createTaggingEvent({
        data: {
          ...taggingEvent,
          isLive,
          videoSourceRef: {
            type: 'vod',
            id: videoSource.id,
          },
        },
        onSuccess,
      });
    },
    [onSuccess, videoSource, createTaggingEvent, id],
  );

  return { createTaggingEventFromCurrentVideo };
};
