import SvgIcon, { SvgIconProps } from '../svg-icon';

const IconForward = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M18 6h-2v12h2zM6 18V6l8.5 6z' />
    </SvgIcon>
  );
};

export default IconForward;
