import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePlaylist } from 'api/playlist/usePlaylist';
import { useClientId } from 'shared/contexts/app-state';
import { NotificationType, useNotifications } from 'shared/hooks/notifications';
import { Playlist } from 'shared/types';

import { CopyPlaylistModal } from '../copy-playlist-modal';

interface Props {
  playlistId: string;
}

export const CopyPlaylist = ({ playlistId }: Props) => {
  const { t } = useTranslation();
  const [isCopyPlaylistModalOpen, setIsCopyPlaylistModalOpen] = useState(true);
  const userClient = useClientId();
  const triggerNotification = useNotifications();
  const handleFetchPlaylistSuccess = useCallback(
    (data: Playlist) => {
      const notAllowed = data.clientId !== userClient.clientId;

      if (notAllowed) {
        triggerNotification({
          type: NotificationType.ERROR,
          message: t('playlists:copy-playlist.not-allowed'),
        });
      }
    },
    [t, triggerNotification, userClient.clientId],
  );

  const { data: playlist } = usePlaylist({
    playlistId: playlistId,
    onSuccess: handleFetchPlaylistSuccess,
  });

  const handleCopyPlaylistModalClose = useCallback(() => setIsCopyPlaylistModalOpen(false), []);

  const shouldShowCopyPlaylistModal = playlist && isCopyPlaylistModalOpen && userClient.clientId === playlist.clientId;

  if (!shouldShowCopyPlaylistModal) return null;

  return (
    <CopyPlaylistModal
      onClose={handleCopyPlaylistModalClose}
      playlistId={playlist.id}
      playlistUserName={`${playlist.user.firstName} ${playlist.user.lastName}`}
      playlistName={playlist.name}
    />
  );
};
