import { Box } from '@mui/material';
import { Colors } from 'kognia-ui';
import { TacticId } from 'overlay-generator';
import { PropsWithChildren, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxWithColor } from 'shared/components/checkbox-with-color';
import { IconColors } from 'shared/components/icons/svg-icon';

import styles from './TacticCheckbox.module.scss';

export const CheckboxItem = ({ children, ...rest }: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) => (
  <div {...rest} className={styles.tacticCheckbox}>
    {children}
  </div>
);

type TacticCheckBoxProps = {
  tactic: TacticId;
  selected: boolean;
  onChange: (tactic: TacticId) => void;
  isAvailableInFrame: boolean;
};

export const TacticCheckBox = ({ tactic, selected, onChange, isAvailableInFrame }: TacticCheckBoxProps) => {
  const { t } = useTranslation();
  const handleToggleTactic = useCallback(() => {
    onChange(tactic);
  }, [tactic, onChange]);

  return (
    <CheckboxItem style={{ color: isAvailableInFrame ? Colors.primary : Colors.night }}>
      <CheckboxWithColor customColor={IconColors.night} checked={selected} onChange={handleToggleTactic} />
      <Box>{t(`fundamentals:fundamentals.${tactic}`)}</Box>
    </CheckboxItem>
  );
};
