import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';

import { SeasonApi } from './types';
import { SeasonsApiResponse } from './use-seasons/types';
import { Season } from '../../types/seasons';
import { transformStageSummaries } from '../stages/use-stages/transformer';

export const transformSeason = (item: SeasonApi): Season => ({
  id: item.uuid,
  year: item.year,
  crossesYear: item.crosses_year,
  competition: {
    id: item.competition.uuid,
    name: item.competition.name,
    country: item.competition.country,
  },
  stages: transformStageSummaries(item.stages),
});

export const transformSeasons = (response: SeasonsApiResponse): InfinityQueryDataResult<Season> => {
  return {
    data: {
      items: response.data.map(transformSeason),
      page: response.page,
    },
    nextCursor: response.page.totalPages > response.page.number ? response.page.number + 1 : undefined,
  };
};
