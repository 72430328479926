import { RefObject, useCallback, useEffect } from 'react';

interface Props {
  masterDiv: RefObject<HTMLDivElement>;
  syncDiv: RefObject<HTMLDivElement>;
}

export const useSyncHeight = ({ masterDiv, syncDiv }: Props) => {
  const handleUpdateSize = useCallback((element: HTMLDivElement) => {
    const { height } = element.getBoundingClientRect();
    syncDiv.current!.style.minHeight = `${height}px`;
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleContainerResize = useCallback(() => {
    masterDiv.current && handleUpdateSize(masterDiv.current);
  }, [masterDiv.current]);

  useEffect(() => {
    if (masterDiv?.current !== null) {
      window.addEventListener('resize', handleContainerResize);

      const observer = new ResizeObserver(() => {
        handleContainerResize();
      });

      const containerElement: HTMLElement = masterDiv.current;
      observer.observe(containerElement);

      return () => {
        window.removeEventListener('resize', handleContainerResize);
        observer.unobserve(containerElement);
      };
    }
  }, [masterDiv]);

  return null;
};
