import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';

import {
  ProcessGameAnnotationTool,
  ProcessGameEventsProvider,
  ProcessGamePipelineType,
  ProcessGameTrackingProvider,
} from '../../../pages/process-game/form';
import { annotationApiUrls } from '../../routes';
import { useAllowedBackofficeOrigin } from '../../use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from '../../utils';

export interface ProcessGameParamsData {
  recording_id: string;
  video_path: string;
  bucket: string;
  pipeline_type: ProcessGamePipelineType;
  settings: {
    pitch: {
      length: string;
      width: string;
    };
    provider: {
      video: 'third-party-tactical';
      tracking?: ProcessGameTrackingProvider;
      events?: ProcessGameEventsProvider;
    };
    annotation_tool: ProcessGameAnnotationTool;
    run_processing: boolean;
  };
  files?: unknown;
}

interface ProcessGameParams {
  data: ProcessGameParamsData;
}

export const useProcessGame = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Process game success',
    errorMessage: (data) => metadataApiErrorParser(data, 'Process game error'),
  });

  const processGame = ({ data }: ProcessGameParams) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate({ url: `${annotationApiUrls.PROCESS_GAME}`, data });
  };

  return { ...mutationRequest, processGame };
};
