import { RefObject } from 'react';

import { PLAYER_ACTIONS } from './state/states';
import { FundamentalsSelection } from '../../types/playlist/types';
import { VideoSourceRef } from '../../types/video-sources/types';

export type PlayerType = RefObject<HTMLVmPlayerElement>;
export type PlayerContainerType = RefObject<HTMLDivElement>;

export enum PlayingModes {
  TACTICAL_CAMERA = 'TACTICAL_CAMERA',
  EPISODES = 'EPISODES',
  PLAYLIST = 'PLAYLIST',
  PANORAMIC = 'PANORAMIC',
}

export interface PlayingMode {
  mode: PlayingModes;
  showOverlays: boolean;
  useEffectiveTime: boolean;
  isPreferred?: boolean;
}

export interface PlayerStatePlaylist {
  preferredPlayingMode: PlayingMode;
  currentSelectedPlayingMode: PlayingMode;
  currentPlaylistItemId: string;
  playlistItems: PlaylistItemType[];
  playingItem: {
    currentSourceTime: number;
    videoSourceIndex: number;
    playlistItem: PlaylistItemType;
  };
}

export interface PlayerStateMachineContext {
  currentTime: number;
  isFullScreen: boolean;
  isInStandBy: boolean;
  isPlaying: boolean;
  autoPlayNextPlaylistItem: boolean;
  playerId: string;
  playlist: PlayerStatePlaylist;
  videoRef: PlayerType | undefined;
}

export type SetPlaylistAction = {
  autoplay?: boolean;
  playerRef?: PlayerType;
  playingMode?: PlayingMode;
  playlistItems: PlaylistItemType[];
  tryToKeepCurrentTime?: boolean;
  type: PLAYER_ACTIONS.LOAD_PLAYLIST;
  initialStartTime: number;
};

export type AddPlaylistItemsAction = {
  type: PLAYER_ACTIONS.REPLACE_PLAYLIST_ITEMS;
  playlistItems: PlaylistItemType[];
  initialStartTime?: number;
};
export type UpdatePlaylistItemsAction = {
  type: PLAYER_ACTIONS.UPDATE_PLAYLIST_ITEMS;
  playlistItems: PlaylistItemType[];
};
export type JumpToPercentTimeAction = { type: PLAYER_ACTIONS.JUMP_TO_TIME_PERCENT; percent: number };
export type JumpToMatchTimeAction = { type: PLAYER_ACTIONS.JUMP_TO_MATCH_TIME; time: number };

export type SetPlaylistItemAction = {
  type: PLAYER_ACTIONS.LOAD_PLAYLIST_ITEM;
  playlistItemId: string;
  autoPlay: boolean;
};
export type SetReplacePlaylistItemAction = {
  type: PLAYER_ACTIONS.REPLACE_PLAYLIST;
  playlistItems: PlaylistItemType[];
  playingMode: PlayingMode;
  tryToKeepCurrentTime?: boolean;
  autoplay?: boolean;
};
export type RemovePlaylistAction = { type: PLAYER_ACTIONS.REMOVE_PLAYLIST_ITEM; playlistItemId: string };
export type RemovePlaylistItemsAction = { type: PLAYER_ACTIONS.REMOVE_PLAYLIST_ITEMS; playlistItemsIds: string[] };
export type ChangePlayingModeAction = {
  type: PLAYER_ACTIONS.CHANGE_PLAYING_MODE;
  playingMode: PlayingMode;
  tryToKeepCurrentTime?: boolean;
  autoplay?: boolean;
};

export type UpdatePlaylistItemAction = {
  type: PLAYER_ACTIONS.UPDATE_PLAYLIST_ITEM;
  playlistItem: PlaylistItemType;
  currentTime?: number;
};

export type ReorderPlaylistItemAction = {
  type: PLAYER_ACTIONS.REORDER_PLAYLIST_ITEM;
  currentVideoIndex: number;
  newVideoIndex: number;
};

export type ChangeAutoplayPlaylistItemAction = {
  type: PLAYER_ACTIONS.CHANGE_AUTOPLAY_NEXT_PLAYLIST_ITEM;
  autoplayNextPlaylistItem: boolean;
};

export type PlayerStateMachineEvent =
  | AddPlaylistItemsAction
  | ChangePlayingModeAction
  | JumpToMatchTimeAction
  | JumpToPercentTimeAction
  | RemovePlaylistAction
  | RemovePlaylistItemsAction
  | ReorderPlaylistItemAction
  | SetPlaylistAction
  | SetPlaylistItemAction
  | SetReplacePlaylistItemAction
  | UpdatePlaylistItemAction
  | UpdatePlaylistItemsAction
  | ChangeAutoplayPlaylistItemAction
  | { type: PLAYER_ACTIONS };

export interface VideoSourceWithTimes {
  endTime: number;
  endTimeInMatch?: number;
  poster?: string | null;
  src: string;
  srcDownload?: string;
  startTime: number;
  startTimeInMatch?: number;
  type?: string | null;
  id: string;
}

export interface VideoSourceType {
  playingMode: PlayingMode;
  videoSources: VideoSourceWithTimes[];
  videoSourceRef?: VideoSourceRef;
}

export interface PlaylistItemType {
  id: string;
  duration: number;
  name?: string;
  index?: number;
  videoTypes: VideoSourceType[];
  videoSourceRef?: VideoSourceRef;
  fundamentalsSelected: FundamentalsSelection;
  hasHomographies: boolean;
  origin?: {
    type: string;
    id: string;
  };
  recordingName?: string | undefined;
  recordingMatchday?: string | undefined;
  recordingId: string;
}

export type PlaylistVideo = {
  id: string;
  startTime: number;
  endTime: number;
  videoSource: VideoSource;
};

export interface VideoSource {
  id: string;
  poster?: string | null;
  src: string;
  srcDownload?: string;
  type?: string | null;
}
