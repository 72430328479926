import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, FormControl, Grid, Stack, TextField, Typography } from '@mui/material';
import { Colors } from 'kognia-ui';
import { useCallback, useRef, useState } from 'react';
import { Controller, Path, useForm } from 'react-hook-form';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import { FormFormLabel } from 'shared/components/form/form-form-label';
import { FormSelectField } from 'shared/components/form/form-select-field';
import { getTypeOptions } from 'shared/components/form/get-type-options';
import { MatchTeamTypes } from 'shared/types/teams/types';

import { TextsWithFieldHoverLabel } from './texts-with-field-hover-label';
import { useCreateNewGameWithPlayers } from './use-create-new-game-with-players';
import { useUpdateNewGameWithPlayers } from './use-update-new-game-with-players';
import { useInvalidateSeasons } from '../../../../../api/seasons/use-seasons';
import { SelectCoach } from '../../../../../components/select-coach';
import { SelectVenue } from '../../../../../components/select-venue';
import { StageModal } from '../../../../../components/stage-modal';
import { Coach } from '../../../../../types/coaches';
import { MetadataGame, MetadataTeam } from '../../../../../types/games';
import { SelectSeason } from '../../../../stages/components/select-season';
import { FORM_IDS, useGameInfo, useSetGameInfo, useTeamData } from '../../config';
import { GameInfoFormFieldsNames, gameInfoFormSchema, GameInfoSchema } from '../../forms';
import { GameFormRouteParams } from '../../types';

type Props = {
  step: number;
  game?: MetadataGame;
};

const generateDefaultTitle = (homeTeam: MetadataTeam | undefined, awayTeam: MetadataTeam | undefined) => {
  if (!homeTeam || !awayTeam) return '';

  return `${homeTeam.abbreviation.toUpperCase()} - ${awayTeam.abbreviation.toUpperCase()}`;
};

export const GameInfoForm = ({ step, game }: Props) => {
  const history = useHistory();
  const gameInfo = useGameInfo();
  const setGameInfo = useSetGameInfo();
  const { id } = useParams<GameFormRouteParams>();
  const invalidateSeasons = useInvalidateSeasons();
  const [isCreateStageModalOpen, setIsCreateStageModalOpen] = useState(false);

  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    control,
  } = useForm<GameInfoSchema>({
    resolver: zodResolver(gameInfoFormSchema),
    defaultValues: gameInfo ? { ...gameInfo, season: game?.season } : undefined,
  });

  const season = watch(GameInfoFormFieldsNames.season);
  const homeTeam = useTeamData(MatchTeamTypes.HOME);
  const awayTeam = useTeamData(MatchTeamTypes.OPPONENT);
  const gameInfoFormRef = useRef<HTMLFormElement>(null);
  const createGame = useCreateNewGameWithPlayers((game: MetadataGame) => {
    history.push(generatePath(routes.BACKOFFICE_NEW_GAME_SUMMARY, { id: game.id }));
  });
  const updateGame = useUpdateNewGameWithPlayers((game: MetadataGame) => {
    history.push(generatePath(routes.BACKOFFICE_NEW_GAME_SUMMARY, { id: game.id }));
  });

  const handleSetTeamData = useCallback(
    (gameInfo: GameInfoSchema) => {
      if (!homeTeam || !awayTeam) return;

      setGameInfo(gameInfo);
      id ? updateGame(id, gameInfo, homeTeam, awayTeam) : createGame(gameInfo, homeTeam, awayTeam);
    },
    [id, updateGame, setGameInfo, awayTeam, homeTeam, createGame],
  );

  const handleOnSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    return handleSubmit(handleSetTeamData)(event);
  };

  return (
    <>
      <form id={FORM_IDS[step]} ref={gameInfoFormRef} onSubmit={handleOnSubmit}>
        <Grid container justifyContent={'center'} sx={{ paddingBottom: 4 }}>
          <Grid item xs={12} md={6}>
            <Stack gap={2}>
              <FormControl fullWidth>
                <FormFormLabel>Name</FormFormLabel>
                <TextField
                  defaultValue={generateDefaultTitle(homeTeam?.team, awayTeam?.team)}
                  size='small'
                  {...register(GameInfoFormFieldsNames.name)}
                  error={!!errors[GameInfoFormFieldsNames.name]}
                  helperText={
                    errors[GameInfoFormFieldsNames.name] && <>{errors[GameInfoFormFieldsNames.name].message}</>
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <FormFormLabel optional>Date</FormFormLabel>
                <TextField
                  size='small'
                  fullWidth
                  type='datetime-local'
                  {...register(GameInfoFormFieldsNames.date)}
                  error={!!errors[GameInfoFormFieldsNames.date]}
                  helperText={
                    errors[GameInfoFormFieldsNames.date] && <>{errors[GameInfoFormFieldsNames.date].message}</>
                  }
                />
              </FormControl>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <FormFormLabel>Score</FormFormLabel>
                    <Stack direction={'row'} gap={1}>
                      <TextField
                        type={'number'}
                        InputProps={{ inputProps: { min: 0 } }}
                        size='small'
                        {...register(GameInfoFormFieldsNames.scoreHomeTeam)}
                        error={!!errors[GameInfoFormFieldsNames.scoreHomeTeam]}
                        helperText={
                          errors[GameInfoFormFieldsNames.scoreHomeTeam] && (
                            <>{errors[GameInfoFormFieldsNames.scoreHomeTeam].message}</>
                          )
                        }
                      />
                      <TextField
                        type={'number'}
                        InputProps={{ inputProps: { min: 0 } }}
                        size='small'
                        {...register(GameInfoFormFieldsNames.scoreOpponentTeam)}
                        error={!!errors[GameInfoFormFieldsNames.scoreOpponentTeam]}
                        helperText={
                          errors[GameInfoFormFieldsNames.scoreOpponentTeam] && (
                            <>{errors[GameInfoFormFieldsNames.scoreOpponentTeam].message}</>
                          )
                        }
                      />
                    </Stack>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <FormFormLabel>Duration</FormFormLabel>
                    <TextField
                      type='number'
                      InputProps={{ inputProps: { min: 0 } }}
                      size='small'
                      {...register(GameInfoFormFieldsNames.duration)}
                      error={!!errors[GameInfoFormFieldsNames.duration]}
                      helperText={
                        errors[GameInfoFormFieldsNames.duration] && (
                          <>{errors[GameInfoFormFieldsNames.duration].message}</>
                        )
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <FormControl fullWidth>
                <FormFormLabel optional>Home coach</FormFormLabel>
                <Controller
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <SelectCoach setCoachOnChange={onChange} coach={value} />
                      {error && <Typography color={Colors.red}>{error.message}</Typography>}
                    </>
                  )}
                  name={GameInfoFormFieldsNames.homeCoach}
                  control={control}
                />
              </FormControl>

              <FormControl fullWidth>
                <FormFormLabel optional>Away coach</FormFormLabel>
                <Controller
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <SelectCoach
                        setCoachOnChange={(coach: Coach | undefined) => {
                          onChange(coach);
                        }}
                        coach={value || undefined}
                      />
                      {error && <Typography color={Colors.red}>{error.message}</Typography>}
                    </>
                  )}
                  name={GameInfoFormFieldsNames.awayCoach}
                  control={control}
                />
              </FormControl>

              <Controller
                name={GameInfoFormFieldsNames.season}
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormControl fullWidth>
                    <FormFormLabel>Season</FormFormLabel>
                    <SelectSeason setSeasonOnChange={onChange} season={value} />
                    {error && <Typography color={Colors.red}>{error.message}</Typography>}
                  </FormControl>
                )}
              />

              {Boolean(season) && (
                <FormControl fullWidth>
                  <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <FormFormLabel>Stages</FormFormLabel>
                    <Box>
                      <Button onClick={() => setIsCreateStageModalOpen(true)}>+ new stage</Button>
                    </Box>
                  </Stack>
                  <Controller
                    name={GameInfoFormFieldsNames.stageId}
                    control={control}
                    render={({ field: { value }, fieldState: { error } }) => (
                      <>
                        {season && season.stages && season.stages.length > 0 ? (
                          <FormSelectField
                            id={GameInfoFormFieldsNames.stageId}
                            options={getTypeOptions({
                              options: season.stages.map((stage) => ({
                                value: stage.id,
                                label: stage.name,
                              })),
                            })}
                            error={!!errors[GameInfoFormFieldsNames.stageId]}
                            helperText={
                              errors[GameInfoFormFieldsNames.stageId] && (
                                <>{errors[GameInfoFormFieldsNames.stageId]?.message}</>
                              )
                            }
                            value={value}
                            fullWidth={true}
                            {...register(GameInfoFormFieldsNames.stageId as Path<GameInfoSchema>)}
                          />
                        ) : null}
                        {error && <Typography color={Colors.red}>{error.message}</Typography>}
                      </>
                    )}
                  />
                </FormControl>
              )}

              <FormControl fullWidth>
                <FormFormLabel optional>Venue</FormFormLabel>
                <Controller
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <>
                      <SelectVenue setVenueOnChange={onChange} venue={value} />
                      {error && <Typography color={Colors.red}>{error.message}</Typography>}
                    </>
                  )}
                  name={GameInfoFormFieldsNames.venue}
                  control={control}
                />
              </FormControl>

              <FormControl fullWidth>
                <FormFormLabel>Pitch Size</FormFormLabel>
                <Stack direction={'row'} gap={2}>
                  <TextsWithFieldHoverLabel label={'length'}>
                    <TextField
                      size='small'
                      type='number'
                      InputProps={{ inputProps: { min: 0 } }}
                      {...register(GameInfoFormFieldsNames.pitchLength)}
                      error={!!errors[GameInfoFormFieldsNames.pitchLength]}
                      helperText={
                        errors[GameInfoFormFieldsNames.pitchLength] && (
                          <>{errors[GameInfoFormFieldsNames.pitchLength].message}</>
                        )
                      }
                    />
                  </TextsWithFieldHoverLabel>
                  <TextsWithFieldHoverLabel label={'Width'}>
                    <TextField
                      size='small'
                      type='number'
                      InputProps={{ inputProps: { min: 0 } }}
                      {...register(GameInfoFormFieldsNames.pitchWidth)}
                      error={!!errors[GameInfoFormFieldsNames.pitchWidth]}
                      helperText={
                        errors[GameInfoFormFieldsNames.pitchWidth] && (
                          <>{errors[GameInfoFormFieldsNames.pitchWidth].message}</>
                        )
                      }
                    />
                  </TextsWithFieldHoverLabel>
                </Stack>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </form>
      {isCreateStageModalOpen ? (
        <StageModal
          open={isCreateStageModalOpen}
          onClose={() => setIsCreateStageModalOpen(false)}
          onSuccess={invalidateSeasons}
        />
      ) : null}
    </>
  );
};
