import { MetricsData, MetricsNames } from '../types/metrics';

export enum ActionTypes {
  VIEW_PAGE = 'VIEW_PAGE',
  OPEN_RECORDINGS_FILTER = 'OPEN_RECORDINGS_FILTER',
  APPLY_RECORDINGS_FILTER = 'APPLY_RECORDINGS_FILTER',
  OPEN_TACTICAL_ANALYSIS_FILTER = 'OPEN_TACTICAL_ANALYSIS_FILTER',
  APPLY_TACTICAL_ANALYSIS_FILTER = 'APPLY_TACTICAL_ANALYSIS_FILTER',
  PLAY_TACTICAL_ANALYSIS_ROW = 'PLAY_TACTICAL_ANALYSIS_ROW',
  PLAY_TACTICAL_ANALYSIS_SELECTED_CLIPS = 'PLAY_TACTICAL_ANALYSIS_SELECTED_CLIPS',
  OPEN_TACTICAL_ANALYSIS_EXPORT_XML = 'OPEN_TACTICAL_ANALYSIS_EXPORT_XML',
  EXPORT_TACTICAL_ANALYSIS_XML = 'EXPORT_TACTICAL_ANALYSIS_XML',
  OPEN_TACTICAL_ANALYSIS_EXPORT_VIDEO = 'OPEN_TACTICAL_ANALYSIS_EXPORT_VIDEO',
  EXPORT_TACTICAL_ANALYSIS_VIDEO = 'EXPORT_TACTICAL_ANALYSIS_VIDEO',
  CHANGE_TACTICAL_ANALYSIS_ZOOM = 'CHANGE_TACTICAL_ANALYSIS_ZOOM',
  LOG_OVERLAYS_FRAME_RATE = 'LOG_OVERLAYS_FRAME_RATE',
  SEND_METRICS = 'SEND_METRICS',
}

export interface PageViewAction {
  type: ActionTypes.VIEW_PAGE;
  payload: {
    name: MetricsNames.PAGE_VIEW;
    data: MetricsData[MetricsNames.PAGE_VIEW];
  };
}

export interface RecordingsOpenFilterAction {
  type: ActionTypes.OPEN_RECORDINGS_FILTER;
  payload: {
    name: MetricsNames.RECORDINGS_OPEN_FILTER;
    data: MetricsData[MetricsNames.RECORDINGS_OPEN_FILTER];
  };
}

export interface RecordingsApplyFilterAction {
  type: ActionTypes.APPLY_RECORDINGS_FILTER;
  payload: {
    name: MetricsNames.RECORDINGS_APPLY_FILTER;
    data: MetricsData[MetricsNames.RECORDINGS_APPLY_FILTER];
  };
}

export interface TacticalAnalysisOpenFilterAction {
  type: ActionTypes.OPEN_TACTICAL_ANALYSIS_FILTER;
  payload: {
    name: MetricsNames.TACTICAL_ANALYSIS_OPEN_FILTER;
    data?: MetricsData[MetricsNames.TACTICAL_ANALYSIS_OPEN_FILTER];
  };
}

export interface TacticalAnalysisApplyFilterAction {
  type: ActionTypes.APPLY_TACTICAL_ANALYSIS_FILTER;
  payload: {
    name: MetricsNames.TACTICAL_ANALYSIS_APPLY_FILTER;
    data: MetricsData[MetricsNames.TACTICAL_ANALYSIS_APPLY_FILTER];
  };
}

export interface TacticalAnalysisPlayRowAction {
  type: ActionTypes.PLAY_TACTICAL_ANALYSIS_ROW;
  payload: {
    name: MetricsNames.TACTICAL_ANALYSIS_PLAY_ROW;
    data?: MetricsData[MetricsNames.TACTICAL_ANALYSIS_PLAY_ROW];
  };
}

export interface TacticalAnalysisPlaySelectedClipsAction {
  type: ActionTypes.PLAY_TACTICAL_ANALYSIS_SELECTED_CLIPS;
  payload: {
    name: MetricsNames.TACTICAL_ANALYSIS_PLAY_SELECTED_CLIPS;
    data?: MetricsData[MetricsNames.TACTICAL_ANALYSIS_PLAY_SELECTED_CLIPS];
  };
}

export interface TacticalAnalysisOpenExportXmlAction {
  type: ActionTypes.OPEN_TACTICAL_ANALYSIS_EXPORT_XML;
  payload: {
    name: MetricsNames.TACTICAL_ANALYSIS_OPEN_EXPORT_XML;
    data?: MetricsData[MetricsNames.TACTICAL_ANALYSIS_OPEN_EXPORT_XML];
  };
}

export interface TacticalAnalysisExportXmlAction {
  type: ActionTypes.EXPORT_TACTICAL_ANALYSIS_XML;
  payload: {
    name: MetricsNames.TACTICAL_ANALYSIS_EXPORT_XML;
    data?: MetricsData[MetricsNames.TACTICAL_ANALYSIS_EXPORT_XML];
  };
}

export interface TacticalAnalysisOpenExportVideoAction {
  type: ActionTypes.OPEN_TACTICAL_ANALYSIS_EXPORT_VIDEO;
  payload: {
    name: MetricsNames.TACTICAL_ANALYSIS_OPEN_EXPORT_VIDEO;
    data?: MetricsData[MetricsNames.TACTICAL_ANALYSIS_OPEN_EXPORT_VIDEO];
  };
}

export interface TacticalAnalysisExportVideoAction {
  type: ActionTypes.EXPORT_TACTICAL_ANALYSIS_VIDEO;
  payload: {
    name: MetricsNames.TACTICAL_ANALYSIS_EXPORT_VIDEO;
    data?: MetricsData[MetricsNames.TACTICAL_ANALYSIS_EXPORT_VIDEO];
  };
}

export interface TacticalAnalysisChangeZoomAction {
  type: ActionTypes.CHANGE_TACTICAL_ANALYSIS_ZOOM;
  payload: {
    name: MetricsNames.TACTICAL_ANALYSIS_CHANGE_ZOOM;
    data: MetricsData[MetricsNames.TACTICAL_ANALYSIS_CHANGE_ZOOM];
  };
}

export interface OverlaysFrameRateAction {
  type: ActionTypes.LOG_OVERLAYS_FRAME_RATE;
  payload: number;
}

export interface MetricsAction {
  type: ActionTypes.SEND_METRICS;
  payload: {
    name: MetricsNames;
    data: MetricsData[MetricsNames];
  };
}

export type Action =
  | PageViewAction
  | RecordingsOpenFilterAction
  | MetricsAction
  | OverlaysFrameRateAction
  | RecordingsApplyFilterAction
  | TacticalAnalysisOpenFilterAction
  | TacticalAnalysisApplyFilterAction
  | TacticalAnalysisPlayRowAction
  | TacticalAnalysisPlaySelectedClipsAction
  | TacticalAnalysisOpenExportXmlAction
  | TacticalAnalysisExportXmlAction
  | TacticalAnalysisOpenExportVideoAction
  | TacticalAnalysisExportVideoAction
  | TacticalAnalysisChangeZoomAction;
