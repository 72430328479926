import { sendParent } from 'xstate';

import { getVideoSourceByIndex } from '../../../util';
import { PLAYER_ACTIONS } from '../../states';
import { ChangeSourceAndTimeMachineContext } from '../types';

export const isSameVideoSource = (context: ChangeSourceAndTimeMachineContext) => {
  if (!context.videoRef?.current || !context.playlistItem) return false;
  const videoSource = getVideoSourceByIndex(context.playlistItem, context.playingMode, context.videoSourceIndex);

  return context.videoRef?.current?.currentSrc === videoSource?.src;
};

export const isVideoSourceReady = (context: ChangeSourceAndTimeMachineContext) => {
  if (!context.videoRef?.current || !context.playlistItem) return false;

  const videoSource = getVideoSourceByIndex(context.playlistItem, context.playingMode, context.videoSourceIndex);
  const isCurrentSource = context.videoRef?.current?.currentSrc === videoSource?.src;

  if (!isCurrentSource) {
    if (!context.videoRef?.current?.paused) {
      context.videoRef?.current?.pause();
    }

    context.videoRef.current.currentSrc = videoSource?.src;
    return false;
  }

  return isCurrentSource;
};

export const isCurrentTime = (context: ChangeSourceAndTimeMachineContext) => {
  if (!context.videoRef?.current?.currentTime && context.videoRef?.current?.currentTime !== 0) return false;

  const correction = context.currentTime === 0 ? 0.0001 : 0;
  const timeDifference = Math.abs(context.videoRef?.current?.currentTime - context.currentTime + correction);

  const isInStartTime =
    context.videoRef?.current?.currentTime === context.currentTime + correction || timeDifference < 0.1;

  if (!isInStartTime) {
    context.videoRef.current.currentTime = context.currentTime + correction;
    return false;
  }

  sendParent({ type: PLAYER_ACTIONS.TIMING, time: context.currentTime });
  return isInStartTime;
};
