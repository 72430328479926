import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import { useCallback, useReducer } from 'react';

import { FiltersList } from 'shared/types';

import { ApplyFilterPayloadType, filtersReducer, RecordingsFiltersActions } from './reducer';

const useRecordingsFilters = (filtersList: FiltersList) => {
  const initialFiltersState: { [key: string]: string[] } = {};
  map(filtersList, (filter, index) => {
    initialFiltersState[index] = Object.keys(pickBy(filter.options, (option) => option.isApplied));
  });

  const [filtersApplied, dispatch] = useReducer(filtersReducer, initialFiltersState);

  const applyFilters = useCallback((payload: ApplyFilterPayloadType) => {
    dispatch({
      type: RecordingsFiltersActions.APPLY_FILTER,
      payload,
    });
  }, []);

  const removeFilter = useCallback((payload: string) => {
    dispatch({
      type: RecordingsFiltersActions.REMOVE_FILTER,
      payload,
    });
  }, []);

  return {
    filtersApplied,
    applyFilters,
    removeFilter,
  };
};

export default useRecordingsFilters;
