import classNames from 'classnames';
import { ReactNode } from 'react';

import { Team } from '../../../types';
import timelineStyles from '../../TimelineTable.module.scss';
import styles from '../Rows.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  isHighlighted?: boolean;
  children: ReactNode;
  team: Team;
  borderTop: boolean;
  isSelected?: boolean;
}

export const RowContentContainer = ({
  children,
  borderTop = false,
  team,
  isHighlighted,
  isSelected,
  ...rest
}: Props) => (
  <div
    {...rest}
    className={classNames(timelineStyles.rowContent, styles.rowContentContainer, {
      [styles.isHighlighted]: isHighlighted,
      [styles.borderTop]: borderTop,
      [timelineStyles.isSelectedForPlaying]: isSelected,
      [timelineStyles.isHomeTeamRow]: team === 'home',
      [timelineStyles.isOpponentTeamRow]: team === 'opponent',
    })}
  >
    {children}
  </div>
);
