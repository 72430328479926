import SvgIcon, { SvgIconProps } from '../svg-icon';

const IconTime = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M10 5.003h4v1.333h-4V5.003zm1.333 4.667h1.333v4h-1.333v-4zm5.353-.413l.947-.947a7.36 7.36 0 0 0-.94-.94l-.947.947c-1.033-.827-2.333-1.32-3.747-1.32a6 6 0 0 0-6 6c0 3.313 2.68 6 6 6s6-2.687 6-6a5.99 5.99 0 0 0-1.313-3.74zM12 17.67c-2.58 0-4.667-2.087-4.667-4.667S9.42 8.337 12 8.337s4.667 2.087 4.667 4.667S14.58 17.67 12 17.67z' />
    </SvgIcon>
  );
};

export default IconTime;
