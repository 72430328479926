import { Box, Button, Slider, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TaggingEvent } from 'api/tagging-tool/types';
import { useDuration, useVideoPlayerActions } from 'shared/components/video-player';
import { TypeOfPlay } from 'shared/types/type-of-play/types';

import { ClipTrimmerActions } from './styled';
import { fetchTrimTaggingEvent } from '../../../../tagging-tool/service/taggingEvent.service';
import { secondsToTimeString } from '../../../../tagging-tool/utility/date';

const MIN_RANGE_TIME = 60;
const RESCALE_FACTOR = 3;

const ValueLabelComponent = (props: { children: React.ReactElement; value: number }) => {
  const { children, value } = props;

  return (
    <Tooltip open={true} placement='top' title={value}>
      {children}
    </Tooltip>
  );
};

type State = {
  min: number;
  max: number;
  value: [number, number];
};

const updateState = (event: TaggingEvent, duration: number): State => {
  const { timeBefore, time, timeAfter } = event;

  const range = Math.max((timeBefore + timeAfter) * RESCALE_FACTOR, MIN_RANGE_TIME);
  const max = Math.round(range / 2);
  const min = -max;

  let valueLeft = -timeBefore;
  let valueRight = timeAfter;
  if (time - -valueLeft <= 0) {
    valueLeft = -time;
  }
  if (time + valueRight > duration) {
    valueRight = duration - time;
  }

  return {
    min,
    max,
    value: [valueLeft, valueRight],
  };
};

type ClipTrimmerProps = {
  className?: string;
  event: TaggingEvent;
  onSave: (event: TaggingEvent) => void;
  onCancel: () => void;
};

export const ClipTrimmer = (props: ClipTrimmerProps) => {
  const duration = useDuration();
  const actions = useVideoPlayerActions();
  const { t } = useTranslation();

  const [state, setState] = useState<State>(() => updateState(props.event, duration ?? Number.MAX_SAFE_INTEGER));
  const [loading, setLoading] = useState<boolean>(false);

  const style = useMemo(() => {
    const { typeOfPlay } = props.event;
    const color =
      typeOfPlay === TypeOfPlay.Offense ? '#5440f7' : typeOfPlay === TypeOfPlay.Defense ? '#0bb07b' : '#facd75';
    return { color };
  }, [props.event]);

  useEffect(() => {
    setState(updateState(props.event, duration ?? Number.MAX_SAFE_INTEGER));

    actions.jumpToTimePercent((props.event.time * 100) / duration);
  }, [props.event]);

  const handleSave = useCallback(async () => {
    setLoading(true);

    const times = {
      timeBefore: -state.value[0],
      timeAfter: state.value[1],
    };

    await fetchTrimTaggingEvent({
      recordingId: props.event.recordingId,
      taggingEventId: props.event.id,
      time: props.event.time,
      ...times,
    });

    setLoading(false);

    props.onSave({
      ...props.event,
      ...times,
    });

    actions.resumeStandBy();
  }, [props.event, props.onSave, state]);

  const handleCancel = useCallback(() => {
    actions.resumeStandBy();
    props.onCancel();
  }, [props.onCancel]);

  return (
    <Box marginTop={5}>
      <Slider
        {...state}
        style={style}
        marks={[
          {
            value: 0,
            label: secondsToTimeString(state.value[1] + -state.value[0]),
          },
        ]}
        valueLabelFormat={(v) => {
          return secondsToTimeString(props.event.time + v);
        }}
        components={{
          ValueLabel: ValueLabelComponent,
        }}
        valueLabelDisplay='on'
        aria-labelledby='range-slider'
        onChange={(_ev, newValue) => {
          let [left, right] = newValue as [number, number];
          const { time } = props.event;

          if (left !== state.value[0]) {
            if (left >= 0) {
              left = -1;
            }
            if (time + left <= 0) {
              left = -time;
            }

            actions.jumpToTimePercent(((time + left) * 100) / duration);
          }
          if (right !== state.value[1]) {
            if (right <= 0) {
              right = 1;
            }
            if (time + right > duration) {
              right = duration - time;
            }

            actions.jumpToTimePercent(((time + right) * 100) / duration);
          }

          setState({ ...state, value: [left, right] });
        }}
        onChangeCommitted={(_ev, newValue) => {
          const value = newValue as [number, number];
          const [left, right] = value;

          const { time } = props.event;
          const factor =
            (left === state.min && time + left >= 0) || (right === state.max && time + right < duration)
              ? RESCALE_FACTOR
              : 1;

          setState({
            ...state,
            min: state.min * factor,
            max: state.max * factor,
          });
        }}
      />
      <ClipTrimmerActions>
        <Button variant='contained' size='large' color='secondary' onClick={handleCancel} disabled={loading}>
          {t('common:actions.cancel')}
        </Button>
        <Button variant='contained' size='large' onClick={handleSave} disabled={loading}>
          {t('common:actions.save')}
        </Button>
      </ClipTrimmerActions>
    </Box>
  );
};
