import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconTag from 'shared/components/icons/icon-tag';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';

import styles from '../RecordingStates.module.scss';

interface Props {
  active: boolean;
}

const StateTags = ({ active }: Props) => {
  const { t } = useTranslation();
  return (
    <span className={classNames(styles.stateBlock, active ? styles.active : styles.inactive)}>
      <IconTag size={IconSizes.small} color={active ? IconColors.primary : IconColors.iron} />
      <div>{t('recording:states.tags')}</div>
    </span>
  );
};

export default StateTags;
