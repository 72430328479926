import { makeContainer } from './base';
import { ServiceError } from '../service/base';

export type ServiceErrorState = {
  lastServiceError?: ServiceError | undefined;
};

// state

const { useContainer, nextValue } = makeContainer<ServiceErrorState>({});

export const nextServiceError = (lastServiceError?: ServiceError) => {
  nextValue((state) => {
    return {
      ...state,
      lastServiceError,
    };
  });
};
