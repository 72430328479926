import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

interface PlayerPhotoSkeletonProps {
  small?: boolean;
}

export const PlayerPhotoSkeletonContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'small',
})<PlayerPhotoSkeletonProps>(({ small }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: Colors.iron,
  width: small ? '36px' : '120px',
  height: small ? '36px' : '120px',
  borderRadius: '50%',
  border: `4px solid ${Colors.athens}`,
}));
