import React from 'react';

import { ListContainer } from 'shared/components/list/list-container';
import { ListTitle } from 'shared/components/list/list-title';
import { ListTitleContainer } from 'shared/components/list/list-title-container';

import { PlaylistListSkeleton } from './playlist-list-skeleton';
import styles from './PlaylistsDetailSkeleton.module.scss';
import { CreatePlaylist } from '../playlists-list/components/create-playlist';

const PlaylistsSkeleton = () => {
  return (
    <ListContainer>
      <ListTitleContainer>
        <ListTitle className={styles.title}>Playlists</ListTitle>
        <CreatePlaylist className={styles.actions} />
      </ListTitleContainer>

      <PlaylistListSkeleton />
    </ListContainer>
  );
};

export default PlaylistsSkeleton;
