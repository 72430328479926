import React, { forwardRef, PropsWithChildren } from 'react';

import styles from './RowsHeaders.module.scss';
import { useTacticalAnalysisAppliedFilters } from '../../../../../hooks/use-tactical-analysis-applied-filters';
import { useTimelineTableData } from '../../../../../hooks/use-timeline-table-data';

export const RowsHeaders = forwardRef(
  (
    {
      children,
      recordingId,
      ...rest
    }: PropsWithChildren<
      React.HTMLAttributes<HTMLDivElement> & {
        recordingId: string;
      }
    >,
    ref: any,
  ) => {
    const timelineTableData = useTimelineTableData(recordingId);
    const { appliedFilters } = useTacticalAnalysisAppliedFilters(recordingId);
    const areFiltersApplied = Boolean(
      appliedFilters.eventsEnding || appliedFilters.eventsStarting || appliedFilters.scenariosOrTacticsInside,
    );

    const showHeaders =
      timelineTableData.rowGroups.filter((r) => r.totalClips > 0).length > 0 ||
      (areFiltersApplied && timelineTableData?.filtersRow && timelineTableData.filtersRow.clips.length > 0);

    if (!showHeaders) return null;

    return (
      <div {...rest} ref={ref} className={styles.rowsHeaders}>
        {children}
      </div>
    );
  },
);

RowsHeaders.displayName = 'RowsHeaders';
