import ReactDOM from 'react-dom/client';

import 'focus-visible';

import App from './App';
import { initErrorTracker } from './kognia/error-tracking/init';
import { initMetricsCollector } from './kognia/metrics/init';
import { APP_COLLECT_METRICS } from './kognia/metrics/utils';
import * as serviceWorker from './serviceWorker';

initErrorTracker();
APP_COLLECT_METRICS && initMetricsCollector();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
