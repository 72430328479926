import { useCallback, useEffect, useState } from 'react';

import { Client } from 'shared/types/user/types';

import { useClients } from '../../../../../api/backoffice/client/use-clients';

type Options = {
  setClients: (recordings: Client[]) => void;
};

export const useSyncClientsSelection = ({ setClients }: Options) => {
  const [pageSize, setPageSize] = useState(0);
  const [isEnabled, setIsEnabled] = useState(false);
  const { items: clientsResults, setFilters } = useClients({ enabled: isEnabled, pageSize });

  useEffect(() => {
    if (isEnabled && clientsResults) {
      setClients(clientsResults);
    }
  }, [clientsResults, isEnabled, setClients]);

  const handleSearchClients = useCallback(
    (clientIds: string[]) => {
      setPageSize(clientIds.length);
      setFilters({ clientIds });
      setIsEnabled(clientIds.length > 0);
    },
    [setPageSize, setIsEnabled, setFilters],
  );

  return useCallback(
    (clients: Client[], clientIds: string[]) => {
      if (clientIds.length === 0) return;

      clients.length === 0 ? handleSearchClients(clientIds) : setIsEnabled(false);
    },
    [handleSearchClients, setIsEnabled],
  );
};
