import { Grid, Step, StepLabel, Stepper } from '@mui/material';

import { NEW_GAMES_STEPS } from '../../config';

type Props = {
  step: number;
};

const LABELS = {
  ['home-team']: 'Home Team',
  ['away-team']: 'Away Team',
  ['game-info']: 'Game Info',
};

export default function GameFormStepper({ step }: Props) {
  return (
    <Grid container justifyContent={'center'}>
      <Grid item lg={10} xl={8}>
        <Stepper activeStep={step} alternativeLabel>
          {NEW_GAMES_STEPS.map((label, idx) => {
            return (
              <Step key={label} completed={idx < step}>
                <StepLabel>{LABELS[label]}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
    </Grid>
  );
}
