import SvgIcon, { SvgIconProps } from '../svg-icon';

const IconEdit = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M13.217 9.017l.767.767-7.55 7.55h-.767v-.767l7.55-7.55zm3-5.017c-.208 0-.425.083-.583.242l-1.525 1.525 3.125 3.125 1.525-1.525a.83.83 0 0 0 0-1.175l-1.95-1.95A.82.82 0 0 0 16.217 4zm-3 2.658L4 15.875V19h3.125l9.217-9.217-3.125-3.125z' />
    </SvgIcon>
  );
};

export default IconEdit;
