import Cookies from 'js-cookie';
import React, { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { unregisterTrackedUser } from 'kognia/error-tracking/register-user';
import { User, UserAuthority } from 'shared/types/user/types';

import {
  appState,
  currentClientIdAtom,
  defaultFeatureFlagsState,
  defaultUserState,
  featureFlagsState,
  isAuthorizedAtom,
  userAtom,
} from './atoms';
import useAppData, { UseAppDataType } from './use-app-data';
import AppLoading from '../../components/app-loading';
import usePageStateMachine, { PAGE_STATES } from '../../hooks/use-page-state-machine';

type AppStateProviderProps = { children: React.ReactNode };

function useClientId() {
  const [clientId, updateClientId] = useRecoilState(currentClientIdAtom);

  const setClientId = useCallback(
    (newClientId: string) => {
      Cookies.set('current-client-id', newClientId);
      updateClientId(newClientId);
    },
    [updateClientId],
  );

  return {
    clientId,
    setClientId,
  };
}

export const useClientIdValue = () => useRecoilValue(currentClientIdAtom);

function useUnauthorize() {
  const { setClientId } = useClientId();
  const setAppState = useSetRecoilState(appState);
  const setUserState = useSetRecoilState(userAtom);
  const setIsAuthorized = useSetRecoilState(isAuthorizedAtom);
  const setFeatureFlagState = useSetRecoilState(featureFlagsState);

  return () => {
    unregisterTrackedUser();
    setIsAuthorized(false);
    setFeatureFlagState(defaultFeatureFlagsState);
    setUserState(defaultUserState);
    setAppState(PAGE_STATES.ERROR);
    setClientId('');
  };
}

function AppStateProvider({ children }: AppStateProviderProps) {
  const setUser = useSetRecoilState(userAtom);
  const setFeatureFlags = useSetRecoilState(featureFlagsState);
  const logout = useUnauthorize();
  const setIsAuthorized = useSetRecoilState(isAuthorizedAtom);
  const setCurrentClientId = useSetRecoilState(currentClientIdAtom);
  const setAppState = useSetRecoilState(appState);

  const { current, data, PAGE_STATES } = usePageStateMachine<UseAppDataType>(() =>
    useAppData(() => {
      logout();
    }),
  );

  useEffect(() => {
    if (current.matches(PAGE_STATES.READY) && data?.user && data?.featureFlags) {
      setUser(data.user as User);
      setIsAuthorized(true);
      setCurrentClientId(Cookies.get('current-client-id') || data.user.clients[0].id);
      setFeatureFlags(data.featureFlags);
      setAppState(PAGE_STATES.READY);
    } else {
      setAppState(current.value as PAGE_STATES);
    }
  }, [
    current.value,
    data?.user,
    data?.featureFlags,
    current,
    PAGE_STATES.READY,
    setUser,
    setIsAuthorized,
    setCurrentClientId,
    setFeatureFlags,
    setAppState,
    data,
  ]);

  const isAppSettled = current.matches(PAGE_STATES.READY) || current.matches(PAGE_STATES.ERROR);

  return (
    <>
      <AppLoading isLoaded={isAppSettled} />
      {children}
    </>
  );
}

function useFeatureFlag(featureFlag?: FEATURE_FLAG) {
  const featureFlags = useRecoilValue(featureFlagsState);

  return Boolean(featureFlags && !!featureFlag && featureFlags[featureFlag]);
}

function useAppStatus() {
  return useRecoilValue(appState);
}

function useIsAppSettled() {
  const status = useRecoilValue(appState);

  return status === PAGE_STATES.READY || status === PAGE_STATES.ERROR;
}

function useUser(): User {
  return useRecoilValue(userAtom);
}

function isUserAdmin() {
  const user = useRecoilValue(userAtom);

  return user.authorities.includes(UserAuthority.ROLE_ADMIN);
}

function useUserActiveClient() {
  const currentClientId = useRecoilValue(currentClientIdAtom);
  const user = useRecoilValue(userAtom);

  return user.clients.find((client) => client.id === currentClientId);
}

function useIsUserAuthorized() {
  return useRecoilValue(isAuthorizedAtom);
}

export {
  AppStateProvider,
  useFeatureFlag,
  useAppStatus,
  useUser,
  useClientId,
  useUserActiveClient,
  useIsUserAuthorized,
  isUserAdmin,
  useIsAppSettled,
  useUnauthorize,
};
