import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useRowTeam } from '../../hooks/use-row-team';
import { useRow, useRowIsHighlighted, useSetRowIsHighlighted } from '../../store/atoms';
import { RowHeaderContainer } from '../row-header-container';
import { RowHeaderContent } from '../row-header-content';
import { RowHeaderTotal } from '../row-header-total';
import { TeamRowHighlight } from '../team-row-highlight';

type Props = {
  totalNumberOfClips: number;
  recordingId: string;
  title: string;
  rowId: string;
};

export const RowHeaderTactic = ({ totalNumberOfClips, title, rowId, recordingId }: Props) => {
  const row = useRow(rowId);
  const team = useRowTeam(recordingId, row.teamId);
  const { t } = useTranslation();
  const isRowHighlighted = useRowIsHighlighted(rowId);
  const setRowHighlighted = useSetRowIsHighlighted(rowId);

  const handleMouseEnter = useCallback(() => {
    setRowHighlighted(true);
  }, [setRowHighlighted]);

  const handleMouseLeave = useCallback(() => {
    setRowHighlighted(false);
  }, [setRowHighlighted]);

  return (
    <RowHeaderContainer
      team={team}
      borderTop={rowId === `${recordingId}-filters`}
      isHighlighted={isRowHighlighted}
      isSelected={row.isSelected}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-row-id={rowId}
      data-timeline-element='row-header'
      data-is-selected={row.isSelected}
      data-row-type={row.type}
    >
      <TeamRowHighlight team={team} />
      <RowHeaderTotal>{totalNumberOfClips}</RowHeaderTotal>
      <RowHeaderContent title={t(title)}>{t(title)}</RowHeaderContent>
    </RowHeaderContainer>
  );
};
