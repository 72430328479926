import { Grid } from '@mui/material';
import classNames from 'classnames';

import styles from './ListHeader.module.scss';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

export const ListHeader = ({ children, className = '' }: Props) => {
  return (
    <Grid item>
      <div className={classNames(styles.header, className)}>{children}</div>
    </Grid>
  );
};
