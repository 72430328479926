import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';

import { CreateCompetitionApiParams } from './types';
import { annotationApiUrls } from '../../routes';
import { useAllowedBackofficeOrigin } from '../../use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from '../../utils';
import { useInvalidateCompetitions } from '../use-competitions';

interface RequestData {
  data: CreateCompetitionApiParams;
  onSuccess?: () => void;
}

export const useCreateCompetition = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateCompetitions = useInvalidateCompetitions();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Competition created',
    errorMessage: (data) => metadataApiErrorParser(data, 'Competition creation error'),
    onSuccess: invalidateCompetitions,
  });

  const create = ({ data, onSuccess }: RequestData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: annotationApiUrls.COMPETITIONS,
        data,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, create };
};
