import React, { forwardRef, PropsWithChildren } from 'react';

import styles from './RowHeaderContent.module.scss';

export const RowHeadersContent = forwardRef(
  ({ children, ...rest }: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>, ref: any) => (
    <div {...rest} ref={ref} className={styles.rowHeadersContent}>
      {children}
    </div>
  ),
);

RowHeadersContent.displayName = 'RowHeadersContent';
