import { PopoverActions } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import classNames from 'classnames';
import filter from 'lodash/filter';
import map from 'lodash/map';
import some from 'lodash/some';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Zones } from 'pages/tactical-analysis/components/tactical-analysis/filters/types';
import Button, { ButtonTypes } from 'shared/components/button';
import Checkbox from 'shared/components/checkbox';
import styles from 'shared/components/filter-zone/FilterZone.module.scss';
import IconChevronDown from 'shared/components/icons/icon-chevron-down';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';

import { ZoneValues } from '../../types/recording/types';

export type FilterProps = {
  disabled?: boolean;
  displayName: string;
  onUpdate: (zones: ZoneValues) => void;
  selectedValue?: ZoneValues;
};

export const defaultZoneValues: ZoneValues = {
  [Zones.zone1]: false,
  [Zones.zone2]: false,
  [Zones.zone3]: false,
  [Zones.zone4]: false,
};

const FilterZone = ({ disabled, displayName, onUpdate, selectedValue = defaultZoneValues }: FilterProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const actionRef = useRef<PopoverActions | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const filterId = 'filter-menu';

  const generateDisplayName = useCallback((): string => {
    if (!some(selectedValue, (value) => value)) return displayName;

    return `${t('recordings:filters:zone', {
      count: filter(selectedValue, (value) => value).length,
    })} ${map(selectedValue, (value, zoneId) => ({ zone: zoneId, value }))
      .filter(({ value }) => value)
      .map((value) => {
        return `${value.zone}`;
      })
      .join(', ')}`;
  }, [selectedValue]);

  const handleSetValue = (zoneNumber: Zones) => {
    onUpdate({
      ...selectedValue,
      [zoneNumber]: !selectedValue[zoneNumber],
    });
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleClose = () => {
    if (!anchorEl) return;

    setAnchorEl(undefined);
    setIsOpen(false);
  };

  return (
    <span className={styles.container}>
      <Button
        disabled={disabled}
        className={classNames(styles.dropdownButton, {
          [styles.active]: isOpen,
          [styles.selected]: some(selectedValue, (value) => value),
        })}
        type={ButtonTypes.White}
        onClick={handleButtonClick}
      >
        <>
          <div className={styles.group}>
            <div className={styles.groupContent}>{generateDisplayName()}</div>
            <span
              className={classNames(styles.chevron, {
                [styles.chevronUp]: isOpen,
              })}
            >
              <IconChevronDown color={IconColors.iron} size={IconSizes.small} />
            </span>
          </div>
        </>
      </Button>
      {isOpen && anchorEl && (
        <ClickAwayListener onClickAway={handleClose}>
          <Menu
            action={actionRef}
            id={filterId}
            anchorEl={anchorEl}
            open
            onClose={handleClose}
            TransitionComponent={Fade}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            className={styles.menu}
          >
            <div className={styles.contentContainer}>
              <div className={styles.listContainer}>
                {Array(4)
                  .fill('')
                  .map((_, idx: number) => {
                    const zone = (idx + 1) as Zones;
                    return (
                      <div key={idx + 1} className={styles[`filterDropdownOptionZone${idx + 1}`]}>
                        <Checkbox
                          id={(idx + 1).toString()}
                          key={idx + 1}
                          checked={selectedValue[zone]}
                          onClick={() => {
                            handleSetValue(zone);
                          }}
                        >
                          <>{idx}</>
                        </Checkbox>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Menu>
        </ClickAwayListener>
      )}
    </span>
  );
};

export default FilterZone;
