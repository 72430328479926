import classNames from 'classnames';

import IconLoading from 'shared/components/icons/icon-loading';
import { IconColors, IconSizes, SvgIconProps } from 'shared/components/icons/svg-icon';

import styles from './Spinner.module.scss';

interface SpinnerProps extends SvgIconProps {
  isFullPage?: boolean;
}

const Spinner = ({
  className,
  size = IconSizes.medium,
  color = IconColors.primary,
  isFullPage = false,
}: SpinnerProps) => (
  <span className={classNames({ [styles.spinnerFull]: isFullPage, className: !!className })}>
    <IconLoading size={size} color={color} className={classNames(styles.spinner, className)} />
  </span>
);

export default Spinner;
