import { useRecoilValue, useSetRecoilState } from 'recoil';

import { timelineAtoms } from '../../atoms';

export const useSetTimelineHeight = () => {
  return useSetRecoilState(timelineAtoms.height);
};

export const useTimelineHeight = () => {
  return useRecoilValue(timelineAtoms.height);
};
