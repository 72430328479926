import { GameApi, MetaDataPlayerApi } from './types';
import { MetadataGamesApiResponse } from './use-metadata-games/types';
import { Coach } from '../../types/coaches';
import { MetadataGame, MetadataGamePlayer, MetadataTeam } from '../../types/games';
import { CoachApi } from '../coaches/types';
import { transformSeason } from '../seasons/transformers';
import { TeamApi } from '../teams/types';

export const transformMetadataTeam = (team: TeamApi): MetadataTeam => ({
  id: team.uuid,
  name: team.name,
  abbreviation: team.abbreviation,
  logoUrl: team.logo_url,
  teamType: team.team_type,
  countryCode: team.country_code,
});

export const transformMetadataCoach = (coach: CoachApi | null): Coach | undefined => {
  if (!coach) return undefined;

  return {
    id: coach.uuid,
    name: coach.name,
    photoUrl: coach.photo_url,
  };
};

const transformMetadataPlayer = (player: MetaDataPlayerApi): MetadataGamePlayer => {
  return {
    id: player.player_uuid,
    name: player.player_name,
    jerseyNumber: player.jersey_number,
    photoUrl: player.player_photo_url,
    side: player.side,
    position: player.position,
    teamId: player.team_uuid,
  };
};

export const transformMetadataGame = (item: GameApi): MetadataGame => ({
  id: item.uuid,
  name: item.name,
  duration: item.duration,
  homeTeamScore: item.home_team_score,
  awayTeamScore: item.away_team_score,
  date: item.date ?? undefined,
  pitchLength: item.pitch_length,
  pitchWidth: item.pitch_width,
  homePenaltyScore: item.home_penalty_score,
  awayPenaltyScore: item.away_penalty_score,
  matchEndType: item.match_end_type,
  homeTeam: transformMetadataTeam(item.home_team),
  awayTeam: transformMetadataTeam(item.away_team),
  homeCoach: transformMetadataCoach(item.home_coach),
  awayCoach: transformMetadataCoach(item.away_coach),
  venue: item.venue
    ? {
        id: item.venue.uuid,
        name: item.venue.name,
        city: item.venue.city,
        countryCode: item.venue.country_code,
      }
    : undefined,
  stage: {
    id: item.stage.uuid,
    name: item.stage.name,
    orderIndex: item.stage.order_index,
  },
  season: transformSeason(item.season),
  players: item.players.map(transformMetadataPlayer),
  recordings: item.recordings.map((recording) => ({
    clientIds: recording.client_ids,
    createdAt: recording.created_at,
    id: recording.uuid,
    recordingType: recording.recording_type,
  })),
});

const transformMetadataGames = (items: GameApi[]): MetadataGame[] => items.map(transformMetadataGame);

export const transformMetadataGamesFromApiResponse = (response: MetadataGamesApiResponse) => ({
  data: {
    items: transformMetadataGames(response.data),
    page: response.page,
  },
  nextCursor: response.page.totalPages > response.page.number ? response.page.number + 1 : undefined,
});
