import { Stack } from '@mui/material';
import { PropsWithChildren, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import Toggle from 'shared/components/toggle';
import { useCurrentPlaylistItem, useVideoPlayerPlayingMode } from 'shared/components/video-player';
import {
  EPISODES_PLAYING_MODE,
  getPlayingMode,
  TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE,
} from 'shared/components/video-player/defaultPlayingModes';
import { PlayingModes } from 'shared/components/video-player/types';
import { useFeatureFlag } from 'shared/contexts/app-state';

import styles from './PlayingModeToggle.module.scss';
import { useTimelinePlayingMode } from '../../../../hooks/use-timeline-playing-mode';
import { TIMELINE_CONFIG } from '../../config';

const PlayingModelToggleContainer = ({ children }: PropsWithChildren) => (
  <div
    className={styles.playingModelToggleContainer}
    style={{ height: `${TIMELINE_CONFIG.STICKY_HEADER_HEIGHT / 2}px` }}
  >
    {children}
  </div>
);

export const PlayingModeToggle = (): JSX.Element => {
  const { t } = useTranslation();
  const currentPlaylistItem = useCurrentPlaylistItem();
  const playingMode = useVideoPlayerPlayingMode();
  const { setPlayingMode } = useTimelinePlayingMode(currentPlaylistItem.recordingId);
  const timelineEpisodesOverlaysFeatureFlag = useFeatureFlag(FEATURE_FLAG.EPISODES_OVERLAYS_TIMELINE);

  const handleToggle = useCallback(() => {
    if (currentPlaylistItem.hasHomographies && !timelineEpisodesOverlaysFeatureFlag) {
      return setPlayingMode(
        getPlayingMode({
          playingMode,
          useEffectiveTime: !playingMode.useEffectiveTime,
        }),
      );
    }

    return setPlayingMode(
      playingMode.mode === PlayingModes.EPISODES
        ? TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE
        : EPISODES_PLAYING_MODE,
    );
  }, [currentPlaylistItem.hasHomographies, timelineEpisodesOverlaysFeatureFlag, setPlayingMode, playingMode]);

  return (
    <PlayingModelToggleContainer>
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <span>{t('timeline:playing-mode-toggle.title')}</span>
        <Toggle isOn={playingMode.useEffectiveTime} onClick={() => handleToggle()} />
      </Stack>
    </PlayingModelToggleContainer>
  );
};
