import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { HTTPMethod } from '../../types';

export type BackendApiRequestTypes =
  | HTTPMethod.GET
  | HTTPMethod.POST
  | HTTPMethod.PATCH
  | HTTPMethod.PUT
  | HTTPMethod.DELETE;

axios.defaults.withCredentials = true;

export type UseBackendApiParams = AxiosRequestConfig['params'];

export const useBackendApi = (
  url: string,
  requestType: BackendApiRequestTypes,
  onSuccess: (response: any) => void = () => {},
  params: UseBackendApiParams = {},
): Promise<any> => {
  const handleError = (error: AxiosError) => {
    console.log(error);
    throw error;
  };

  switch (requestType) {
    case HTTPMethod.GET:
      return axios
        .get(url)
        .then((response) => onSuccess(response.data))
        .catch((e) => handleError(e));
    case HTTPMethod.PATCH:
      return axios
        .patch(url, params)
        .then((response) => onSuccess(response.data))
        .catch((e) => handleError(e));
    case HTTPMethod.POST:
      return axios
        .post(url, params)
        .then((response) => onSuccess(response.data))
        .catch((e) => handleError(e));
    case HTTPMethod.PUT:
      return axios
        .put(url, params)
        .then((response) => onSuccess(response.data))
        .catch((e) => handleError(e));
    case HTTPMethod.DELETE:
      return axios
        .delete(url, params)
        .then((response) => onSuccess(response.data))
        .catch((e) => handleError(e));
  }
};
