import { useTranslation } from 'react-i18next';

import Button, { ButtonTypes, ButtonBorderRadius, ButtonPadding, ButtonSizes } from 'shared/components/button';

type CopyButtonProps = {
  hasCopiedUrl: boolean;
  copyUrl: () => void;
};

const CopyButton = ({ hasCopiedUrl, copyUrl }: CopyButtonProps) => {
  const { t } = useTranslation();
  const text = hasCopiedUrl ? t('common:actions.copied') : t('common:actions.copy');
  const buttonType = hasCopiedUrl ? ButtonTypes.Green : ButtonTypes.PrimaryRoman;

  return (
    <Button
      onClick={copyUrl}
      type={buttonType}
      radius={ButtonBorderRadius.R4}
      padding={ButtonPadding.P8}
      btnSize={ButtonSizes.S}
    >
      {text}
    </Button>
  );
};

export default CopyButton;
