import { Box, LinearProgress, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

export const UploadVideoDropArea = styled('div')(({ theme }) => ({
  alignItems: 'center',
  background: Colors.white,
  borderColor: Colors.storm,
  borderRadius: theme.spacing(2),
  borderStyle: 'dashed',
  borderWidth: '2px',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  height: '20rem',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
}));

export const UploadProgress = styled(LinearProgress)(({ theme }) => ({
  height: theme.spacing(2),
}));

export const UploadMessageContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: theme.spacing(0, 1),
}));
