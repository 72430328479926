import { atom } from 'recoil';

import { PAGE_STATES } from 'shared/hooks/use-page-state-machine';
import { FeatureFlags, User, UserAuthority, UserRole } from 'shared/types/user/types';

export const defaultUserState: User = {
  id: '',
  clients: [],
  email: '',
  firstName: '',
  lastName: '',
  role: 'Video Analyst' as UserRole,
  locale: 'en-US',
  authorities: [UserAuthority.ROLE_USER],
};

export const defaultFeatureFlagsState = {};

const USER_ATOM_KEY = 'user';
const IS_AUTHORIZED_ATOM_KEY = 'isAuthorized';
const CURRENT_CLIENT_ID_ATOM_KEY = 'currentClientId';
const FEATURE_FLAGS_ATOM_KEY = 'featureFlags';
const APP_STATE_ATOM_KEY = 'appState';

export const userAtom = atom<User>({
  key: USER_ATOM_KEY,
  default: defaultUserState,
});

export const isAuthorizedAtom = atom<boolean>({
  key: IS_AUTHORIZED_ATOM_KEY,
  default: false,
});

export const currentClientIdAtom = atom<string>({
  key: CURRENT_CLIENT_ID_ATOM_KEY,
  default: '',
});

export const featureFlagsState = atom<FeatureFlags>({
  key: FEATURE_FLAGS_ATOM_KEY,
  default: defaultFeatureFlagsState,
});

export const appState = atom<PAGE_STATES>({
  key: APP_STATE_ATOM_KEY,
  default: PAGE_STATES.IDLE,
});
