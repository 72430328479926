import { Grid } from '@mui/material';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterMultiSelect } from 'shared/components/filter-multi-select';
import { FilterPlayer } from 'shared/components/filter-player';
import FilterSelect from 'shared/components/filter-select';
import { RecordingFiltersTactic } from 'shared/types/recording/types';
import { TacticsVariants } from 'shared/types/tactics/types';
import { MatchTeam } from 'shared/types/teams/types';

interface Props {
  appliedFilters: RecordingFiltersTactic[];
  disabled?: boolean;
  handleRemove: (removeIdx: number) => void;
  onPlayersChange: (players: string[], idx: number) => void;
  onScenarioChange: (fundamental: string, idx: number) => void;
  onTeamsChange: (teams: string[], idx: number) => void;
  tactics: { id: string; name: string }[];
  teams: MatchTeam[];
  variant: TacticsVariants;
}

export const RecordingTacticsPlayersFilter = ({
  appliedFilters,
  disabled,
  handleRemove,
  onPlayersChange,
  onScenarioChange,
  onTeamsChange,
  tactics,
  teams,
  variant,
}: Props) => {
  const { t } = useTranslation();

  const teamsWithPlayers = useMemo(() => teams.filter((team) => team.players.length > 0), [teams]);

  const hasPlayers = teamsWithPlayers.length > 0;

  const teamsOptions = useMemo(
    () => [
      {
        displayName: t(`recordings:filters.scenarios-inside-episode.select-team`),
        value: '',
      },
      ...map(teams, (team) => ({
        displayName: team.name,
        value: team.id,
      })),
    ],
    [teams, t],
  );

  return (
    <>
      {appliedFilters.map((value, idx) => {
        const listOfTactics = tactics
          .map((tactic) => ({
            displayName: t(`fundamentals:fundamentals.${tactic.id}`),
            value: tactic.id,
          }))
          .filter((fundamental) => {
            return (
              appliedFilters.every((tactic) => tactic.tacticalFundamentalType !== fundamental.value) ||
              appliedFilters[idx].tacticalFundamentalType === fundamental.value
            );
          });

        const listOfTacticsOptions = [
          {
            displayName: t('recordings:filters.scenarios-inside-episode.select-tactical-fundamental'),
            value: '',
          },
          ...sortBy(listOfTactics, 'displayName'),
        ];

        return (
          <Grid key={`${idx}`} container spacing={1}>
            {idx === 0 && (
              <Grid item>
                <FilterMultiSelect
                  disabled={disabled || teams.length === 1}
                  displayName={t(`recordings:filters.scenarios-inside-episode.select-${variant}-team`)}
                  onUpdate={(value: string[]) => {
                    onTeamsChange(value, idx);
                  }}
                  options={teamsOptions}
                  selectedValues={value?.teamIds}
                />
              </Grid>
            )}
            <Grid item>
              <FilterSelect
                disabled={disabled || listOfTactics.length === 0}
                displayName={t('recordings:filters.scenarios-inside-episode.tactical-fundamental')}
                selectedValue={value.tacticalFundamentalType}
                onUpdate={(value: string) => {
                  onScenarioChange(value, idx);
                }}
                options={listOfTacticsOptions}
              />
            </Grid>

            <Grid item>
              <FilterPlayer
                disabled={disabled || !hasPlayers}
                displayName={t('recordings:filters.scenarios-inside-episode.players')}
                idx={idx}
                onRemove={() => handleRemove(idx)}
                onUpdate={(values: string[]) => onPlayersChange(values, idx)}
                selectedValues={value.playerIds}
                showRemoveButton={idx !== 0}
                teamsWithPlayers={teamsWithPlayers}
              />
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};
