import { styled } from '@mui/material';
import { Colors, fontWeight } from 'kognia-ui';

import Button from 'shared/components/button';

type PlaylistButtonProps = {
  active: boolean;
};

export const PlaylistButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active',
})<PlaylistButtonProps>(({ active }) => ({
  color: active ? Colors.primary : 'inherit',
}));

export const PlaylistButtonText = styled('span')(() => ({
  fontWeight: fontWeight['400'],
}));
