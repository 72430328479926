import classNames from 'classnames';

import styles from './FilterContainer.module.scss';

interface Props {
  children: React.ReactNode;
  disabled?: boolean;
}

export const FilterContainer = ({ children, disabled }: Props) => {
  return <div className={classNames({ [styles.disabled]: disabled })}>{children}</div>;
};
