import { useFetchRequest } from 'api/hooks/useFetchRequest';

import { googleAuthUrl } from '../routes';

interface Params {
  enabled?: boolean;
}

export const useIsGoogleAuthorized = ({ enabled }: Params = {}) => {
  const fetchRequest = useFetchRequest({
    queryRef: ['googleAuth'],
    url: googleAuthUrl,
    options: { enabled, retry: 0 },
  });

  const { isLoading, isError } = fetchRequest;

  const isBackofficeAuthorized = !(isError && !isLoading);

  return { isBackofficeAuthorized, isLoading };
};
