import { Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import { Colors, fontWeight } from 'kognia-ui';

import { IconUser } from 'shared/components/icons/icon-user';

import { SelectedPlayersList } from './selected-players-list';
import { MetadataPlayerSummary } from '../../../../../types/players';

type Props = {
  selectedPlayers: MetadataPlayerSummary[];
  removePlayers: (player: MetadataPlayerSummary[]) => void;
  onUpdatePlayer: (player: MetadataPlayerSummary) => void;
  error: string;
};

export const SelectedPlayers = ({ selectedPlayers = [], removePlayers, onUpdatePlayer, error }: Props) => {
  return (
    <Stack gap={1}>
      <Card>
        <CardContent>
          <Stack gap={2}>
            <Stack gap={2} direction={'row'} alignItems={'center'}>
              <Typography
                sx={{
                  fontWeight: fontWeight['500'],
                  color: Colors.shark,
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                }}
              >
                <IconUser /> <span>{`Players selection (${(selectedPlayers ?? []).length})`}</span>
              </Typography>
              {error && <Typography color={Colors.red}>{error}</Typography>}
            </Stack>
            <Divider />
            <Stack gap={2}>
              <SelectedPlayersList
                selectedPlayers={selectedPlayers}
                removePlayers={removePlayers}
                onUpdatePlayer={onUpdatePlayer}
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};
export { getTeamLastGameDate } from './utils';
export { getTeamName } from './utils';
export { getJerseyNumber } from './utils';
