import { FormControl, TextField } from '@mui/material';
import { Path, UseFormRegister, FieldErrors } from 'react-hook-form';

import { FormFormLabel } from 'shared/components/form/form-form-label';

import { CompetitionFormFieldsNames } from './types';

interface Props<TFormValues extends Record<string, any>> {
  register: UseFormRegister<TFormValues>;
  errors: FieldErrors<TFormValues>;
}

export function CompetitionsForm<TFormValues extends Record<string, any>>({ register, errors }: Props<TFormValues>) {
  return (
    <>
      <FormControl fullWidth margin='normal'>
        <FormFormLabel>Competition Name</FormFormLabel>
        <TextField
          size='small'
          {...register(CompetitionFormFieldsNames.name as Path<TFormValues>)}
          error={!!errors[CompetitionFormFieldsNames.name]}
          helperText={errors[CompetitionFormFieldsNames.name] && <>{errors[CompetitionFormFieldsNames.name].message}</>}
        />
      </FormControl>
      <FormControl fullWidth margin='normal'>
        <FormFormLabel>Country</FormFormLabel>
        <TextField
          size='small'
          {...register(CompetitionFormFieldsNames.country as Path<TFormValues>)}
          error={!!errors[CompetitionFormFieldsNames.country]}
          helperText={
            errors[CompetitionFormFieldsNames.country] && <>{errors[CompetitionFormFieldsNames.country].message}</>
          }
        />
      </FormControl>
    </>
  );
}
