import { Grid } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TacticalAnalysesFilters } from 'api/recording/useEpisodesWithFilters/types';
import Button, { ButtonTypes } from 'shared/components/button';
import { FilterContainer } from 'shared/components/filter-container';
import { FilterTab, FilterTabPanel, FilterTabs } from 'shared/components/filter-tabs';
import { IconSizes } from 'shared/components/icons/svg-icon';
import { LoadingBlock } from 'shared/components/loading-block';
import Spinner from 'shared/components/spinner';
import { RecordingsFilters } from 'shared/types/recording/types';

import { useMultipleRecordingsFilters } from './hooks/use-multiple-recordings-filters';
import { getFilterStatus } from './utils';
import FilterEvents, { EventType } from '../../filter-events';
import RecordingsFiltersActions from '../../recordings-filters-actions';
import { RecordingsFiltersInsideAnEpisode } from '../../recordings-filters-inside-an-episode';

interface Props {
  filtersData: TacticalAnalysesFilters;
  recordingIds: string[];
  isLoadingFilters: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  onApply: (filters: RecordingsFilters) => void;
  loadingButtonLabel: string;
  applyButtonLabel: string;
  onUpdate?: (filters: RecordingsFilters) => void;
  getFilters?: (params: RecordingsFilters) => void;
  appliedFilters?: RecordingsFilters;
}

const Tabs = {
  Starting: '0',
  Inside: '1',
  Ending: '2',
};

export const MultiAnalysisFilters = ({
  recordingIds,
  loadingButtonLabel,
  applyButtonLabel,
  getFilters,
  onApply,
  isDisabled,
  filtersData,
  isLoading,
  isLoadingFilters,
  onUpdate,
  appliedFilters,
}: Props) => {
  const { filters, reset, setEventsEndingFilter, setEventsStartingFilter, setScenariosInsideAnEpisodeFilter } =
    useMultipleRecordingsFilters(appliedFilters ? { ...appliedFilters } : { recordingIds });
  const { areFiltersStartingApplied, areFiltersInsideApplied, areFiltersEndingApplied } = getFilterStatus(filters);

  const areFiltersApplied = areFiltersStartingApplied || areFiltersEndingApplied || areFiltersInsideApplied;

  useEffect(() => {
    onUpdate && onUpdate(filters);
  }, [areFiltersApplied, filters, onUpdate]);

  const handleOnApply = useCallback(() => {
    onApply(filters);
  }, [onApply, filters]);

  const { t } = useTranslation();
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(Tabs.Starting);

  const handleTabChange = useCallback((event: React.ChangeEvent<any>, newValue: string) => {
    setSelectedTabIndex(newValue);
  }, []);

  const areFiltersDisabled = recordingIds.length === 0;

  useEffect(() => {
    if (!getFilters) return;

    if (filters.recordingIds.length > 0 && areFiltersApplied) {
      getFilters(filters);
    }
  }, [filters, getFilters]);

  return (
    <LoadingBlock isLoading={isLoadingFilters}>
      <FilterContainer disabled={areFiltersDisabled}>
        <FilterTabs disabled={areFiltersDisabled} value={selectedTabIndex} onChange={handleTabChange}>
          <FilterTab
            disabled={areFiltersDisabled}
            active={areFiltersStartingApplied}
            label={t('recordings:filters.tabs.events-starting-episode')}
            value={'0'}
          />
          <FilterTab
            disabled={areFiltersDisabled}
            active={areFiltersInsideApplied}
            label={t('recordings:filters.tabs.scenarios-inside-episode')}
            value={'1'}
          />
          <FilterTab
            disabled={areFiltersDisabled}
            active={areFiltersEndingApplied}
            label={t('recordings:filters.tabs.events-ending-episode')}
            value={'2'}
          />
        </FilterTabs>
        <FilterTabPanel value={selectedTabIndex} index={Tabs.Starting}>
          <FilterEvents
            disabled={areFiltersDisabled}
            type={EventType.STARTING}
            onUpdate={setEventsStartingFilter}
            filters={filters.eventsStarting}
            teams={filtersData.teams}
            actionsStarting={filtersData.eventsStarting}
          />
        </FilterTabPanel>
        <FilterTabPanel value={selectedTabIndex} index={Tabs.Inside}>
          <RecordingsFiltersInsideAnEpisode
            filters={filters.scenariosOrTacticsInside}
            onUpdate={setScenariosInsideAnEpisodeFilter}
            scenarios={filtersData.scenarios}
            tactics={filtersData.tactics}
            teams={filtersData.teams}
          />
        </FilterTabPanel>
        <FilterTabPanel value={selectedTabIndex} index={Tabs.Ending}>
          <FilterEvents
            disabled={areFiltersDisabled}
            type={EventType.ENDING}
            onUpdate={setEventsEndingFilter}
            filters={filters.eventsEnding}
            teams={filtersData.teams}
            actionsStarting={filtersData.eventsEnding}
          />
        </FilterTabPanel>
        <RecordingsFiltersActions>
          <Grid item>
            <Button onClick={reset} type={ButtonTypes.WhiteIronOutlined}>
              <span>{t('recordings:filters.clear-filters')}</span>
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!areFiltersApplied || isLoading || isDisabled}
              onClick={handleOnApply}
              type={ButtonTypes.Primary}
            >
              <span>
                {areFiltersApplied && isLoading && (
                  <>
                    <Spinner size={IconSizes.small} />{' '}
                  </>
                )}
                {areFiltersApplied && isLoading ? loadingButtonLabel : applyButtonLabel}
              </span>
            </Button>
          </Grid>
        </RecordingsFiltersActions>
      </FilterContainer>
    </LoadingBlock>
  );
};
