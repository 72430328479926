import { UseQueryResult } from '@tanstack/react-query';

import { useAppQueryClient } from 'api/hooks/useAppQueryClient';
import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { liveTaggingSessionUrl } from 'api/routes';

import { LiveTaggingSession } from '../types';

export const generateLiveSessionRef = (liveTaggingSessionId: string) => {
  return [`fetch-live-tagging-session:${liveTaggingSessionId}`];
};

interface useFetchLiveTaggingSessionInterface {
  (liveTaggingSessionId: string): UseQueryResult<LiveTaggingSession> & {
    setQueryData: (data: LiveTaggingSession) => void;
  };
}

export const useFetchLiveTaggingSession: useFetchLiveTaggingSessionInterface = (liveTaggingSessionId) => {
  const fetchQueryRef = generateLiveSessionRef(liveTaggingSessionId);
  const { queryClient } = useAppQueryClient();

  const fetchRequest = useFetchRequest<LiveTaggingSession>({
    queryRef: fetchQueryRef,
    url: liveTaggingSessionUrl(liveTaggingSessionId),
    transformer: (response: LiveTaggingSession) => response,
  });

  const invalidateQuery = () => queryClient.invalidateQueries(fetchQueryRef);
  const setQueryData = (data: LiveTaggingSession) => queryClient.setQueryData(fetchQueryRef, data);

  return { ...fetchRequest, invalidateQuery, setQueryData };
};
