import React from 'react';

import { PlayingMode } from '../../types';
import { VideoBarTacticDrawingsMode } from '../video-bar-tactic-drawings-mode';

interface Props {
  disabledTacticDrawings?: boolean;
  setPlayingMode: (playingMode: PlayingMode) => void;
  shouldShowTacticDrawings: boolean;
  showTacticDrawingsOnly: boolean;
}

export const VideoBarHeader = ({
  disabledTacticDrawings,
  setPlayingMode,
  shouldShowTacticDrawings,
  showTacticDrawingsOnly,
}: Props) => {
  if (!shouldShowTacticDrawings) return null;

  return (
    <VideoBarTacticDrawingsMode
      showTacticDrawingsOnly={showTacticDrawingsOnly}
      disabledTacticDrawings={disabledTacticDrawings}
      setPlayingMode={setPlayingMode}
    />
  );
};
