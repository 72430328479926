import { styled } from '@mui/material';
import { Colors } from 'kognia-ui';
import { forwardRef } from 'react';

import styles from './Toggle.module.scss';

interface Props {
  isDisabled?: boolean;
  isOn?: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  onColor?: string;
  offColor?: string;
}

type ToggleContainerProps = {
  isOn: boolean;
  onColor: string;
  offColor: string;
  isDisabled: boolean;
};
const ToggleContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isOn' && prop !== 'onColor' && prop !== 'offColor' && prop !== 'isDisabled',
})<ToggleContainerProps>(({ theme, isOn, onColor, offColor, isDisabled }) => ({
  background: isOn ? onColor : offColor,
  ['> div div']: {
    left: isOn ? `calc(100% - ${theme.spacing(2)})` : 0,
  },
  borderRadius: '48px',
  cursor: 'pointer',
  height: '24px',
  padding: theme.spacing(0.5),
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.shorter,
  }),
  opacity: isDisabled ? 0.3 : 1,
  pointerEvents: isDisabled ? 'none' : 'auto',
  filter: isDisabled ? 'grayscale(100%)' : 'none',
  minWidth: '40px',
  lineHeight: 1,
}));

const Toggle = forwardRef(
  (
    {
      isOn = false,
      isDisabled = false,
      onClick,
      offColor = Colors.storm,
      onColor = Colors.primary,
      ...rest
    }: React.HTMLAttributes<HTMLDivElement> & Props,
    ref: any,
  ) => (
    <ToggleContainer
      ref={ref}
      onClick={onClick}
      isOn={isOn}
      offColor={offColor}
      onColor={onColor}
      isDisabled={isDisabled}
      {...rest}
    >
      <div className={styles.toggleTrack}>
        <div className={styles.toggle} />
      </div>
    </ToggleContainer>
  ),
);

Toggle.displayName = 'Toggle';

export default Toggle;
