import { FormSwitchField } from 'shared/components/form/form-switch-field';

import { AnnotationFormLabels } from '../../../utils/form-fields-validation';
import { AnnotationFormFieldsNames } from '../../../utils/form-fields-validation/types';
import { FormFieldProps } from '../types';

export const DryRunField = ({ register }: FormFieldProps) => {
  return (
    <FormSwitchField
      id={AnnotationFormFieldsNames.DRY_RUN}
      label={AnnotationFormLabels[AnnotationFormFieldsNames.DRY_RUN]}
      {...register(AnnotationFormFieldsNames.DRY_RUN)}
    />
  );
};
