import { z } from 'zod';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { useUserPresets } from 'api/user-presets/use-user-presets';
import { getPreset } from 'api/user-presets/use-user-presets/utils';
import { PlayingMode, PlayingModes } from 'shared/components/video-player/types';
import { useFeatureFlag } from 'shared/contexts/app-state';
import { TacticIdOrAll } from 'shared/types/playlist/types';
import { RecordingsFilters } from 'shared/types/recording/types';
import {
  timelineAppliedFiltersPreset,
  timelineHeadersWidthPreset,
  timelineHeightPreset,
  timelinePinScenariosPreset,
  timelinePlayingModePreset,
  timelineSelectedTacticsPreset,
  timelineShowBallPossession,
  timelineShowNoBallPossession,
  timelineTeamIdFocus,
  timelineTimePreset,
  timelineZoomPreset,
  UserPresetScope,
} from 'shared/types/user-preset/types';

import { ZOOM_LEVELS_TYPE } from '../../../components/tactical-analysis/zoom-range';
import { useSetPinScenarios } from '../../../hooks/use-pin-scenarios';
import { useSetPresetPlayingMode } from '../../../hooks/use-preset-playing-mode';
import { useSetTacticalAnalysisAppliedFilters } from '../../../hooks/use-tactical-analysis-applied-filters';
import { useSetTacticalAnalysisSelectedTactics } from '../../../hooks/use-tactical-analysis-selected-tactics';
import {
  useSetTimelineNoShowBallPossession,
  useSetTimelineShowBallPossession,
} from '../../../hooks/use-timeline-ball-possession';
import { useSetTimelineHeadersWidth } from '../../../hooks/use-timeline-headers-width';
import { useSetTimelineHeight } from '../../../hooks/use-timeline-height';
import { useSetTimelineInitialTime } from '../../../hooks/use-timeline-initial-time';
import { useSetTimelineTeamIdFocus } from '../../../hooks/use-timeline-team-id-focus';
import { useSetTimelineZoomLevel } from '../../../hooks/use-timeline-zoom-level';
import { TimelinePresets } from '../types';

const fixPlayingMode = (playingMode: PlayingMode, useOverlays: boolean) => {
  if (playingMode.mode === PlayingModes.EPISODES && useOverlays) return PlayingModes.TACTICAL_CAMERA;
  if (!useOverlays && playingMode.mode === PlayingModes.TACTICAL_CAMERA && playingMode.useEffectiveTime)
    return PlayingModes.EPISODES;

  return playingMode.mode;
};

export const useTacticalAnalysisPresets = (recordingId: string) => {
  const setPinScenarios = useSetPinScenarios();
  const setTimelineZoomLevel = useSetTimelineZoomLevel();
  const setTimelineHeight = useSetTimelineHeight();
  const setTimelineInitialTime = useSetTimelineInitialTime(recordingId);
  const setTimelineTeamIdFocus = useSetTimelineTeamIdFocus(recordingId);
  const setSelectedTactics = useSetTacticalAnalysisSelectedTactics(recordingId);
  const setPlayingMode = useSetPresetPlayingMode(recordingId);
  const setShowBallPossession = useSetTimelineShowBallPossession(recordingId);
  const setNoShowBallPossession = useSetTimelineNoShowBallPossession(recordingId);
  const setTacticalAnalysisAppliedFilters = useSetTacticalAnalysisAppliedFilters(recordingId);
  const customOverlaysFeatureFlag = useFeatureFlag(FEATURE_FLAG.CUSTOM_OVERLAYS);
  const timelineEpisodesOverlaysFeatureFlag = useFeatureFlag(FEATURE_FLAG.EPISODES_OVERLAYS_TIMELINE);
  const useOverlays = customOverlaysFeatureFlag && !timelineEpisodesOverlaysFeatureFlag;

  const setTimelineHeadersWidth = useSetTimelineHeadersWidth();

  const fetchTimelineRecordingPresets = useUserPresets<TimelinePresets>({
    prefix: 'team-centered-timeline',
    scope: UserPresetScope.timeline,
    ref: recordingId,
    onSuccess: (data) => {
      const initialTimePreset = getPreset<number>(data, timelineTimePreset.key);
      const teamIdFocus = getPreset<string>(data, timelineTeamIdFocus.key);
      const selectedTactics = getPreset<TacticIdOrAll[]>(data, timelineSelectedTacticsPreset.key) ?? [];
      const selectedPlayingMode = getPreset<PlayingMode>(data, timelinePlayingModePreset.key) ?? undefined;
      const showBallPossession = getPreset<boolean>(data, timelineShowBallPossession.key, z.boolean());
      const showBallNoPossession = getPreset<boolean>(data, timelineShowNoBallPossession.key, z.boolean());
      const appliedFilters = getPreset<RecordingsFilters>(data, timelineAppliedFiltersPreset.key);

      if (selectedPlayingMode) {
        setPlayingMode({
          ...selectedPlayingMode,
          mode: fixPlayingMode(selectedPlayingMode, useOverlays),
          showOverlays: selectedPlayingMode?.useEffectiveTime ?? true,
          useEffectiveTime: selectedPlayingMode?.useEffectiveTime ?? true,
        });
      }

      setSelectedTactics(selectedTactics);

      if (appliedFilters !== undefined) setTacticalAnalysisAppliedFilters(appliedFilters, false);
      if (showBallPossession !== undefined) setShowBallPossession(showBallPossession, false);
      if (showBallNoPossession !== undefined) setNoShowBallPossession(showBallNoPossession, false);
      if (teamIdFocus !== undefined) setTimelineTeamIdFocus(teamIdFocus, false);
      if (initialTimePreset !== undefined) setTimelineInitialTime(initialTimePreset);
    },
  });

  const fetchTimelineGlobalPresets = useUserPresets({
    scope: UserPresetScope.timeline,
    onSuccess: (data) => {
      const zoomPreset = getPreset<ZOOM_LEVELS_TYPE>(data, timelineZoomPreset.key);
      const heightPreset = getPreset<number>(data, timelineHeightPreset.key, z.number());
      const headersWidth = getPreset<number>(data, timelineHeadersWidthPreset.key, z.number());
      const pinScenarios = getPreset<boolean>(data, timelinePinScenariosPreset.key, z.boolean());

      if (pinScenarios !== undefined) setPinScenarios(pinScenarios, false);
      if (headersWidth !== undefined) setTimelineHeadersWidth(headersWidth);
      if (zoomPreset !== undefined) setTimelineZoomLevel(zoomPreset);
      if (heightPreset !== undefined) setTimelineHeight(heightPreset);
    },
  });

  return {
    isSuccess: fetchTimelineGlobalPresets.isSuccess && fetchTimelineRecordingPresets.isSuccess,
    isLoading: fetchTimelineGlobalPresets.isLoading || fetchTimelineRecordingPresets.isLoading,
    isFetching: fetchTimelineGlobalPresets.isFetching || fetchTimelineRecordingPresets.isFetching,
  };
};
