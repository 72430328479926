import { PopoverActions } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';

import Button, { ButtonTypes } from 'shared/components/button';

import { FilterButtonLink } from './filter-button-link';
import styles from './Filterv2.module.scss';
import IconChevronDown from '../icons/icon-chevron-down';
import { IconColors, IconSizes } from '../icons/svg-icon';

enum DropdownVariants {
  BUTTON = 'button',
  LINK = 'link',
}

type FilterProps = {
  children: JSX.Element;
  displayName: string;
  hasSelectedOptions?: boolean;
  isOpen: boolean;
  onClickOpen: () => void;
  onClose: () => void;
  variant?: DropdownVariants;
};

export type FilterOption = {
  id: string;
  title: string;
  isSelected: boolean;
};

const Filter = ({
  isOpen,
  onClickOpen,
  displayName,
  onClose,
  hasSelectedOptions = false,
  children,
  variant = DropdownVariants.LINK,
}: FilterProps) => {
  const actionRef = useRef<PopoverActions | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const filterId = 'filter-menu';

  const updateMenuPosition = () => {
    actionRef.current?.updatePosition();
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    onClickOpen();
  };

  const handleClose = () => {
    if (!anchorEl) return;

    setAnchorEl(undefined);
    onClose();
  };

  return (
    <>
      {variant === DropdownVariants.BUTTON && (
        <Button className={classNames(styles.dropdownButton, { active: isOpen })} type={ButtonTypes.White}>
          <div className={styles.group} onClick={handleButtonClick}>
            <div className={styles.groupContent}>{displayName}</div>
            <span
              className={classNames(styles.chevron, {
                [styles.chevronUp]: isOpen,
              })}
            >
              <IconChevronDown color={IconColors.iron} size={IconSizes.small} />
            </span>
          </div>
        </Button>
      )}
      {variant === DropdownVariants.LINK && (
        <FilterButtonLink
          filterId={filterId}
          buttonType={isOpen || hasSelectedOptions ? ButtonTypes.LinkActive : ButtonTypes.Link}
          onClick={handleButtonClick}
        >
          {displayName}
        </FilterButtonLink>
      )}
      {isOpen && anchorEl && (
        <ClickAwayListener onClickAway={handleClose}>
          <Menu
            action={actionRef}
            id={filterId}
            anchorEl={anchorEl}
            open
            onClose={handleClose}
            TransitionComponent={Fade}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            className={styles.menu}
          >
            <div className={styles.contentContainer}>{React.cloneElement(children, { updateMenuPosition })}</div>
          </Menu>
        </ClickAwayListener>
      )}
    </>
  );
};

export default Filter;
