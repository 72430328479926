import { useTranslation } from 'react-i18next';

import Container from 'shared/components/container';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import { useUser } from 'shared/contexts/app-state';

import Solutions from './components/solutions';
import styles from './HomeContainer.module.scss';

export const HomeContainer = () => {
  const user = useUser();
  const { t } = useTranslation();

  return (
    <SidebarLayout>
      <Container>
        <div className={styles.homePage}>
          <h1 data-testid='page-title'>{t('home:welcome', { firstName: user.firstName })}</h1>
          <p>{t('home:what-would-you-like-to-do')}</p>
          <Solutions />
        </div>
      </Container>
    </SidebarLayout>
  );
};

export default HomeContainer;
