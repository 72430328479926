import classNames from 'classnames';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';

import { useAddManyToPlaylist } from 'api/playlist/useAddManyToPlaylist';
import { useDeletePlaylistItem } from 'api/playlist/useDeletePlaylistItem';
import { invalidatePlaylistsQuery, usePlaylists } from 'api/playlist/useFetchPlaylists';
import IconClose from 'shared/components/icons/icon-close';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import { PlaylistMenuVerticalPosition } from 'shared/components/select-playlist-dialog';
import { useCurrentPlaylistItem } from 'shared/components/video-player';
import { FundamentalsSelection, Playlist } from 'shared/types';

import { usePlaylistMenuId } from './add-to-playlist-menu-state';
import { playlistMenuAtoms } from './add-to-playlist-menu-state/atoms';
import {
  generatePlaylistMenuItemId,
  usePlaylistMenuItems,
  useRemoveMultiplePlaylistMenuItem,
} from './add-to-playlist-menu-state/hooks';
import styles from './AddToPlaylistMenu.module.scss';
import { CurrentPlaylistMenu } from './current-playlist-menu';
import { PlaylistItemsSelectorMenu } from './playlist-items-selector-menu';
import { PlaylistSelectorMenu } from './playlist-selector-menu';
import { UnsavedChangesDialog } from './unsaved-changes-dialog';
import {
  selectionActionsCreator,
  useClipsIdsSelection,
  useGetClipsList,
  useGetRowsList,
} from '../../../api/use-tactical-analysis-data/generate-timeline-rows/atoms';
import {
  useTimelineShowBallPossession,
  useTimelineShowNoBallPossession,
} from '../../../hooks/use-timeline-ball-possession';
import { useTeamUtils, useTimelineTeamIdFocus } from '../../../hooks/use-timeline-team-id-focus';
import { useGeneratePlaylistItemName } from '../../timeline/timeline-table/hooks/use-generate-playlist-item-name';

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  recordingId: string;
  verticalPosition?: PlaylistMenuVerticalPosition;
}

export const useUpdatePlaylistMenuClips = (recordingId: string) => {
  const generatePlaylistItemName = useGeneratePlaylistItemName(recordingId);
  const playlistMenuId = usePlaylistMenuId();
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (clipIds: string[]) => {
        const actions = selectionActionsCreator(set, snapshot);

        clipIds.forEach((clipId) => {
          const clip = actions.getClip(clipId);
          if (!clip) return;

          set(playlistMenuAtoms.playlistMenuItem(generatePlaylistMenuItemId(playlistMenuId, clipId)), {
            id: clip.id,
            startTime: clip.startTime,
            endTime: clip.endTime,
            playlistId: '',
            name: generatePlaylistItemName(clip),
          });
        });
        set(playlistMenuAtoms.playlistMenuState(playlistMenuId), clipIds);
      },
    [generatePlaylistItemName],
  );
};

const useSynchronizeTimelineSelectionWithPlaylistMenu = (recordingId: string) => {
  const getRowsList = useGetRowsList();
  const getClipsList = useGetClipsList();
  const showBallPossession = useTimelineShowBallPossession(recordingId);
  const showNoBallPossession = useTimelineShowNoBallPossession(recordingId);
  const clipIdsSelection = useClipsIdsSelection();
  const updatePlaylistMenuClips = useUpdatePlaylistMenuClips(recordingId);
  const { isHomeTeamSelected, isOpponentTeamSelected } = useTimelineTeamIdFocus(recordingId);
  const { isHomeTeam, isOpponentTeam } = useTeamUtils(recordingId);

  useEffect(() => {
    const clips = getClipsList(clipIdsSelection);
    const hiddenRows = getRowsList([...new Set(clips.map((c) => c.rowId))])
      .filter((r) => r.isHidden)
      .map((r) => r.id);

    const filteredClips = clips
      .filter((c) => !hiddenRows.includes(c.rowId))
      .filter((c) => {
        if (!c.teamId) return true;
        if (showBallPossession && showNoBallPossession) return true;

        if (
          isHomeTeam(c.teamId) &&
          ((isHomeTeamSelected && showBallPossession) || (isOpponentTeamSelected && showNoBallPossession))
        )
          return true;
        if (
          isOpponentTeam(c.teamId) &&
          ((isOpponentTeamSelected && showBallPossession) || (isHomeTeamSelected && showNoBallPossession))
        )
          return true;

        return false;
      });

    updatePlaylistMenuClips(filteredClips.map((c) => c.id));
  }, [
    getClipsList,
    getRowsList,
    isHomeTeam,
    isOpponentTeam,
    clipIdsSelection,
    updatePlaylistMenuClips,
    isHomeTeamSelected,
    isOpponentTeamSelected,
    showBallPossession,
    showNoBallPossession,
  ]);
};

const AddToPlaylistMenu = ({
  isOpen,
  onClose = () => {},
  recordingId,
  verticalPosition = PlaylistMenuVerticalPosition.Center,
}: Props) => {
  const [isMenuOpen, setMenuOpen] = useState(isOpen);

  const { t } = useTranslation();
  const {
    state,
  }: {
    state:
      | {
          playlist: Playlist;
        }
      | undefined;
  } = useLocation();
  const [isUnsavedDialogOpen, setIsUnsavedDialogOpen] = useState(false);
  const [selectedPlaylist, setSelectedPlaylist] = useState<Playlist | undefined>(
    state?.playlist ? state.playlist : undefined,
  );
  const reset = useRemoveMultiplePlaylistMenuItem();
  const { data } = usePlaylists({ enabled: isMenuOpen, refetchInterval: false, isAutocomplete: true });
  const selectedPlaylistItems = usePlaylistMenuItems();
  const { addManyToPlaylist } = useAddManyToPlaylist();
  const { deletePlaylistItem } = useDeletePlaylistItem(selectedPlaylist?.id || '');
  const currentPlaylistItem = useCurrentPlaylistItem();

  useEffect(() => {
    if (
      data?.playlists &&
      selectedPlaylist &&
      data.playlists.find((item) => item.id === selectedPlaylist?.id) &&
      !isEqual(
        selectedPlaylist,
        data.playlists.find((item) => item.id === selectedPlaylist?.id),
      )
    ) {
      setSelectedPlaylist(data.playlists.find((item) => item.id === selectedPlaylist?.id));
    }
  }, [data.playlists, selectedPlaylist]);

  const handleSave = useCallback(() => {
    if (!selectedPlaylist) return;
    const fundamentalsSelected: FundamentalsSelection = currentPlaylistItem.hasHomographies
      ? currentPlaylistItem.fundamentalsSelected
      : {
          tacticalAnalysisId: currentPlaylistItem.fundamentalsSelected.tacticalAnalysisId,
          fundamentalsSelected: ['all'],
        };
    addManyToPlaylist({
      items: selectedPlaylistItems.map((newItem) => {
        return {
          playlistId: selectedPlaylist.id,
          name: newItem.name,
          startTime: newItem.startTime,
          endTime: newItem.endTime,
          recordingId,
          fundamentalsSelected,
        };
      }),
      options: {
        onSuccess: async () => {
          await invalidatePlaylistsQuery();
          reset(selectedPlaylistItems.map((clip) => clip.id));
        },
      },
    });
  }, [
    selectedPlaylist,
    addManyToPlaylist,
    selectedPlaylistItems,
    recordingId,
    reset,
    currentPlaylistItem.hasHomographies,
    currentPlaylistItem.fundamentalsSelected,
  ]);

  useEffect(() => {
    setMenuOpen(isOpen);
  }, [isOpen]);

  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
    setSelectedPlaylist(undefined);
    onClose();
    reset(selectedPlaylistItems.map((clip) => clip.id));
  }, [onClose, reset, selectedPlaylistItems]);

  const handleClose = useCallback(() => {
    if (selectedPlaylistItems.length > 0) {
      setIsUnsavedDialogOpen(true);
    } else {
      handleMenuClose();
    }
  }, [handleMenuClose, selectedPlaylistItems.length]);

  const handleSelectedPlaylistChange = useCallback((playlist: Playlist) => setSelectedPlaylist(playlist), []);
  const handleDeletePlaylistItem = useCallback(
    (playlistItemId: string) => {
      deletePlaylistItem(playlistItemId);
    },
    [deletePlaylistItem],
  );

  useSynchronizeTimelineSelectionWithPlaylistMenu(recordingId);

  if (!data) return null;

  return (
    <>
      <div
        className={classNames(styles.menuContainer, {
          [styles.closed]: !isMenuOpen,
        })}
      >
        <div className={styles.menuContent}>
          <div className={styles.titleBar}>
            <span className={styles.title}>{t('timeline:add-to-playlist-menu.title')}</span>
            <span className={styles.closeButton} onClick={handleClose}>
              <IconClose size={IconSizes.small} color={IconColors.white} />
            </span>
          </div>
          <PlaylistSelectorMenu
            verticalPosition={verticalPosition}
            selectedPlaylist={selectedPlaylist}
            onSelect={handleSelectedPlaylistChange}
          />
          <div className={styles.container}>
            <PlaylistItemsSelectorMenu onSave={handleSave} playlistId={selectedPlaylist?.id} />
            {selectedPlaylist ? (
              <CurrentPlaylistMenu
                selectedPlaylist={selectedPlaylist}
                onDeletePlaylistItem={handleDeletePlaylistItem}
              />
            ) : null}
          </div>
        </div>
      </div>
      <UnsavedChangesDialog
        isOpen={isUnsavedDialogOpen}
        onCancel={() => {
          setIsUnsavedDialogOpen(false);
          handleMenuClose();
        }}
        onSave={handleSave}
        onClose={() => setIsUnsavedDialogOpen(false)}
      />
    </>
  );
};

export default AddToPlaylistMenu;
