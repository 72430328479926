import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';

import { annotationApiUrls } from '../../routes';
import { useInvalidateSeasonClients } from '../use-season-clients';

interface DeleteSeasonClientsApiParams {
  id: string;
  onSuccess?: () => void;
}

export const useDeleteSeasonClients = () => {
  const invalidateSeasonClients = useInvalidateSeasonClients();
  const mutationRequest = useMutationRequest({
    type: HTTPMethod.DELETE,
    successMessage: 'Association season clients deleted',
    errorMessage: 'Association season clients delete error',
    onSuccess: invalidateSeasonClients,
  });

  const deleteSeasonClients = ({ id, onSuccess }: DeleteSeasonClientsApiParams) => {
    mutationRequest.mutate({ url: `${annotationApiUrls.SEASON_CLIENTS}/${id}` }, { onSuccess });
  };

  return { ...mutationRequest, deleteSeasonClients };
};
