import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { updatePlaylistItemUrl } from 'api/routes';
import { useVideoPlayerId } from 'shared/components/video-player/hooks';
import { usePlayerSetPlaylist } from 'shared/components/video-player/state/atoms/hooks';
import { PlaylistItemType } from 'shared/components/video-player/types';

import { useMutationRequest } from '../../hooks/useMutationRequest';
import { HTTPMethod } from '../../types';
import { transformPlaylist } from '../transformers';

const moveTo = function <T>(arrayToReorder: T[], from: number, to: number) {
  const reorderedArray = [...arrayToReorder];
  reorderedArray.splice(to, 0, reorderedArray.splice(from, 1)[0]);

  return reorderedArray;
};

export const useUpdatePlaylistItemOrder = (playlistId: string) => {
  const { t } = useTranslation();
  const playerId = useVideoPlayerId();
  const setPlaylist = usePlayerSetPlaylist(playerId);
  const { mutate, isLoading, isError, isSuccess } = useMutationRequest({
    type: HTTPMethod.PATCH,
    transformer: transformPlaylist,
    errorMessage: t('api:use-update-playlist-item-order.error'),
  });

  const updatePlaylistItemOrder = useCallback(
    (playlistItemId: string, newIndex: number) => {
      //Optimistic update of the reordered playlist
      setPlaylist((playlist) => {
        const playlistItemIndex = playlist.playlistItems.findIndex(
          (playlistItem) => playlistItem.id === playlistItemId,
        );
        const playlistItems = [...playlist.playlistItems];

        const reorderedItems = moveTo<PlaylistItemType>(playlistItems, playlistItemIndex, newIndex);
        return { ...playlist, playlistItems: reorderedItems };
      });
      mutate({ url: updatePlaylistItemUrl(playlistId, playlistItemId), data: { index: newIndex } });
    },
    [setPlaylist, mutate, playlistId],
  );

  return { updatePlaylistItemOrder, isLoading, isError, isSuccess };
};
