import React, { useRef } from 'react';

import { Episode } from 'shared/types';
import { GroupedTags } from 'shared/types/tagging-events/types';

import { EpisodesClips } from './components/episodes-clips';
import { EpisodesRow } from './components/episodes-row';
import { FiltersContentRow } from './components/filters-content-row';
import { FiltersHeaderRow } from './components/filters-headers-row';
import { RowGroupsList } from './components/row-groups-list';
import { RowHeadersContent } from './components/row-headers-content';
import { RowHeadersResizeBar } from './components/row-headers-resize-bar';
import { RowsContent } from './components/rows-content';
import { RowsContentContainer } from './components/rows-content-container';
import { RowsContentInner } from './components/rows-content-inner';
import { RowsHeaders } from './components/rows-headers';
import { ScenariosRowGroupContent } from './components/scenarios-row-group-content';
import { ScenariosRowGroupHeader } from './components/scenarios-row-group-header';
import { TimelineContentHeaderContainer } from './components/timeline-content-header-container';
import { TimelineCssVariables } from './components/timeline-css-variables';
import { TimelineTableWrapper } from './components/timeline-table-wrapper';
import { usePlayHeadPositionInTimeline } from './hooks/use-play-head-position-in-timeline';
import { useSyncHeight } from './hooks/use-sync-height';
import { useSyncRowContent } from './hooks/use-sync-row-content';
import styles from './TimelineTable.module.scss';
import { useIsTimelineReadyToRender } from '../../../hooks/use-is-timeline-ready-to-render';
import { useTimelineHeadersWidth } from '../../../hooks/use-timeline-headers-width';
import { useTimelineTableData } from '../../../hooks/use-timeline-table-data';
import { useTimelineWheelZoom } from '../../../hooks/use-timeline-wheel-zoom';
import { EffectiveTimeSeparators } from '../effective-time-separators';
import { FilterHighlights } from '../filter-highlights';
import { PlayHead } from '../play-head';
import { TimeRuler } from '../time-ruler';
import { TimelineControls, TimelineControlsWrapper } from '../timeline-controls';

interface Props {
  recordingId: string;
  episodes: Episode[];
  tags: GroupedTags;
}

export const TimelineTable = ({ episodes, recordingId }: Props) => {
  const containerDiv = useRef<HTMLDivElement>(null);
  const episodesRowRef = useRef<HTMLDivElement>(null);
  const headerContentRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const playHeadContainerRef = useRef<HTMLDivElement>(null);
  const rulerContainerRef = useRef<HTMLDivElement>(null);
  const visibleContentRef = useRef<HTMLDivElement>(null);

  useTimelineWheelZoom(visibleContentRef);
  useSyncHeight({ masterDiv: visibleContentRef, syncDiv: playHeadContainerRef });
  useSyncRowContent({
    recordingId,
    visibleContentRef,
    headerRef,
  });

  const playHeadPositioningActions = usePlayHeadPositionInTimeline({
    recordingId,
    visibleContentRef,
    headerContentRef,
  });

  const isTimelineReadyToRender = useIsTimelineReadyToRender(recordingId);
  const initialHeadersWidth = useTimelineHeadersWidth();
  const timelineTableData = useTimelineTableData(recordingId);

  return (
    <div className={styles.timelineTableWrapper} style={{ opacity: isTimelineReadyToRender ? 1 : 0 }}>
      <TimelineTableWrapper ref={containerDiv} recordingId={recordingId}>
        <TimelineCssVariables recordingId={recordingId} />

        <RowsHeaders recordingId={recordingId} ref={headerRef} style={{ width: `${initialHeadersWidth}px` }}>
          <RowHeadersContent ref={headerContentRef}>
            <TimelineControlsWrapper
              scenarios={
                <ScenariosRowGroupHeader
                  key={'scenarios-row-group'}
                  recordingId={recordingId}
                  rowGroup={timelineTableData.scenariosRowGroup}
                />
              }
              recordingId={recordingId}
            >
              <TimelineControls recordingId={recordingId} />
              <FiltersHeaderRow recordingId={recordingId} />
            </TimelineControlsWrapper>
            <RowGroupsList recordingId={recordingId} rowGroups={timelineTableData.rowGroups} />
          </RowHeadersContent>
        </RowsHeaders>

        <RowHeadersResizeBar headerContentDiv={headerRef} contentDiv={visibleContentRef} />

        <RowsContent ref={visibleContentRef}>
          <RowsContentInner>
            <RowsContentContainer
              recordingId={recordingId}
              stickComponents={
                <>
                  <TimeRuler
                    ref={rulerContainerRef}
                    recordingId={recordingId}
                    episodesClips={timelineTableData.episodesRow.clips}
                  />

                  <EpisodesRow ref={episodesRowRef} recordingId={recordingId}>
                    <EpisodesClips recordingId={recordingId} clips={timelineTableData.episodesRow.clips} />
                  </EpisodesRow>

                  <TimelineContentHeaderContainer ref={playHeadContainerRef}>
                    <FilterHighlights recordingId={recordingId} />
                    <EffectiveTimeSeparators episodesClips={timelineTableData.episodesRow.clips} />
                    <PlayHead
                      playHeadPositioningActions={playHeadPositioningActions}
                      episodes={episodes}
                      recordingId={recordingId}
                      visibleContentRef={visibleContentRef}
                    />
                  </TimelineContentHeaderContainer>
                  <FiltersContentRow recordingId={recordingId} />
                </>
              }
              scenariosRow={
                <ScenariosRowGroupContent
                  key={'scenarios-content'}
                  recordingId={recordingId}
                  rowGroup={timelineTableData.scenariosRowGroup}
                />
              }
              contentRowGroups={timelineTableData.rowGroups}
            />
          </RowsContentInner>
        </RowsContent>
      </TimelineTableWrapper>
    </div>
  );
};
