import { ReactNode } from 'react';

import { TypeCounterContainer, TypeCounterContainerProps } from './styled';

interface Props extends TypeCounterContainerProps {
  children: ReactNode;
}

export const TypeCounter = ({ children, color, backgroundColor, disabled }: Props) => {
  return (
    <TypeCounterContainer color={color} backgroundColor={backgroundColor} disabled={disabled}>
      {children}
    </TypeCounterContainer>
  );
};
