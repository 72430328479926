import React from 'react';

import styles from './ButtonControlsLeft.module.scss';

interface Props {
  children: React.ReactNode;
}

const ButtonControlsLeft = ({ children }: Props) => {
  return <div className={styles.controlsLeft}>{children}</div>;
};

export default ButtonControlsLeft;
