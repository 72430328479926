import { useRecoilState } from 'recoil';

import { timelineAtoms } from '../../atoms';

export const useTimelineIsMultiselectModeActive = (recordingId: string) => {
  const [isMultiselectModeActive, setIsMultiselectModeActive] = useRecoilState(
    timelineAtoms.timelineMultiselectActive(recordingId),
  );

  return {
    isMultiselectModeActive,
    setIsMultiselectModeActive,
  };
};
