import { zodResolver } from '@hookform/resolvers/zod';
import { AccordionActions, AccordionDetails } from '@mui/material';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useLabelsTask } from '../../../../api/operations/use-labels-task';
import { labelsTaskFormSchema } from '../../utils/form-fields-validation';
import { LabelsForm, LabelsTaskFormSchema } from '../../utils/form-fields-validation/types';
import { AnnotationFormActions } from '../annotation-form-actions';
import { AnnotationToolField } from '../form-fields/annotation-tool-field';
import { IndexTypeGroup } from '../form-fields/index-type-group';
import { RecordingIdField } from '../form-fields/recording-id-field';
import { TaskTypeField } from '../form-fields/task-type-field';

export const LabelsTask = () => {
  const { labelsTask } = useLabelsTask();
  const {
    register,
    formState: { errors },
    reset,
    resetField,
    handleSubmit: handleFormSubmit,
  } = useForm<LabelsTaskFormSchema>({
    resolver: zodResolver(labelsTaskFormSchema),
  });

  const handleSubmit = useCallback(
    (data: LabelsForm) => {
      labelsTask({ data });
    },
    [labelsTask],
  );

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)}>
      <AccordionDetails>
        <RecordingIdField errors={errors} register={register} />
        <TaskTypeField errors={errors} register={register} />
        <AnnotationToolField errors={errors} register={register} />
        <IndexTypeGroup register={register} errors={errors} resetField={resetField} />
      </AccordionDetails>
      <AccordionActions>
        <AnnotationFormActions reset={() => reset()} />
      </AccordionActions>
    </form>
  );
};
