import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

import { MINIMUM_TIMELINE_TRACKS_HEIGHT } from '../../../atoms';

export const FILTERS_BAR_HEIGHT = '4.5rem';

export const TacticalAnalysisSkeletonContainer = styled(Box)(() => ({
  backgroundColor: Colors.background,
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
  overflow: 'hidden',
  width: '100%',
  position: 'relative',
  height: '100%',
}));

export const TacticalAnalysisSkeletonVideoContainer = styled(Box)(() => ({
  alignItems: 'flex-start',
  backgroundColor: Colors.shark,
  display: 'flex',
  flex: '1',
  justifyContent: 'center',
  overflow: 'hidden',
}));

export const TacticalAnalysisSkeletonVideoSkeleton = styled(Box)(() => ({
  paddingBottom: '56.25%',
  background: Colors.night,
}));

export const TacticalAnalysisSkeletonContent = styled(Box)(() => ({
  height: `${MINIMUM_TIMELINE_TRACKS_HEIGHT}px`,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
}));

export const TacticalAnalysisSkeletonContentFilters = styled(Box)(({ theme }) => ({
  background: Colors.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: FILTERS_BAR_HEIGHT,
  padding: theme.spacing(0, 1),
  zIndex: '6',
  boxShadow: '0 3px 3px -1px rgba(10, 22, 70, 0.1), 0 0 1px 0 rgba(10, 22, 70, 0.06)', // TODO: replace with shadows from theme
}));

export const TacticalAnalysisSkeletonContentTimeline = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
  overflow: 'hidden',
  justifyContent: 'center',
}));

export const TacticalAnalysisSkeletonFiltersLoading = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  flex: '1',
  height: '100%',
  justifyContent: 'center',
}));
