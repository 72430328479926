import { object, string } from 'zod';

export enum CreateClientFormFieldsNames {
  ID = 'id',
  NAME = 'name',
  CITY = 'city',
  COUNTRY = 'country',
}

const isValidId = (id: string): boolean => {
  const regex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
  return regex.test(id);
};

export const createClientFormSchema = object({
  [CreateClientFormFieldsNames.ID]: string()
    .trim()
    .min(3)
    .transform((val) => val.toLowerCase())
    .refine((val) => isValidId(val), {
      message: "Invalid ID format (e.g. 'my-client-id')",
    }),
  [CreateClientFormFieldsNames.NAME]: string().trim().min(3),
  [CreateClientFormFieldsNames.CITY]: string().trim().min(3),
  [CreateClientFormFieldsNames.COUNTRY]: string().min(2),
});
