import { Checkbox, CheckboxProps } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Colors } from 'kognia-ui';

import { IconColors } from '../icons/svg-icon';

const checkBoxStyles = ($activeColor: string, $baseColor = 'inherit') => {
  return {
    root: {
      color: $baseColor,
      padding: 3,
      '&.MuiCheckbox-indeterminate': {
        color: $activeColor,
      },
      '&$checked': {
        color: $activeColor,
        '& .MuiIconButton-label': {
          position: 'relative',
          zIndex: 0,
          backgroundColor: $activeColor,
        },
        '&.Mui-disabled': {
          '& .MuiIconButton-label': {
            backgroundColor: $baseColor,
          },
        },
      },
      '&.Mui-disabled': {
        color: Colors.ghost,
        '& label': {
          opacity: 0.5,
          color: Colors.ghost,
        },
      },
      '& .MuiIconButton-label': {
        borderRadius: 3,
        height: 18,
        width: 18,
      },
    },
    checked: {},
  };
};

const WhiteCheckbox = withStyles(checkBoxStyles(Colors.white, Colors.white))((props: CheckboxProps) => (
  <Checkbox disableRipple color='default' {...props} />
));

const NightCheckbox = withStyles(checkBoxStyles(Colors.night, Colors.storm))((props: CheckboxProps) => (
  <Checkbox color='default' disableRipple {...props} />
));

const RedCheckbox = withStyles(checkBoxStyles(Colors.red, Colors.storm))((props: CheckboxProps) => (
  <Checkbox disableRipple color='default' {...props} />
));

const PrimaryCheckbox = withStyles(checkBoxStyles(Colors.primary, Colors.storm))((props: CheckboxProps) => (
  <Checkbox color='default' disableRipple {...props} />
));

const BluePastelCheckbox = withStyles(checkBoxStyles(Colors.bluePastel, Colors.bluePastel))((props: CheckboxProps) => (
  <Checkbox color='default' disableRipple {...props} />
));

const CloudCheckbox = withStyles(checkBoxStyles(Colors.cloud, Colors.cloud))((props: CheckboxProps) => (
  <Checkbox color='default' disableRipple {...props} />
));

const FrescoCheckbox = withStyles(checkBoxStyles(Colors.fresco, Colors.fresco))((props: CheckboxProps) => (
  <Checkbox color='default' disableRipple {...props} />
));

const FountainCheckbox = withStyles(checkBoxStyles(Colors.fountain, Colors.fountain))((props: CheckboxProps) => (
  <Checkbox color='default' disableRipple {...props} />
));

export const CheckboxWithColor = (props: { customColor: IconColors } & CheckboxProps) => {
  const { customColor, ...checkboxProps } = props;

  switch (customColor) {
    case IconColors.primary:
      return <PrimaryCheckbox {...checkboxProps} />;
    case IconColors.white:
      return <WhiteCheckbox {...checkboxProps} />;
    case IconColors.night:
      return <NightCheckbox {...checkboxProps} />;
    case IconColors.red:
      return <RedCheckbox {...checkboxProps} />;
    case IconColors.bluePastel:
      return <BluePastelCheckbox {...checkboxProps} />;
    case IconColors.cloud:
      return <CloudCheckbox {...checkboxProps} />;
    case IconColors.fresco:
      return <FrescoCheckbox {...checkboxProps} />;
    case IconColors.fountain:
      return <FountainCheckbox {...checkboxProps} />;
    default:
      return <NightCheckbox {...checkboxProps} />;
  }
};
