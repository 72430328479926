import classNames from 'classnames';
import React, { CSSProperties, useMemo } from 'react';

import { useVideoPlayerPlayingMode } from 'shared/components/video-player';

import styles from './FilterHighlights.module.scss';
import { Clip } from '../../../api/use-tactical-analysis-data/generate-timeline-rows';
import { useTimelineTableData } from '../../../hooks/use-timeline-table-data';
import { CSS_CLIP_ZOOM_WIDTH } from '../timeline-table/components/timeline-css-variables';

type Props = {
  recordingId: string;
};

// TODO calculation of top (104px) should be dynamic
const ColumnHighlightContent = ({ clip, parentClip }: { clip: Clip; parentClip: Clip }) => {
  const contentStyle: CSSProperties = useMemo(
    () => ({
      top: '104px',
      height: 'calc(100% - 104px)',
      left: `calc(var(${CSS_CLIP_ZOOM_WIDTH}) * ${clip.startTime - parentClip.startTime})`,
      width: `calc(var(${CSS_CLIP_ZOOM_WIDTH}) * ${clip.endTime - clip.startTime})`,
    }),
    [clip, parentClip],
  );

  return <div className={styles.columnHighlightContent} style={contentStyle} />;
};

const ColumnHighlight = ({ clip }: { clip: Clip }) => {
  const clipStyle: CSSProperties = useMemo(
    () => ({
      width: `calc(var(${CSS_CLIP_ZOOM_WIDTH}) * ${clip.endTime - clip.startTime})`,
    }),
    [clip],
  );

  return (
    <div id={'filter-highlight-' + clip.id} key={clip.id} className={styles.columnHighlight} style={clipStyle}>
      {clip.clips?.map((filterClip) => (
        <ColumnHighlightContent clip={filterClip} parentClip={clip} key={filterClip.id} />
      ))}
    </div>
  );
};

const ColumnEmpty = ({ clip, isEffectiveTime }: { clip: Clip; isEffectiveTime: boolean }) => {
  const clipStyle: CSSProperties = useMemo(
    () => ({
      width: !isEffectiveTime ? `calc(var(${CSS_CLIP_ZOOM_WIDTH}) * ${clip.endTime - clip.startTime})` : 'auto',
      background: 'transparent',
    }),
    [clip, isEffectiveTime],
  );

  return (
    <div
      key={clip.id}
      style={clipStyle}
      className={classNames(styles.container, styles.columnEmpty, { [styles.isHalfTime]: clip.title })}
    />
  );
};

export const FilterHighlights = ({ recordingId }: Props) => {
  const { useEffectiveTime } = useVideoPlayerPlayingMode();
  const timelineData = useTimelineTableData(recordingId);

  if (!timelineData.filtersRow) return null;

  return (
    <div className={styles.separator}>
      {timelineData.filtersRow.clips.map((clip) =>
        clip.type === 'not-effective-time' ? (
          <ColumnEmpty key={clip.id} clip={clip} isEffectiveTime={useEffectiveTime} />
        ) : (
          <ColumnHighlight key={clip.id} clip={clip} />
        ),
      )}
    </div>
  );
};
