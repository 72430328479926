import React, { memo, useEffect, useState } from 'react';

interface Props {
  max: number;
  min: number;
  onChange: (value: number) => void;
  step: number;
  value?: number;
}

const RangeSlider = (props: Props) => {
  const [sliderValue, setSliderValue] = useState(props.value ?? props.min);

  const changeCallback = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLTextAreaElement;

    props.onChange(parseInt(target.value));

    setSliderValue(parseInt(target.value));
  };

  useEffect(() => {
    if (props.value !== undefined && props.value >= 0) {
      setSliderValue(props.value);
    }
  }, [props.value]);

  return (
    <input
      max={props.max}
      min={props.min}
      onChange={changeCallback}
      step={props.step}
      type='range'
      value={sliderValue}
      style={{ height: '16px' }}
    />
  );
};

export default memo(RangeSlider);
