import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { DialogNew } from 'shared/components/dialog-new';
import { IconUser } from 'shared/components/icons/icon-user';
import { useDates } from 'shared/hooks/use-dates';

import { PlayerFixture } from '../../../../types/players';

interface Props {
  onClose: () => void;
  playerDetails: PlayerFixture[];
}

function createData(name: string, value: string) {
  return { name, value };
}

const TableData = ({ playerFixture }: { playerFixture: PlayerFixture }) => {
  const { dateToString } = useDates();
  const rows = [
    createData('jersey_number', playerFixture.jerseyNumber.toString()),
    createData('date', dateToString(playerFixture.date)),
    createData('side', `${playerFixture.side ?? ''}`),
    createData('position', `${playerFixture.position ?? ''}`),
    createData('score (home - away)', `${playerFixture.homeTeamScore} - ${playerFixture.awayTeamScore}`),
    createData('team.name', playerFixture.team.name),
    createData('team.abbreviation', playerFixture.team.abbreviation),
    createData('team.country', playerFixture.team.country ?? ''),
  ];

  return (
    <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
      <Table sx={{ minWidth: 650 }} aria-label='player details table'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align='right'>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component='th' scope='row'>
                {row.name}
              </TableCell>
              <TableCell align='right'>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const PlayerDetailsModal = ({ onClose, playerDetails }: Props) => {
  return (
    <DialogNew
      fullWidth
      maxWidth='md'
      title={'Player details'}
      icon={<IconUser />}
      onCancel={onClose}
      onClose={onClose}
      open={true}
    >
      {playerDetails.length === 0 ? (
        <Typography variant='h6' sx={{ textAlign: 'center' }}>
          No data
        </Typography>
      ) : (
        playerDetails.map((fixture) => <TableData key={fixture.id} playerFixture={fixture} />)
      )}
    </DialogNew>
  );
};
