import { Popover, styled } from '@mui/material';
import { PopoverOrigin } from '@mui/material/Popover/Popover';
import React from 'react';

import FiltersSummary from 'pages/tactical-analysis/components/tactical-analysis/filters/components/filters-summary';
import EventsEndingEpisodeSummary from 'pages/tactical-analysis/components/tactical-analysis/filters/components/filters-summary/events-ending-episode-summary';
import EventsStartingEpisodeSummary from 'pages/tactical-analysis/components/tactical-analysis/filters/components/filters-summary/events-starting-episode-summary';
import { ScenariosInsideEpisodeSummary } from 'pages/tactical-analysis/components/tactical-analysis/filters/components/filters-summary/scenarios-inside-episode-summary';
import { MatchTeams } from 'shared/types';
import { RecordingsFilters } from 'shared/types/recording/types';

interface Props {
  filters: RecordingsFilters;
  showFiltersApplied: boolean;
  summaryAnchor: Element | null;
  teams: MatchTeams;
  recordingId: string;
  hasTeamFocus?: boolean;
}

const ANCHOR_ORIGIN: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

const TRANSFORM_ORIGIN: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const PopoverSummary = styled(Popover)(() => ({
  pointerEvents: 'none',

  '& > div': {
    background: 'transparent',
    boxShadow: 'none',
  },
}));

const FiltersBarSummary = ({ filters, teams, summaryAnchor, showFiltersApplied, recordingId, hasTeamFocus }: Props) => {
  return (
    <PopoverSummary
      anchorEl={summaryAnchor}
      hideBackdrop={true}
      open={Boolean(summaryAnchor) && showFiltersApplied}
      anchorOrigin={ANCHOR_ORIGIN}
      transformOrigin={TRANSFORM_ORIGIN}
    >
      <FiltersSummary>
        {filters.eventsStarting ? (
          <EventsStartingEpisodeSummary
            hasTeamFocus={hasTeamFocus}
            contained
            teams={teams}
            filters={filters.eventsStarting}
          />
        ) : null}
        {filters.scenariosOrTacticsInside ? (
          <ScenariosInsideEpisodeSummary
            contained
            filters={filters.scenariosOrTacticsInside}
            hasTeamFocus={hasTeamFocus}
            recordingId={recordingId}
            teams={teams}
          />
        ) : null}
        {filters.eventsEnding ? (
          <EventsEndingEpisodeSummary
            hasTeamFocus={hasTeamFocus}
            contained
            teams={teams}
            filters={filters.eventsEnding}
          />
        ) : null}
      </FiltersSummary>
    </PopoverSummary>
  );
};

export default FiltersBarSummary;
