import { ListItemText, Tooltip } from '@mui/material';
import { Colors } from 'kognia-ui';
import React, { useCallback, useRef } from 'react';

import { MenuItem } from '..';
import { MenuButton, MenuIcon } from '../styled';
import { getMenuItemSelected, ListItemTextStyles } from '../utils';

interface Props {
  item: MenuItem;
  isSelected?: boolean;
  isSidebarOpen: boolean;
  onClick: (ref: React.RefObject<HTMLDivElement>) => void;
}

export const SidebarMenuItem = ({ item, isSelected, onClick, isSidebarOpen }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const isMenuItemSelected =
    isSelected || ('link' in item ? getMenuItemSelected(location.pathname, [], item.link) : false);

  const handleOnClick = useCallback(() => {
    onClick(ref);
  }, [onClick]);

  return (
    <Tooltip placement={'right'} title={isSidebarOpen ? '' : item.label}>
      <MenuButton
        disabled={item.isDisabled}
        onClick={handleOnClick}
        ref={ref}
        selected={isMenuItemSelected}
        sx={{
          overflow: 'hidden',
          ...(item.bottomDivider && {
            borderBottom: `1px solid ${Colors.athens}`,
            '&:last-child': {
              borderBottom: 0,
            },
          }),
        }}
      >
        <MenuIcon>{item.icon}</MenuIcon>
        <ListItemText primary={item.label} sx={{ margin: 0 }} primaryTypographyProps={{ sx: ListItemTextStyles }} />
      </MenuButton>
    </Tooltip>
  );
};
