import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useCallback } from 'react';
import { UseFormResetField } from 'react-hook-form';

import { useTacticalAnalyses } from '../../../../../../../api/tactical-analysis/use-tactical-analyses';
import { TacticalAnalysisApiResponse } from '../../../../../../../api/types';
import { AnnotationFormFieldsNames } from '../../../utils/form-fields-validation/types';
import { ProcessEpisodeFormSchema } from '../../process-episode/types';
import { EpisodeIndexField } from '../episode-index-field';
import { GitCommitField } from '../git-commit-field';
import { TacticalAnalysisIdField } from '../tactical-analysis-id-field';
import { FormFieldProps } from '../types';

enum EpisodeIndexGroupValues {
  FULL_RECORDING = 'fullRecording',
  PARTIAL_RECORDING = 'partialRecording',
}

interface Props extends FormFieldProps {
  resetField: UseFormResetField<ProcessEpisodeFormSchema>;
  recordingId: string;
}

const isPartialAndRecording = (radioValue: EpisodeIndexGroupValues, recordingId: string): boolean =>
  radioValue === EpisodeIndexGroupValues.PARTIAL_RECORDING && recordingId.length > 2;

export const EpisodeIndexGroup = ({ errors, register, resetField, recordingId }: Props) => {
  const [radioValue, setRadioValue] = React.useState(EpisodeIndexGroupValues.FULL_RECORDING);

  const handleAnalysis = useCallback(
    (data: TacticalAnalysisApiResponse[]) => {
      const analysis = data[0];
      resetField(AnnotationFormFieldsNames.TACTICAL_ANALYSIS_ID, { defaultValue: analysis?.id ?? '' });
      resetField(AnnotationFormFieldsNames.GIT_COMMIT, { defaultValue: analysis?.tacticalAnalysisGitCommit ?? '' });
    },
    [resetField],
  );

  useTacticalAnalyses({
    recordingId: recordingId,
    onSuccess: handleAnalysis,
    enabled: isPartialAndRecording(radioValue, recordingId ?? ''),
  });

  const handleRadioChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setRadioValue(value as EpisodeIndexGroupValues);
      if (value === EpisodeIndexGroupValues.FULL_RECORDING) {
        resetField(AnnotationFormFieldsNames.EPISODE_INDEX);
        resetField(AnnotationFormFieldsNames.TACTICAL_ANALYSIS_ID);
        resetField(AnnotationFormFieldsNames.GIT_COMMIT);
      }
    },
    [resetField],
  );

  return (
    <>
      <FormControl sx={{ mb: 2 }}>
        <RadioGroup onChange={handleRadioChange} name='index-buttons-group' value={radioValue}>
          <FormControlLabel value={EpisodeIndexGroupValues.FULL_RECORDING} control={<Radio />} label='Full recording' />
          <FormControlLabel
            value={EpisodeIndexGroupValues.PARTIAL_RECORDING}
            control={<Radio />}
            label='Episode index'
          />
        </RadioGroup>
      </FormControl>

      {radioValue === EpisodeIndexGroupValues.PARTIAL_RECORDING ? (
        <>
          <EpisodeIndexField register={register} errors={errors} />
          <TacticalAnalysisIdField errors={errors} register={register} />
          <GitCommitField errors={errors} register={register} />
        </>
      ) : null}
    </>
  );
};
