import { Box } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';
import { PropsWithChildren } from 'react';

type Props = {
  label: string;
};

export const TextsWithFieldHoverLabel = ({ children, label }: PropsWithChildren<Props>) => {
  return (
    <Box sx={{ position: 'relative' }}>
      {children}
      <Box
        sx={{
          position: 'absolute',
          width: '30%',
          height: '40px ',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'right',
          top: 0,
          right: 0,
          paddingRight: 1,
          color: Colors.storm,
          textTransform: 'capitalize',
          fontSize: fontSizes.medium,
        }}
      >
        {label}
      </Box>
    </Box>
  );
};
