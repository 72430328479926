import { Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';

import { PlayerPhotoContainer, PlayerPhotoPreviewContainer } from './styled';
import { PlayerPhoto } from '../player-photo/player-photo';
import { PlayerPhotoSkeleton } from '../player-photo-skeleton';

interface Props {
  photoUrl?: string | null;
}

export const PlayerPhotoPreview = ({ photoUrl }: Props) => {
  return (
    <PlayerPhotoPreviewContainer>
      <PlayerPhotoContainer>
        {photoUrl ? <PlayerPhoto fullSize src={photoUrl} /> : <PlayerPhotoSkeleton />}
      </PlayerPhotoContainer>
      <Typography fontSize={fontSizes.small} marginTop={1}>
        Image preview
      </Typography>
    </PlayerPhotoPreviewContainer>
  );
};
