import { useCallback } from 'react';

import { Season } from '../../../../../types/seasons';

export const useOnChangeSeasonSelection = (
  onChange: (season: Season) => void,
  setAutocompleteValue: (season: Season) => void,
) => {
  return useCallback((results: Season[], selectedSeason?: Season) => {
    const updatedSelectedSeason = results.find((s) => s.id === selectedSeason?.id);
    if (updatedSelectedSeason) {
      onChange(updatedSelectedSeason);
      setAutocompleteValue(updatedSelectedSeason);
    }
  }, []);
};
