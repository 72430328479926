import { IconButton, Radio, Typography } from '@mui/material';
import { fontSizes, fontWeight } from 'kognia-ui';
import { ChangeEvent, useCallback } from 'react';

import { TaggingEvent } from 'api/tagging-tool/types';
import IconPlay from 'shared/components/icons/icon-play';
import { IconSizes } from 'shared/components/icons/svg-icon';
import { TypeCounter } from 'shared/components/type-counter';
import { getIconPlayColor } from 'shared/utils/get-icon-play-color';
import { TaggingEventClipTypeBackgroundColor, TaggingEventClipTypeColor } from 'shared/utils/tagging-event-clip-type';

import { AlignRecordingClipCard, AlignRecordingClipContainer } from './styled';
import { secondsToTimeString } from '../../../../tagging-tool/utility/date';

export type RecordingAlignmentClipProps = {
  event: TaggingEvent;
  aligning: boolean;
  alignmentDiff: number;
  videoDuration: number;
  idx: number;
  checked: boolean;
  onCheckedChange: (value: boolean) => void;
  onPlay: (diffTime: number) => void;
};

export const RecordingAlignmentClip = ({
  event,
  aligning,
  alignmentDiff,
  videoDuration,
  idx,
  checked,
  onCheckedChange,
  onPlay,
}: RecordingAlignmentClipProps) => {
  const { name, time, typeOfPlay, timeAfter, timeBefore } = event;

  const startTime = (time - timeBefore > 0 ? time - timeBefore : 0) + alignmentDiff;
  const disabled = startTime < 0 || startTime > videoDuration;
  const endTime = time + timeAfter + alignmentDiff;

  const handleRadioChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onCheckedChange(event.target.checked);
    },
    [onCheckedChange],
  );

  return (
    <AlignRecordingClipContainer>
      {aligning && <Radio checked={checked} onChange={handleRadioChange} />}
      <AlignRecordingClipCard>
        <TypeCounter
          color={TaggingEventClipTypeColor[typeOfPlay]}
          backgroundColor={TaggingEventClipTypeBackgroundColor[typeOfPlay]}
          disabled={disabled}
        >
          {`${idx + 1}`.padStart(3, '0')}
        </TypeCounter>

        <IconButton size={'small'} disabled={disabled} onClick={() => onPlay(startTime)}>
          <IconPlay size={IconSizes.small} color={getIconPlayColor({ disabled, typeOfPlay })} />
        </IconButton>
        {!disabled && (
          <Typography fontWeight={fontWeight['500']} fontSize={fontSizes.default}>
            {secondsToTimeString(startTime)} - {secondsToTimeString(endTime)}
          </Typography>
        )}
        <Typography fontSize={fontSizes.default}>{name}</Typography>
      </AlignRecordingClipCard>
    </AlignRecordingClipContainer>
  );
};
