import { Box, Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { routes } from 'kognia/router/routes';

import IconMatchAnalysis from './icons/icon-match-analysis';
import { IconPlaylists } from './icons/icon-playlists';
import IconTaggingTool from './icons/icon-tagging-tool';
import SolutionCard, { SolutionsVariants } from './solution-card';
import styles from './Solutions.module.scss';

const SolutionCardWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
}));

const Solutions = () => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent='center'>
      <Grid item justifyContent='center' display='flex' flexWrap='wrap'>
        <SolutionCardWrapper>
          <SolutionCard variant={SolutionsVariants.RECORDINGS} to={generatePath(routes.RECORDING_LIST)}>
            <h3>{t('home:solutions.tactical-analysis.title')}</h3>
            <IconMatchAnalysis />
            <div className={styles.cta}>{t('home:solutions.tactical-analysis.cta')}</div>
          </SolutionCard>
        </SolutionCardWrapper>
      </Grid>

      <Grid item justifyContent='center' display='flex' flexWrap='wrap'>
        <SolutionCardWrapper>
          <SolutionCard variant={SolutionsVariants.TAGGING_TOOL} to={routes.TAGGING_TOOL}>
            <h3>{t('home:solutions.tagging.title')}</h3>
            <IconTaggingTool />
            <div className={styles.cta}>{t('home:solutions.tagging.cta.start-tagging')}</div>
          </SolutionCard>
        </SolutionCardWrapper>

        <SolutionCardWrapper>
          <SolutionCard variant={SolutionsVariants.PLAYLISTS} to={routes.PLAYLISTS}>
            <h3>{t('home:solutions.playlists.title')}</h3>
            <IconPlaylists />
            <div className={styles.cta}>{t('home:solutions.playlists.cta')}</div>
          </SolutionCard>
        </SolutionCardWrapper>
      </Grid>
    </Grid>
  );
};

export default Solutions;
