import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useBackendApi } from 'api/hooks/useBackendApi';
import { liveTaggingSessionUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { NotificationType, useNotifications } from 'shared/hooks/notifications';

import { LiveTaggingSession } from '../types';
import { useFetchLiveTaggingSession } from '../use-fetch-live-session';

type LiveTaggingSessionUpdate = {
  name: string;
};

export const useUpdateLiveTaggingSession = (
  liveTaggingSessionId: string,
  onSuccess?: () => void,
  onError?: () => void,
  onSettled?: () => void,
) => {
  const { t } = useTranslation();
  const { setQueryData } = useFetchLiveTaggingSession(liveTaggingSessionId);

  const onPatchSuccess = (data: LiveTaggingSession) => data;
  const triggerNotification = useNotifications();

  const updateLiveTaggingSession = useMutation(
    (params) => useBackendApi(liveTaggingSessionUrl(liveTaggingSessionId), HTTPMethod.PATCH, onPatchSuccess, params),
    {
      onMutate: async (params: LiveTaggingSessionUpdate) => {},
      onError: () => {
        if (onError) onError();
        triggerNotification({ type: NotificationType.ERROR, message: t('api:use-update-playlist.error') });
      },
      onSuccess: (updatedLiveTaggingSession) => {
        setQueryData && setQueryData(updatedLiveTaggingSession);
        if (onSuccess) onSuccess();
      },
      onSettled: () => {
        if (onSettled) onSettled();
      },
    },
  );

  const sendLiveTaggingSessionUpdate = (name: string) => {
    const updateParams = { name: name } as LiveTaggingSessionUpdate;
    updateLiveTaggingSession.mutate(updateParams);
  };

  return { sendLiveTaggingSessionUpdate, isLoading: updateLiveTaggingSession.isLoading };
};
