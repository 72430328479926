import React from 'react';

import Filters from 'pages/tactical-analysis/components/tactical-analysis/filters';
import { Episode, MatchTeams } from 'shared/types';
import { VideoSource } from 'shared/types/recording/types';

import AddTagButton from './add-tag-button';
import { AddToPlaylistButton } from './add-to-playlist-button';
import ExportButton from './export-button';
import styles from './FiltersBar.module.scss';
import PlaylistsButton from './playlists-button';
import { useTimelineIsMultiselectModeActive } from '../../../hooks/use-timeline-is-multiselect-mode-active';
import { useTimelineTableData } from '../../../hooks/use-timeline-table-data';
import ZoomRange from '../zoom-range';

interface Props {
  episodes: Episode[];
  isLoading?: boolean;
  recordingId: string;
  teams: MatchTeams;
  videoSources: VideoSource[];
}

export const FiltersBar = ({ episodes, isLoading = false, recordingId, teams, videoSources }: Props): JSX.Element => {
  const timelineTableData = useTimelineTableData(recordingId);
  const { isMultiselectModeActive, setIsMultiselectModeActive } = useTimelineIsMultiselectModeActive(recordingId);

  if (timelineTableData.rowGroups.length === 0) return <></>;

  return (
    <>
      <div className={styles.filtersBar}>
        <div className={styles.filters}>
          {episodes.length > 0 && !isLoading && (
            <>
              <Filters episodeCount={episodes.length} episodes={episodes} recordingId={recordingId} teams={teams} />
            </>
          )}
        </div>
        <div className={styles.externalButtons}>
          <AddToPlaylistButton
            isMultiselectModeActive={isMultiselectModeActive}
            onClick={() => setIsMultiselectModeActive(!isMultiselectModeActive)}
          />
          <ExportButton
            recordingId={recordingId}
            tacticalAnalysisId={episodes.length > 0 ? episodes[0].tacticalAnalysisId : undefined}
            videoSources={videoSources}
          />
          <PlaylistsButton matchId={recordingId} />
          <AddTagButton matchId={recordingId} />
          <ZoomRange />
        </div>
      </div>
    </>
  );
};
