import { Box, styled } from '@mui/material';
import { Colors, fontSizes, fontWeight } from 'kognia-ui';

export const TitleBar = styled(Box)(({ theme }) => ({
  backgroundColor: Colors.storm,
  display: 'flex',
  alignItems: 'center',
}));

export const Title = styled(Box)(({ theme }) => ({
  fontSize: fontSizes.small,
  fontWeight: fontWeight['500'],
  color: Colors.white,
  padding: theme.spacing(0.5, 1, 0.5, 2),
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

export const PlaylistItemsList = styled(Box)(({ theme }) => ({
  color: Colors.white,
  display: 'flex',
  gap: theme.spacing(1),
  flexDirection: 'column',
  padding: theme.spacing(2, 1, 2, 3),
}));

export const PlaylistItemsRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const PlaylistItem = styled(Box)(({ theme }) => ({
  fontSize: fontSizes.small,
  fontWeight: fontWeight['400'],
}));

export const NoClipText = styled(Box)(({ theme }) => ({
  fontSize: fontSizes.xxSmall,
  fontWeight: fontWeight['500'],
}));
