import { DialogActions, DialogContent, DialogContentText, Popover, PopoverOrigin } from '@mui/material';
import React from 'react';

import Button, { ButtonTypes } from 'shared/components/button';

import styles from './ConfirmPopoverDialog.module.scss';
import IconClose from '../icons/icon-close';
import { IconColors, IconSizes } from '../icons/svg-icon';

export type ConfirmDialogTypes = 'warning' | 'default' | 'primary';

interface Props {
  cancelLabel: string;
  confirmLabel: string;
  description: string;
  onCancel?: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  type?: ConfirmDialogTypes;
  anchorEl: HTMLDivElement | HTMLElement | null;
  setIsOpen: (isOpen: boolean) => void;
  anchorOrigin?: PopoverOrigin | undefined;
  transformOrigin?: PopoverOrigin | undefined;
}

const getButtonTypeFromConfirmDialogType = (type: ConfirmDialogTypes) => {
  const buttonTypesMapping = {
    warning: ButtonTypes.PrimaryDanger,
    default: ButtonTypes.Tertiary,
    primary: ButtonTypes.Primary,
  };

  return buttonTypesMapping[type];
};

const ConfirmPopoverDialog = ({
  anchorEl,
  isOpen,
  cancelLabel,
  confirmLabel,
  description,
  onCancel,
  onConfirm,
  setIsOpen,
  type = 'default',
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin = {
    vertical: 'center',
    horizontal: 'left',
  },
}: Props) => {
  const handleCancel = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (onCancel) onCancel();
    setIsOpen(false);
  };

  const handleConfirm = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onConfirm();
    setIsOpen(false);
  };

  return (
    <Popover
      className={styles.popOver}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleCancel}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <div className={styles.dialog} onClick={(event) => event.stopPropagation()}>
        <div className={styles.close} onClick={handleCancel}>
          <IconClose size={IconSizes.small} color={IconColors.iron} />
        </div>
        <DialogContent>
          <DialogContentText className={styles.description} id='confirm-dialog-description'>
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button onClick={handleCancel} type={ButtonTypes.Tertiary}>
            {cancelLabel}
          </Button>
          <Button onClick={handleConfirm} autoFocus type={getButtonTypeFromConfirmDialogType(type)}>
            {confirmLabel}
          </Button>
        </DialogActions>
      </div>
    </Popover>
  );
};

export default ConfirmPopoverDialog;
