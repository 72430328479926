import { useTranslation } from 'react-i18next';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { LogoutUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';

export const logoutRedirect = (response: { logoutUrl: string }) => {
  if (response.logoutUrl) window.location.href = response.logoutUrl;
};

export const useLogOutAccount = () => {
  const { t } = useTranslation();
  const { mutate, isLoading, isError, isSuccess } = useMutationRequest({
    type: HTTPMethod.POST,
    errorMessage: t('api:use-logout-account.error'),
    onSuccess: (response: any) => {
      logoutRedirect(response);
    },
  });

  const logOutUser = () => {
    mutate({ url: LogoutUrl });
  };

  return { logOutUser, isLoading, isError, isSuccess };
};
