import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';

import { annotationApiUrls } from '../../routes';
import { useAllowedBackofficeOrigin } from '../../use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from '../../utils';
import { useInvalidateVenues } from '../use-venues';

interface DeleteVenueApiParams {
  id: string;
  onSuccess?: () => void;
}

export const useDeleteVenue = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateVenues = useInvalidateVenues();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.DELETE,
    successMessage: 'Venue deleted',
    errorMessage: (data) => metadataApiErrorParser(data, 'Venue delete error'),
    onSuccess: invalidateVenues,
  });

  const deleteVenue = ({ id, onSuccess }: DeleteVenueApiParams) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: `${annotationApiUrls.VENUES}/${id}`,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, deleteVenue };
};
