import { List } from '@mui/material';

import { Coach } from '../../../../types/coaches';
import { CoachItem } from '../coach-item';

interface Props {
  coaches: Coach[];
}

export const CoachesList = ({ coaches }: Props) => {
  const coachesItems = coaches.map((coache) => {
    return <CoachItem key={coache.id} coach={coache} />;
  });

  return <List>{coachesItems}</List>;
};
