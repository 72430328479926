import { UseQueryResult } from '@tanstack/react-query';

import { useFetchRequest } from 'api/hooks/useFetchRequest';

import { Season } from '../../../types/seasons';
import { annotationApiUrls } from '../../routes';
import { transformSeason } from '../transformers';

type Params = {
  seasonId?: string;
  onSuccess?: (season: Season) => void;
};

export const useSeason = ({ seasonId, onSuccess }: Params): UseQueryResult<Season> => {
  return useFetchRequest<Season>({
    queryRef: ['metadata-season', seasonId ?? ''],
    url: `${annotationApiUrls.SEASONS}/${seasonId ?? ''}`,
    transformer: transformSeason,
    options: {
      enabled: seasonId !== undefined,
    },
    onSuccess,
  });
};
