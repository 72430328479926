import { Grid, GridSize } from '@mui/material';
import React from 'react';

import EmptyCell from './empty-cell';

interface Props {
  cells: number;
  size?: GridSize;
  isLoading?: boolean;
}

const EmptyCellGrid = ({ isLoading = false, cells, size = 3 }: Props) => {
  return (
    <Grid container spacing={4}>
      {Array(cells)
        .fill('')
        .map((_, idx) => (
          <Grid key={idx} item xs={size}>
            <EmptyCell isLoading={isLoading} />
          </Grid>
        ))}
    </Grid>
  );
};

export default EmptyCellGrid;
