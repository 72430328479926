import { Base64 } from 'js-base64';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

export const makeRecordingDetailRoute = (params: { recordingId: string; showsKeypad?: boolean; isLive?: boolean }) =>
  `/tagging-tool/${encodeURIComponent(params.recordingId)}${params.isLive === true ? '?isLive=true' : '?isLive=false'}${
    params.showsKeypad === true ? '&showsKeypad=1' : ''
  }`;

export const makeRecordingAlignmentSourcesRoute = (params: { recordingId: string; isLive?: boolean }) =>
  `/tagging-tool/${encodeURIComponent(params.recordingId)}/align/sources${
    params.isLive === true ? '?isLive=true' : '?isLive=false'
  }`;

export const makeRecordingAlignmentRoute = (params: {
  recordingId: string;
  sourceRecordingId?: string;
  isLive?: boolean;
}) =>
  `/tagging-tool/${encodeURIComponent(params.recordingId)}/align${
    params.sourceRecordingId ? `/${params.sourceRecordingId}` : ''
  }${params.isLive === true ? '?isLive=true' : '?isLive=false'}`;

export const makeTimelineRoute = (params: { recordingId: string }) =>
  `/performance-report/${encodeURIComponent(params.recordingId)}/timeline`;

export const makePlaylistIndexRoute = (params: { recordingId: string }) =>
  `/recordings/${params.recordingId}/playlists`;

const QUERY_STRING_KEY = 'state';

export const useJSONQueryStringState = <T extends unknown>(): [T | undefined, (next: T) => void] => {
  const history = useHistory();
  const { search } = useLocation();
  const { path } = useRouteMatch();
  const pathRef = useRef<string>(path);

  const decodeSearch = () => {
    const params = new URLSearchParams(search);
    const state = params.get(QUERY_STRING_KEY);
    if (state !== null) {
      try {
        const obj: T = JSON.parse(Base64.decode(state));
        return obj;
      } catch {}
    }
    return undefined;
  };

  const [state, setState] = useState<T | undefined>(decodeSearch);
  const firstRef = useRef<boolean>(true);

  useEffect(() => {
    if (firstRef.current) {
      firstRef.current = false;
      return;
    }

    setState(decodeSearch());
  }, [search]);

  useEffect(() => {
    pathRef.current = path;
  }, [path]);

  const nextState = useCallback((nextValue: T) => {
    try {
      const state_jqs = Base64.encodeURI(JSON.stringify(nextValue));
      history.replace(`${pathRef.current}?${QUERY_STRING_KEY}=${state_jqs}`);
    } catch {}
  }, []);

  return [state, nextState];
};
