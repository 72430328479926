import { Stack } from '@mui/material';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';

import { MetadataGame } from '../../../types/games';
import { addUpdatePlayersToGamesUrl } from '../../routes';
import { useAllowedBackofficeOrigin } from '../../use-allowed-backoffice-origin';
import { transformMetadataGame } from '../transformers';
import { GameApi } from '../types';

type PlayerParam = {
  jersey_number: number;
  side: number | undefined;
  position: number | undefined;
  player_uuid: string;
  team_uuid: string;
};

interface RequestData {
  players: PlayerParam[];
  gameId: string;
  onSuccess?: (data: MetadataGame) => void;
  onError?: () => void;
}

type MetadataAddPlayerToGamesApiError = {
  loc: [string, string, number, string];
  msg: string;
  type: string;
};

export type MetadataAddPlayerToGamesApiErrorResponse = {
  detail: MetadataAddPlayerToGamesApiError[] | string;
};

export const addPlayersToGameErrorParser = (
  data: MetadataAddPlayerToGamesApiErrorResponse | undefined,
  fallbackErrorMessage: string,
) => {
  if (!data) return fallbackErrorMessage;

  if (typeof data.detail === 'string') {
    return data.detail;
  }

  return data ? (
    <Stack>
      {data.detail.map((error, idx) => (
        <div key={idx}>{`${error.msg}: Player ${error.loc[2]} ${error.loc[3]}`}</div>
      ))}
    </Stack>
  ) : (
    fallbackErrorMessage
  );
};

type Options = {
  successMessage?: string;
};

export const useAddPlayersToGame = ({ successMessage }: Options = { successMessage: 'Players added to game' }) => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<GameApi, MetadataGame, MetadataAddPlayerToGamesApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: successMessage,
    errorMessage: (data) => addPlayersToGameErrorParser(data, 'Adding players to game error'),
    transformer: transformMetadataGame,
  });

  const create = ({ gameId, players, onSuccess, onError }: RequestData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: addUpdatePlayersToGamesUrl(gameId),
        data: { players },
      },
      {
        onSuccess,
        onError,
      },
    );
  };

  return { ...mutationRequest, create };
};
