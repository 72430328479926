import { useTranslation } from 'react-i18next';

import { ButtonSizes, ButtonTypes } from 'shared/components/button';
import IconFolder from 'shared/components/icons/icon-folder';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';

import { PlaylistButton, PlaylistButtonText } from './styled';

interface Props {
  isMultiselectModeActive: boolean;
  onClick: () => void;
}

export const AddToPlaylistButton = ({ isMultiselectModeActive, onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <PlaylistButton
      active={isMultiselectModeActive}
      id='add-to-playlist-button'
      onClick={onClick}
      type={ButtonTypes.Clear}
      btnSize={ButtonSizes.S}
    >
      <PlaylistButtonText>
        <IconFolder size={IconSizes.small} color={isMultiselectModeActive ? IconColors.primary : IconColors.night} />
        {t('timeline:actions.add-to-playlist')}
      </PlaylistButtonText>
    </PlaylistButton>
  );
};
