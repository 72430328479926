import isEmpty from 'lodash/isEmpty';
import React, { useMemo } from 'react';

import { useRecordings } from 'api/recording/useFetchRecordings';
import { RecordingTypes } from 'shared/types/recording/types';

import { RecordingListPageSkeleton } from './components/recording-list-page-skeleton';
import { RecordingsListPage } from './components/recordings-list-page';
import { RecordingFilters } from './types';

export interface TabType {
  label: string;
  value: string[];
  type: RecordingFilters;
}

const TRAINING_TAB_TYPE: TabType = {
  label: 'Trainings',
  value: [RecordingTypes.TRAINING],
  type: RecordingFilters.TYPE,
};

const COMPETITION_PRIORITY: { [key in string]: number } = {
  'LaLiga 2023-24': 0,
  'Uefa Champions League 2023-24': 1,
  'Uefa Youth League 23-24': 2,
  'LaLiga 2022-23': 3,
  'LaLiga 2021-22': 4,
  'LaLiga 2 2022-23': 5,
  'LaLiga 2 2021-22': 6,
  'Serie A 2023-24': 7,
  'Allsvenskan 2023': 8,
};

const sortTabsTypesByPriority = (a: TabType, b: TabType) => {
  const first = a.label in COMPETITION_PRIORITY ? COMPETITION_PRIORITY[a.label] : Number.MAX_SAFE_INTEGER;
  const second = b.label in COMPETITION_PRIORITY ? COMPETITION_PRIORITY[b.label] : Number.MAX_SAFE_INTEGER;

  let result = 0;
  if (first < second) result = -1;
  else if (first > second) result = 1;
  return result;
};

const RecordingsListPageContainer = (): JSX.Element | null => {
  const { filters, isSuccess, isLoading } = useRecordings({ extraKey: 'only-filters' });

  const competitionsTabs: TabType[] = useMemo(
    () =>
      (filters?.competition?.options &&
        Object.keys(filters?.competition?.options)
          .filter((item) => !isEmpty(item))
          .map((item) => ({
            label: item,
            value: [item],
            type: RecordingFilters.COMPETITION,
          }))
          .sort(sortTabsTypesByPriority)) ||
      ([] as TabType[]),
    [filters],
  );

  const recordingTypes = useMemo(() => [...competitionsTabs, TRAINING_TAB_TYPE], [competitionsTabs]);

  if (isLoading) {
    return <RecordingListPageSkeleton />;
  }

  if (isSuccess) {
    return (
      <RecordingsListPage
        competitionsTabs={competitionsTabs}
        recordingTypes={recordingTypes}
        defaultFilters={filters}
      />
    );
  }

  return null;
};

export default React.memo(RecordingsListPageContainer);
