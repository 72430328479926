import { Box, Grid, Stack, styled } from '@mui/material';
import { boxShadows, Colors, fontSizes, fontWeight } from 'kognia-ui';

import {
  playlistDetailPageHeaderHeight,
  playlistDetailPageMainHeight,
  playlistDetailPageTimelineHeight,
} from '../../styled';

export const playlistTimelineHeaderHeight = 56;

export const PlaylistDetailPageContainer = styled(Grid)(() => ({
  height: '100%',
  overflow: 'hidden',
}));

export const PlaylistDetailPageHeaderContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 4),
  height: playlistDetailPageHeaderHeight,
}));

export const PlaylistDetailPageTimelineContainer = styled(Grid)(() => ({
  height: playlistDetailPageTimelineHeight,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
}));

export const PlaylistDetailPageMainContainer = styled(Grid)(() => ({
  height: playlistDetailPageMainHeight,
}));

export const PlaylistDetailPageTimelineHeader = styled(Stack)(() => ({
  boxShadow: boxShadows[2],
  height: playlistTimelineHeaderHeight,
  background: Colors.white,
}));

export const BadgeText = styled(Box)(({ theme }) => ({
  background: Colors.background,
  display: 'flex',
  fontSize: fontSizes.xxSmall,
  fontWeight: fontWeight['500'],
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  borderRadius: '2px',
  height: '24px',
  lineHeight: fontSizes.xxSmall,
}));
