import { CategoryScale, Chart, Filler, LinearScale, LineElement, PointElement } from 'chart.js';
import { Colors } from 'kognia-ui';
import React, { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';

import { useRenderFrameRate } from '../../../../../../hooks/use-overlays-controller';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Filler);

const timeRange = 60; // Time range in seconds
export const FrameRateChart = () => {
  const frameRate = useRenderFrameRate();
  const [data, setData] = useState<number[]>(Array(timeRange).fill(0));

  useEffect(() => {
    setData((prevData) => {
      const newData = [...prevData, frameRate];
      if (newData.length > timeRange) {
        newData.shift(); // Remove the oldest data point if there are more than 30 data points
      }
      return newData;
    });
  }, [frameRate]);

  const chartData = useMemo(
    () => ({
      labels: data.map((_, index) => index),
      datasets: [
        {
          fill: true,
          label: 'Frame Rate',
          data,
          borderColor: Colors.melrose,
          backgroundColor: Colors.lavender,
          tension: 0.1,
        },
      ],
    }),
    [data],
  );

  const chartOptions = useMemo(
    () => ({
      responsive: true,
      scales: {
        y: {
          min: 0,
          max: 60,
          ticks: {
            stepSize: 10, // Optional: control the step size of the ticks
          },
        },
        x: {
          min: 0,
          max: timeRange,
          ticks: {
            stepSize: 20, // Optional: control the step size of the ticks
          },
        },
      },
    }),
    [],
  );

  return (
    <div style={{ height: '200px', width: '100%', display: 'flex' }}>
      <Line height={'100%'} width={'250px'} data={chartData} options={chartOptions} />
    </div>
  );
};
