import styles from '../Icons.module.scss';
import { SolutionIconProps } from '../types';

export const IconPlaylists = ({ disabled }: SolutionIconProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='85' height='132' fill='none' className={styles.icon}>
      <g className={disabled ? styles.disabledStroke : styles.darkStroke} strokeDasharray='1 1'>
        <rect x='.5' y='81.5' width='84' height='14' rx='2.5' />
        <rect x='.5' y='99.5' width='84' height='14' rx='2.5' />
        <rect x='.5' y='117.5' width='84' height='14' rx='2.5' />
      </g>
      <g className={disabled ? styles.disabledFill : styles.darkFill}>
        <path d='M7.157 91.388c.117.074.251.112.384.112a.72.72 0 0 0 .326-.079l4.378-2.25c.247-.127.403-.387.403-.671s-.156-.544-.403-.671l-4.378-2.25a.71.71 0 0 0-.71.033c-.215.137-.346.378-.346.638v4.5c0 .26.131.501.346.638h0zm1.114-3.924l2.017 1.036-2.017 1.036v-2.073z' />
        <use xlinkHref='#B' />
        <use xlinkHref='#B' y='18' />
        <path d='M36.604 51.421L46.044 45l-9.439-6.42v12.841zm10.889-8.554a2.58 2.58 0 0 1 .683 3.584c-.183.269-.415.501-.683.684l-9.439 6.42c-1.177.801-2.78.495-3.581-.683a2.58 2.58 0 0 1-.446-1.45V38.58A2.58 2.58 0 0 1 36.605 36c.517 0 1.021.155 1.449.446l9.439 6.42z' />
      </g>
      <g className={disabled ? styles.disabledStroke : styles.darkStroke}>
        <rect x='4.5' y='20.5' width='76' height='49' rx='3.5' strokeDasharray='1 1' />
        <rect x='1' y='17' width='83' height='56' rx='3' strokeWidth='2' />
      </g>
      <g className={disabled ? styles.disabledFill : styles.darkFill}>
        <rect y='7' width='85' height='2' rx='1' />
        <rect x='10' width='66' height='2' rx='1' />
      </g>
      <defs>
        <path
          id='B'
          d='M7.157 109.388a.72.72 0 0 0 .384.112c.111 0 .223-.026.326-.079l4.378-2.25c.247-.127.403-.387.403-.671s-.156-.544-.403-.671l-4.378-2.25c-.226-.116-.495-.104-.71.033s-.346.378-.346.638v4.5c0 .26.131.501.346.638h0zm1.114-3.924l2.017 1.036-2.017 1.036v-2.072z'
        />
      </defs>
    </svg>
  );
};
