import { zodResolver } from '@hookform/resolvers/zod';
import { Button, DialogActions, DialogContent, FormControl, Stack, Typography } from '@mui/material';
import { Colors } from 'kognia-ui';
import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { DialogButtonContainer } from 'shared/components/dialog/dialog-button-container';
import { DialogHeader } from 'shared/components/dialog/dialog-header';
import { FormInputField } from 'shared/components/form/form-input-field';
import IconKeypad from 'shared/components/icons/icon-keypad';
import { IconSizes } from 'shared/components/icons/svg-icon';

import { useInvalidateMetadataGame } from '../../../../../../api/games/use-metadata-game';
import { useInvalidateMetadataGames } from '../../../../../../api/games/use-metadata-games';
import { useCreateMetadataRecording } from '../../../../../../api/recordings/use-create-metadata-recording';
import { SelectClients } from '../../../../../../components/select-clients';
import { MetadataGame } from '../../../../../../types/games';
import { GameRecordingsList } from '../../../game-recordings-list';
import { NewRecordingForm, NewRecordingFormFieldsNames, newRecordingSchema } from '../index';
import { generateRecordingId } from '../utils';

type Props = {
  game: MetadataGame;
  onSuccess: () => void;
  onCancel: (event: React.MouseEvent<HTMLElement>) => void;
};

const DEFAULT_RECORDING_TYPE = 'game';

export const AddRecordingIdDialogForm = ({ game, onSuccess, onCancel }: Props) => {
  const { create } = useCreateMetadataRecording();
  const invalidateMetadataGames = useInvalidateMetadataGames();
  const invalidateMetadataGame = useInvalidateMetadataGame();
  const {
    register,
    formState: { isValid },
    handleSubmit: handleFormSubmit,
    control,
  } = useForm<NewRecordingForm>({
    resolver: zodResolver(newRecordingSchema),
    defaultValues: {},
  });

  const handleSubmit = useCallback(
    (data: NewRecordingForm) => {
      create({
        data: {
          recording_type: DEFAULT_RECORDING_TYPE,
          client_ids: data.clientIds,
          uuid: data.id,
          fixture_uuid: game.id,
        },
        onSuccess: () => {
          invalidateMetadataGame(game.id);
          invalidateMetadataGames();
          onSuccess();
        },
      });
    },
    [onSuccess, invalidateMetadataGame, game, create, invalidateMetadataGames],
  );

  const hasRecordingIds = game && game?.recordings?.length > 0;
  const defaultRecordingId = game
    ? generateRecordingId(game.date ?? '', game.homeTeam.abbreviation, game.awayTeam.abbreviation)
    : '';

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)}>
      <DialogHeader icon={<IconKeypad size={IconSizes.small} />}>Add Recording Id</DialogHeader>
      <DialogContent>
        <Stack gap={2}>
          <FormInputField
            label='Recording ID'
            id={NewRecordingFormFieldsNames.id}
            defaultChecked
            {...register(NewRecordingFormFieldsNames.id)}
            fullWidth
            placeholder={`yyyy-mm-dd-${game.homeTeam.abbreviation}-${game.awayTeam.abbreviation}`}
            defaultValue={hasRecordingIds ? '' : defaultRecordingId}
            size='small'
          />

          <FormControl fullWidth>
            <Controller
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <SelectClients clientIds={value ?? []} onChange={onChange} />
                  {error && <Typography color={Colors.red}>{error.message}</Typography>}
                </>
              )}
              name={NewRecordingFormFieldsNames.clientIds}
              control={control}
            />
          </FormControl>
          {game && game.recordings.length > 0 ? (
            <GameRecordingsList gameId={game.id} recordings={game.recordings} />
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <DialogButtonContainer>
          <Button variant='outlined' color='secondary' onClick={onCancel}>
            <span>Cancel</span>
          </Button>
        </DialogButtonContainer>
        <DialogButtonContainer>
          <Button type={'submit'} variant='contained' color='primary' disabled={!isValid}>
            <span>Add</span>
          </Button>
        </DialogButtonContainer>
      </DialogActions>
    </form>
  );
};
