import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';

import { Team } from '../../../types';
import timelineStyles from '../../TimelineTable.module.scss';
import styles from '../Rows.module.scss';

type Props = {
  isHighlighted?: boolean;
  children: ReactNode;
  team: Team;
  borderTop?: boolean;
  isSelected?: boolean;
};

export const RowHeaderContainer = ({
  children,
  isHighlighted,
  borderTop = false,
  isSelected = false,
  team,
  ...rest
}: PropsWithChildren<React.HTMLAttributes<HTMLDivElement> & Props>) => (
  <div
    className={classNames(timelineStyles.row, styles.rowHeaderContainer, {
      [styles.isHighlighted]: isHighlighted,
      [styles.borderTop]: borderTop,
      [timelineStyles.isSelectedForPlaying]: isSelected,
      [timelineStyles.isHomeTeamRow]: team === 'home',
      [timelineStyles.isOpponentTeamRow]: team === 'opponent',
    })}
    {...rest}
  >
    {children}
  </div>
);
