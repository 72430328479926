import { Grid } from '@mui/material';

import styles from './RecordingsFiltersActions.module.scss';

interface Props {
  children: React.ReactNode;
}
const RecordingsFiltersActions = ({ children }: Props) => {
  return (
    <div className={styles.container}>
      <Grid className={styles.actions} container spacing={2} justifyContent={'center'}>
        {children}
      </Grid>
    </div>
  );
};

export default RecordingsFiltersActions;
