import { UseQueryResult } from '@tanstack/react-query';

import { queryClient } from 'api/config';
import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { transformEditRecording } from 'api/recording/transformers';
import { specificRecordingUrl } from 'api/routes';
import { RecordingEditAPI } from 'pages/recordings-list/api/types';
import { useClientId } from 'shared/contexts/app-state';
import { EditFormRecording } from 'shared/types/recording/types';

const generateUseGetRecordingQueryRef = (recordingId: string) => {
  return [`fetchRecording-${recordingId}`];
};

interface useFetchRecordingInterface {
  (recordingId: string): UseQueryResult<EditFormRecording> & { invalidateQuery: () => Promise<void> };
}

export const useFetchRecording: useFetchRecordingInterface = (recordingId: string) => {
  const { clientId } = useClientId();
  const fetchQueryRef = generateUseGetRecordingQueryRef(clientId);

  const invalidateQuery = () => queryClient.invalidateQueries(fetchQueryRef);

  const fetchRequest = useFetchRequest<EditFormRecording>({
    queryRef: fetchQueryRef,
    url: specificRecordingUrl(recordingId),
    transformer: (response: RecordingEditAPI) => transformEditRecording(response),
    options: { retry: false },
  });

  return { ...fetchRequest, invalidateQuery };
};
