import { Box, styled } from '@mui/material';
import { Colors, fontSizes, fontWeight } from 'kognia-ui';

export const AutocompleteGroup = styled(Box)(() => ({
  fontSize: fontSizes.small,
  fontWeight: fontWeight[500],
  flex: 1,
  position: 'relative',
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
}));

export const AutocompleteGroupContent = styled(Box)(({ theme }) => ({
  fontSize: fontSizes.default,
  alignItems: 'center',
  textAlign: 'left',
  overflow: 'hidden',
  color: Colors.night,
  fontWeight: fontWeight[500],
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('xs')]: {
    ['& svg']: {
      marginRight: '0.1rem',
    },
  },
  [theme.breakpoints.down('md')]: {
    ['& svg']: {
      marginRight: '0.25rem',
    },
  },
  [theme.breakpoints.down('xl')]: {
    ['& svg']: {
      marginRight: '0.5rem',
    },
  },
}));
