import classNames from 'classnames';

import { IconSizes } from 'shared/components/icons/svg-icon';
import Spinner from 'shared/components/spinner';

import styles from './VideoSpinner.module.scss';
import { useIsBuffering, useIsSeeking } from '../../../hooks';

const VideoSpinner = () => {
  const isSeeking = useIsSeeking();
  const isBuffering = useIsBuffering();

  const isLoading = isSeeking || isBuffering;

  return (
    <div className={classNames(styles.spinner, { [styles.isLoading]: isLoading })}>
      <Spinner size={IconSizes.xlarge} />
    </div>
  );
};

export default VideoSpinner;
