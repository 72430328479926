import { createMachine } from 'xstate';

import { CHANGE_SOURCE_ACTIONS, CHANGE_SOURCE_STATES, ChangeSourceAndTimeMachineContext } from './types';
import { isCurrentTime, isSameVideoSource, isVideoSourceReady } from './utils/guards';

export const changeSourceMachine = createMachine<ChangeSourceAndTimeMachineContext, Event>(
  {
    predictableActionArguments: true,
    id: 'change-source-machine',
    initial: CHANGE_SOURCE_STATES.CHECK_CURRENT_INITIAL_STATE,
    states: {
      [CHANGE_SOURCE_STATES.CHECK_CURRENT_INITIAL_STATE]: {
        always: [
          { target: CHANGE_SOURCE_STATES.CHECK_CURRENT_TIME, cond: 'isSameVideoSource' },
          { target: CHANGE_SOURCE_STATES.CHECK_CURRENT_SOURCE },
        ],
      },
      [CHANGE_SOURCE_STATES.CHECK_CURRENT_SOURCE]: {
        after: {
          10: [
            { target: CHANGE_SOURCE_ACTIONS.CHECK_CURRENT_TIME, cond: 'isVideoSourceReady' },
            { target: CHANGE_SOURCE_ACTIONS.CHECK_CURRENT_SOURCE },
          ],
        },
      },
      [CHANGE_SOURCE_STATES.CHECK_CURRENT_TIME]: {
        after: {
          10: [{ target: 'ENDED', cond: 'isCurrentTime' }, { target: CHANGE_SOURCE_ACTIONS.CHECK_CURRENT_TIME }],
        },
      },
      ENDED: { type: 'final' },
    },
  },
  { guards: { isVideoSourceReady, isCurrentTime, isSameVideoSource } },
);
