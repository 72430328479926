import { PlayBackTypes, VideoSource, ViewTypes } from 'shared/types/recording/types';

export const getVODTacticalCameraVideo = (
  videoSources: VideoSource[],
  isEffectiveTime = false,
): VideoSource | undefined => {
  return videoSources.find(
    (source) =>
      source.playBackType === PlayBackTypes.VOD &&
      source.videoView.type === ViewTypes.TACTICAL &&
      source.videoView.isEffectiveTime === isEffectiveTime,
  );
};

export const getVODPanoramaVideoSource = (videoSources: VideoSource[], isEffectiveTime = false): VideoSource => {
  return <VideoSource>(
    videoSources.find(
      (source) =>
        source.playBackType === PlayBackTypes.VOD &&
        source.videoView.type === ViewTypes.PANORAMA &&
        source.videoView.isEffectiveTime === isEffectiveTime,
    )
  );
};

export const getVODUploadedVideoSource = (videoSources: VideoSource[]): VideoSource | undefined => {
  return videoSources.find(
    (source) =>
      source.playBackType === PlayBackTypes.VOD &&
      source.videoView.type === ViewTypes.UPLOAD &&
      !source.videoView.isEffectiveTime,
  );
};

export const defaultEmptyVideoSource = {
  id: '',
  recordingId: '',
  playBackType: PlayBackTypes.LIVE,
  videoView: {
    type: ViewTypes.TACTICAL,
    isEffectiveTime: false,
  },
  src: '',
};

export const getDefaultVideoSource = (videoSources: VideoSource[]): VideoSource => {
  // TODO when the separation of recording and live tagging is done default parameter should be removed
  return (
    getVODTacticalCameraVideo(videoSources) ??
    getVODPanoramaVideoSource(videoSources) ??
    getVODUploadedVideoSource(videoSources) ??
    defaultEmptyVideoSource
  );
};

export interface GetVideoSourcesResponse {
  tacticalCameraVideo: VideoSource;
  panoramicVideo: VideoSource;
  uploadedVideo: VideoSource;
}

export const getVideoSources = (videoSources: VideoSource[]): GetVideoSourcesResponse => {
  return <GetVideoSourcesResponse>{
    tacticalCameraVideo: getVODTacticalCameraVideo(videoSources),
    panoramicVideo: getVODPanoramaVideoSource(videoSources),
    uploadedVideo: getVODUploadedVideoSource(videoSources),
  };
};
