import { Box, Stack, styled } from '@mui/material';
import { Colors, fontSizes, fontWeight, boxShadows } from 'kognia-ui';

export const playlistItemHeight = 70;

export const CounterBadge = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontWeight: fontWeight['500'],
  fontSize: fontSizes.xxSmall,
  background: Colors.melrose,
  color: Colors.primary,
  padding: theme.spacing(0, 0.5),
  borderRadius: '2px',
  alignItems: 'center',
  lineHeight: '18px',
}));

interface PlaylistItemWrapperProps {
  isCurrent: boolean;
  isDeleting: boolean;
  isEditing: boolean;
  isDisabled: boolean;
}

export const PlaylistItemWrapper = styled(Stack, {
  shouldForwardProp: (prop) =>
    prop !== 'isCurrent' && prop !== 'isEditing' && prop !== 'isDisabled' && prop !== 'isDeleting',
})<PlaylistItemWrapperProps>(({ theme, isCurrent, isDeleting, isEditing, isDisabled }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px solid transparent`,
  backgroundColor: Colors.white,
  alignItems: 'center',
  height: playlistItemHeight,
  position: 'relative',
  overflow: 'hidden',
  cursor: 'pointer',
  transition: theme.transitions.easing.easeOut,

  '&:hover': {
    borderColor: Colors.primary,
  },

  ...(isCurrent && {
    borderColor: 'transparent',
    backgroundColor: Colors.lavender,
    boxShadow: boxShadows[2],

    '[data-element-name="name-text"], [data-element-name="duration"]': {
      color: Colors.white,
    },

    '[data-element-name="counter"]': {
      backgroundColor: Colors.white,
      color: Colors.primary,
    },

    svg: {
      fill: Colors.white,
    },
  }),

  ...(isDisabled && {
    backdropFilter: 'grayscale(100%)',
    pointerEvents: 'none',
    borderColor: Colors.storm,
    backgroundColor: Colors.background,
    opacity: 0.5,

    '[data-element-name="name-text"], [data-element-name="duration"]': {
      color: Colors.storm,
    },

    '[data-element-name="counter"]': {
      backgroundColor: Colors.storm,
      color: Colors.white,
    },

    svg: {
      fill: Colors.storm,
    },
  }),

  ...(isEditing && {
    pointerEvents: 'none',
    borderColor: Colors.orange,
    backgroundColor: Colors.dawn,
    opacity: 0.5,

    '[data-element-name="name-text"], [data-element-name="duration"]': {
      color: Colors.orange,
    },

    '[data-element-name="counter"]': {
      backgroundColor: Colors.orange,
      color: Colors.white,
    },

    svg: {
      fill: Colors.orange,
    },
  }),

  ...(isDeleting && {
    boxShadows: boxShadows[1],
    borderColor: Colors.red,
    opacity: 0.3,
    backgroundColor: Colors.red,

    '[data-element-name="name-text"], [data-element-name="duration"]': {
      color: Colors.white,
    },

    '[data-element-name="counter"]': {
      backgroundColor: Colors.white,
      color: Colors.red,
    },

    svg: {
      fill: Colors.red,
    },
  }),
}));

export const PlaylistItemContentWrapper = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  flexGrow: 1,
  justifyContent: 'space-between',
  maxWidth: '100%',
}));

export const PlaylistItemContent = styled(Stack)(() => ({
  height: '100%',
  justifyContent: 'flex-start',
  position: 'relative',
  maxWidth: 'calc(100% - 24px)',
}));

export const PlaylistItemContentTopRow = styled(Stack)(({ theme }) => ({
  paddingLeft: theme.spacing(0.5),
  paddingTop: theme.spacing(0.5),
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
}));

export const PlaylistItemContentBottomRow = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
}));
