import { useCallback } from 'react';

import { useSaveUserPreset } from 'api/user-presets/use-save-user-preset';

import { RecordingsFilters } from '../../../recording/types';
import { PlaylistMultimatchAppliedFiltersPreset } from '../../types';

export const useSavePlaylistMultimatchFiltersAppliedPreset = () => {
  const { savePreset } = useSaveUserPreset();

  return useCallback((playlistId: string, filters: RecordingsFilters) => {
    const playingModePreset = new PlaylistMultimatchAppliedFiltersPreset(filters, playlistId);
    savePreset({ data: playingModePreset });
  }, []);
};
