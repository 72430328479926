import { Button, Grid, Typography } from '@mui/material';
import { Colors, fontSizes, fontWeight } from 'kognia-ui';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import UploadIcon from 'shared/assets/upload-icon.svg';
import Container from 'shared/components/container';
import { Message } from 'shared/components/message';
import { PageSubTitle } from 'shared/components/page-sub-title';
import { PageTitle } from 'shared/components/page-title';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import { NotificationType, useNotifications } from 'shared/hooks/notifications';

import { UploadMessageContainer, UploadProgress, UploadVideoDropArea } from './index.styled';
import {
  fetchAbortMultipartUpload,
  fetchUploadMultipartVideo,
} from '../../tagging-tool/service/taggingRecording.service';

export const UploadVideoStep2Screen = () => {
  const triggerNotification = useNotifications();
  const { t } = useTranslation();

  const history = useHistory();
  const { recordingId } = useParams<{ recordingId: string }>();

  // state

  const [error, setError] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>();
  const [uploading, setUploading] = useState<boolean>(false);
  const [multipartProgress, setMultipartProgress] = useState<number>(0);
  const [finished, setFinished] = useState<boolean>(false);
  const [multipartUploadId, setMultipartUploadId] = useState<string>('');
  const [abortController, setAbortController] = useState<AbortController>(new AbortController());

  // state: dnd

  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      if (!acceptedFiles.length) {
        return;
      }

      const file: File = acceptedFiles[0];

      const onError = () => {
        setError(true);
        setUploading(false);
        setMultipartProgress(0);
        setFinished(false);
        setFileName(undefined);
        setAbortController(new AbortController());
      };

      setError(false);
      setUploading(true);
      setMultipartProgress(0);
      setFinished(false);
      setFileName(file.name);

      fetchUploadMultipartVideo({
        recordingId,
        file,
        onInit: (uploadId) => {
          setMultipartUploadId(uploadId);
        },
        onProgress: (progress) => {
          setMultipartProgress(progress);
        },
        abortController,
      }).then((res) => {
        if (res.error) {
          return onError();
        }
        setUploading(false);
        setFinished(true);
      });
    },
    [abortController],
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    minSize: 1,
    maxFiles: 1,
    disabled: uploading || finished,
    accept: ['video/mp4', 'video/x-matroska', 'video/x-msvideo', 'video/quicktime', 'video/*,.mkv'],
  });

  // handlers

  const handleNext = useCallback(() => {
    triggerNotification({
      type: NotificationType.WARNING,
      message: t('tagging-tool:upload-video.processing-upload-video-warning'),
    });
    history.replace(routes.TAGGING_TOOL);
  }, [history]);

  const handleCancel = useCallback(() => {
    history.replace(routes.TAGGING_TOOL);
  }, [history]);

  const handleCancelUpload = useCallback(() => {
    abortController.abort();
    fetchAbortMultipartUpload({ recordingId, uploadId: multipartUploadId });
  }, [recordingId, multipartUploadId]);

  // shortcuts

  const { onClick, ...rootProps } = getRootProps();

  return (
    <SidebarLayout>
      <Container>
        <Grid container justifyContent={'center'}>
          <Grid item xs={10}>
            <PageTitle>{t('tagging-tool:upload-video.uploader-title')}</PageTitle>
            <PageSubTitle>{t('tagging-tool:upload-video.uploader-mime-types')}</PageSubTitle>
            <UploadVideoDropArea {...rootProps}>
              <input {...getInputProps()} />
              {!isDragActive && (
                <>
                  <img src={UploadIcon} alt='' />
                  <Typography
                    fontSize={fontSizes.default}
                    fontWeight={fontWeight['500']}
                    color={uploading ? Colors.storm : Colors.night}
                  >
                    {t('tagging-tool:upload-video.uploader-new-video')}
                  </Typography>
                  <Typography fontSize={fontSizes.small} color={Colors.storm}>
                    {t('tagging-tool:upload-video.uploader-click-or-press')}
                  </Typography>

                  {uploading ? (
                    <Button onClick={handleCancelUpload} variant='contained' color='error' size='large'>
                      {t('common:actions.cancel')}
                    </Button>
                  ) : (
                    <Button onClick={onClick as () => void} variant='contained' size='large' disabled={finished}>
                      {t('tagging-tool:upload-video.uploader-button-title')}
                    </Button>
                  )}
                </>
              )}
              {isDragActive && isDragAccept && (
                <Typography fontSize={fontSizes.default} fontWeight={fontWeight['500']} color={Colors.storm}>
                  {t('tagging-tool:upload-video.uploader-drag-accept-hint')}
                </Typography>
              )}
              {isDragActive && isDragReject && (
                <>
                  <Typography fontSize={fontSizes.default} fontWeight={fontWeight['500']} color={Colors.red}>
                    {t('tagging-tool:upload-video.uploader-drag-reject-hint')}
                  </Typography>
                  <Typography fontSize={fontSizes.default} color={Colors.storm}>
                    {t('tagging-tool:upload-video.uploader-mime-types')}
                  </Typography>
                </>
              )}
            </UploadVideoDropArea>
            {(uploading || finished) && (
              <>
                <UploadMessageContainer>
                  {!finished && (
                    <Typography fontSize={fontSizes.default} fontWeight={fontWeight['500']}>{`${t(
                      'tagging-tool:upload-video.uploading',
                      {
                        fileName: fileName ?? '',
                      },
                    )}${Math.round(multipartProgress * 100)}%`}</Typography>
                  )}
                  {finished && (
                    <Typography fontSize={fontSizes.default} fontWeight={fontWeight['500']}>{`${t(
                      'tagging-tool:upload-video.upload-complete',
                      {
                        fileName: fileName ?? '',
                      },
                    )}`}</Typography>
                  )}
                  <UploadProgress variant='determinate' value={Math.round(multipartProgress * 100)} />
                </UploadMessageContainer>
                {!finished && <Message>{t('tagging-tool:upload-video.uploading-hint')}</Message>}
              </>
            )}
            {error && (
              <Typography fontSize={fontSizes.default} fontWeight={fontWeight['500']} color={Colors.red}>
                {t('tagging-tool:upload-video.upload-failed', { fileName: fileName ?? '' })}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid display='flex' gap={2} justifyContent='center' marginTop={2}>
          {!finished && (
            <Button variant='outlined' size='large' color='secondary' onClick={handleCancel} disabled={uploading}>
              {t('common:actions.cancel')}
            </Button>
          )}
          <Button variant='contained' size='large' onClick={handleNext} disabled={uploading || !finished}>
            {t('common:actions.finish')}
          </Button>
        </Grid>
      </Container>
    </SidebarLayout>
  );
};
