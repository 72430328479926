import SvgIcon, { SvgIconProps } from '../svg-icon';

const IconStar = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M7.45 20.25c-.08-.003-.157-.024-.228-.062s-.132-.09-.179-.153-.08-.137-.096-.215-.014-.159.006-.236l1.208-4.856-3.827-3.214c-.082-.069-.141-.16-.17-.262s-.028-.211.005-.313.095-.191.179-.257.185-.105.292-.113l4.99-.35 1.874-4.634c.04-.099.109-.184.197-.244s.193-.092.3-.092.211.032.3.092.157.145.197.244l1.874 4.634 4.987.35c.107.007.208.046.293.112s.147.155.18.256.035.211.006.313-.089.194-.17.263l-3.831 3.218 1.208 4.852c.025.103.019.212-.017.311s-.102.187-.188.249-.189.098-.295.102-.211-.024-.302-.079L12 17.517l-4.241 2.65c-.092.058-.2.087-.309.083zm.862-1.69l3.402-2.127c.085-.053.184-.082.284-.082s.199.028.284.082l3.402 2.123-.971-3.892c-.024-.097-.02-.199.011-.294s.088-.18.165-.244l3.071-2.582-4.001-.278c-.1-.007-.196-.042-.277-.1s-.144-.139-.182-.231L12 7.215l-1.505 3.719c-.038.093-.101.173-.182.231s-.177.093-.277.1l-3.997.278 3.071 2.582c.076.065.133.149.163.245s.034.197.01.294l-.971 3.896z' />
    </SvgIcon>
  );
};

export default IconStar;
