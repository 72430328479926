import { Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import React, { useCallback, useState } from 'react';

import ConfirmDialog from 'shared/components/confirm-dialog';
import IconDelete from 'shared/components/icons/icon-delete';
import IconEdit from 'shared/components/icons/icon-edit';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';

import { VenuesListItem } from './styled';
import { useDeleteVenue } from '../../../../api/venues/use-delete-venue';
import { Venue } from '../../../../api/venues/use-venues/types';
import { Countries } from '../../../../utils/countries';
import { VenueFormFieldsNames } from '../venue-form/types';
import { VenueModal } from '../venue-modal';

interface Props {
  venue: Venue;
}

export const VenueItem = ({ venue }: Props) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
  const { deleteVenue } = useDeleteVenue();

  const handleEditModalOpen = () => setIsEditModalOpen(true);
  const handleEditModalClose = () => setIsEditModalOpen(false);

  const handleDeleteVenue = useCallback(() => {
    deleteVenue({ id: venue.id });
  }, [venue.id, deleteVenue]);

  const options: MenuListOption[] = [
    {
      displayText: 'Edit',
      icon: <IconEdit size={IconSizes.small} color={IconColors.storm} />,
      onClick: handleEditModalOpen,
    },
    {
      displayText: 'Delete',
      icon: <IconDelete size={IconSizes.small} color={IconColors.storm} />,
      onClick: () => setIsConfirmDeleteDialogOpen(true),
    },
  ];

  const defaultValues = {
    [VenueFormFieldsNames.id]: venue.id,
    [VenueFormFieldsNames.name]: venue.name,
    [VenueFormFieldsNames.city]: venue.city ? venue.city : '',
    [VenueFormFieldsNames.countryCode]: Countries.map((country) => country.code).some(
      (countryCode) => countryCode === venue.countryCode,
    )
      ? venue.countryCode
      : '',
  };

  return (
    <>
      <VenuesListItem key={venue.id}>
        <Typography fontWeight={fontWeight['500']}>{venue.name}</Typography>
        <Typography>{venue.city}</Typography>
        <Typography>
          {Countries.find((item) => item.code.toUpperCase() === venue.countryCode?.toUpperCase())?.name || ''}
        </Typography>
        <KebabMenu options={options} />
      </VenuesListItem>

      {isEditModalOpen ? (
        <VenueModal open={isEditModalOpen} onClose={handleEditModalClose} defaultValues={defaultValues} />
      ) : null}

      <ConfirmDialog
        type={'warning'}
        title={`You sure you want delete "${venue.name}" venue?`}
        cancelLabel={'Cancel'}
        confirmLabel={'Delete'}
        onConfirm={handleDeleteVenue}
        isOpen={isConfirmDeleteDialogOpen}
        setIsOpen={setIsConfirmDeleteDialogOpen}
      />
    </>
  );
};
