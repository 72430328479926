import { Box, styled } from '@mui/material';
import { Colors, fontSizes, fontWeight } from 'kognia-ui';

import { MessageVariant } from './index';

interface MessageContainerProps {
  variant: MessageVariant;
}

export const MessageContainer = styled(Box, { shouldForwardProp: (prop) => prop != 'variant' })<MessageContainerProps>(
  ({ theme }) => ({
    padding: theme.spacing(1, 2),
    background: Colors.dawn,
    fontWeight: fontWeight['500'],
    fontSize: fontSizes.small,
    color: Colors.storm,
  }),
);
