import { KogniaUser, UsersApiResponse } from './use-kognia-users/types';
import { InfinityQueryDataResult } from '../../hooks/useInfinityQuery/types';

export const transformUsers = (response: UsersApiResponse): InfinityQueryDataResult<KogniaUser> => {
  return {
    data: {
      items: response.content,
      page: response.page,
    },
    nextCursor: response.page.totalPages > response.page.number ? response.page.number + 1 : undefined,
  };
};
