import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ErrorPage } from './error-page';

export const ErrorBoundaryPage = (props: { error: Error; resetError: () => void }) => {
  const [errorPageId, setErrorPageId] = useState('');
  const location = useLocation();

  useEffect(() => {
    if (props?.error && !errorPageId && location.key) {
      setErrorPageId(location.key);
    }
  }, [errorPageId, location.key, props?.error]);

  useEffect(() => {
    if (errorPageId && errorPageId !== location.key && props?.resetError) {
      setErrorPageId('');
      props.resetError();
    }
  }, [errorPageId, location.key, props, props?.resetError]);

  return <ErrorPage />;
};
