import map from 'lodash/map';

import { RecordingFiltersScenariosOrTacticsInside, RecordingsFilters } from 'shared/types/recording/types';
import { MatchTeam } from 'shared/types/teams/types';

import { TacticalAnalysesFilters, TacticalAnalysesPlaylistItemsFiltersAPI } from './useEpisodesWithFilters/types';

export const transformFilters = (filters: TacticalAnalysesPlaylistItemsFiltersAPI): TacticalAnalysesFilters => {
  const eventsStarting = new Set(
    map(filters.eventStarting.options, (event, key: string) => {
      return key;
    }),
  );

  const eventsEnding = new Set(
    map(filters.eventEnding.options, (event, key: string) => {
      return key;
    }),
  );

  const scenarios = map(filters.scenario.options, (scenario, key: string) => {
    return { id: key, name: scenario.title };
  });

  const offensiveTactics = filters.tactic.options.offensive?.options
    ? map(filters.tactic.options.offensive.options, (tactic, key: string) => {
        return { id: key, name: tactic.title };
      })
    : [];

  const defensiveTactics = filters.tactic.options.defensive?.options
    ? map(filters.tactic.options.defensive.options, (tactic, key: string) => {
        return { id: key, name: tactic.title };
      })
    : [];

  function sortByJerseyNumber() {
    return (player1: { name: string }, player2: { name: string }) => {
      const matchPlayer1Number = player1.name.split('.')[0].split('-')[0];
      const matchPlayer2Number = player2.name.split('.')[0].split('-')[0];

      return Number(matchPlayer1Number) > Number(matchPlayer2Number) ? 1 : -1;
    };
  }

  const teams: MatchTeam[] = map(filters.team.options, (team, teamId) => {
    return {
      id: teamId,
      name: team.title,
      logo: '',
      players: map(team.options.players.options, (player, playerId) => ({
        id: playerId,
        name: player.title,
      })).sort(sortByJerseyNumber()),
    };
  });

  return {
    eventsStarting,
    eventsEnding,
    scenarios,
    tactics: {
      offensive: offensiveTactics,
      defensive: defensiveTactics,
    },
    teams,
  };
};

export const transformScenariosOrTacticsInside = (
  filtersScenariosOrTacticsInside?: RecordingFiltersScenariosOrTacticsInside,
) => {
  if (!filtersScenariosOrTacticsInside) return [];

  const offensiveTactics = filtersScenariosOrTacticsInside.tactics.offensive.filter((tactic) => {
    return tactic.tacticalFundamentalType !== '' || tactic.teamIds.length > 0 || tactic.playerIds.length > 0;
  });

  const defensiveTactics = filtersScenariosOrTacticsInside.tactics.defensive.filter((tactic) => {
    return tactic.tacticalFundamentalType !== '' || tactic.teamIds.length > 0 || tactic.playerIds.length > 0;
  });

  const tactics = offensiveTactics.concat(defensiveTactics);

  return [
    {
      ...filtersScenariosOrTacticsInside,
      tactics: tactics.length > 0 ? tactics : [],
    },
  ];
};

export const transformFiltersForRequest = (filters: RecordingsFilters) => {
  return {
    ...filters,
    scenariosOrTacticsInside: transformScenariosOrTacticsInside(filters.scenariosOrTacticsInside),
    eventsEnding: filters.eventsEnding ?? undefined,
    eventsStarting: filters.eventsStarting ?? undefined,
  };
};
