import { HTTPMethod } from 'api/types';

import { useMutationRequest } from '../../../hooks/useMutationRequest';
import { userBatchClientIdsUrl } from '../../../routes';
import { KogniaUser } from '../use-kognia-users/types';

interface Options {
  onSuccess?: (KogniaUser: KogniaUser[]) => void;
}

export const useAddUsersToClients = ({ onSuccess }: Options) => {
  const { mutate, isLoading, isError, isSuccess } = useMutationRequest({
    type: HTTPMethod.PATCH,
    errorMessage: 'Error adding client(s) to user(s)',
    successMessage: 'Client(s) added to user(s)',
    onSuccess,
  });

  const addUsersToClients = (kogniaUsers: KogniaUser[], clientIds: string[]) => {
    mutate({
      url: userBatchClientIdsUrl,
      data: { clientIds, userIds: kogniaUsers.map((user) => user.id) },
    });
  };

  return { addUsersToClients, isLoading, isError, isSuccess };
};
