import { Button } from '@mui/material';
import classNames from 'classnames';
import React, { useRef } from 'react';

import IconForward from 'shared/components/icons/icon-forward';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import {
  useCurrentPlaylistItemVideoSources,
  useCurrentVideoSourceIndex,
  useVideoPlayerIsInStandBy,
} from 'shared/components/video-player/hooks';

import { useVideoPlayerActions, useVideoPlayerState } from '../../../index';
import { PLAYER_STATES } from '../../../state/states';
import styles from '../Controls.module.scss';

const NextVideoSourceButton = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { state, readyState } = useVideoPlayerState();
  const isInStandBy = useVideoPlayerIsInStandBy();
  const videoSources = useCurrentPlaylistItemVideoSources();
  const actions = useVideoPlayerActions();
  const videoSourceIndex = useCurrentVideoSourceIndex();

  const isDisabled =
    !readyState || state === PLAYER_STATES.ENDED || isInStandBy || videoSourceIndex === videoSources.length - 1;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    buttonRef?.current?.blur();
    actions.nextVideoSource();
  };

  if (videoSources.length <= 1) return null;

  return (
    <span>
      <Button
        ref={buttonRef}
        className={classNames({ [styles.disabled]: isDisabled })}
        disabled={isDisabled}
        size={'small'}
        onClick={handleClick}
      >
        <IconForward size={IconSizes.medium} color={IconColors.white} />
      </Button>
    </span>
  );
};

export default NextVideoSourceButton;
