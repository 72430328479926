import { useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useSaveUserPreset } from 'api/user-presets/use-save-user-preset';
import { TimelineTeamIdFocusPreset } from 'shared/types/user-preset/types';

import { timelineMatchAtoms } from '../../atoms';
import { useTacticalAnalysisTeams } from '../use-tactical-analysis-teams';

type Response = (recordingId: string, updatePreset?: boolean) => void;

export const useSetTimelineTeamIdFocus = (recordingId: string): Response => {
  const setTimelineTeamIdFocus = useSetRecoilState(timelineMatchAtoms.teamIdFocus(recordingId));
  const { savePreset } = useSaveUserPreset();

  return useCallback(
    (teamId: string, updatePreset = true) => {
      updatePreset && savePreset({ data: new TimelineTeamIdFocusPreset(teamId, recordingId) });
      setTimelineTeamIdFocus(teamId);
    },
    [savePreset, recordingId, setTimelineTeamIdFocus],
  );
};

export const useTeamUtils = (recordingId: string) => {
  const teams = useTacticalAnalysisTeams(recordingId);
  return useMemo(
    () => ({
      isHomeTeam: (teamId: string) => Boolean(teams?.homeTeam?.id && teamId && teams.homeTeam.id === teamId),
      isOpponentTeam: (teamId: string) =>
        Boolean(teams?.opponentTeam?.id && teamId && teams.opponentTeam.id === teamId),
    }),
    [teams],
  );
};

export const useTimelineTeamIdFocus = (recordingId: string) => {
  const teams = useTacticalAnalysisTeams(recordingId);
  const teamIdFocus = useRecoilValue(timelineMatchAtoms.teamIdFocus(recordingId));

  return useMemo(
    () => ({
      isHomeTeamSelected: Boolean(teams?.homeTeam?.id && teamIdFocus && teams.homeTeam.id === teamIdFocus),
      isOpponentTeamSelected: Boolean(teams?.opponentTeam?.id && teamIdFocus && teams.opponentTeam.id === teamIdFocus),
      teamIdFocus,
    }),
    [teams, teamIdFocus],
  );
};
