import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';

import { UpdateStageApiParams } from './types';
import { annotationApiUrls } from '../../routes';
import { useAllowedBackofficeOrigin } from '../../use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from '../../utils';
import { useInvalidateStages } from '../use-stages';

interface RequestData {
  data: UpdateStageApiParams;
  stageId: string;
  onSuccess?: () => void;
}

export const useUpdateStage = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateStages = useInvalidateStages();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.PATCH,
    successMessage: 'Stage updated',
    errorMessage: (data) => metadataApiErrorParser(data, 'Stage update error'),
    onSuccess: invalidateStages,
  });

  const update = ({ data, stageId, onSuccess }: RequestData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: `${annotationApiUrls.STAGES}/${stageId}`,
        data,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, update };
};
