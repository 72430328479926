import {
  CustomButtonsSkeletonContainer,
  CustomButtonsSkeletonGradient,
  CustomButtonsSkeletonItem,
} from './index.styled';

export const CustomButtonsSkeleton = () => {
  return (
    <CustomButtonsSkeletonContainer>
      <CustomButtonsSkeletonItem />
      <CustomButtonsSkeletonItem />
      <CustomButtonsSkeletonItem />
      <CustomButtonsSkeletonGradient />
    </CustomButtonsSkeletonContainer>
  );
};
