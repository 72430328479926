import { SidebarLayout } from 'shared/components/sidebar-layout';

import { ErrorContainer } from '../error-container';

export const ErrorPage = () => {
  return (
    <SidebarLayout>
      <ErrorContainer />
    </SidebarLayout>
  );
};
