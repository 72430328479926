import { useClientId } from 'shared/contexts/app-state';
import { DataFetching } from 'shared/hooks/use-page-state-machine';

import {
  useTaggingToolTagRecordingData,
  UseTaggingToolTagRecordingDataType,
} from '../use-tagging-tool-tag-recording-data';

export const useTaggingToolTagRecordingPage = (
  recordingId: string,
  isLive: boolean,
): DataFetching<UseTaggingToolTagRecordingDataType> => {
  const { clientId } = useClientId();
  const taggingToolTagRecordingData = useTaggingToolTagRecordingData(recordingId, isLive);

  const isInvalidClient = !taggingToolTagRecordingData?.data?.matchWithEpisodes?.match?.clientIds.includes(clientId);

  return {
    ...taggingToolTagRecordingData,
    isInvalidClient,
  };
};
