import { useTranslation } from 'react-i18next';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { invalidatePlaylistQuery } from 'api/playlist/usePlaylist';
import { deletePlaylistItemUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';

import { invalidatePlaylistsQuery } from '../useFetchPlaylists';

export const useDeletePlaylistItem = (playlistId: string) => {
  const { t } = useTranslation();
  const { mutate, isLoading, isError } = useMutationRequest({
    type: HTTPMethod.DELETE,
    errorMessage: t('api:use-delete-playlist-item.error'),
    successMessage: t('api:use-delete-playlist-item.success'),
  });

  const deletePlaylistItem = (playlistItemId: string, onSuccess?: () => void) => {
    mutate(
      { url: deletePlaylistItemUrl(playlistId, playlistItemId) },
      {
        onSuccess: () => {
          invalidatePlaylistQuery();
          invalidatePlaylistsQuery();
          if (onSuccess) onSuccess();
        },
      },
    );
  };

  return { deletePlaylistItem, isLoading, isError };
};
