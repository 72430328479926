import React from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonTypes } from 'shared/components/button';

import styles from './FilterBlockClear.module.scss';

interface Props {
  onClick: () => void;
}

const FilterBlockClear = ({ onClick }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <Button type={ButtonTypes.Simple} className={styles.resetFilters} onClick={onClick}>
        <span>{t('recordings:filters.clear-filter')}</span>
      </Button>
    </div>
  );
};

export default FilterBlockClear;
