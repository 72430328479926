import { List } from '@mui/material';

import { Competition } from '../../../../types/competitions';
import { CompetitionItem } from '../competition-item';

interface Props {
  competitions: Competition[];
}

export const CompetitionsList = ({ competitions }: Props) => {
  const competitionsItems = competitions.map((competition) => {
    return <CompetitionItem key={competition.id} competition={competition} />;
  });

  return <List>{competitionsItems}</List>;
};
