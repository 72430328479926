import { Box, Skeleton } from '@mui/material';
import { Colors } from 'kognia-ui';

import Container from 'shared/components/container';
import { SidebarLayout } from 'shared/components/sidebar-layout';

import RecordingsListLoading from '../recordings-list/loading';

export const RecordingListPageSkeleton = () => {
  return (
    <SidebarLayout>
      <Container>
        <Box>
          <Skeleton variant='rectangular' width='20%' height={36} sx={{ backgroundColor: Colors.ghost }} />
        </Box>
        <Box marginBottom={3} marginTop={3}>
          <Skeleton variant='rectangular' height={48} sx={{ backgroundColor: Colors.ghost }} />
        </Box>
        <Box display='flex' justifyContent='flex-end'>
          <Skeleton variant='rectangular' width='25%' height={32} sx={{ backgroundColor: Colors.ghost }} />
        </Box>
        <Box marginBottom={3} marginTop={3}>
          <Skeleton variant='rectangular' height={40} sx={{ backgroundColor: Colors.ghost }} />
        </Box>
        <RecordingsListLoading />
      </Container>
    </SidebarLayout>
  );
};
