import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export const useRedirectTo = () => {
  const history = useHistory();

  return useCallback(
    (link: string) => {
      if (link.startsWith('http')) {
        return window.open(link);
      }

      return history.push(link);
    },
    [history],
  );
};
