import { IconButton } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useTacticalAnalysisFilteredItems } from 'api/recording/use-tactical-analysis-filtered-items';
import { useMetrics } from 'kognia/metrics/hooks/use-metrics';
import IconClose from 'shared/components/icons/icon-close';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import { Episode } from 'shared/types';
import { MetricsData, MetricsNames } from 'shared/types/metrics';
import { RecordingsFilters } from 'shared/types/recording/types';
import { MatchTeams } from 'shared/types/teams/types';

import { IconCloseContainer, ModalContainer, ModalContent } from './styled';
import { MultiAnalysisFilters } from '../../../../../../playlist-detail/components/add-multiple-clips/multimatch-filters-modal/multi-analysis-filters';
import { useTacticalAnalysisAvailableFilters } from '../../../../../hooks/use-tactical-analysis-available-filters';
import { useTacticalAnalysisId } from '../../../../../hooks/use-tactical-analysis-id';

interface Props {
  episodeCount: number;
  episodes: Episode[];
  isOpen: boolean;
  onClose: () => void;
  onApply: (appliedFilters: RecordingsFilters, episodes: Episode[]) => void;
  recordingId: string;
  teams: MatchTeams;
  appliedFilters: RecordingsFilters;
}

const FiltersModal = ({ isOpen, onClose, recordingId, onApply, appliedFilters }: Props) => {
  const { t } = useTranslation();
  const tacticalAnalysisId = useTacticalAnalysisId(recordingId);
  const { getFilters, isLoading, data } = useTacticalAnalysisFilteredItems(tacticalAnalysisId);
  const availableFilters = useTacticalAnalysisAvailableFilters(recordingId);
  const { pushEvent } = useMetrics();

  const handleOnApply = useCallback(
    (filters: RecordingsFilters) => {
      pushEvent<MetricsData[MetricsNames.TACTICAL_ANALYSIS_APPLY_FILTER]>(MetricsNames.TACTICAL_ANALYSIS_APPLY_FILTER, {
        filter: JSON.stringify(filters),
      });
      onApply(filters, data);
    },
    [data, pushEvent, onApply],
  );

  const handleOnUpdate = useCallback(
    (filters: RecordingsFilters) => {
      getFilters(filters);
    },
    [getFilters],
  );

  return (
    <ModalContainer
      open={isOpen}
      onClose={onClose}
      aria-labelledby='filters-title'
      aria-describedby='filters-description'
    >
      <ModalContent>
        <IconCloseContainer>
          <IconButton size={IconSizes.small} onClick={onClose}>
            <IconClose isButton color={IconColors.storm} size={IconSizes.small} />
          </IconButton>
        </IconCloseContainer>
        <MultiAnalysisFilters
          applyButtonLabel={t('timeline:filter-results-count', { count: data.length ?? 0 })}
          filtersData={availableFilters}
          appliedFilters={appliedFilters}
          getFilters={getFilters}
          isDisabled={false}
          isLoading={isLoading}
          isLoadingFilters={false}
          loadingButtonLabel={t('common:searching').toUpperCase()}
          onApply={handleOnApply}
          onUpdate={handleOnUpdate}
          recordingIds={[recordingId]}
        />
      </ModalContent>
    </ModalContainer>
  );
};

export default FiltersModal;
