import { useTranslation } from 'react-i18next';

import ExportModal, { ExportVideoTypes } from '../export-modal';
import { ExportModals } from '../index';

type ExportVideoModalProps = {
  onClose: () => void;
  fullMatchDownloadUrl?: string;
  effectiveTimeDownloadUrl?: string;
};

const ExportVideoModal = ({ onClose, fullMatchDownloadUrl, effectiveTimeDownloadUrl }: ExportVideoModalProps) => {
  const { t } = useTranslation();

  const options = [
    {
      type: ExportVideoTypes.FULL_MATCH,
      displayValue: t('timeline:actions.export.video.export-full-match-video'),
      downloadUrl: fullMatchDownloadUrl,
      enabled: !!fullMatchDownloadUrl,
    },
    {
      type: ExportVideoTypes.EFFECTIVE_TIME,
      displayValue: t('timeline:actions.export.video.export-effective-time-video'),
      downloadUrl: effectiveTimeDownloadUrl,
      enabled: !!effectiveTimeDownloadUrl,
    },
  ];

  return (
    <ExportModal
      onClose={onClose}
      modalTitle={t('timeline:actions.export.video.export-video')}
      options={options}
      type={ExportModals.VIDEO}
    />
  );
};

export default ExportVideoModal;
