import React, { forwardRef } from 'react';

import Button, { ButtonBorderRadius, ButtonPadding, ButtonSizes, ButtonTypes } from '../../button';

interface Props {
  buttonType: ButtonTypes;
  children: React.ReactNode;
  filterId: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export const FilterButtonLink = forwardRef<HTMLButtonElement, React.PropsWithChildren<Props>>(
  ({ buttonType, children, filterId, onClick }, ref) => {
    return (
      <Button
        ref={ref}
        type={buttonType}
        radius={ButtonBorderRadius.R0}
        padding={ButtonPadding.P0}
        btnSize={ButtonSizes.S}
        aria-controls={filterId}
        onClick={onClick}
      >
        <>{children}</>
      </Button>
    );
  },
);

FilterButtonLink.displayName = 'FilterButtonLink';
