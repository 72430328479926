import { z } from 'zod';

import { numberTextField } from 'shared/utils/zod/number-text-field';

import { StageFormFieldsNames } from './types';
import { SeasonSchema } from '../../../../types/seasons';

export const stageFormSchema = z.object({
  [StageFormFieldsNames.id]: z.string().optional(),
  [StageFormFieldsNames.name]: z.string().trim().min(2),
  [StageFormFieldsNames.orderIndex]: numberTextField,
  [StageFormFieldsNames.season]: SeasonSchema.optional(),
  [StageFormFieldsNames.parentStageId]: z.string().optional(),
});
