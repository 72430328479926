import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Pagination.module.scss';
import Button, { ButtonBorderRadius } from '../button';

export interface PaginationProps {
  total: number;
  displayed: number;
  resultsPerPage?: number;
  onShowMore: () => void;
  loading?: boolean;
  getStatsText: (displayed: number, total: number) => string;
}

export const ELEMENTS_PER_PAGE = 8;

const Pagination = ({
  total,
  displayed,
  onShowMore,
  getStatsText,
  loading = false,
  resultsPerPage = ELEMENTS_PER_PAGE,
}: PaginationProps) => {
  const { t } = useTranslation();
  const disabled = total <= resultsPerPage || displayed === total || !displayed;
  const percentageLoaded = Math.floor((displayed / total) * 100);
  const className = classnames(styles.pagination, {
    [styles.paginationLoading]: loading,
    [styles.paginationDisabled]: loading || disabled,
  });
  const loadingBarClassed = classnames([styles.pagination__progressFilled], {
    [styles.pagination__progressFilledLoading]: loading,
    [styles.pagination__progressFilledDisabled]: disabled,
  });
  if (!total) {
    return null;
  }
  return (
    <div className={className}>
      <p className={styles.pagination__stats}>{getStatsText(displayed, total)}</p>
      <div className={styles.pagination__progress} aria-hidden='true'>
        <div className={loadingBarClassed} style={{ width: `${percentageLoaded}%` }} />
      </div>
      <Button
        className={styles.pagination__button}
        disabled={loading || disabled}
        onClick={onShowMore}
        radius={ButtonBorderRadius.R2}
      >
        {loading ? t('common:pagination.loading') : t('common:pagination.show-more')}
      </Button>
    </div>
  );
};

export default Pagination;
