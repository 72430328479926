import { ZOOM_LEVELS } from '../../zoom-range';

export const getTimeZoomLevelAdjustment = (zoomLevel: number) => {
  const zoomLevelRatio = {
    [ZOOM_LEVELS.EXTRA_LARGE]: 0.2,
    [ZOOM_LEVELS.LARGE]: 0.6,
    [ZOOM_LEVELS.MEDIUM_LARGE]: 1,
    [ZOOM_LEVELS.MEDIUM]: 4,
    [ZOOM_LEVELS.MEDIUM_SMALL]: 8,
    [ZOOM_LEVELS.SMALL]: 12,
    [ZOOM_LEVELS.EXTRA_SMALL]: 16,
  };

  return zoomLevelRatio[zoomLevel];
};

export const getBlockSizeByZoomLevel = (zoomLevel: number) => {
  const zoomLevelRatio = {
    [ZOOM_LEVELS.EXTRA_LARGE]: 40,
    [ZOOM_LEVELS.LARGE]: 60,
    [ZOOM_LEVELS.MEDIUM_LARGE]: 80,
    [ZOOM_LEVELS.MEDIUM]: 100,
    [ZOOM_LEVELS.MEDIUM_SMALL]: 120,
    [ZOOM_LEVELS.SMALL]: 140,
    [ZOOM_LEVELS.EXTRA_SMALL]: 180,
  };

  return zoomLevelRatio[zoomLevel];
};

export const adjustTimeSpaceByZoomLevel = (time: number, zoomLevel: number) =>
  time * getTimeZoomLevelAdjustment(zoomLevel);
