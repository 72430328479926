import { FormControl, FormHelperText, Select, TextField } from '@mui/material';
import { Control, Controller, Path, UseFormRegister, FieldErrors } from 'react-hook-form';

import { FormFormLabel } from 'shared/components/form/form-form-label';
import { getTypeOptions } from 'shared/components/form/get-type-options';

import { VenueFormFieldsNames } from './types';
import { Countries } from '../../../../utils/countries';

interface Props<TFormValues extends Record<string, any>> {
  register: UseFormRegister<TFormValues>;
  errors: FieldErrors<TFormValues>;
  control: Control<TFormValues>;
}

export function VenuesForm<TFormValues extends Record<string, any>>({ register, errors, control }: Props<TFormValues>) {
  return (
    <>
      <FormControl fullWidth margin='normal'>
        <FormFormLabel>Name</FormFormLabel>
        <TextField
          size='small'
          {...register(VenueFormFieldsNames.name as Path<TFormValues>)}
          error={!!errors[VenueFormFieldsNames.name]}
          helperText={errors[VenueFormFieldsNames.name] && <>{errors[VenueFormFieldsNames.name].message}</>}
        />
      </FormControl>
      <FormControl fullWidth margin='normal'>
        <FormFormLabel>City</FormFormLabel>
        <TextField
          size='small'
          {...register(VenueFormFieldsNames.city as Path<TFormValues>)}
          error={!!errors[VenueFormFieldsNames.city]}
          helperText={errors[VenueFormFieldsNames.city] && <>{errors[VenueFormFieldsNames.city].message}</>}
        />
      </FormControl>
      <Controller
        control={control}
        name={VenueFormFieldsNames.countryCode as Path<TFormValues>}
        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
          <FormControl error={!!error} fullWidth>
            <FormFormLabel>Country</FormFormLabel>
            <Select size={'small'} labelId={`${name}-label`} id={name} value={value || ''} onChange={onChange}>
              {getTypeOptions({
                options: Countries.map((country) => ({
                  value: country.code,
                  label: country.name,
                })),
              })}
            </Select>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </FormControl>
        )}
      />
    </>
  );
}
