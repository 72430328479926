import { OverlayElementNames } from '../types';
import { ReferenceType } from '../utils/loaders';

export enum OffensiveTacticNames {
  ACCOMPANY_PLAY_TEAM_TOGETHER = 'accompany-play-team-together',
  FINISHING = 'finishing',
  FINISHING_PASS = 'finishing-pass',
  IDENTIFYING_PASSING_LINES_UNDER_PRESSURE = 'identifying-passing-lines-under-pressure',
  LONG_BALL = 'long-ball',
  MOVING_BEHIND_THE_DEFENSIVE_LINE = 'moving-behind-the-defensive-line',
  OCCUPYING_SPACE_IN_THE_BOX = 'occupying-space-in-the-box',
  OPEN_PASSING_LINES_AFTER_LONG_BALL = 'open-passing-lines-after-long-ball',
  OVERCOMING_OPPONENTS_WITH_VERTICAL_PASSES = 'overcoming-opponents-with-vertical-passes',
  POSITIONING_BEHIND_CENTER_BACKS_WHEN_LATERAL_BALLS = 'positioning-behind-center-backs-when-lateral-balls',
  REALIZED_EMERGENCY_SUPPORT = 'realized-emergency-support',
  REALIZED_STRIKER_SUPPORT = 'realized-striker-support',
  REALIZED_FINISHING_SUPPORT = 'realized-finishing-support',
  REALIZED_HORIZONTAL_OVERCOMING_SUPPORT = 'realized-horizontal-overcoming-support',
  REALIZED_VERTICAL_OVERCOMING_SUPPORT = 'realized-vertical-overcoming-support',
  RECEIVING_BETWEEN_LINES = 'receiving-between-lines',
  RECEIVING_POSITIONING_BETWEEN_LINES = 'receiving-positioning-between-lines',
  PASSING_BETWEEN_LINES = 'passing-between-lines',
  SPACE_BETWEEN_DEFENSIVE_LINE_AND_HALFWAY_LINE = 'space-between-defensive-line-and-halfway-line',
  SUPPORTS = 'supports',
  SWITCH_OF_PLAY = 'switch-of-play',
  BALANCE_OF_THE_TEAM_AFTER_RECOVERY = 'balance-of-the-team-after-recovery',
  TAKING_ADVANTAGE_OF_DEFENSIVE_LINE_IMBALANCES = 'taking-advantage-of-defensive-line-imbalances',
  WIDTH_OF_THE_TEAM = 'width-of-the-team',
  WIDTH_OF_THE_TEAM_OPPOSITE_CHANNEL = 'width-of-the-team-opposite-channel',
  GOAL_KICK_START_LONG_OUTSIDE_CHANNELS = 'goal-kick-start-long-outside-channels',
  GOAL_KICK_START_LONG_INSIDE_CHANNELS = 'goal-kick-start-long-inside-channels',
  GOAL_KICK_START_SHORT_OUTSIDE_CHANNELS = 'goal-kick-start-short-outside-channels',
  GOAL_KICK_START_SHORT_INSIDE_CHANNELS = 'goal-kick-start-short-inside-channels',
  CROSS_INTO_THE_BOX = 'cross-into-the-box',
  FINISHING_AFTER_CROSS = 'finishing-after-cross',
  PROGRESSION_AFTER_RECOVERY = 'progression-after-recovery',
  POSSESSION_AFTER_RECOVERY = 'possession-after-recovery',
  RECEIVE_FOUL_AFTER_RECOVERY = 'receive-foul-after-recovery',
  GOAL_CHANCE = 'goal-chance',
  GOAL = 'goal',
  GOAL_ASSIST = 'goal-assist',
  LOST_BALL = 'lost-ball',
  RUNNING_INTO_THE_BOX = 'running-into-the-box',
  SECOND_BALL_OFFENSIVE_WINNING_AFTER_DIRECT_PLAY = 'second-ball-offensive-winning-after-direct-play',
  SECOND_BALL_OFFENSIVE_WINNING_AFTER_FINISHING = 'second-ball-offensive-winning-after-finishing',
  SECOND_BALL_OFFENSIVE_WINNING_AFTER_CROSS = 'second-ball-offensive-winning-after-cross',
  SECOND_BALL_OFFENSIVE_WINNING_AFTER_SET_PIECE = 'second-ball-offensive-winning-after-set-piece',
  PASS_BEHIND_DEFENSIVE_LINE = 'pass-behind-defensive-line',
}

export enum DefensiveTacticNames {
  BALANCE_OF_THE_TEAM = 'balance-of-the-team',
  BALANCE_OF_THE_TEAM_AFTER_LOSS = 'balance-of-the-team-after-loss',
  COMPACTNESS_OF_TEAM = 'compactness-of-team',
  DEFENDING_AGAINST_THE_POSSESSOR = 'defending-against-the-possessor',
  DEFENSIVE_LINE_IMBALANCE_IN_DEPTH = 'defensive-line-imbalance-in-depth',
  DEFENSIVE_LINE_IMBALANCE_IN_WIDTH = 'defensive-line-imbalance-in-width',
  MARKING_OPPONENTS_INSIDE_THE_BOX = 'marking-opponents-inside-the-box',
  MARKING_SUPPORTS = 'marking-supports',
  NEUTRALIZING_OPPONENT_ADVANTAGE_OF_DEFENSIVE_LINE_IMBALANCE = 'neutralizing-opponent-advantage-of-defensive-line-imbalance',
  HOLD_AFTER_LOSS = 'hold-after-loss',
  PRESS_AFTER_LOSS = 'press-after-loss',
  COMMIT_FOUL_AFTER_LOSS = 'commit-foul-after-loss',
  RECOVERED_BALL = 'recovered-ball',
  TACKLE = 'tackle',
  PRESSURE_ON_THE_BALL_POSSESSOR = 'pressure-on-the-ball-possessor',
  MOVING_FORWARD_DURING_ORGANIZED_PRESSURE = 'moving-forward-during-organized-pressure',
  DEFENDING_MOVING_BEHIND_THE_DEFENSIVE_LINE = 'defending-moving-behind-the-defensive-line',
  DEFENDING_RUNNING_INTO_THE_BOX = 'defending-running-into-the-box',
  CLEAR_THE_BOX = 'clear-the-box',
  SECOND_BALL_DEFENSIVE_WINNING_AFTER_DIRECT_PLAY = 'second-ball-defensive-winning-after-direct-play',
  SECOND_BALL_DEFENSIVE_WINNING_AFTER_FINISHING = 'second-ball-defensive-winning-after-finishing',
  SECOND_BALL_DEFENSIVE_WINNING_AFTER_CROSS = 'second-ball-defensive-winning-after-cross',
  SECOND_BALL_DEFENSIVE_WINNING_AFTER_SET_PIECE = 'second-ball-defensive-winning-after-set-piece',
}

export type OverlayElement = {
  overlayElementTypeId: OverlayElementNames;
  references: {
    referenceType: ReferenceType;
  }[];
};

export type TacticConfig = {
  name: OffensiveTacticNames | DefensiveTacticNames;
  overlayElements: OverlayElement[];
};

export type TacticsConfig = {
  [key in OffensiveTacticNames | DefensiveTacticNames]?: TacticConfig;
};

export const tacticsConfig: TacticsConfig = {
  [OffensiveTacticNames.WIDTH_OF_THE_TEAM]: {
    name: OffensiveTacticNames.WIDTH_OF_THE_TEAM,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.WIDE_PLAYER_HIGHLIGHT,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [DefensiveTacticNames.PRESSURE_ON_THE_BALL_POSSESSOR]: {
    name: DefensiveTacticNames.PRESSURE_ON_THE_BALL_POSSESSOR,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.DEFENDER_PRESSING_POSSESSOR_HIGHLIGHT,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [DefensiveTacticNames.MOVING_FORWARD_DURING_ORGANIZED_PRESSURE]: {
    name: DefensiveTacticNames.MOVING_FORWARD_DURING_ORGANIZED_PRESSURE,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.MOVING_FORWARD_DURING_ORGANIZED_PRESSURE_ARROWS,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [DefensiveTacticNames.DEFENDING_MOVING_BEHIND_THE_DEFENSIVE_LINE]: {
    name: DefensiveTacticNames.DEFENDING_MOVING_BEHIND_THE_DEFENSIVE_LINE,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.DEFENDING_RUN_BEHIND_DEFENSIVE_LINE,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [OffensiveTacticNames.MOVING_BEHIND_THE_DEFENSIVE_LINE]: {
    name: OffensiveTacticNames.MOVING_BEHIND_THE_DEFENSIVE_LINE,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.RUN_BEHIND_DEFENSIVE_LINE,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [DefensiveTacticNames.CLEAR_THE_BOX]: {
    name: DefensiveTacticNames.CLEAR_THE_BOX,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.CLEAR_THE_BOX_DEFENDER_ARROW,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [DefensiveTacticNames.SECOND_BALL_DEFENSIVE_WINNING_AFTER_DIRECT_PLAY]: {
    name: DefensiveTacticNames.SECOND_BALL_DEFENSIVE_WINNING_AFTER_DIRECT_PLAY,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.SECOND_BALL_DEFENSIVE_CONTROLLER_HIGHLIGHT,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [DefensiveTacticNames.SECOND_BALL_DEFENSIVE_WINNING_AFTER_FINISHING]: {
    name: DefensiveTacticNames.SECOND_BALL_DEFENSIVE_WINNING_AFTER_FINISHING,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.SECOND_BALL_DEFENSIVE_CONTROLLER_HIGHLIGHT,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [DefensiveTacticNames.SECOND_BALL_DEFENSIVE_WINNING_AFTER_CROSS]: {
    name: DefensiveTacticNames.SECOND_BALL_DEFENSIVE_WINNING_AFTER_CROSS,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.SECOND_BALL_DEFENSIVE_CONTROLLER_HIGHLIGHT,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [DefensiveTacticNames.SECOND_BALL_DEFENSIVE_WINNING_AFTER_SET_PIECE]: {
    name: DefensiveTacticNames.SECOND_BALL_DEFENSIVE_WINNING_AFTER_SET_PIECE,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.SECOND_BALL_DEFENSIVE_CONTROLLER_HIGHLIGHT,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [OffensiveTacticNames.SECOND_BALL_OFFENSIVE_WINNING_AFTER_DIRECT_PLAY]: {
    name: OffensiveTacticNames.SECOND_BALL_OFFENSIVE_WINNING_AFTER_DIRECT_PLAY,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.SECOND_BALL_OFFENSIVE_CONTROLLER_HIGHLIGHT,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [OffensiveTacticNames.SECOND_BALL_OFFENSIVE_WINNING_AFTER_FINISHING]: {
    name: OffensiveTacticNames.SECOND_BALL_OFFENSIVE_WINNING_AFTER_FINISHING,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.SECOND_BALL_OFFENSIVE_CONTROLLER_HIGHLIGHT,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [OffensiveTacticNames.SECOND_BALL_OFFENSIVE_WINNING_AFTER_CROSS]: {
    name: OffensiveTacticNames.SECOND_BALL_OFFENSIVE_WINNING_AFTER_CROSS,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.SECOND_BALL_OFFENSIVE_CONTROLLER_HIGHLIGHT,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [OffensiveTacticNames.SECOND_BALL_OFFENSIVE_WINNING_AFTER_SET_PIECE]: {
    name: OffensiveTacticNames.SECOND_BALL_OFFENSIVE_WINNING_AFTER_SET_PIECE,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.SECOND_BALL_OFFENSIVE_CONTROLLER_HIGHLIGHT,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [DefensiveTacticNames.DEFENDING_RUNNING_INTO_THE_BOX]: {
    name: DefensiveTacticNames.DEFENDING_RUNNING_INTO_THE_BOX,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.DEFENDING_RUN_INTO_THE_BOX,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [OffensiveTacticNames.RUNNING_INTO_THE_BOX]: {
    name: OffensiveTacticNames.RUNNING_INTO_THE_BOX,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.RUN_INTO_THE_BOX,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [OffensiveTacticNames.PASS_BEHIND_DEFENSIVE_LINE]: {
    name: OffensiveTacticNames.PASS_BEHIND_DEFENSIVE_LINE,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.PASS_BEHIND_DEFENSIVE_LINE_RECEIVER_HIGHLIGHT,
        references: [
          {
            referenceType: ReferenceType.StaticCoordinates,
          },
        ],
      },
      {
        overlayElementTypeId: OverlayElementNames.PASS_BEHIND_DEFENSIVE_LINE_ARROW,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
};
