import * as Sentry from '@sentry/react';
import map from 'lodash/map';
import { matchPath } from 'react-router-dom';

import browserHistory from 'browserHistory';
import { SENTRY_DSN_URL } from 'config';
import { routes } from 'kognia/router/routes';
import { Environments, getEnvironment, getReleaseVersion } from 'shared/utils/environment';

export const initErrorTracker = () => {
  const environment = getEnvironment();
  const sentryRoutes: Array<{ path: string }> = map(routes, (route: string) => ({ path: route }));

  Sentry.init({
    dsn: SENTRY_DSN_URL,
    release: getReleaseVersion(),
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(browserHistory, sentryRoutes, matchPath),
      }),
    ],
    environment,
    enabled: environment !== Environments.Unknown,
    tracesSampleRate: 1.0,
  });
};
