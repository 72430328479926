import classNames from 'classnames';
import React from 'react';

import styles from './FilterColumn.module.scss';

interface Props {
  children: React.ReactNode;
  expand?: boolean;
}

const FilterColumn = ({ children, expand = false }: Props) => {
  return <section className={classNames(styles.container, { [styles.expand]: expand })}>{children}</section>;
};

export default FilterColumn;
