import { Box, styled, TextField } from '@mui/material';
import { Colors, fontSizes, fontWeight } from 'kognia-ui';

export const KeypadCustomButtonForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const KeypadCustomButtonCutBox = styled(Box)(({ theme }) => ({
  background: Colors.shark,
  flex: '1 1 0',
  height: '40px',
  color: Colors.white,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
}));

export const KeypadSelectContainer = styled(Box)(({ theme }) => ({
  flex: '1 1 0',
}));

export const KeypadFormItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 0',
  position: 'relative',
}));

export const KeypadFormRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
}));

export const KeypadOptionalText = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  fontWeight: fontWeight['500'],
  fontSize: fontSizes.keypadOptionalText,
  color: Colors.storm,
  top: '3px',
}));

export const TextFieldExtended = styled(TextField)(({ theme }) => ({
  '&:hover, &:focus': {
    '.MuiBox-root': {
      display: 'none',
    },
  },
}));

export const TimeframeEndAdornment = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  background: Colors.athens,
  color: Colors.storm,
  fontSize: fontSizes.keypadOptionalText,
  fontWeight: fontWeight['500'],
  padding: theme.spacing(0.25, 0.5),
  display: 'flex',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  height: '20px',
}));
