import React from 'react';

import { FormSelectField } from 'shared/components/form/form-select-field';
import { getTypeOptions } from 'shared/components/form/get-type-options';

import { BackofficePipelineType } from '../../../../../api/types';
import { AnnotationFormLabels } from '../../../utils/form-fields-validation';
import { AnnotationFormFieldsNames } from '../../../utils/form-fields-validation/types';
import { FormFieldProps } from '../types';

export const PipelineTypeField = ({ errors = {}, register }: FormFieldProps) => {
  return (
    <FormSelectField
      id={AnnotationFormFieldsNames.PIPELINE_TYPE}
      label={AnnotationFormLabels[AnnotationFormFieldsNames.PIPELINE_TYPE]}
      options={getTypeOptions({ options: Object.values(BackofficePipelineType).map((option) => ({ value: option })) })}
      error={!!errors[AnnotationFormFieldsNames.PIPELINE_TYPE]}
      helperText={
        errors[AnnotationFormFieldsNames.PIPELINE_TYPE] && (
          <>{errors[AnnotationFormFieldsNames.PIPELINE_TYPE]?.message}</>
        )
      }
      defaultValue={''}
      {...register(AnnotationFormFieldsNames.PIPELINE_TYPE)}
    />
  );
};
