import React from 'react';

import styles from './ButtonControls.module.scss';

interface Props {
  children: React.ReactNode;
}

const ButtonControls = ({ children }: Props) => {
  return <div className={styles.controls}>{children}</div>;
};

export default ButtonControls;
