import SvgIcon, { SvgIconProps } from '../svg-icon';

const IconOffense = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M12 3.667A8.34 8.34 0 0 0 3.667 12 8.34 8.34 0 0 0 12 20.333 8.34 8.34 0 0 0 20.333 12 8.34 8.34 0 0 0 12 3.667zm.833 2.75l1.125-.792c1.517.467 2.808 1.467 3.65 2.783l-.325 1.117-1.125.383-3.325-2.325V6.417zm-2.792-.792l1.125.792v1.167L7.842 9.908l-1.125-.383-.325-1.117C7.233 7.1 8.525 6.1 10.042 5.625zM7.9 16.258l-.95.083C5.942 15.175 5.333 13.658 5.333 12c0-.1.008-.192.017-.292l.833-.608 1.15.4 1.217 3.617-.65 1.142zm6.183 2.067c-.658.217-1.358.342-2.083.342s-1.425-.125-2.083-.342l-.575-1.242.533-.917h4.258l.533.925-.583 1.233zm-.192-3.825h-3.783l-1.125-3.35L12 9.033l3.025 2.117-1.133 3.35zm3.158 1.842l-.95-.083-.658-1.142 1.217-3.617 1.158-.392.833.608.017.283c0 1.658-.608 3.175-1.617 4.342z' />
    </SvgIcon>
  );
};

export default IconOffense;
