import { Button, Grid, Typography } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { KeypadTag } from 'api/tagging-tool/types';
import IconDefense from 'shared/components/icons/icon-defense';
import IconOffense from 'shared/components/icons/icon-offense';
import IconPlus from 'shared/components/icons/icon-plus';
import IconTransition from 'shared/components/icons/icon-transition';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';

import { CustomButtonsBox } from './components/custom-buttons-box';
import { CustomButtonsData } from '../keypad-edit/state';

type Props = {
  children?: React.ReactNode;
  data?: CustomButtonsData;
  handleAddNew: () => void;
  handleEdit: (payload: KeypadTag) => void;
  handleRemove: (payload: KeypadTag) => void;
  handleEnabledChange: (tag: KeypadTag, is_enabled: boolean) => void;
};

export const CustomButtonPanel = ({ data, handleEdit, handleAddNew, handleEnabledChange, handleRemove }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid container direction={'column'} spacing={2}>
      <Grid item display='flex' justifyContent='space-between' marginTop={8} alignItems='center'>
        <Typography variant={'h6'}>{t('tagging-tool:keypad.custom-buttons-title')}</Typography>
        <Button
          variant='outlined'
          color='secondary'
          startIcon={<IconPlus size={IconSizes.small} color={IconColors.storm} />}
          onClick={handleAddNew}
        >
          {t('tagging-tool:keypad.add-custom-button')}
        </Button>
      </Grid>
      <Grid item>
        <Typography color={Colors.storm} fontSize={fontSizes.default}>
          {t('tagging-tool:keypad.custom-buttons')}
        </Typography>
      </Grid>
      <Grid item display='flex' gap={1} paddingTop={2}>
        <CustomButtonsBox
          data={data?.offense}
          icon={<IconOffense size={IconSizes.small} color={IconColors.storm} />}
          onEdit={handleEdit}
          onEnabledChange={handleEnabledChange}
          onRemove={handleRemove}
          title={t('tagging-tool:keypad-tag.type-of-play-offense')}
        />
        <CustomButtonsBox
          data={data?.transition}
          icon={<IconTransition size={IconSizes.small} color={IconColors.storm} />}
          onEdit={handleEdit}
          onEnabledChange={handleEnabledChange}
          onRemove={handleRemove}
          title={t('tagging-tool:keypad-tag.type-of-play-transition')}
        />
        <CustomButtonsBox
          data={data?.defense}
          icon={<IconDefense size={IconSizes.small} color={IconColors.storm} />}
          onEdit={handleEdit}
          onEnabledChange={handleEnabledChange}
          onRemove={handleRemove}
          title={t('tagging-tool:keypad-tag.type-of-play-defense')}
        />
      </Grid>
    </Grid>
  );
};
