export const offensiveTactics = [
  'accompany-play-team-together',
  'finishing',
  'finishing-pass',
  'identifying-passing-lines-under-pressure',
  'long-ball',
  'moving-behind-the-defensive-line',
  'occupying-space-in-the-box',
  'open-passing-lines-after-long-ball',
  'overcoming-opponents-with-vertical-passes',
  'positioning-behind-center-backs-when-lateral-balls',
  'realized-emergency-support',
  'realized-striker-support',
  'realized-finishing-support',
  'realized-horizontal-overcoming-support',
  'realized-vertical-overcoming-support',
  'receiving-between-lines',
  'receiving-positioning-between-lines',
  'passing-between-lines',
  'space-between-defensive-line-and-halfway-line',
  'supports',
  'switch-of-play',
  'balance-of-the-team-after-recovery',
  'taking-advantage-of-defensive-line-imbalances',
  'width-of-the-team',
  'width-of-the-team-opposite-channel',
  'goal-kick-start-long-outside-channels',
  'goal-kick-start-long-inside-channels',
  'goal-kick-start-short-outside-channels',
  'goal-kick-start-short-inside-channels',
  'cross-into-the-box',
  'finishing-after-cross',
  'progression-after-recovery',
  'possession-after-recovery',
  'receive-foul-after-recovery',
  'goal-chance',
  'goal',
  'goal-assist',
  'lost-ball',
  'running-into-the-box',
  'second-ball-offensive-winning-after-direct-play',
  'second-ball-offensive-winning-after-finishing',
  'second-ball-offensive-winning-after-cross',
  'second-ball-offensive-winning-after-set-piece',
  'completed-forward-pass',
  'uncompleted-forward-pass',
  'pass-behind-defensive-line',
];

export const defensiveTactics = [
  'balance-of-the-team',
  'balance-of-the-team-after-loss',
  'compactness-of-team',
  'defending-against-the-possessor',
  'defensive-line-imbalance-in-depth',
  'defensive-line-imbalance-in-width',
  'marking-opponents-inside-the-box',
  'marking-supports',
  'neutralizing-opponent-advantage-of-defensive-line-imbalance',
  'hold-after-loss',
  'press-after-loss',
  'commit-foul-after-loss',
  'recovered-ball',
  'tackle',
  'pressure-on-the-ball-possessor',
  'moving-forward-during-organized-pressure',
  'defending-moving-behind-the-defensive-line',
  'defending-running-into-the-box',
  'second-ball-defensive-winning-after-direct-play',
  'second-ball-defensive-winning-after-finishing',
  'second-ball-defensive-winning-after-cross',
  'second-ball-defensive-winning-after-set-piece',
  'clear-the-box',
  'completed-backward-pass',
  'uncompleted-backward-pass',
  'completed-horizontal-pass',
  'uncompleted-horizontal-pass',
];

export const isOffensiveTactic = (tacticId: string) => offensiveTactics.includes(tacticId);
