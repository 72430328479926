import { usePlaylist } from 'api/playlist/usePlaylist';
import { useClientId } from 'shared/contexts/app-state';

import { useMultimatchAppliedFiltersPreset } from '../use-multimatch-applied-filters-preset';

export const usePlaylistPage = ({ playlistId }: { playlistId: string }) => {
  const { isLoading } = useMultimatchAppliedFiltersPreset({ playlistId });
  const { clientId } = useClientId();
  const playlist = usePlaylist({ playlistId });

  const isInvalidClient = clientId !== playlist?.data?.clientId;

  return {
    ...playlist,
    isLoading: isLoading || playlist.isLoading,
    isInvalidClient,
  };
};
