import { FormInputField } from 'shared/components/form/form-input-field';

import { AnnotationFormLabels } from '../../../utils/form-fields-validation';
import { AnnotationFormFieldsNames } from '../../../utils/form-fields-validation/types';
import { FormFieldProps } from '../types';

export const GitCommitField = ({ errors = {}, register }: FormFieldProps) => {
  return (
    <FormInputField
      id={AnnotationFormFieldsNames.GIT_COMMIT}
      label={AnnotationFormLabels[AnnotationFormFieldsNames.GIT_COMMIT]}
      error={!!errors[AnnotationFormFieldsNames.GIT_COMMIT]}
      fullWidth
      helperText={
        errors[AnnotationFormFieldsNames.GIT_COMMIT] && <>{errors[AnnotationFormFieldsNames.GIT_COMMIT]?.message}</>
      }
      InputProps={{ readOnly: true }}
      {...register(AnnotationFormFieldsNames.GIT_COMMIT)}
    />
  );
};
