import SvgIcon, { SvgIconProps } from '../svg-icon';

const IconShield = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M17.65 5.29358h0v-.00363-.11788-.12025-.15h-.15-.1169-.1036-.0768-.0295-.1428H6.61671 6.5h-.15v.15.12025 7.40203c0 2.8299 1.50901 4.745 2.76619 5.8492 1.30591 1.1472 2.59541 1.6763 2.74621 1.7361l.0006.0002.0402.0158h.0002l.0413.0162.0542.0211.0542-.0209.0418-.0161.0008-.0003.0425-.0167.0004-.0001c.1495-.059 1.4388-.5881 2.7449-1.7353 1.2571-1.104 2.7667-3.02 2.7663-5.8493V6.98823v-.00021-1.28851-.00048l-.0002-.31097v-.03002l.0004-.06446zM12.0703 19.3451h0l-.0019.0009-.0689.0314c-.3759-.1663-1.4546-.6921-2.50329-1.6472-.72709-.6629-1.28729-1.3857-1.68137-2.1631l9.07246-9.3338v6.3407.0001c-.0007 2.0288-.8378 3.7973-2.5026 5.2618-.6545.5758-1.309.9818-1.7432 1.2209l-.5712.2883zM7.11273 5.67808h9.24297L7.48672 14.8027c-.2485-.7016-.37383-1.4452-.37399-2.2286v-.0001-6.89592z' />
    </SvgIcon>
  );
};

export default IconShield;
