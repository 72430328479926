import { IconColors } from 'shared/components/icons/svg-icon';
import { TypeOfPlay } from 'shared/types/type-of-play/types';

export const getIconPlayColor = ({
  disabled,
  typeOfPlay,
}: {
  disabled: boolean;
  typeOfPlay: TypeOfPlay;
}): IconColors => {
  if (disabled) {
    return IconColors.iron;
  }

  switch (typeOfPlay) {
    case TypeOfPlay.Offense:
      return IconColors.primary;
    case TypeOfPlay.Defense:
      return IconColors.green;
    case TypeOfPlay.Transition:
      return IconColors.peach;
    case TypeOfPlay.GameEvent:
      return IconColors.shark;
    default:
      return IconColors.default;
  }
};
