import { Box, Card, CardContent, List, Stack, Typography } from '@mui/material';
import { Colors, fontWeight } from 'kognia-ui';

import { MetadataRecordingSummary } from '../../../../types/recordings';
import { GamerRecording } from '../game-recording';
import { GridListItemRecording } from '../grid-list-item-recording';

type Props = {
  recordings: MetadataRecordingSummary[];
  gameId: string;
};

export const GameRecordingsList = ({ recordings, gameId }: Props) => {
  return (
    <Card>
      <CardContent>
        <Stack gap={1}>
          <Typography variant='h6'>Recordings</Typography>
          <List>
            <GridListItemRecording sx={{ fontWeight: fontWeight['500'], background: Colors.background }}>
              <Box>Id</Box>
              <Box>client Id</Box>
            </GridListItemRecording>
            {recordings.map((recording) => (
              <GamerRecording gameId={gameId} key={recording.id} recording={recording} />
            ))}
          </List>
        </Stack>
      </CardContent>
    </Card>
  );
};
