import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useBackendApi } from 'api/hooks/useBackendApi';
import { transformPlaylist } from 'api/playlist/transformers';
import { playlistUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { NotificationType, useNotifications } from 'shared/hooks/notifications';

import { PlaylistApiResponse } from '../types';
import { usePlaylist } from '../usePlaylist';

type UpdatePlaylistParams = {
  name: string;
  onSuccess?: (data: unknown) => void;
};

type PlaylistUpdate = {
  name: string;
};

export const useUpdatePlaylist = (
  playlistId: string,
  onSuccess?: () => void,
  onError?: () => void,
  onSettled?: () => void,
) => {
  const { t } = useTranslation();
  const { setQueryData } = usePlaylist({ playlistId });
  const onPatchSuccess = (data: PlaylistApiResponse) => transformPlaylist(data);
  const patchUrl = playlistUrl(playlistId);
  const triggerNotification = useNotifications();
  const updatePlaylist = useMutation((params) => useBackendApi(patchUrl, HTTPMethod.PATCH, onPatchSuccess, params), {
    onMutate: async (params: PlaylistUpdate) => {},
    onError: () => {
      if (onError) onError();
      triggerNotification({ type: NotificationType.ERROR, message: t('api:use-update-playlist.error') });
    },
    onSuccess: (updatedPlaylist) => {
      setQueryData(updatedPlaylist);
      if (onSuccess) onSuccess();
    },
    onSettled: () => {
      if (onSettled) onSettled();
    },
  });

  const updatePlaylistName = ({ name, onSuccess }: UpdatePlaylistParams) => {
    const updateParams = <PlaylistUpdate>{ name: name };
    updatePlaylist.mutate(updateParams, { onSuccess: onSuccess });
  };

  return { updatePlaylistName, isLoading: updatePlaylist.isLoading };
};
