import classNames from 'classnames';
import { ReactNode } from 'react';

import LinkWithExternal from 'kognia/router/link-with-external';

import styles from './SolutionCard.module.scss';

export enum SolutionsVariants {
  TAGGING_TOOL = 'taggingTool',
  LIVE_RECORDINGS = 'liveRecordings',
  RECORDINGS = 'recordings',
  PLAYLISTS = 'playlists',
}

interface Props {
  variant: SolutionsVariants;
  children: ReactNode;
  disabled?: boolean;
  to: string;
}

const SolutionCard = ({ children, variant, disabled, to }: Props) => {
  return (
    <div
      className={classNames(styles.solution, styles[variant], {
        [styles.disabled]: disabled,
      })}
    >
      <LinkWithExternal
        onClick={disabled ? (event) => event.preventDefault() : undefined}
        to={to}
        data-testid={`solution-card-${variant}`}
      >
        {children}
      </LinkWithExternal>
    </div>
  );
};

export default SolutionCard;
