import isEqual from 'lodash/isEqual';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LiveTaggingSession } from 'api/tagging-tool/types';
import Button, { ButtonTypes, ButtonSizes, ButtonPadding } from 'shared/components/button';
import Input from 'shared/components/input';

import styles from './LiveTaggingSessionEditForm.module.scss';

type LiveTaggingSessionEditFormProps = {
  initialData: LiveTaggingSession;
  onCancel: () => void;
  onSubmit: (params: string) => void;
};

const LiveTaggingSessionEditForm = ({ initialData, onCancel, onSubmit }: LiveTaggingSessionEditFormProps) => {
  const { t } = useTranslation();
  const [liveTaggingSessionData, setLiveTaggingSessionData] = useState(initialData);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLiveTaggingSessionData({ ...liveTaggingSessionData, ...{ name: event.target.value } });
  };

  return (
    <>
      <h3 className={styles.pageHeader}>{t('recording-edit:form.title')}</h3>
      <div className={styles.formContainer}>
        <Input
          id='name'
          label={t('recording-edit:form.labels.recording-name')}
          placeholder='Input Text'
          onChange={onChange}
          value={liveTaggingSessionData.name}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          id='cancel'
          type={ButtonTypes.SecondaryNight}
          btnSize={ButtonSizes.M}
          padding={ButtonPadding.P16}
          onClick={onCancel}
        >
          {t('common:actions.cancel')}
        </Button>
        <Button
          id='submit'
          type={ButtonTypes.Primary}
          btnSize={ButtonSizes.M}
          padding={ButtonPadding.P16}
          disabled={isEqual(initialData, liveTaggingSessionData)}
          onClick={() => {
            onSubmit(liveTaggingSessionData.name);
          }}
        >
          {t('common:actions.update')}
        </Button>
      </div>
    </>
  );
};

export default LiveTaggingSessionEditForm;
