import React, { forwardRef, PropsWithChildren } from 'react';

import styles from './TimelineContentHeaderContainer.module.scss';

export const TimelineContentHeaderContainer = forwardRef(({ children }: PropsWithChildren, ref: any) => (
  <div ref={ref} className={styles.timelineContentHeaderContainer}>
    {children}
  </div>
));

TimelineContentHeaderContainer.displayName = 'TimelineContentHeaderContainer';
