import { MenuItem } from '@mui/material';
import Menu from '@mui/material/Menu';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAddManyToPlaylist } from 'api/playlist/useAddManyToPlaylist';
import { invalidatePlaylistsQuery } from 'api/playlist/useFetchPlaylists';
import IconClose from 'shared/components/icons/icon-close';
import IconFolder from 'shared/components/icons/icon-folder';
import IconPlay from 'shared/components/icons/icon-play';
import { IconSelect } from 'shared/components/icons/icon-select';
import { IconSelectAll } from 'shared/components/icons/icon-select-all';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import { SelectPlaylistDialog } from 'shared/components/select-playlist-dialog';
import { useCurrentPlaylistItem } from 'shared/components/video-player';
import { FundamentalsSelection, Playlist } from 'shared/types/index';

import styles from './ClipActions.module.scss';
import { Clip } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows';
import {
  useCleanSelection,
  useClipsIdsSelection,
  useGetClipsList,
} from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows/atoms';
import { useTacticalAnalysisMode } from '../../../../../hooks/use-tactical-analysis-mode';
import { useGeneratePlaylistItemName } from '../../hooks/use-generate-playlist-item-name';
import { UseTimelineEventsHandlers } from '../../hooks/use-timeline-events-handlers/types';

type Props = {
  clip: Clip;
  menuAnchorEl: HTMLDivElement | null;
  onClose: () => void;
  isSelected: boolean;
  recordingId: string;
  playClip: UseTimelineEventsHandlers['handlePlayClip'];
  playSelectedClips: UseTimelineEventsHandlers['handlePlaySelectedClips'];
  selectClip: UseTimelineEventsHandlers['handleSelectClip'];
  deselectClip: UseTimelineEventsHandlers['handleDeselectClip'];
  selectRow: UseTimelineEventsHandlers['handleSelectRowClips'];
};

interface HandleSelectPlaylist {
  (playlist: Playlist | null, isMultipleClipsMode?: boolean): void;
}

export const ClipActions = ({
  menuAnchorEl,
  clip,
  onClose,
  recordingId,
  selectClip,
  deselectClip,
  selectRow,
  playClip,
  playSelectedClips,
  isSelected,
}: Props) => {
  const generatePlaylistItemName = useGeneratePlaylistItemName(recordingId);
  const { t } = useTranslation();
  const { addManyToPlaylist } = useAddManyToPlaylist();
  const currentPlaylistItem = useCurrentPlaylistItem();
  const getClips = useGetClipsList();
  const clipIdsSelection = useClipsIdsSelection();
  const [anchorSingleClipSubmenu, setAnchorSingleClipSubmenu] = useState<HTMLElement | null>(null);
  const [anchorMultipleClipsSubmenu, setAnchorMultipleClipsSubmenu] = useState<HTMLElement | null>(null);
  const tacticalAnalysisMode = useTacticalAnalysisMode(recordingId);
  const clearSelection = useCleanSelection();

  const areMultipleClipsSelectedWithCurrentClip = clipIdsSelection.length > 1 && clipIdsSelection.includes(clip.id);
  const selectedClipsCount = clipIdsSelection.length;
  const showPlaySelectedOption = tacticalAnalysisMode === 'default' && areMultipleClipsSelectedWithCurrentClip;

  const handleOpenSingleClipSubmenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorSingleClipSubmenu(event.currentTarget);
  }, []);

  const handleOpenMultipleClipSubmenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorMultipleClipsSubmenu(event.currentTarget);
  }, []);

  const closeAllMenus = useCallback(() => {
    onClose();
    setAnchorMultipleClipsSubmenu(null);
    setAnchorSingleClipSubmenu(null);
  }, [onClose]);

  const handleClickAway = useCallback(
    (event: MouseEvent | TouchEvent) => {
      event.stopPropagation();
      closeAllMenus();
    },
    [closeAllMenus],
  );

  const handleClose = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      closeAllMenus();
    },
    [closeAllMenus],
  );

  const handleSelectPlaylist: HandleSelectPlaylist = useCallback(
    (playlist, isMultipleClipsMode) => {
      if (playlist === null || Array.isArray(playlist)) return;
      const fundamentalsSelected: FundamentalsSelection = currentPlaylistItem.hasHomographies
        ? currentPlaylistItem.fundamentalsSelected
        : {
            tacticalAnalysisId: currentPlaylistItem.fundamentalsSelected.tacticalAnalysisId,
            fundamentalsSelected: ['all'],
          };
      const clips = clipIdsSelection.includes(clip.id) && isMultipleClipsMode ? getClips(clipIdsSelection) : [clip];
      const clipsToAdd = clips.map((clip) => ({
        startTime: clip.startTime,
        endTime: clip.endTime,
        playlistId: playlist.id,
        name: generatePlaylistItemName(clip),
        recordingId,
        fundamentalsSelected,
      }));

      addManyToPlaylist({
        items: clipsToAdd,
        options: {
          onSuccess: invalidatePlaylistsQuery,
        },
      });
      closeAllMenus();
    },
    [
      currentPlaylistItem.hasHomographies,
      currentPlaylistItem.fundamentalsSelected,
      clipIdsSelection,
      clip,
      getClips,
      addManyToPlaylist,
      closeAllMenus,
      generatePlaylistItemName,
      recordingId,
    ],
  );

  const handlePlayClip = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      playClip(clip.startTime);
      handleClose(event);
    },
    [playClip, clip.startTime, handleClose],
  );

  const handlePlaySelectedClips = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      playSelectedClips();
      handleClose(event);
    },
    [playSelectedClips, handleClose],
  );

  const handleSelectClip = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      selectClip({ clipId: clip.id, ctrlKey: event.ctrlKey, shiftKey: event.shiftKey, metaKey: event.metaKey });
      handleClose(event);
    },
    [clip.id, handleClose, selectClip],
  );

  const handleDeselectClip = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      deselectClip(clip.id);
      handleClose(event);
    },
    [deselectClip, clip.id, handleClose],
  );

  const handleClearSelection = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      clearSelection();
      handleClose(event);
    },
    [clearSelection, handleClose],
  );

  const handleSelectRow = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      selectRow({ rowId: clip.rowId, ctrlKey: event.ctrlKey });
      handleClose(event);
    },
    [selectRow, clip.rowId, handleClose],
  );

  return (
    <>
      <Menu
        open
        anchorEl={menuAnchorEl}
        onClose={handleClickAway}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClick={(event) => event.stopPropagation()}
      >
        <MenuItem className={styles.menuTitle} disabled>
          {t('timeline:clip-actions.single-clip')}
        </MenuItem>
        <MenuItem className={styles.menuItem} onClick={handleOpenSingleClipSubmenu}>
          <IconFolder color={IconColors.storm} size={IconSizes.small} /> {t('timeline:actions.add-to-playlist')}
        </MenuItem>
        <MenuItem className={styles.menuItem} onClick={handlePlayClip}>
          <IconPlay color={IconColors.storm} size={IconSizes.small} /> {t('timeline:actions.play-clip')}
        </MenuItem>
        {!isSelected ? (
          <MenuItem className={styles.menuItem} onClick={handleSelectClip}>
            <IconSelect color={IconColors.storm} size={IconSizes.small} /> {t('timeline:actions.select-clip')}
          </MenuItem>
        ) : null}
        {isSelected ? (
          <MenuItem className={styles.menuItem} onClick={handleDeselectClip}>
            <IconClose color={IconColors.storm} size={IconSizes.small} /> {t('timeline:actions.deselect-clip')}
          </MenuItem>
        ) : null}
        {areMultipleClipsSelectedWithCurrentClip ? (
          <>
            <MenuItem
              className={classNames(styles.menuTitle, styles.menuItemDividerTop, styles.menuItemSpaceTop)}
              disabled
            >
              {t('timeline:clip-actions.selected-clips')}
            </MenuItem>
            <MenuItem className={styles.menuItem} onClick={handleOpenMultipleClipSubmenu}>
              <IconFolder color={IconColors.storm} size={IconSizes.small} />
              {t('timeline:actions.add-all-selected-to-playlist', { selectedClipsCount })}
            </MenuItem>
            {showPlaySelectedOption ? (
              <MenuItem className={styles.menuItem} onClick={handlePlaySelectedClips}>
                <IconPlay color={IconColors.storm} size={IconSizes.small} />
                {t('timeline:actions.play-all-selected', { selectedClipsCount })}
              </MenuItem>
            ) : null}
            <MenuItem className={styles.menuItem} onClick={handleClearSelection}>
              <IconClose color={IconColors.storm} size={IconSizes.small} />{' '}
              {t('timeline:actions.deselect-all-clips', { selectedClipsCount })}
            </MenuItem>
          </>
        ) : null}
        {clip.type !== 'episode' ? (
          <MenuItem className={classNames(styles.menuItem, styles.menuItemDividerTop)} onClick={handleSelectRow}>
            <IconSelectAll color={IconColors.storm} size={IconSizes.small} />
            {t('timeline:actions.select-clips-in-row')}
          </MenuItem>
        ) : null}
      </Menu>
      {anchorSingleClipSubmenu ? (
        <SelectPlaylistDialog
          anchorEl={anchorSingleClipSubmenu}
          onClose={handleClose}
          onClickItem={(playlist) => handleSelectPlaylist(playlist)}
        />
      ) : null}
      {anchorMultipleClipsSubmenu ? (
        <SelectPlaylistDialog
          anchorEl={anchorMultipleClipsSubmenu}
          onClose={handleClose}
          onClickItem={(playlist) => handleSelectPlaylist(playlist, true)}
        />
      ) : null}
    </>
  );
};
