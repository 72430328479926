import { useTranslation } from 'react-i18next';

import Button, { ButtonTypes, ButtonSizes } from 'shared/components/button';

import styles from './filterControls.module.scss';

export interface FilterDropdownControlsProps {
  isResetDisabled?: boolean;
  onClickReset?: () => void;
  onClickApply: () => void;
  showApplyButton?: boolean;
  showResetButton?: boolean;
}

const FilterControls = (props: FilterDropdownControlsProps) => {
  const { t } = useTranslation();
  const { isResetDisabled = false, onClickReset, onClickApply, showApplyButton = true, showResetButton = true } = props;

  const handleResetClick = () => {
    onClickReset && onClickReset();
  };

  const containerClass =
    showApplyButton && showResetButton ? styles.controlsContainerMultiple : styles.controlsContainerSingle;

  return (
    <div className={containerClass}>
      {showResetButton && (
        <Button type={ButtonTypes.Reset} btnSize={ButtonSizes.S} disabled={isResetDisabled} onClick={handleResetClick}>
          {t('common:actions.reset-filter')}
        </Button>
      )}
      {showApplyButton && (
        <Button type={ButtonTypes.SimpleAccent} btnSize={ButtonSizes.S} onClick={() => onClickApply()}>
          {t('common:actions.apply')}
        </Button>
      )}
    </div>
  );
};

export default FilterControls;
