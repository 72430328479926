import { IconUser } from 'shared/components/icons/icon-user';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';

import { CoachPhotoSkeletonContainer } from './styled';

interface Props {
  small?: boolean;
}

export const CoachPhotoSkeleton = ({ small }: Props) => {
  return (
    <CoachPhotoSkeletonContainer small={small}>
      <IconUser color={IconColors.storm} size={small ? IconSizes.xsmall : IconSizes.medium} />
    </CoachPhotoSkeletonContainer>
  );
};
