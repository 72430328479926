import { styled } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';

import { IconArrowLeft } from 'shared/components/icons/icon-arrow-left';
import { IconArrowRight } from 'shared/components/icons/icon-arrow-right';
import IconMinus from 'shared/components/icons/icon-minus';
import IconPlus from 'shared/components/icons/icon-plus';
import { IconScrollDown } from 'shared/components/icons/icon-scroll-down';
import { IconScrollUp } from 'shared/components/icons/icon-scroll-up';
import { IconSizes } from 'shared/components/icons/svg-icon';

import { KeyboardKeyOptions } from '../../types/keyboard-key-options';

const KeyboardKeyContainer = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '32px',
  backgroundColor: theme.palette.background.default,
  color: Colors.night,
  fontSize: fontSizes.small,
  padding: theme.spacing(0, 1),
  border: `1px solid ${Colors.storm}`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

interface Props {
  keyItem: KeyboardKeyOptions;
}

export const KeyboardKey = ({ keyItem }: Props) => {
  switch (keyItem) {
    case KeyboardKeyOptions.SpaceBar:
      return <KeyboardKeyContainer>Space bar</KeyboardKeyContainer>;
    case KeyboardKeyOptions.ArrowRight:
      return (
        <KeyboardKeyContainer>
          <IconArrowLeft size={IconSizes.xsmall} />
        </KeyboardKeyContainer>
      );
    case KeyboardKeyOptions.ArrowLeft:
      return (
        <KeyboardKeyContainer>
          <IconArrowRight size={IconSizes.xsmall} />
        </KeyboardKeyContainer>
      );
    case KeyboardKeyOptions.Control:
      return <KeyboardKeyContainer>Ctrl</KeyboardKeyContainer>;
    case KeyboardKeyOptions.Command:
      return <KeyboardKeyContainer>Cmd</KeyboardKeyContainer>;
    case KeyboardKeyOptions.Alt:
      return <KeyboardKeyContainer>Alt</KeyboardKeyContainer>;
    case KeyboardKeyOptions.Plus:
      return (
        <KeyboardKeyContainer>
          <IconPlus size={IconSizes.xsmall} />
        </KeyboardKeyContainer>
      );
    case KeyboardKeyOptions.Minus:
      return (
        <KeyboardKeyContainer>
          <IconMinus size={IconSizes.xsmall} />
        </KeyboardKeyContainer>
      );
    case KeyboardKeyOptions.ScrollDown:
      return <IconScrollDown size={IconSizes.small} />;
    case KeyboardKeyOptions.ScrollUp:
      return <IconScrollUp size={IconSizes.small} />;
    case KeyboardKeyOptions.c:
      return <KeyboardKeyContainer>c</KeyboardKeyContainer>;
    case KeyboardKeyOptions.o:
      return <KeyboardKeyContainer>o</KeyboardKeyContainer>;
    case KeyboardKeyOptions.f:
      return <KeyboardKeyContainer>f</KeyboardKeyContainer>;
    case KeyboardKeyOptions.QuestionMark:
      return <KeyboardKeyContainer>?</KeyboardKeyContainer>;
    default:
      return null;
  }
};
