import classNames from 'classnames';

import styles from './TeamRowHighlight.module.scss';
import { Team } from '../../../types';

export const TeamRowHighlight = ({ team }: { team: Team }) => {
  return (
    <div
      className={classNames(styles.teamHomeHighlight, {
        [styles.isHomeTeam]: team === 'home',
        [styles.isOpponentTeam]: team === 'opponent',
      })}
    />
  );
};
