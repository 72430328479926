import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

import { GRAFANA_FARO_COLECTOR_URL } from 'config';
import { getEnvironment, getReleaseVersion } from 'shared/utils/environment';

export const initMetricsCollector = () => {
  initializeFaro({
    url: GRAFANA_FARO_COLECTOR_URL,
    app: {
      name: 'Kognia App',
      version: getReleaseVersion(),
      environment: getEnvironment(),
    },
    sessionTracking: {
      enabled: false,
    },
    instrumentations: [
      // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
      ...getWebInstrumentations(),

      // Initialization of the tracing package.
      // These packages are optional because it increases the bundle size noticeably.
      // Only add it if you want tracing data.
      new TracingInstrumentation(),
    ],
  });
};
