import React from 'react';

import { FormInputField } from 'shared/components/form/form-input-field';

import { AnnotationFormLabels } from '../../../utils/form-fields-validation';
import { AnnotationFormFieldsNames } from '../../../utils/form-fields-validation/types';
import { FormFieldProps } from '../types';

export const StartIxField = ({ errors = {}, register }: FormFieldProps) => {
  return (
    <FormInputField
      id={AnnotationFormFieldsNames.START_IX}
      label={AnnotationFormLabels[AnnotationFormFieldsNames.START_IX]}
      error={!!errors[AnnotationFormFieldsNames.START_IX]}
      fullWidth
      helperText={
        errors[AnnotationFormFieldsNames.START_IX] && <>{errors[AnnotationFormFieldsNames.START_IX]?.message}</>
      }
      {...register(AnnotationFormFieldsNames.START_IX)}
    />
  );
};
