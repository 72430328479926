import React from 'react';

import styles from './ActiveFilters.module.scss';

interface Props {
  children: React.ReactNode;
}

const ActiveFilters = ({ children }: Props) => {
  return <section className={styles.container}>{children}</section>;
};

export default ActiveFilters;
