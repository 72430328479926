import { useCallback } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { useSaveUserPreset } from 'api/user-presets/use-save-user-preset';
import { TimelineZoomPreset } from 'shared/types/user-preset/types';

import { timelineAtoms } from '../../atoms';
import { ZOOM_LEVELS_TYPE, ZOOM_LEVELS_VALUES } from '../../components/tactical-analysis/zoom-range';

export const useSetTimelineZoomLevel = () => {
  return useSetRecoilState(timelineAtoms.zoomLevel);
};

export const useTimelineZoomLevel = () => {
  const { savePreset } = useSaveUserPreset();
  const [zoomLevel, setZoomLevel] = useRecoilState(timelineAtoms.zoomLevel);

  const handleSaveZoomLevelPreset = useCallback(
    (value: number) => {
      const zoomPreset = new TimelineZoomPreset(value);
      savePreset({ data: zoomPreset });
    },
    [savePreset],
  );

  const setZoomLevelAndCenterPlayHead = useCallback(
    (value: ZOOM_LEVELS_TYPE) => {
      setZoomLevel(value);
      handleSaveZoomLevelPreset(value);
    },
    [handleSaveZoomLevelPreset, setZoomLevel],
  );

  const handleLessZoomLevel = useCallback(() => {
    setZoomLevel((value) => {
      const previous = value - 1;
      if (previous < 0) return value;

      handleSaveZoomLevelPreset(previous);

      return previous;
    });
  }, [handleSaveZoomLevelPreset, setZoomLevel]);

  const handleMoreZoomLevel = useCallback(() => {
    setZoomLevel((value) => {
      const next = value + 1;
      if (next > Math.max(...ZOOM_LEVELS_VALUES)) return value;
      handleSaveZoomLevelPreset(next);

      return next;
    });
  }, [handleSaveZoomLevelPreset, setZoomLevel]);

  return {
    zoomLevel,
    setZoomLevel: setZoomLevelAndCenterPlayHead,
    lessZoomLevel: handleLessZoomLevel,
    moreZoomLevel: handleMoreZoomLevel,
  };
};
