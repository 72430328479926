import { Fade } from '@mui/material';
import React, { useRef } from 'react';

import { useVideoPlayerIsInStandBy } from 'shared/components/video-player/hooks';

import styles from './ProgressBar.module.scss';
import { useCurrentTime, useDuration, useIsBuffering, useIsSeeking, useVideoPlayerActions } from '../../../index';

const ProgressBar = () => {
  const ref = useRef<HTMLDivElement>(null);
  const duration = useDuration();
  const currentTime = useCurrentTime();
  const isBuffering = useIsBuffering();
  const isSeeking = useIsSeeking();
  const isInStandBy = useVideoPlayerIsInStandBy();
  const actions = useVideoPlayerActions();

  const handleJumpToPercent = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const isDisabled = isInStandBy || isSeeking;

    event.stopPropagation();
    if (!ref.current || isDisabled) return;

    const clientRect = ref.current.getBoundingClientRect();
    const pageX = event.pageX;
    const clickPosition = pageX - clientRect.x;
    actions.jumpToTimePercent((100 * clickPosition) / ref.current.clientWidth);
  };

  const showBuffering = isBuffering || isSeeking;

  return (
    <div className={styles.container} ref={ref} onClick={handleJumpToPercent}>
      <div className={styles.content}>
        <Fade unmountOnExit in={showBuffering} exit={!showBuffering}>
          <div className={styles.buffering} />
        </Fade>
        <div className={styles.bar} style={{ width: `${(100 * currentTime) / duration}%` }} />
      </div>
    </div>
  );
};

export default ProgressBar;
