import { Button, Grid, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  invalidateLiveSessionsWithFiltersQuery,
  useLiveSessionsWithFilters,
} from 'api/tagging-tool/use-live-sessions-with-filters';
import RecordingsList from 'pages/recordings-list/components/recordings-list';
import { DateRange } from 'shared/components/date-range';
import IconPlus from 'shared/components/icons/icon-plus';
import IconStrawberry from 'shared/components/icons/icon-strawberry';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import { ListTitle } from 'shared/components/list/list-title';
import { ListTitleContainer } from 'shared/components/list/list-title-container';
import Pagination from 'shared/components/pagination';
import { useDates } from 'shared/hooks/use-dates';
import { fetchCreateLiveTaggingRecording } from 'tagging-tool/service/taggingRecording.service';
import { dateToPrintableDateTimeString } from 'tagging-tool/utility/date';
import { makeRecordingDetailRoute } from 'tagging-tool/utility/navigation';

import { LiveSessionsFilterNames } from '../../../../api/tagging-tool/use-live-sessions-with-filters/hooks/use-live-sessions-filters';

export const TaggingRecordingList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [openFilter, setOpenFilter] = useState<LiveSessionsFilterNames>();
  const { data, isFetchingNextPage, filters, updateFilters } = useLiveSessionsWithFilters();
  const { parseDateForApi } = useDates();

  const handleNewLiveRecording = useCallback(() => {
    const now = new Date();
    const name = dateToPrintableDateTimeString(now);
    const date = now.toISOString();
    const start_recording_time = now.getTime();

    fetchCreateLiveTaggingRecording({
      name,
      date,
      startTime: start_recording_time,
    }).then((res) => {
      if (res.error) {
        return;
      }

      setTimeout(() => {
        history.push(
          makeRecordingDetailRoute({
            recordingId: res.data.id,
            showsKeypad: true,
            isLive: true,
          }),
        );
      }, 0);
    });
  }, [history]);

  const handleDateRangeClose = useCallback(
    (start: Date | null, end: Date | null) => {
      updateFilters({
        from: start ? parseDateForApi(start) : start,
        to: end ? parseDateForApi(end) : end,
      });
      setOpenFilter(undefined);
    },
    [parseDateForApi, updateFilters],
  );

  return (
    <Grid container direction={'column'} spacing={4}>
      <Grid container item justifyContent={'flex-end'} alignItems={'center'} spacing={1}>
        <Grid item>
          <Button
            variant='contained'
            onClick={handleNewLiveRecording}
            startIcon={<IconPlus size={IconSizes.small} color={IconColors.white} />}
          >
            {t('tagging-tool:tagging-recording.new-live-tag')}
          </Button>
        </Grid>
      </Grid>
      <Grid item container justifyContent={'space-between'} alignItems={'center'}>
        <Grid item container alignItems={'center'} spacing={1} xs={4}>
          <ListTitleContainer>
            <ListTitle dataTestId='page-title'>{t('tagging-tool:tagging-recording.recordings-title')}</ListTitle>
          </ListTitleContainer>
        </Grid>
        <Grid item container spacing={4} xs={8} justifyContent={'flex-end'}>
          <Grid display='flex' item alignItems={'center'}>
            <IconStrawberry color={IconColors.default} size={IconSizes.small} />
            <Typography fontSize={fontSizes.small}>{t('tagging-tool:tagging-recording.filter-by')}</Typography>
          </Grid>
          <Grid item display='flex' gap={2}>
            <DateRange
              from={filters.from}
              to={filters.to}
              isOpen={openFilter === LiveSessionsFilterNames.from}
              onClick={() => setOpenFilter(LiveSessionsFilterNames.from)}
              hasSelectedOptions={Boolean(filters.from) && Boolean(filters.to)}
              onClose={handleDateRangeClose}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <RecordingsList
          isLoading={!data.liveSessions}
          onDeleteSuccess={invalidateLiveSessionsWithFiltersQuery}
          recordings={data.liveSessions ? data.liveSessions : []}
        />
        <Pagination
          total={data.totalElements}
          displayed={data.liveSessions.length}
          onShowMore={data.fetchNextPage}
          loading={isFetchingNextPage}
          getStatsText={(displayed, total) => t('recordings-list:pagination.total', { displayed, total, count: total })}
        />
      </Grid>
    </Grid>
  );
};
