import { Grid } from '@mui/material';
import React from 'react';

import { CheckboxWithColor } from 'shared/components/checkbox-with-color';
import { IconColors } from 'shared/components/icons/svg-icon';
import { useDates } from 'shared/hooks/use-dates';
import { RecordingByName } from 'shared/types/recording/types';

import styles from './RecordingResult.module.scss';

interface ResultsProps {
  recording: RecordingByName;
  isChecked: boolean;
}

export const RecordingResult = ({ recording, isChecked }: ResultsProps) => {
  const { dateToString } = useDates();

  const isScoreAvailable = recording.homeTeamScore !== null && recording.awayTeamScore !== null;

  return (
    <Grid className={styles.result} container justifyContent='space-between' alignItems='center'>
      <Grid item>
        <Grid container spacing={1} alignItems='center'>
          <Grid item>
            <CheckboxWithColor checked={isChecked} onChange={() => {}} customColor={IconColors.primary} />
          </Grid>
          <Grid item>
            <div className={styles.details}>
              <div title={recording.name} className={styles.name}>
                {recording.name}
              </div>
              {isScoreAvailable && (
                <div className={styles.score}>
                  ({recording.homeTeamScore}-{recording.awayTeamScore})
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <span className={styles.date}>{dateToString(recording.date)}</span>
      </Grid>
    </Grid>
  );
};
