import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  RecordingFiltersScenariosOrTacticsInside,
  RecordingFiltersTactic,
  RecordingFiltersTactics,
  RecordingsFilters,
} from 'shared/types/recording/types';
import { TacticsVariants } from 'shared/types/tactics/types';

import {
  TacticalAnalysesPlaylistItemsAPI,
  TacticalAnalysesPlaylistItemsData,
  TacticalAnalysesPlaylistItemsResponse,
} from './types';
import { useMutationRequest } from '../../hooks/useMutationRequest';
import { transformPlaylistItem } from '../../playlist/transformers';
import { tacticalAnalysesWithFilters } from '../../routes';
import { HTTPMethod } from '../../types';
import { transformFilters, transformFiltersForRequest } from '../utils';

export const TIMELINE_DEFAULT_FILTERS = {
  teams: [],
  eventsStarting: new Set([]),
  eventsEnding: new Set([]),
  scenarios: [],
  tactics: { offensive: [], defensive: [] },
};

const placeHolderData: TacticalAnalysesPlaylistItemsData = {
  playlistItems: [],
  filters: TIMELINE_DEFAULT_FILTERS,
};

export const defaultRecordingsFiltersEvents = { teams: [], event: '', zones: [], players: [] };
export const defaultRecordingsFiltersTactic = (category: TacticsVariants): RecordingFiltersTactic => ({
  tacticalFundamentalType: '',
  teamIds: [],
  playerIds: [],
  category,
});

export const defaultRecordingsFiltersTactics: RecordingFiltersTactics = {
  offensive: [defaultRecordingsFiltersTactic(TacticsVariants.OFFENSIVE)],
  defensive: [defaultRecordingsFiltersTactic(TacticsVariants.DEFENSIVE)],
};

export const defaultRecordingFiltersScenariosOrTacticsInside: RecordingFiltersScenariosOrTacticsInside = {
  teams: [],
  zones: [],
  scenario: '',
  tactics: defaultRecordingsFiltersTactics,
};

export const useTacticalAnalysesPlaylistItems = (): TacticalAnalysesPlaylistItemsResponse => {
  const { t } = useTranslation();

  const { mutate, isLoading, isError, isSuccess, data } = useMutationRequest<
    TacticalAnalysesPlaylistItemsAPI,
    TacticalAnalysesPlaylistItemsData
  >({
    type: HTTPMethod.POST,
    errorMessage: t('api:use-tactical-analysis-filter.error'),
    transformer: (data: TacticalAnalysesPlaylistItemsAPI): TacticalAnalysesPlaylistItemsData => {
      return {
        playlistItems: data.playlistItems.map((playlistItem) => transformPlaylistItem(playlistItem)),
        filters: transformFilters(data.filters),
      };
    },
  });

  const getFilters = useCallback(
    (params: RecordingsFilters) => {
      mutate({ url: tacticalAnalysesWithFilters, data: transformFiltersForRequest(params) });
    },
    [mutate],
  );

  return { getFilters, isLoading, isError, isSuccess, data: !data ? placeHolderData : data };
};
