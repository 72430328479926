import SvgIcon, { SvgIconProps } from '../svg-icon';

const IconPlaylist = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <g>
        <path d='M6.42 9.502a.63.63 0 0 0-.33.066c-.103.052-.189.131-.248.23s-.092.211-.092.326v6.25c0 .166.067.324.184.441s.275.184.441.184a.62.62 0 0 0 .363-.115l4.375-3.125a.63.63 0 0 0 .262-.51.62.62 0 0 0-.068-.285.63.63 0 0 0-.193-.225L6.738 9.615a.62.62 0 0 0-.318-.113zM7 11.34l2.674 1.91L7 15.16v-3.82zm10.625-3.09H6.375c-.166 0-.325-.066-.442-.183s-.183-.276-.183-.442.066-.325.183-.442S6.209 7 6.375 7h11.25c.166 0 .325.066.442.183s.183.276.183.442-.066.325-.183.442-.276.183-.442.183z' />
        <use xlinkHref='#A' />
        <use y='4.375' xlinkHref='#A' />
      </g>
      <defs>
        <path
          id='A'
          d='M17.625 12.625H13.25c-.166 0-.325-.066-.442-.183s-.183-.276-.183-.442.066-.325.183-.442.276-.183.442-.183h4.375c.166 0 .325.066.442.183s.183.276.183.442-.066.325-.183.442-.276.183-.442.183z'
        />
      </defs>
    </SvgIcon>
  );
};

export default IconPlaylist;
