import { styled, Tabs as MUITabs } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';

type TabsProps = {
  color: Colors;
};

export const Tabs = styled(MUITabs, { shouldForwardProp: (prop) => prop !== 'color' })<TabsProps>(
  ({ theme, color }) => ({
    '& .MuiTab-root': {
      fontWeight: 'normal',
      fontSize: fontSizes.default,
      textTransform: 'none',
      borderBottom: `2px solid ${Colors.storm}`,
      color: Colors.storm,
    },
    '& .Mui-selected': {
      color,
      ['button']: {
        color,
      },
    },
    '& .MuiTabs-indicator': {
      backgroundColor: color,
      height: theme.spacing(0.5),
    },
  }),
);
