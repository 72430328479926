import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';

import { CreateSeasonClientsApiParams } from './types';
import { annotationApiUrls } from '../../routes';
import { useInvalidateSeasonClients } from '../use-season-clients';

interface RequestData {
  data: CreateSeasonClientsApiParams;
  onSuccess?: () => void;
}

export const useCreateSeasonClients = () => {
  const invalidateSeasonClients = useInvalidateSeasonClients();
  const mutationRequest = useMutationRequest({
    type: HTTPMethod.POST,
    successMessage: 'Association season clients created',
    errorMessage: 'Association season clients create error',
    onSuccess: invalidateSeasonClients,
  });

  const create = ({ data, onSuccess }: RequestData) => {
    mutationRequest.mutate(
      {
        url: annotationApiUrls.SEASON_CLIENTS,
        data: {
          original_client_id: data.originalClientId,
          season_uuid: data.seasonId,
          client_ids: data.clientIds,
        },
      },
      { onSuccess },
    );
  };

  return { ...mutationRequest, create };
};
