import { PlayerStateMachineContext } from '../../../types';
import { getCurrentPlaylistItemTime } from '../get-current-playlist-item-time';
import { getVideoSourceIndexAndTimeFromPercentTime } from '../get-video-source-index';

export const SKIP_STEP_SIZE = 5;

export const skipTime = (context: PlayerStateMachineContext, time: number) => {
  const currentPlaylistItemTime = getCurrentPlaylistItemTime(
    context.playlist.currentSelectedPlayingMode,
    context.videoRef?.current?.currentTime ?? 0,
    context.playlist.playingItem.videoSourceIndex,
    context.playlist.playingItem.playlistItem,
  );

  const percent = ((currentPlaylistItemTime + time) * 100) / context.playlist.playingItem.playlistItem.duration;

  const { videoSourceIndex, currentSourceTime } = getVideoSourceIndexAndTimeFromPercentTime(
    context.playlist.currentSelectedPlayingMode,
    context.playlist.playingItem.playlistItem,
    percent <= 0 ? 0 : percent >= 100 ? 100 : percent,
  );

  return {
    currentTime: currentSourceTime,
    playlist: {
      ...context.playlist,
      playingItem: {
        ...context.playlist.playingItem,
        videoSourceIndex,
      },
    },
  };
};
