import React from 'react';

import { useTacticalAnalysisAppliedFilters } from '../../../../../hooks/use-tactical-analysis-applied-filters';
import { useTimelineTableData } from '../../../../../hooks/use-timeline-table-data';
import { RowContent } from '../row-content';

export const FiltersContentRow = ({ recordingId }: { recordingId: string }) => {
  const timelineTableData = useTimelineTableData(recordingId);
  const { areFiltersApplied } = useTacticalAnalysisAppliedFilters(recordingId);

  if (!areFiltersApplied || !timelineTableData.filtersRow) return null;

  return (
    <RowContent
      rowId={timelineTableData.filtersRow.id}
      clips={timelineTableData.filtersRow.clips}
      recordingId={recordingId}
    />
  );
};
