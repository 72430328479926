import { useParams } from 'react-router-dom';

import Container from 'shared/components/container';
import { SidebarLayout } from 'shared/components/sidebar-layout';

import { KeypadEdit } from './components/keypad-edit';

// TODO: [refactor]: implement page state machine

export const KeypadEditPage = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <SidebarLayout>
      <Container>
        <KeypadEdit id={id} key={id} />
      </Container>
    </SidebarLayout>
  );
};
