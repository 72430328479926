import { useTranslation } from 'react-i18next';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { specificRecordingUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';

export const useDeleteRecording = (recordingId: string, onSuccess?: (recordingId: string) => void) => {
  const { t } = useTranslation();

  const { mutate, isLoading, isError, isSuccess } = useMutationRequest({
    type: HTTPMethod.DELETE,
    errorMessage: t('api:use-delete-recording.error'),
    successMessage: t('api:use-delete-recording.success'),
    onSuccess: async () => {
      onSuccess && onSuccess(recordingId);
    },
  });

  const deleteRecording = () => {
    mutate({ url: specificRecordingUrl(recordingId) });
  };

  return { deleteRecording, isLoading, isError, isSuccess };
};
