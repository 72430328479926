import { FormInputField } from 'shared/components/form/form-input-field';

import { AnnotationFormLabels } from '../../../utils/form-fields-validation';
import { AnnotationFormFieldsNames } from '../../../utils/form-fields-validation/types';
import { FormFieldProps } from '../types';

export const TacticalAnalysisIdField = ({ errors = {}, register }: FormFieldProps) => {
  return (
    <FormInputField
      id={AnnotationFormFieldsNames.TACTICAL_ANALYSIS_ID}
      label={AnnotationFormLabels[AnnotationFormFieldsNames.TACTICAL_ANALYSIS_ID]}
      error={!!errors[AnnotationFormFieldsNames.TACTICAL_ANALYSIS_ID]}
      fullWidth
      helperText={
        errors[AnnotationFormFieldsNames.TACTICAL_ANALYSIS_ID] && (
          <>{errors[AnnotationFormFieldsNames.TACTICAL_ANALYSIS_ID]?.message}</>
        )
      }
      InputProps={{ readOnly: true }}
      {...register(AnnotationFormFieldsNames.TACTICAL_ANALYSIS_ID)}
    />
  );
};
