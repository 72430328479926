import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';

import { queryClient } from 'api/config';
import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { duplicatePlaylistUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';

import { generateFetchPlaylistsQueryRef } from '../useCreatePlaylist';

type PlaylistDuplicate = {
  name: string;
  onSuccess?: () => void;
  onSettled?: () => void;
};

interface Parameters {
  playlistId: string;
  successMessage?: string;
}

export const useDuplicatePlaylist = ({ playlistId, successMessage }: Parameters) => {
  const { t } = useTranslation();

  const fetchQueryRef = generateFetchPlaylistsQueryRef(playlistId);

  const { mutate, isLoading, isError, isSuccess } = useMutationRequest({
    type: HTTPMethod.POST,
    errorMessage: t('api:use-duplicate-playlist.error'),
    successMessage: successMessage ? successMessage : t('api:use-duplicate-playlist.success'),
    onSuccess: async () => {
      if (fetchQueryRef) await queryClient.invalidateQueries(fetchQueryRef);
    },
  });

  const duplicatePlaylist = ({ name, onSuccess = noop, onSettled = noop }: PlaylistDuplicate) => {
    mutate({ url: duplicatePlaylistUrl(playlistId), data: { name } }, { onSuccess, onSettled });
  };

  return { duplicatePlaylist, isLoading, isError, isSuccess };
};
