import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { useDeleteRecording } from 'api/recording/useDeleteRecording';
import { routes } from 'kognia/router/routes';
import RecordingCard from 'pages/recordings-list/components/recording-card';
import StateInfo from 'pages/recordings-list/components/vod-recording-card/state-info';
import IconAnalysis from 'shared/components/icons/icon-analysis';
import IconBall from 'shared/components/icons/icon-ball';
import IconTag from 'shared/components/icons/icon-tag';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import { ListInfo } from 'shared/components/list/list-info';
import { ListItemTitle } from 'shared/components/list/list-item-title';
import useIsTaggingTool from 'shared/hooks/is-tagging-tool';
import { Recording, RecordingTypes, VideoSourceStates } from 'shared/types/recording/types';

import CompetitionInfo from './competition-info';
import DateInfo from './date-info';
import MatchDayInfo from './match-day-info';
import ScoresInfo from './scores-info';
import stylesCard from '../vod-recording-card/RecordingCard.module.scss';

interface Props {
  hideActions?: boolean;
  onDeleteSuccess?: (recordingId: string) => void;
  recording: Recording;
}

const VODRecordingCard = React.memo(({ recording, hideActions = false, onDeleteSuccess }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [isDeleted, setIsDeleted] = useState(false);
  const { deleteRecording, isError, isLoading } = useDeleteRecording(recording.id, onDeleteSuccess);

  const taggingToolPath = useMemo(
    () => generatePath(routes.TAGGING_TOOL_TAG, { recordingId: recording.id }),
    [recording.id],
  );

  const { id, type, name, teams, date, competitionName, matchDay } = recording;
  const isTaggingTool = useIsTaggingTool();
  const cardPath = useMemo(
    () =>
      isTaggingTool
        ? generatePath(routes.TAGGING_TOOL_TAG, { recordingId: id })
        : generatePath(routes.PERFORMANCE_REPORT_TIMELINE, { id }),
    [id, isTaggingTool],
  );

  const analysisPath = useMemo(() => generatePath(routes.PERFORMANCE_REPORT_TIMELINE, { id }), [id]);

  useEffect(() => {
    if (isError) {
      setIsDeleted(false);
    }
  }, [isError]);

  const handleConfirmDelete = () => {
    deleteRecording();
    setIsDeleted(true);
  };

  const hasVideoSource =
    recording.videoSourcesStates &&
    recording.videoSourcesStates.some((videoSourceState) => videoSourceState.state === VideoSourceStates.FINISHED);

  const handleLinkToTagging = useCallback(
    (event: React.MouseEvent<HTMLLIElement | HTMLDivElement, MouseEvent>) => {
      event && event.stopPropagation();
      history.push(taggingToolPath);
    },
    [history, taggingToolPath],
  );

  const handleLinkToAnalysis = useCallback(
    (event?: React.MouseEvent<HTMLLIElement | HTMLDivElement, MouseEvent>) => {
      if (!hasVideoSource) return false;
      event && event.stopPropagation();
      history.push(analysisPath);
    },
    [history, analysisPath, hasVideoSource],
  );

  const handleLinkToCardPath = useCallback(
    (event?: React.MouseEvent<HTMLLIElement | HTMLDivElement, MouseEvent>) => {
      if (!hasVideoSource) return false;
      event && event.stopPropagation();
      history.push(cardPath);
    },
    [cardPath, history, hasVideoSource],
  );

  const extraMenuOptions = useMemo(() => {
    if (!hasVideoSource) return [];

    return [
      {
        displayText: '',
      },
      {
        displayText: t('recording:actions.add-tags'),
        icon: <IconTag size={IconSizes.small} color={IconColors.storm} />,
        onClick: handleLinkToTagging,
      },
      {
        displayText: t('recording:actions.view-analysis'),
        icon: <IconAnalysis size={IconSizes.small} color={IconColors.storm} />,
        onClick: handleLinkToAnalysis,
      },
    ];
  }, [t, hasVideoSource, handleLinkToAnalysis, handleLinkToTagging]);

  return (
    <RecordingCard
      editPath={generatePath(routes.RECORDING_EDIT, { id })}
      hasVideoSource={hasVideoSource}
      hideActions={hideActions}
      isDeleted={isDeleted}
      isLoading={isLoading}
      menuOptions={extraMenuOptions}
      onClick={handleLinkToCardPath}
      onDelete={handleConfirmDelete}
      recording={recording}
      title={
        <ListInfo>
          {type === RecordingTypes.GAME && !!teams.length ? (
            <div className={stylesCard.recordingScore}>
              <ScoresInfo teams={teams} />
            </div>
          ) : (
            <div className={stylesCard.recordingIcon}>
              <IconBall />
            </div>
          )}
          <ListItemTitle>
            <h2>{name}</h2>
            {type !== RecordingTypes.TRAINING ? (
              <CompetitionInfo type={type} competitionName={competitionName} t={t} />
            ) : (
              <div />
            )}
          </ListItemTitle>
        </ListInfo>
      }
    >
      <div />
      <div>
        <DateInfo date={date} />
        <MatchDayInfo type={type} matchDay={matchDay} />
      </div>
      <div className={stylesCard.recordingState}>
        <StateInfo hasVideoSource={hasVideoSource} recording={recording} />
      </div>
    </RecordingCard>
  );
});

VODRecordingCard.displayName = 'VODRecordingCard';

export default VODRecordingCard;
