import { Typography } from '@mui/material';
import { Colors } from 'kognia-ui';
import React from 'react';

import { formattedTime } from 'pages/tactical-analysis/utils/formatted-time';
import {
  useCurrentMatchTime,
  useCurrentPlaylistItemVideoSources,
  useVideoPlayerPlayingMode,
} from 'shared/components/video-player/hooks';
import { PlayingMode, VideoSourceWithTimes } from 'shared/components/video-player/types';
import { SegmentConfig } from 'shared/types/segment/types';
import { secondsAsDuration } from 'shared/utils/seconds-as-duration';

import { useCurrentTime, useDuration } from '../../../index';
import { isFullMatchVideo } from '../../../is-full-match-video';

interface Props {
  useSegments?: boolean;
  matchSegments?: SegmentConfig[];
}

const format = (time: number, matchSegments?: SegmentConfig[]) => {
  if (matchSegments) return formattedTime(time, matchSegments, false);

  return secondsAsDuration(time, false);
};

const getEndTimeOfMatch = (duration: number, matchSegments?: SegmentConfig[]) => {
  if (matchSegments) {
    const endTime = format(duration, matchSegments);
    return endTime === '--:--' ? format(duration) : endTime;
  }

  return secondsAsDuration(duration, false);
};

const getEndTimeByPlayingMode = (
  playingMode: PlayingMode,
  videoSources: VideoSourceWithTimes[],
  matchSegments?: SegmentConfig[],
) => {
  const lastVideoSourceEndTime =
    videoSources.length > 0 ? videoSources[videoSources.length - 1].endTimeInMatch ?? 0 : 0;

  return lastVideoSourceEndTime === 0 && matchSegments && matchSegments.length > 0 && isFullMatchVideo(playingMode.mode)
    ? matchSegments[matchSegments.length - 1].start + matchSegments[matchSegments.length - 1].length
    : lastVideoSourceEndTime;
};

const ProgressTime = ({ matchSegments }: Props) => {
  const duration = useDuration();
  const currentTime = useCurrentTime();
  const videoSources = useCurrentPlaylistItemVideoSources();
  const playingMode = useVideoPlayerPlayingMode();
  const currentVideoSourceTime = useCurrentMatchTime();

  const endTime = getEndTimeByPlayingMode(playingMode, videoSources, matchSegments);

  const formattedCurrentTime = format(matchSegments ? currentVideoSourceTime : currentTime, matchSegments);
  const formattedEndTimeOfMatch = getEndTimeOfMatch(
    matchSegments && endTime > 0 ? endTime - 0.1 : duration,
    matchSegments,
  );

  return (
    <Typography
      color={Colors.white}
      whiteSpace={'nowrap'}
    >{`${formattedCurrentTime} / ${formattedEndTimeOfMatch}`}</Typography>
  );
};

export default ProgressTime;
