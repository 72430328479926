import { Grid } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

import styles from './List.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  disablePadding?: boolean;
}

export const List = ({ children, className = '', disablePadding = false }: Props) => {
  return (
    <Grid item sx={{ ...(disablePadding && { paddingTop: '0 !important' }) }}>
      <ul className={classNames(styles.list, className)}>{children}</ul>
    </Grid>
  );
};
