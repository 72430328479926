import Container from 'shared/components/container';
import { SidebarLayout } from 'shared/components/sidebar-layout';

import { KeypadListScreen } from './components/keypad-list/KeypadListScreen';

export const KeypadListPage = () => {
  return (
    <SidebarLayout>
      <Container>
        <KeypadListScreen />
      </Container>
    </SidebarLayout>
  );
};
