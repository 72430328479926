import { ReactNode } from 'react';

import { MessageContainer } from './index.styled';

export enum MessageVariant {
  INFO = 'info',
}

interface Props {
  children: ReactNode;
  variant?: MessageVariant;
}

export const Message = ({ children, variant = MessageVariant.INFO }: Props) => {
  return <MessageContainer variant={variant}>{children}</MessageContainer>;
};
