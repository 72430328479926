import { useTranslation } from 'react-i18next';

import { useFetchTaggingRecordings } from 'api/recording/useFetchTaggingRecordings';
import { AlignmentSourceList } from 'pages/tagging-tool-aling-sources/components/alignment-source-list';
import Container from 'shared/components/container';
import NotFound from 'shared/components/not-found';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import Spinner from 'shared/components/spinner';
import usePageStateMachine from 'shared/hooks/use-page-state-machine';
import { Recording } from 'shared/types/recording/types';

export const AlignmentSourceListContainer = () => {
  const { t } = useTranslation();
  const { data, isPageLoading, isPageReady, isPageMissingData } = usePageStateMachine<Recording[]>(() =>
    useFetchTaggingRecordings(),
  );

  return (
    <SidebarLayout>
      <Container>
        {isPageLoading && <Spinner isFullPage />}
        {isPageMissingData && <NotFound header={t('tagging-tool:not-found')} />}
        {isPageReady && data && <AlignmentSourceList recordings={data} />}
      </Container>
    </SidebarLayout>
  );
};
