import { Grid } from '@mui/material';

import { useDates } from 'shared/hooks/use-dates';

import styles from '../RecordingCard.module.scss';

type DateInfoProps = {
  date: Date;
};

const DateInfo = ({ date }: DateInfoProps) => {
  const { dateToString } = useDates();
  return (
    <Grid item xs={12} sm={'auto'} className={styles.recordingInfoDate}>
      {dateToString(date)}
    </Grid>
  );
};

export default DateInfo;
