import { PlayerImageContainer, PlayerImageElement } from './styled';

interface Props {
  src: string;
  fullSize?: boolean;
}

export const PlayerPhoto = ({ src, fullSize = false }: Props) => {
  return (
    <PlayerImageContainer>
      <PlayerImageElement src={src} alt='' fullSize={fullSize} />
    </PlayerImageContainer>
  );
};
