import { useUserPresets } from 'api/user-presets/use-user-presets';
import { getPreset } from 'api/user-presets/use-user-presets/utils';
import { RecordingsFilters } from 'shared/types/recording/types';
import { playlistMultimatchAppliedFilters, UserPresetScope } from 'shared/types/user-preset/types';

import { RecordingsFiltersSchema } from '../../../../shared/types/recording/schemas';
import { useSetPlaylistMultimatchAppliedFilters } from '../../store/atoms';

interface UseAppDataInterface {
  isFetching: boolean;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
}

type Options = {
  playlistId: string;
};

export const useMultimatchAppliedFiltersPreset = ({ playlistId }: Options): UseAppDataInterface => {
  const setPlaylistMultimatchAppliedFilters = useSetPlaylistMultimatchAppliedFilters(playlistId);

  const fetchUserPresets = useUserPresets({
    prefix: 'playlist',
    scope: UserPresetScope.playlist,
    ref: playlistId,
    onSuccess: (data) => {
      const preset = getPreset<RecordingsFilters>(data, playlistMultimatchAppliedFilters.key, RecordingsFiltersSchema);
      if (preset) setPlaylistMultimatchAppliedFilters(preset);
    },
  });

  const isLoading = fetchUserPresets.isLoading;
  const isFetching = fetchUserPresets.isFetching;
  const isSuccess = fetchUserPresets.isSuccess;
  const isError = fetchUserPresets.isError;

  return {
    isError,
    isSuccess,
    isFetching,
    isLoading,
  };
};
