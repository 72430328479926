import { PlayingMode } from 'shared/components/video-player/types';

import { PlayerType, PlaylistItemType } from '../../types';

export interface ChangeSourceAndTimeMachineContext {
  playingMode: PlayingMode;
  playlistItem: PlaylistItemType | undefined;
  videoRef: PlayerType | undefined;
  videoSourceIndex: number;
  currentTime: number;
}

export enum CHANGE_SOURCE_STATES {
  CHECK_CURRENT_INITIAL_STATE = 'CHECK_INITIAL_STATE',
  CHECK_CURRENT_SOURCE = 'CHECK_CURRENT_SOURCE',
  CHECK_CURRENT_TIME = 'CHECK_CURRENT_TIME',
  ENDED = 'ENDED',
}

export enum CHANGE_SOURCE_ACTIONS {
  CHECK_CURRENT_SOURCE = 'CHECK_CURRENT_SOURCE',
  CHECK_CURRENT_TIME = 'CHECK_CURRENT_TIME',
}
