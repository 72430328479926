import { Clip } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows';
import { SelectableClip } from '../clip';

export const BlockClipsList = ({
  parentClip,
  clips,
  recordingId,
}: {
  parentClip: Clip;
  clips?: Clip[];
  recordingId: string;
}) => {
  return (
    <>
      {clips?.map((innerClip) => (
        <SelectableClip recordingId={recordingId} clip={innerClip} parentClip={parentClip} key={innerClip.id} />
      ))}
    </>
  );
};
