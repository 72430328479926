import { Grid } from '@mui/material';

import styles from './ListTitle.module.scss';

interface Props {
  children?: React.ReactNode;
  className?: string;
  dataTestId?: string;
}

export const ListTitle = ({ children, className = '', dataTestId }: Props) => {
  return (
    <Grid item className={className}>
      <h1 className={styles.title} data-testid={dataTestId}>
        {children}
      </h1>
    </Grid>
  );
};
