import { AutocompleteChangeReason, AutocompleteCloseReason } from '@mui/base/AutocompleteUnstyled/useAutocomplete';
import { Box, ListItem, PopperProps, styled } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePlaylists } from 'api/playlist/useFetchPlaylists';

import { usePlaylistsFilters } from '../../../api/playlist/useFetchPlaylists/atoms';
import { Playlist } from '../../types';
import { Autocomplete } from '../autocomplete';
import { SearchPlaylistResults, SearchPlaylistResultsPopOver } from '../search-playlist-results';

const PopperWrapper: React.JSXElementConstructor<PopperProps> = ({ children, open }) => {
  if (!open) return null;
  return <div>{children as React.ReactNode}</div>;
};

const SearchItemClipsCount = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(0.5),
  fontSize: fontSizes.default,
  color: Colors.storm,
}));

interface Props {
  playlistId: string;
  className?: string;
  onUpdateValue?: (playlist: Playlist | null, name: string, reason: AutocompleteChangeReason) => void;
  onClickElement?: (item: Playlist) => void;
  onClose?: (event: React.SyntheticEvent, reason: AutocompleteCloseReason) => void;
  onFocus?: () => void;
  onSearchTermChange?: (name: string) => void;
  onKeyDown?: (key: string) => void;
  onSubmit?: (name?: string) => void;
  onBlur?: (name?: string) => void;
  open?: boolean;
  showResultsInPopOver?: boolean;
  title?: string;
}

const DEBOUNCE_TIME = 300;

export const SearchPlaylistAutocomplete = ({
  playlistId,
  onUpdateValue,
  className,
  onFocus,
  onBlur,
  onSearchTermChange,
  onSubmit,
  onKeyDown,
  onClickElement,
  open = false,
  title,
  showResultsInPopOver = false,
}: Props) => {
  const filters = usePlaylistsFilters(playlistId);
  const [searchTerm, setSearchTerm] = React.useState(filters.name);
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const { data, isLoading, appliedFilters, filterActions, fetchNextPage } = usePlaylists({
    playlistId,
    refetchInterval: false,
    isAutocomplete: true,
  });

  useEffect(() => {
    setSearchTerm(filters.name);
  }, [filters.name]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleTermChange = useCallback(
    debounce((name: string) => {
      setSearchTerm(name);
      filterActions.setName(name);
    }, DEBOUNCE_TIME),
    [filterActions.setName],
  );

  const handleOnFocus = useCallback(() => {
    onFocus && onFocus();
    setIsActive(true);
  }, [onFocus]);

  const handleOnBlur = useCallback(() => {
    onBlur && onBlur(appliedFilters.name);
    setIsActive(false);
  }, [appliedFilters.name, onBlur]);

  const handleOnClose = useCallback(() => {
    setIsActive(false);
  }, []);

  const handleOnSubmit = useCallback(() => {
    onSubmit && onSubmit(appliedFilters.name);
    setIsActive(false);
  }, [appliedFilters.name, onSubmit]);

  const handleOnKeyUp = useCallback(
    (key: string) => {
      onKeyDown && onKeyDown(key);
      setIsActive(true);
    },
    [onKeyDown],
  );

  const isOpen = open || isActive;

  const PlaylistResultsComponent = useCallback(
    (props: any) => (
      <SearchPlaylistResults {...props} title={title} showSearchResults={!appliedFilters.name} open={isOpen} />
    ),
    [appliedFilters.name, isOpen, title],
  );

  const PlaylistResultsPopOverComponent = useCallback(
    (props: any) => (
      <SearchPlaylistResultsPopOver {...props} title={title} showSearchResults={!appliedFilters.name} open={isOpen} />
    ),
    [appliedFilters.name, isOpen, title],
  );

  const renderOption = useCallback((props: React.ComponentProps<typeof ListItem>, item: Playlist) => {
    return (
      <ListItem {...props} key={item.id}>
        <Box px={1} py={0.5}>
          {item.name} <SearchItemClipsCount>{item.playlistItems.length}</SearchItemClipsCount>
        </Box>
      </ListItem>
    );
  }, []);

  const handleUpdateValue = useCallback(
    (playlist: Playlist | Playlist[] | null, reason: AutocompleteChangeReason) => {
      if (Array.isArray(playlist)) return;

      if (reason === 'selectOption') {
        onClickElement && playlist && onClickElement(playlist);
      }

      onUpdateValue && onUpdateValue(playlist, appliedFilters.name, reason);
    },
    [appliedFilters.name, onClickElement, onUpdateValue],
  );

  return (
    <Autocomplete
      PaperComponent={showResultsInPopOver ? PlaylistResultsPopOverComponent : PlaylistResultsComponent}
      PopperComponent={showResultsInPopOver ? undefined : PopperWrapper}
      autoHighlight={false}
      className={className}
      fetchNextPage={fetchNextPage}
      getItemLabel={(item) => item.name}
      inputValue={searchTerm}
      inputWidth='100%'
      isLoading={isLoading}
      onBlur={handleOnBlur}
      onChange={handleTermChange}
      onClose={handleOnClose}
      onFocus={handleOnFocus}
      onKeyUp={handleOnKeyUp}
      onSearchTermChange={onSearchTermChange}
      onSubmit={handleOnSubmit}
      open={open}
      options={data.playlists}
      placeholder={t('common:actions.search')}
      renderOption={renderOption}
      resultsHeight={288}
      updateValue={handleUpdateValue}
      useInputChange
    />
  );
};
