import { z } from 'zod';

import { PlayerFormFieldsNames } from './types';

export const createPlayerFormSchema = z.object({
  [PlayerFormFieldsNames.name]: z.string().trim().min(2),
  [PlayerFormFieldsNames.playerImage]: z.instanceof(FileList).optional(),
});

export const editPlayerFormSchema = z.object({
  [PlayerFormFieldsNames.name]: z.string().trim().min(2),
  [PlayerFormFieldsNames.playerImage]: z.instanceof(FileList).optional(),
});
