import { Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';

import { CoachPhotoContainer, CoachPhotoPreviewContainer } from './styled';
import { CoachPhoto } from '../coach-photo';
import { CoachPhotoSkeleton } from '../coach-photo-skeleton';

interface Props {
  photoUrl?: string | null;
}

export const CoachPhotoPreview = ({ photoUrl }: Props) => {
  return (
    <CoachPhotoPreviewContainer>
      <CoachPhotoContainer>
        {photoUrl ? <CoachPhoto fullSize src={photoUrl} /> : <CoachPhotoSkeleton />}
      </CoachPhotoContainer>
      <Typography fontSize={fontSizes.small} marginTop={1}>
        Image preview
      </Typography>
    </CoachPhotoPreviewContainer>
  );
};
