import { UseQueryResult } from '@tanstack/react-query';

import { TacticalAnalysis } from 'shared/types/recording/types';

import { queryClient } from '../../config';
import { useFetchRequest } from '../../hooks/useFetchRequest';
import { tacticalAnalysesInRecording } from '../../routes';
import { TacticalAnalysisApiResponse } from '../../types';
import { transformTacticalAnalysis } from '../transformers';

interface Params {
  recordingId: string;
  onSuccess?: (data: TacticalAnalysisApiResponse[]) => void;
  enabled?: boolean;
}

const queryRef = 'tactical-analyses';

export const invalidateTacticalAnalysesQuery = () => queryClient.invalidateQueries({ queryKey: [queryRef] });

export const useTacticalAnalyses = ({
  recordingId,
  onSuccess,
  enabled = true,
}: Params): UseQueryResult<TacticalAnalysis[]> => {
  return useFetchRequest<TacticalAnalysis[]>({
    queryRef: [`${queryRef}-${recordingId}`],
    url: tacticalAnalysesInRecording(recordingId),
    transformer: (data: TacticalAnalysisApiResponse[]) => data.map((analysis) => transformTacticalAnalysis(analysis)),
    onSuccess,
    options: {
      enabled: enabled,
    },
  });
};
