import { z } from 'zod';

import { Coach } from './coaches';
import { MetadataRecordingSummary } from './recordings';
import { Season } from './seasons';
import { MatchEndType } from './shared';
import { Venue } from '../api/venues/use-venues/types';

export const metadataTeamSchema = z.object({
  name: z.string(),
  abbreviation: z.string(),
  logoUrl: z.string().optional().nullable(),
  teamType: z.string(),
  countryCode: z.string().optional(),
  id: z.string(),
});

export type MetadataTeam = z.TypeOf<typeof metadataTeamSchema>;

export const metadataGamePlayerSchema = z.object({
  jerseyNumber: z.number(),
  side: z.number().optional(),
  position: z.number().optional(),
  name: z.string(),
  id: z.string(),
  teamId: z.string(),
  photoUrl: z.string().optional().nullable(),
});

export type MetadataGamePlayer = z.TypeOf<typeof metadataGamePlayerSchema>;

export type MetadataGame = {
  name: string;
  duration: number;
  homeTeamScore: number;
  awayTeamScore: number;
  date?: string;
  pitchLength: number;
  pitchWidth: number;
  homePenaltyScore: number;
  awayPenaltyScore: number;
  matchEndType: MatchEndType;
  id: string;
  homeTeam: MetadataTeam;
  awayTeam: MetadataTeam;
  homeCoach?: Coach;
  awayCoach?: Coach;
  venue?: Venue;
  stage: {
    name: string;
    orderIndex: number;
    id: string;
  };
  season: Season;
  players: MetadataGamePlayer[];
  recordings: MetadataRecordingSummary[];
};
