import { Colors } from 'kognia-ui';

import { Clip } from '../../../../api/use-tactical-analysis-data/generate-timeline-rows';
import { useTacticalAnalysisMatchSegments } from '../../../../hooks/use-tactical-analysis-match-segments';
import {
  CSS_CLIP_ZOOM_WIDTH,
  CSS_EFFECTIVE_TIME_SEPARATOR,
  CSS_EFFECTIVE_TIME_SEPARATOR_HALF_TIME,
} from '../../timeline-table/components/timeline-css-variables';
import { TimeRulerBlockContainer } from '../time-ruler-block-container';

type Props = {
  recordingId: string;
  episodesClips: Clip[];
};

export const TimeRulerEffectiveTime = ({ episodesClips, recordingId }: Props) => {
  const matchSegments = useTacticalAnalysisMatchSegments(recordingId);
  return (
    <>
      {episodesClips.map((clip) =>
        clip.type === 'not-effective-time' ? (
          <div
            key={clip.id}
            style={{
              width: `calc(var(${CSS_CLIP_ZOOM_WIDTH}) * ${clip.endTime - clip.startTime})`,
              minWidth: `var(${clip.title ? CSS_EFFECTIVE_TIME_SEPARATOR_HALF_TIME : CSS_EFFECTIVE_TIME_SEPARATOR})`,
              maxWidth: `var(${clip.title ? CSS_EFFECTIVE_TIME_SEPARATOR_HALF_TIME : CSS_EFFECTIVE_TIME_SEPARATOR})`,
              background: Colors.athens,
              height: '100%',
            }}
          />
        ) : (
          <TimeRulerBlockContainer
            key={clip.id}
            recordingId={recordingId}
            matchSegments={matchSegments}
            startTime={clip.startTime}
            endTime={clip.endTime}
          />
        ),
      )}
    </>
  );
};
