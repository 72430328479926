import isEqual from 'lodash/isEqual';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { VideoBarContent, VideoPlayerBarContainer } from './styled';
import { VideoBarHeader } from './video-bar-header';
import { VideoSourceSelect } from './video-source-select';
import {
  VideoSourceSelectListItem,
  VideoSourceSelectListItemTypes,
  VideoSourceSelectListItemView,
} from './video-source-select-list';
import { useCurrentPlaylistItem, useVideoPlayerActions, useVideoPlayerPlayingMode } from '../hooks';
import { PlayingMode, PlayingModes } from '../types';

interface Props {
  showTacticDrawingsOnly: boolean;
  showTacticDrawings?: boolean;
  disabledTacticDrawings?: boolean;
  setPlayingMode: (playingMode: PlayingMode) => void;
}

export const VideoPlayerBar = ({
  showTacticDrawingsOnly = false,
  showTacticDrawings = true,
  disabledTacticDrawings,
  setPlayingMode,
}: Props) => {
  const { t } = useTranslation();
  const playingMode = useVideoPlayerPlayingMode();
  const actions = useVideoPlayerActions();
  const currentPlaylistItem = useCurrentPlaylistItem();

  const shouldShowTacticDrawings = useMemo(
    () =>
      (showTacticDrawings && showTacticDrawingsOnly) ||
      (playingMode.mode !== PlayingModes.PANORAMIC &&
        showTacticDrawings &&
        currentPlaylistItem.videoTypes.some((item) => item.playingMode.showOverlays)),
    [currentPlaylistItem, showTacticDrawingsOnly, showTacticDrawings, playingMode],
  );

  const selectableVideoTypes = useMemo(
    () => currentPlaylistItem.videoTypes.filter((item) => item.playingMode.mode !== PlayingModes.EPISODES),
    [currentPlaylistItem],
  );

  const handlePlayingModeChange = useCallback(
    (playingMode: PlayingMode) => {
      actions.changePlayingMode(playingMode);
    },
    [actions],
  );

  const selectOptionsFromVideoSources: VideoSourceSelectListItemView[] = useMemo(
    () =>
      selectableVideoTypes.map((videoType) => {
        return {
          type: VideoSourceSelectListItemTypes.ListItemMode,
          playingMode: videoType.playingMode,
          id:
            videoType.playingMode.mode === PlayingModes.TACTICAL_CAMERA
              ? PlayingModes.PLAYLIST
              : videoType.playingMode.mode,
          label: t(`video-player:playing-modes.${videoType.playingMode.mode}`),
          onClick: () => handlePlayingModeChange(videoType.playingMode),
        };
      }),
    [handlePlayingModeChange, selectableVideoTypes, t],
  );

  const selectOptions: VideoSourceSelectListItem[] = selectOptionsFromVideoSources;

  const selectedOption = (selectOptions.find((option) => {
    return 'playingMode' in option && isEqual(playingMode, option.playingMode);
  }) || selectOptions[0]) as VideoSourceSelectListItemView;

  const isVideoSourceSelectDisabled = selectOptions.length === 1;

  if (showTacticDrawingsOnly && !shouldShowTacticDrawings) return null;

  return (
    <VideoPlayerBarContainer>
      <VideoBarContent>
        {!showTacticDrawingsOnly ? (
          <VideoSourceSelect options={selectOptions} selected={selectedOption} disabled={isVideoSourceSelectDisabled} />
        ) : null}
        <VideoBarHeader
          disabledTacticDrawings={disabledTacticDrawings}
          setPlayingMode={setPlayingMode}
          shouldShowTacticDrawings={shouldShowTacticDrawings}
          showTacticDrawingsOnly={showTacticDrawingsOnly}
        />
      </VideoBarContent>
    </VideoPlayerBarContainer>
  );
};
