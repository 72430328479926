import { ZOOM_LEVELS_TYPE } from 'pages/tactical-analysis/components/tactical-analysis/zoom-range';

import { PlayingMode } from '../../components/video-player/types';
import { TacticIdOrAll } from '../playlist/types';
import { RecordingsFilters } from '../recording/types';

export interface UserPreset<T> {
  scope: UserPresetScope;
  key: string;
  value: T;
  ref?: string;
}

export type UserPresetScopeKey<T> = Pick<UserPreset<T>, 'scope' | 'key'>;

export enum UserPresetScope {
  timeline = 'timeline',
  playlist = 'playlist',
  videoPlayer = 'video-player',
}

export const playlistMultimatchAppliedFilters: UserPresetScopeKey<RecordingsFilters> = {
  scope: UserPresetScope.playlist,
  key: 'multimatch-applied-filters',
};

export const timelinePlayingModePreset: UserPresetScopeKey<PlayingMode> = {
  scope: UserPresetScope.timeline,
  key: 'playing-mode',
};

export const timelineSelectedTacticsPreset: UserPresetScopeKey<TacticIdOrAll[]> = {
  scope: UserPresetScope.timeline,
  key: 'selected-tactics',
};

export const timelineZoomPreset: UserPresetScopeKey<ZOOM_LEVELS_TYPE> = {
  scope: UserPresetScope.timeline,
  key: 'zoom-level',
};

export const timelineHeightPreset: UserPresetScopeKey<number> = {
  scope: UserPresetScope.timeline,
  key: 'height',
};

export const timelinePinScenariosPreset: UserPresetScopeKey<boolean> = {
  scope: UserPresetScope.timeline,
  key: 'pin-scenarios',
};

export const timelineHeadersWidthPreset: UserPresetScopeKey<number> = {
  scope: UserPresetScope.timeline,
  key: 'headers-width',
};

export const timelineAppliedFiltersPreset: UserPresetScopeKey<RecordingsFilters> = {
  scope: UserPresetScope.timeline,
  key: 'timeline-applied-filters',
};

export const timelineFiltersPreset: UserPresetScopeKey<string> = {
  scope: UserPresetScope.timeline,
  key: 'filters',
};

export const timelineTimePreset: UserPresetScopeKey<number> = {
  scope: UserPresetScope.timeline,
  key: 'time',
};

export const timelineTeamIdFocus: UserPresetScopeKey<string> = {
  scope: UserPresetScope.timeline,
  key: 'team-id-focus',
};

export const timelineShowBallPossession: UserPresetScopeKey<boolean> = {
  scope: UserPresetScope.timeline,
  key: 'show-ball-possession',
};

export const timelineShowNoBallPossession: UserPresetScopeKey<boolean> = {
  scope: UserPresetScope.timeline,
  key: 'show-no-ball-possession',
};

export const videoPlayerSpeedPreset: UserPresetScopeKey<number> = {
  scope: UserPresetScope.videoPlayer,
  key: 'speed',
};

class PlaylistCommonUserPreset<T> implements UserPreset<T> {
  scope: UserPresetScope = UserPresetScope.playlist;
  key = '';
  value = {} as T;
  ref?: string;
}

export class PlaylistMultimatchAppliedFiltersPreset extends PlaylistCommonUserPreset<RecordingsFilters> {
  key = playlistMultimatchAppliedFilters.key;
  scope = playlistMultimatchAppliedFilters.scope;
  value: RecordingsFilters;
  ref: string;

  constructor(value: RecordingsFilters, ref: string) {
    super();
    this.value = value;
    this.ref = ref;
  }
}

class CommonUserPreset<T> implements UserPreset<T> {
  scope: UserPresetScope = UserPresetScope.timeline;
  key = '';
  value = {} as T;
  ref?: string;
}

export class TimelinePlayingModePreset extends CommonUserPreset<PlayingMode> {
  key = timelinePlayingModePreset.key;
  scope = timelinePlayingModePreset.scope;
  value: PlayingMode;
  ref: string;

  constructor(value: PlayingMode, ref: string) {
    super();
    this.value = value;
    this.ref = ref;
  }
}

export class TimelineZoomPreset extends CommonUserPreset<ZOOM_LEVELS_TYPE> {
  key = timelineZoomPreset.key;
  scope = timelineZoomPreset.scope;
  value: ZOOM_LEVELS_TYPE;

  constructor(value: ZOOM_LEVELS_TYPE) {
    super();
    this.value = value;
  }
}

export class TimelineSelectedTacticsPreset extends CommonUserPreset<TacticIdOrAll[]> {
  key = timelineSelectedTacticsPreset.key;
  scope = timelineSelectedTacticsPreset.scope;
  value: TacticIdOrAll[];
  ref: string;

  constructor(value: TacticIdOrAll[], ref: string) {
    super();
    this.value = value;
    this.ref = ref;
  }
}

export class TimelineHeightPreset extends CommonUserPreset<number> {
  key = timelineHeightPreset.key;
  scope = timelineHeightPreset.scope;
  value: number;

  constructor(value: number) {
    super();
    this.value = value;
  }
}

export class TimelinePinScenariosPreset extends CommonUserPreset<boolean> {
  key = timelinePinScenariosPreset.key;
  scope = timelinePinScenariosPreset.scope;
  value: boolean;

  constructor(value: boolean) {
    super();
    this.value = value;
  }
}

export class TimelineAppliedFiltersPreset extends CommonUserPreset<RecordingsFilters> {
  key = timelineAppliedFiltersPreset.key;
  scope = timelineAppliedFiltersPreset.scope;
  value: RecordingsFilters;
  ref: string;

  constructor(value: RecordingsFilters, ref: string) {
    super();
    this.value = value;
    this.ref = ref;
  }
}

export class TimelineHeadersWidthPreset extends CommonUserPreset<number> {
  key = timelineHeadersWidthPreset.key;
  scope = timelineHeadersWidthPreset.scope;
  value: number;

  constructor(value: number) {
    super();
    this.value = value;
  }
}

export class TimelineTimePreset extends CommonUserPreset<number> {
  key = timelineTimePreset.key;
  scope = timelineTimePreset.scope;
  value: number;
  ref: string;

  constructor(value: number, ref: string) {
    super();
    this.value = value;
    this.ref = ref;
  }
}

export class TimelineTeamIdFocusPreset extends CommonUserPreset<string> {
  key = timelineTeamIdFocus.key;
  scope = timelineTeamIdFocus.scope;
  value: string;

  constructor(value: string, ref: string) {
    super();
    this.value = value;
    this.ref = ref;
  }
}

export class TimelineShowBallPossession extends CommonUserPreset<boolean> {
  key = timelineShowBallPossession.key;
  scope = timelineShowBallPossession.scope;
  value: boolean;

  constructor(value: boolean, ref: string) {
    super();
    this.value = value;
    this.ref = ref;
  }
}

export class TimelineShowNoBallPossession extends CommonUserPreset<boolean> {
  key = timelineShowNoBallPossession.key;
  scope = timelineShowNoBallPossession.scope;
  value: boolean;

  constructor(value: boolean, ref: string) {
    super();
    this.value = value;
    this.ref = ref;
  }
}

export class VideoPlayerSpeedPreset extends CommonUserPreset<number> {
  key = videoPlayerSpeedPreset.key;
  scope = videoPlayerSpeedPreset.scope;
  value: number;

  constructor(value: number) {
    super();
    this.value = value;
  }
}
