import { Modal, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

export const ModalContainer = styled(Modal)(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
}));

export const ModalContent = styled('div')(() => ({
  alignItems: 'center',
  backgroundColor: Colors.white,
  borderRadius: '8px',
  justifyContent: 'center',
  maxHeight: 'calc(100vh - 6rem)',
  maxWidth: '80rem',
  overflowY: 'auto',
  padding: '24px 36px 34px 36px',
  position: 'absolute',
  top: '3rem',
  width: '90%',
}));

export const IconCloseContainer = styled('div')(() => ({
  position: 'absolute',
  right: '8px',
  top: '8px',
}));
