import React, { forwardRef, PropsWithChildren } from 'react';

import styles from './RowsContent.module.scss';

export const RowsContent = forwardRef(
  ({ children, ...rest }: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>, ref: any) => (
    <div {...rest} ref={ref} className={styles.rowsContent}>
      {children}
    </div>
  ),
);

RowsContent.displayName = 'RowsContent';
