import { Fade } from '@mui/material';
import classNames from 'classnames';

import styles from './NotificationCircle.module.scss';

type Variants = 'error' | 'warning' | 'success' | 'primary';

interface Props {
  visible?: boolean;
  variant: Variants;
}

export const NotificationCircle = ({ variant, visible = true }: Props) => {
  return (
    <Fade in={visible}>
      <div className={classNames(styles.notificationCircle, styles[variant])} />
    </Fade>
  );
};
