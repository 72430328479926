import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxWithColor } from 'shared/components/checkbox-with-color';
import { IconColors } from 'shared/components/icons/svg-icon';
import { MatchTeam } from 'shared/types';

import styles from './FilterPlayersList.module.scss';

export type PlayersListProps = {
  team: MatchTeam;
  values: string[];
  filteredPlayers?: Set<string>;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export const PlayersList = ({ team, values, handleChange, filteredPlayers }: PlayersListProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div key={`all-${team.id}`} className={styles.listItem}>
        <CheckboxWithColor
          id={`all-${team.id}`}
          checked={values.length === 0}
          onChange={handleChange}
          customColor={IconColors.primary}
        />
        <div className={styles.name}>{t('recordings:filters:players:all-option')}</div>
      </div>

      {team.players.map((player) => {
        const isPlayerSelected = values.includes(player.id);
        const isPlayerDisabled = filteredPlayers && !filteredPlayers.has(player.id);
        const isPlayerSelectionInvalid = isPlayerSelected && isPlayerDisabled;

        return (
          <div
            key={player.id}
            className={classNames(styles.listItem, {
              [styles.disabled]: isPlayerDisabled,
              [styles.invalid]: isPlayerSelectionInvalid,
            })}
          >
            <CheckboxWithColor
              id={player.id}
              checked={isPlayerSelected}
              onChange={handleChange}
              customColor={isPlayerSelectionInvalid ? IconColors.red : IconColors.primary}
            />
            <div className={styles.name}>
              {player.number && `${player.number}.`}
              {player.name}
            </div>
          </div>
        );
      })}
    </>
  );
};
