import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationRequest } from '../../hooks/useMutationRequest';
import { downloadPlaylistUrl } from '../../routes';

export const useDownloadPlaylist = () => {
  const { t } = useTranslation();
  const { mutate } = useMutationRequest({
    successMessage: t('api:use-download-playlist.success'),
    errorMessage: t('api:use-download-playlist.error'),
  });

  return useCallback(
    ({
      playlistId,
      joinSources,
      showOverlays = true,
      showTitles = true,
    }: {
      playlistId: string;
      joinSources: boolean;
      showOverlays: boolean;
      showTitles: boolean;
    }) => {
      mutate({
        url: downloadPlaylistUrl({ playlistId, joinSources, showOverlays, showTitles }),
        data: { playlistId },
      });
    },
    [mutate],
  );
};
