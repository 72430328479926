import { MatchTeamTypes } from 'shared/types/teams/types';

import { TeamForm } from '../../components/team-form';
import { useCheckValidateStep } from '../../config';

export const OpponentTeamForm = () => {
  useCheckValidateStep(1);

  return <TeamForm teamType={MatchTeamTypes.OPPONENT} step={1} />;
};
