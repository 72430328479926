import SvgIcon, { SvgIconProps } from '../svg-icon';

const IconCopy = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M15.3158 3.89478H6.47368C5.66316 3.89478 5 4.55793 5 5.36846V15.6842H6.47368V5.36846H15.3158V3.89478ZM14.5789 6.84215H9.42105C8.61053 6.84215 7.95474 7.50531 7.95474 8.31584L7.94737 18.6316C7.94737 19.4421 8.60316 20.1053 9.41369 20.1053H17.5263C18.3368 20.1053 19 19.4421 19 18.6316V11.2632L14.5789 6.84215ZM9.42105 18.6316V8.31583H13.8421V12H17.5263V18.6316H9.42105Z' />
    </SvgIcon>
  );
};

export default IconCopy;
