import { teamUrl } from 'api/routes';

import { fetchRequest } from './base';
import { FetchTeamsResponse } from './teams';

export const fetchTeams = () => {
  return fetchRequest<FetchTeamsResponse>({
    url: teamUrl,
  });
};
