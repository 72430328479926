import { ZodSchema } from 'zod';

import { UserPreset } from 'shared/types/user-preset/types';

export function validateWithSchema<T>(data: unknown, schema: ZodSchema<T>): boolean {
  const result = schema.safeParse(data);
  return result.success;
}

export function getPreset<F>(
  data: UserPreset<any>[],
  key: string,
  schema?: ZodSchema,
): UserPreset<F>['value'] | undefined {
  const value = data.find((preset) => preset.key === key)?.value;

  if (!value) return undefined;

  if (schema) {
    if (!validateWithSchema(value, schema)) return undefined;
  }

  return value;
}
