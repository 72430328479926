import { TacticalAnalysisStates } from 'shared/types/recording/types';

export interface TacticalAnalysisApiResponse {
  id: string;
  recordingId: string;
  tacticalAnalysisGitCommit: string;
  numberOfEpisodes: number;
  startTime: Date;
  state: TacticalAnalysisStates;
}

export enum HTTPMethod {
  CONNECT = 'CONNECT',
  DELETE = 'DELETE',
  GET = 'GET',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  TRACE = 'TRACE',
}

export interface fetchQueryResponse<T> {
  data: T;
  isFetching: boolean;
  isFetchingNextPage?: boolean;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  isMissingData?: boolean;
  refetch?: () => void;
  setQueryData?: (...args: any[]) => void;
  invalidateQuery?: () => Promise<void>;
}

export type LinkApiResponse = {
  rel: string;
  href: string;
  hreflang?: string;
  media?: string;
  title?: string;
  type?: string;
  deprecation?: string;
  profile?: string;
  name?: string;
};
