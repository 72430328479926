import Select, { Theme } from 'react-select';

import styles from './InputList.module.scss';

export type InputListOption = {
  value: string;
  label: string;
};

type InputListProps = {
  disabled?: boolean;
  id: string;
  label?: string;
  onSelect: (option: InputListOption) => void;
  options: InputListOption[];
  selectedOption?: InputListOption;
};

const InputList = ({ disabled, id, label, onSelect, options, selectedOption }: InputListProps) => {
  const handleChange = (option: InputListOption | null) => {
    option && onSelect(option);
  };

  const className = disabled ? styles.disabledInput : styles.inputList;

  return (
    <div>
      {label && (
        <label className={styles.inputLabel} htmlFor={id}>
          {label}
        </label>
      )}
      <Select
        id={id}
        isDisabled={disabled}
        onChange={handleChange}
        options={options}
        className={className}
        theme={(theme: Theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: styles.colorBrand,
            primary25: styles.colorGrey,
            primary50: styles.colorGrey,
          },
        })}
        value={selectedOption}
      />
    </div>
  );
};

export default InputList;
