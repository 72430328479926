import classNames from 'classnames';
import React, { ComponentType } from 'react';

import Button, { ButtonTypes } from 'shared/components/button';
import { IconColors, IconSizes, SvgIconProps } from 'shared/components/icons/svg-icon';

import styles from './PlaylistBulkButton.module.scss';

interface Props {
  onClick: () => void;
  IconComponent: ComponentType<SvgIconProps>;
  iconColor?: IconColors;
  iconSize?: IconSizes;
  label?: string;
  disabled?: boolean;
}

export const PlaylistBulkButton = ({
  onClick,
  IconComponent,
  label,
  iconColor = IconColors.white,
  iconSize = IconSizes.small,
  disabled = false,
}: Props) => {
  return (
    <Button type={ButtonTypes.Clear} onClick={!disabled ? onClick : undefined}>
      <div className={classNames(styles.buttonInner, { [styles.disabled]: disabled })}>
        <IconComponent color={iconColor} size={iconSize} />
        {label ? <span className={styles.iconText}>{label}</span> : null}
      </div>
    </Button>
  );
};
