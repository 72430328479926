import React, { useCallback } from 'react';

import { queryClient } from 'api/config';
import { generateFetchRecordingsQueryRef, useRecordingsListFilters } from 'api/recording/useFetchRecordings';
import RecordingsList from 'pages/recordings-list/components/recordings-list';
import { useClientId } from 'shared/contexts/app-state';
import { Recording } from 'shared/types/recording/types';

interface Props {
  isLoading: boolean;
  recordings?: Array<Recording>;
}

export const RecordingListWithFilters = ({ recordings, isLoading }: Props) => {
  const { clientId } = useClientId();
  const filters = useRecordingsListFilters();

  const handleOnDeleteSuccess = useCallback(
    async (recordingId: string) => {
      const fetchQueryRef = generateFetchRecordingsQueryRef(clientId);
      queryClient.setQueryData([fetchQueryRef, filters], (data: any) => ({
        pages: data.pages.map((page: any) => ({
          ...page,
          data: {
            ...page.data,
            recordings: page.data.recordings.filter((recording: any) => recording.id !== recordingId),
          },
        })),
        pageParams: data.pageParams,
      }));

      await queryClient.invalidateQueries([fetchQueryRef, filters]);
    },
    [filters, clientId],
  );

  return (
    <RecordingsList
      onDeleteSuccess={handleOnDeleteSuccess}
      isLoading={isLoading}
      recordings={recordings ? recordings : []}
    />
  );
};
