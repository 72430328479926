import SvgIcon, { SvgIconProps } from '../svg-icon';

const IconCamera = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M19.207 8.387c-.179-.092-.39-.061-.54.081l-2.872 2.7v-1.98c0-.931-.681-1.687-1.519-1.687H6.019c-.838 0-1.519.757-1.519 1.688v5.625c0 .931.681 1.688 1.519 1.688h8.255c.838 0 1.519-.757 1.519-1.687v-1.98l2.872 2.699c.093.088.21.133.327.133.072 0 .145-.017.213-.052a.57.57 0 0 0 .293-.51V8.898a.57.57 0 0 0-.294-.51zm-4.425 6.425c0 .31-.227.563-.507.563H6.019c-.279 0-.506-.252-.506-.562V9.188c0-.31.227-.562.506-.562h8.255c.279 0 .507.252.507.563v5.625zm3.706-.923L16.477 12l2.01-1.889v3.779z' />
    </SvgIcon>
  );
};

export default IconCamera;
