import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonTypes, ButtonBorderRadius } from 'shared/components/button';
import IconTag from 'shared/components/icons/icon-tag';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';

import styles from './AddTagButton.module.scss';

type AddTagButtonProps = {
  matchId?: string;
};

const AddTagButton = ({ matchId }: AddTagButtonProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const hasMatchId = !!matchId;
  const buttonColor = hasMatchId ? IconColors.storm : IconColors.iron;
  const handleClick = () => {
    if (!hasMatchId) return;

    history.push(`/tagging-tool/${matchId}/tag`);
  };

  return (
    <Button
      onClick={handleClick}
      type={ButtonTypes.WhiteStormOutlined}
      radius={ButtonBorderRadius.R48}
      disabled={!hasMatchId}
    >
      <div className={styles.tagButtonContent}>
        <IconTag size={IconSizes.small} color={buttonColor} />
        <div>{t('timeline:actions.add-tags')}</div>
      </div>
    </Button>
  );
};

export default AddTagButton;
