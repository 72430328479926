import map from 'lodash/map';
import React from 'react';

import { RowGroup } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows';
import { RowContent } from '../row-content';

type Props = {
  rowsGroup: RowGroup;
  recordingId: string;
};

export const RowGroupRows = React.memo(({ rowsGroup, recordingId }: Props) => {
  return (
    <>
      {map(rowsGroup.rows, (row, idx) => (
        <RowContent rowId={row.id} clips={row.clips} key={idx} recordingId={recordingId} />
      ))}
    </>
  );
});

RowGroupRows.displayName = 'RowGroupRows';
