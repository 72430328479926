import { Box, Button, Fade, Stack } from '@mui/material';
import { useState } from 'react';

import IconEdit from 'shared/components/icons/icon-edit';

import { useInvalidateMetadataGame } from '../../../../api/games/use-metadata-game';
import { useUpdateRecording } from '../../../../api/games/use-update-recording';
import { SelectClients } from '../../../../components/select-clients';
import { MetadataRecordingSummary } from '../../../../types/recordings';
import { GridListItemRecording } from '../grid-list-item-recording';

export const GamerRecording = ({ recording, gameId }: { gameId: string; recording: MetadataRecordingSummary }) => {
  const invalidateMetadataGame = useInvalidateMetadataGame();

  const { update: updateRecording } = useUpdateRecording();
  const [clientIds, setClientIds] = useState<string[]>(recording.clientIds);
  const [isEditing, setIsEditing] = useState(false);

  const handleCancel = () => {
    setIsEditing(false);
    setClientIds(recording.clientIds);
  };

  const handleSave = () => {
    updateRecording({
      data: { client_ids: clientIds, uuid: recording.id },
      id: recording.id,
      onSuccess: () => {
        setIsEditing(false);
        invalidateMetadataGame(gameId);
      },
    });
  };

  if (isEditing) {
    return (
      <Fade in>
        <Stack gap={2} sx={{ padding: 2 }}>
          <Box>{recording.id}</Box>
          <SelectClients clientIds={clientIds} onChange={setClientIds} />
          <Stack direction={'row'} gap={1} justifyContent={'flex-end'}>
            <Button variant='outlined' color='secondary' onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant='contained' onClick={handleSave}>
              Save
            </Button>
          </Stack>
        </Stack>
      </Fade>
    );
  }

  return (
    <GridListItemRecording key={recording.id}>
      <Box>{recording.id}</Box>
      <Stack>
        {recording.clientIds.map((clientId) => (
          <Box key={clientId}>{clientId}</Box>
        ))}
      </Stack>
      <Box>
        <IconEdit isButton onClick={() => setIsEditing(true)} />
      </Box>
    </GridListItemRecording>
  );
};
