import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AccountForm from 'pages/account/components/account-form';
import Container from 'shared/components/container';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import { useUser } from 'shared/contexts/app-state';

const AccountPageContainer = () => {
  const user = useUser();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('account:metas.title');
  }, []);

  return (
    <SidebarLayout>
      <Container>
        <AccountForm user={user} />
      </Container>
    </SidebarLayout>
  );
};

export default AccountPageContainer;
