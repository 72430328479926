import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { videoPlayerStateAtoms } from '../';

export const usePlayerSetIsPlaying = (playerId: string) => {
  return useSetRecoilState(videoPlayerStateAtoms.isPlaying(playerId));
};

export const usePlayerSetPlaylist = (playerId: string) => {
  return useSetRecoilState(videoPlayerStateAtoms.playlist(playerId));
};

export const usePlayerUpdateStandBy = (playerId: string) => {
  return useSetRecoilState(videoPlayerStateAtoms.isInStandBy(playerId));
};

export const useSetCurrentTime = (playerId: string) => {
  return useSetRecoilState(videoPlayerStateAtoms.currentTime(playerId));
};

export const useGetCurrentTime = (playerId: string) => {
  return useRecoilValue(videoPlayerStateAtoms.currentTime(playerId));
};

export const usePlayerState = (playerId: string) => {
  return useRecoilState(videoPlayerStateAtoms.playerStatus(playerId));
};
