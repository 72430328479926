import { useLiveSessionsWithFilters } from 'api/tagging-tool/use-live-sessions-with-filters';
import Container from 'shared/components/container';
import { SidebarLayout } from 'shared/components/sidebar-layout';

import { TaggingRecordingList } from './components/tagging-recording-list';
import { RecordingListPageSkeleton } from '../recordings-list/components/recording-list-page-skeleton';

export const TaggingToolRecordingList = () => {
  const { isLoading, isSuccess } = useLiveSessionsWithFilters();

  if (isLoading) return <RecordingListPageSkeleton />;

  return (
    <SidebarLayout>
      <Container>{isSuccess && <TaggingRecordingList />}</Container>
    </SidebarLayout>
  );
};
