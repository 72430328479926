import SvgIcon, { SvgIconProps } from '../svg-icon';

export const IconTutorial = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M5.004 17.989c0 .433.172.849.478 1.155s.722.478 1.155.478h8.711c.433 0 .849-.172 1.155-.478s.478-.722.478-1.155v-.545h-1.089v.545c0 .144-.057.283-.159.385s-.241.159-.385.159H6.637c-.144 0-.283-.057-.385-.159s-.159-.241-.159-.385V16.9h.544v-1.089h-.544v-1.089h.544v-1.089h-.544v-1.089h.544v-1.089h-.544v-1.089h.544V9.278h-.544V8.189h.544V7.1h-.544V6.011c0-.144.057-.283.159-.385s.241-.159.385-.159h.544v12.522H8.27V4.922c0-.144-.057-.283-.159-.385s-.241-.159-.385-.159H6.637c-.433 0-.849.172-1.155.478s-.478.722-.478 1.155V7.1h-.544v1.089h.544v1.089h-.544v1.089h.544v1.089h-.544v1.089h.544v1.089h-.544v1.089h.544v1.089h-.544V16.9h.544v1.089z' />
      <path d='M15.893 6.011v3.043a4.3 4.3 0 0 0-1.633-.321c-1.556 0-2.994.83-3.772 2.178s-.778 3.008 0 4.356 2.216 2.178 3.772 2.178a4.3 4.3 0 0 0 2.668-.915l1.846 1.846.768-.773-2.205-2.205c-.102-.101-.24-.158-.384-.158s-.282.057-.384.158a3.23 3.23 0 0 1-2.308.958c-.95 0-1.854-.413-2.474-1.133s-.897-1.674-.758-2.614.681-1.772 1.484-2.281 1.787-.641 2.697-.365 1.653.935 2.038 1.804.372 1.862-.035 2.721l.986.468h0c.42-.886.528-1.888.308-2.843s-.757-1.809-1.522-2.421v-3.68c0-.433-.172-.849-.478-1.155s-.722-.478-1.155-.478H8.815v1.089h6.533c.144 0 .283.057.385.159s.16.241.16.385h0zm-1.633 4.356v1.089c.433 0 .849.172 1.155.478s.478.722.478 1.155h1.089a2.72 2.72 0 0 0-2.722-2.722z' />
    </SvgIcon>
  );
};
