import { useLocation } from 'react-router-dom';

import { MatchTeamTypes } from 'shared/types/teams/types';

import { TeamForm } from '../../components/team-form';
import { getCurrentStepFromPathName, useCheckValidateStep } from '../../config';

export const HomeTeamForm = () => {
  const location = useLocation();
  const step = getCurrentStepFromPathName(location.pathname);

  useCheckValidateStep(step);

  return <TeamForm teamType={MatchTeamTypes.HOME} step={step} />;
};
