import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, CardActions, CardContent, Stack, Typography } from '@mui/material';
import { Colors, fontWeight } from 'kognia-ui';
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { useCreateUser } from 'api/backoffice/user/use-create-user';
import {
  CreateKogniaUser,
  KogniaUseLocale,
  KogniaUser,
  kogniaUserLocales,
} from 'api/backoffice/user/use-kognia-users/types';
import { FormInputField } from 'shared/components/form/form-input-field';
import { FormSelectField } from 'shared/components/form/form-select-field';
import { getTypeOptions } from 'shared/components/form/get-type-options';
import { IconChevronRight } from 'shared/components/icons/icon-chevron-right';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';

import { CreateUserFormFieldsNames, createUserFormSchema } from './utils/create-user-form';
import { BackofficeContentContainer } from '../../components/backoffice-content-container';
import { SelectClients } from '../../components/select-clients';

export const CreateClientFormLabels = {
  [CreateUserFormFieldsNames.FIRST_NAME]: 'First name',
  [CreateUserFormFieldsNames.LAST_NAME]: 'Last name',
  [CreateUserFormFieldsNames.EMAIL]: 'Email',
  [CreateUserFormFieldsNames.LOCALE]: 'Locale',
  [CreateUserFormFieldsNames.CLIENT_IDS]: 'Environments',
};

export const CreateUserContainer = () => {
  const [createdUser, setCreatedUser] = React.useState<KogniaUser | null>(null);
  const { createUser, isLoading } = useCreateUser();
  const {
    reset,
    register,
    watch,
    setValue,
    handleSubmit: handleFormSubmit,
    formState: { errors },
  } = useForm<CreateKogniaUser>({
    defaultValues: {
      [CreateUserFormFieldsNames.CLIENT_IDS]: [],
      [CreateUserFormFieldsNames.LOCALE]: kogniaUserLocales[0] as KogniaUseLocale,
      [CreateUserFormFieldsNames.EMAIL]: '',
      [CreateUserFormFieldsNames.FIRST_NAME]: '',
      [CreateUserFormFieldsNames.LAST_NAME]: '',
    },
    resolver: zodResolver(createUserFormSchema),
  });

  const handleAddOnSuccess = useCallback(
    (data: KogniaUser) => {
      setCreatedUser(data);
      reset();
    },
    [reset],
  );

  const handleCreateUser = useCallback(
    (data: CreateKogniaUser) => {
      createUser({ data, onSuccess: handleAddOnSuccess });
    },
    [createUser, handleAddOnSuccess],
  );

  const inputFormFields = useMemo(
    () => [CreateUserFormFieldsNames.FIRST_NAME, CreateUserFormFieldsNames.LAST_NAME, CreateUserFormFieldsNames.EMAIL],
    [],
  );

  const setSelectedClients = useCallback(
    (clientIds: string[]) => {
      setValue(CreateUserFormFieldsNames.CLIENT_IDS, clientIds);
    },
    [setValue],
  );

  const { clientIds: selectedClients } = watch();

  return (
    <BackofficeContentContainer>
      <Stack direction={'column'} spacing={2}>
        <Typography variant={'h6'} fontWeight={fontWeight['400']} display='flex' alignItems='center' gap={0.5}>
          <span>Environments</span>
          <IconChevronRight size={IconSizes.small} color={IconColors.storm} />
          <span>Create user</span>
        </Typography>
        <Card>
          <CardContent>
            <form onSubmit={handleFormSubmit(handleCreateUser)}>
              <Stack direction='column' spacing={2}>
                {inputFormFields.map((field) => (
                  <FormInputField
                    key={field}
                    id={field}
                    label={CreateClientFormLabels[field]}
                    error={!!errors[field]}
                    fullWidth
                    helperText={errors[field] && <>{errors[field]?.message}</>}
                    {...register(field)}
                  />
                ))}

                <FormSelectField
                  id={CreateUserFormFieldsNames.LOCALE}
                  label={CreateClientFormLabels[CreateUserFormFieldsNames.LOCALE]}
                  options={getTypeOptions({
                    options: kogniaUserLocales.map((locale) => ({
                      value: locale,
                      label: locale,
                    })),
                  })}
                  error={!!errors[CreateUserFormFieldsNames.LOCALE]}
                  helperText={
                    errors[CreateUserFormFieldsNames.LOCALE] && <>{errors[CreateUserFormFieldsNames.LOCALE]?.message}</>
                  }
                  defaultValue={''}
                  {...register(CreateUserFormFieldsNames.LOCALE)}
                />

                <SelectClients clientIds={selectedClients} onChange={setSelectedClients} />

                <CardActions sx={{ justifyContent: 'flex-end' }}>
                  <Button onClick={() => reset()}>Reset</Button>
                  <LoadingButton
                    color={'primary'}
                    loading={isLoading}
                    size={'large'}
                    type={'submit'}
                    variant={'contained'}
                  >
                    Create
                  </LoadingButton>
                </CardActions>
              </Stack>
            </form>
          </CardContent>
        </Card>
        {createdUser ? (
          <Card key={createdUser.id} sx={{ background: Colors.twilight }}>
            <CardContent>
              <Typography variant={'h5'} marginBottom={2}>
                <strong>First name:</strong> {createdUser.firstName}
                <Typography variant={'body1'} color={Colors.green} marginBottom={2}>
                  (id: {createdUser.id})
                </Typography>
              </Typography>
              <Typography variant={'h5'} marginBottom={2}>
                <strong>Last name:</strong> {createdUser.lastName}
              </Typography>
              <Typography variant={'h5'} marginBottom={2}>
                <strong>Email:</strong> {createdUser.email}
              </Typography>
              <Typography variant={'h5'} marginBottom={2}>
                <strong>Locale:</strong> {createdUser.locale}
              </Typography>
              <Stack direction={'column'} spacing={1}>
                <Typography variant={'body1'} sx={{ fontWeight: fontWeight['500'] }}>
                  Client ids
                </Typography>
                <Typography>
                  {createdUser.clientIds.map((clientId) => (
                    <Box key={clientId} sx={{ color: Colors.storm }}>
                      {clientId}
                    </Box>
                  ))}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        ) : null}
      </Stack>
    </BackofficeContentContainer>
  );
};
