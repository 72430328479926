import { useRouteMatch } from 'react-router-dom';

import { routes } from 'kognia/router/routes';

const useIsTaggingTool = (): boolean => {
  const routerMatch = useRouteMatch();
  return routes.TAGGING_TOOL === routerMatch.path;
};

export default useIsTaggingTool;
