import { useTranslation } from 'react-i18next';

import IconPlaylist from 'shared/components/icons/icon-playlist';

import styles from './PlaylistsNumberOfClips.module.scss';

interface Props {
  number: number;
}

const PlaylistNumberOfClips = ({ number }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.numberOfClips}>
      <IconPlaylist />
      {t('playlists:card.clips', { count: number })}
    </div>
  );
};

export default PlaylistNumberOfClips;
