import { Button } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconPlus from 'shared/components/icons/icon-plus';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';

import { MultimatchFiltersModal } from './multimatch-filters-modal';

interface Props {
  playlistId: string;
}

export const AddMultipleClips = ({ playlistId }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleOnClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  return (
    <>
      <Button
        variant='contained'
        startIcon={<IconPlus color={IconColors.white} size={IconSizes.small} />}
        onClick={handleOpen}
        size='small'
      >
        {t('common:actions.add-clips')}
      </Button>

      {isOpen ? <MultimatchFiltersModal playlistId={playlistId} isOpen={isOpen} onClose={handleOnClose} /> : null}
    </>
  );
};
