import React from 'react';

import { KeypadTag } from 'api/tagging-tool/types';
import { useCurrentTime } from 'shared/components/video-player';

import { TagButtonBase, TagButtonProps } from './TagButtonBase';

const RecordingTagButton = (props: TagButtonProps & { onPress?: (time: number) => (tag: KeypadTag) => void }) => {
  const currentTime = useCurrentTime();

  const onPress = !props.onPress ? undefined : props.onPress(currentTime);

  const buttonProps = {
    ...props,
    onPress,
  };

  return <TagButtonBase {...buttonProps} />;
};

export default RecordingTagButton;
