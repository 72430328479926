import some from 'lodash/some';
import { useEffect, useState } from 'react';

import Checkbox from 'shared/components/checkbox';
import { FilterOption } from 'shared/components/filter-v2';

import styles from './CheckboxList.module.scss';
import FilterControls from '../../filter-v2/filter-controls';

type CheckBoxMenuProps = {
  initialOptions: FilterOption[];
  submitOptions: (options: FilterOption[], autoSubmit: boolean) => void;
  autoSubmit?: boolean;
};

const CheckBoxMenu = ({ initialOptions, submitOptions, autoSubmit = false }: CheckBoxMenuProps) => {
  const [menuOptions, setMenuOptions] = useState<FilterOption[]>(initialOptions);

  useEffect(() => {
    setMenuOptions(initialOptions);
  }, [initialOptions]);

  const onSelectOption = (id: string) => {
    const updatedOptions = menuOptions.map((option) => {
      return option.id === id ? { ...option, ...{ isSelected: !option.isSelected } } : option;
    }) as FilterOption[];
    autoSubmit ? submitOptions(updatedOptions, autoSubmit) : setMenuOptions(updatedOptions);
  };

  const resetOptions = () => {
    const updatedOptions = menuOptions.map((option) => ({ ...option, ...{ isSelected: false } })) as FilterOption[];
    autoSubmit ? submitOptions(updatedOptions, autoSubmit) : setMenuOptions(updatedOptions);
  };

  const handleSubmit = () => {
    submitOptions(menuOptions, autoSubmit);
  };

  const isResetDisabled = !some(menuOptions, ['isSelected', true]);

  return (
    <>
      <div className={styles.listContainer}>
        {menuOptions.map((option: FilterOption) => (
          <div key={option.id} className={styles.checkboxContainer}>
            <Checkbox
              id={option.id}
              key={option.id}
              checked={option.isSelected}
              onClick={() => onSelectOption(option.id)}
            >
              {option.title}
            </Checkbox>
          </div>
        ))}
      </div>
      <FilterControls
        isResetDisabled={isResetDisabled}
        onClickReset={resetOptions}
        onClickApply={handleSubmit}
        showApplyButton={!autoSubmit}
      />
    </>
  );
};

export default CheckBoxMenu;
