import { useQueries, UseQueryResult } from '@tanstack/react-query';

import { useBackendApi } from 'api/hooks/useBackendApi';
import { HTTPMethod } from 'api/types';

import { transformMetadataGame } from '../../api/games/transformers';
import { getMetadataGameQueryKey } from '../../api/games/use-metadata-game';
import { annotationApiUrls } from '../../api/routes';
import { MetadataGame } from '../../types/games';

export const useMetadataGameQueries = (gameIds: string[] = []) => {
  const gameQueries = gameIds.map((gameId) => {
    return {
      queryKey: getMetadataGameQueryKey(gameId),
      queryFn: () => useBackendApi(`${annotationApiUrls.GAMES}/${gameId}`, HTTPMethod.GET, transformMetadataGame),
    };
  });

  return useQueries<MetadataGame[]>({
    queries: gameQueries,
  }) as UseQueryResult<MetadataGame>[];
};
