import { TypeOf } from 'zod';

import { Client } from 'shared/types/user/types';

import { seasonClientsFormSchema } from './form';
import { Season } from '../../../../types/seasons';

export enum SeasonClientsFormFieldsNames {
  id = 'id',
  originalClient = 'originalClient',
  season = 'season',
  clientIds = 'clientIds',
  seasonName = 'seasonName',
}

export interface SeasonClientsForm {
  [SeasonClientsFormFieldsNames.id]?: string;
  [SeasonClientsFormFieldsNames.originalClient]: Client;
  [SeasonClientsFormFieldsNames.seasonName]?: string;
  [SeasonClientsFormFieldsNames.season]?: Season;
  [SeasonClientsFormFieldsNames.clientIds]: string[];
}

export type SeasonClientsFormSchema = TypeOf<typeof seasonClientsFormSchema>;
