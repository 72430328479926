import React, { PropsWithChildren } from 'react';

import { FiltersBar } from 'pages/tactical-analysis/components/tactical-analysis/filters-bar';
import AddToPlaylistMenuTimeline from 'pages/tactical-analysis/components/timeline/add-to-playlist-menu-timeline';
import { Episode, Match } from 'shared/types';
import { GroupedTags } from 'shared/types/tagging-events/types';

import { TimelineTable } from './timeline-table';
import styles from './timeline-table/TimelineTable.module.scss';
import { useTimelineTableData } from '../../hooks/use-timeline-table-data';

type Props = {
  episodes: Episode[];
  match?: Match;
  onTableReady?: () => void;
  recordingId: string;
  tags: GroupedTags;
};

const TimelineContainer = ({ children }: PropsWithChildren) => (
  <div className={styles.timelineContainer}>{children}</div>
);

const TimelineContent = ({ children }: PropsWithChildren) => <div className={styles.timelineContent}>{children}</div>;

export const Timeline = React.memo(({ episodes, match, recordingId, tags }: Props) => {
  const timelineTableData = useTimelineTableData(recordingId);

  if (timelineTableData.rowGroups.length === 0 || !match) return null;

  return (
    <TimelineContainer>
      <TimelineContent>
        <FiltersBar
          episodes={episodes}
          recordingId={recordingId}
          teams={match.teams}
          videoSources={match.videoSources}
        />
        <TimelineTable recordingId={recordingId} episodes={episodes} tags={tags} />
      </TimelineContent>
      <AddToPlaylistMenuTimeline recordingId={recordingId} />
    </TimelineContainer>
  );
});

Timeline.displayName = 'Timeline';
