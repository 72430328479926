import { z } from 'zod';

import { TacticsVariants } from '../tactics/types';

export const RecordingsFiltersEventsSchema = z.object({
  teams: z.array(z.string()),
  event: z.string(),
  zones: z.array(z.number()),
  players: z.array(z.string()),
});

export const RecordingFiltersTacticSchema = z.object({
  teamIds: z.array(z.string()),
  tacticalFundamentalType: z.string(),
  playerIds: z.array(z.string()),
  category: z.enum([TacticsVariants.OFFENSIVE, TacticsVariants.DEFENSIVE]),
});

export const RecordingFiltersTacticsSchema = z.object({
  offensive: z.array(RecordingFiltersTacticSchema),
  defensive: z.array(RecordingFiltersTacticSchema),
});

export const RecordingFiltersScenariosOrTacticsInsideSchema = z.object({
  teams: z.array(z.string()),
  scenario: z.string(),
  zones: z.array(z.number()),
  tactics: RecordingFiltersTacticsSchema,
});

export const RecordingsFiltersSchema = z.object({
  recordingIds: z.array(z.string()),
  eventsStarting: RecordingsFiltersEventsSchema.optional(),
  eventsEnding: RecordingsFiltersEventsSchema.optional(),
  scenariosOrTacticsInside: RecordingFiltersScenariosOrTacticsInsideSchema.optional(),
});
