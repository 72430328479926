import classNames from 'classnames';
import React from 'react';

import styles from './VerticalIconButton.module.scss';
import { IconSizes, SvgIconProps } from '../icons/svg-icon';

interface VerticalIconButtonProps {
  children: React.ReactChildren | string;
  Icon: React.JSXElementConstructor<React.PropsWithChildren<SvgIconProps>>;
  onClick: () => void;
  className?: string;
}

export const VerticalIconButton = ({
  children,
  Icon,
  onClick,
  className = '',
}: React.PropsWithChildren<VerticalIconButtonProps>) => {
  return (
    <button onClick={onClick} className={classNames(styles.root, className)}>
      <span>
        <Icon size={IconSizes.small} />
      </span>
      <span className={styles.text}>{children}</span>
    </button>
  );
};
