import { Switch, SwitchProps } from '@mui/material';
import React from 'react';

import { FormField } from '../form-field';

export interface Props extends SwitchProps {
  id: string;
  label: string;
  required?: boolean;
}

export const FormSwitchField = React.forwardRef(({ id, label, required, defaultChecked, ...rest }: Props, ref: any) => {
  return (
    <FormField id={id} label={label} required={required}>
      <Switch id={id} defaultChecked={defaultChecked} {...rest} ref={ref} />
    </FormField>
  );
});

FormSwitchField.displayName = 'FormSwitchField';
