import { MatchSegment, Scenarios } from 'shared/types/episodes/types';
import { MatchSegmentTypes } from 'shared/types/segment/types';

import { Clip, ClipType, Row, RowType } from './index';

export const generateNoEffectiveTimeClip = (
  id: string,
  rowId: string,
  startTime: number,
  endTime: number,
  title = '',
  idx = 0,
): Clip => {
  return {
    id: `${id}-not-effective-time-${idx}`,
    type: 'not-effective-time',
    startTime,
    endTime,
    rowId,
    title,
    elementId: '',
    clips: [],
  };
};

type Options = {
  timelineTableBlocks: TimelineTableBlock[];
  clipType: ClipType;
  rowId: string;
  clipIdPrefix?: string;
};

export const generateEpisodeClips = ({ timelineTableBlocks, clipType, clipIdPrefix = '', rowId }: Options): Clip[] => {
  const clips: Clip[] = [];
  return timelineTableBlocks.reduce((acc, episode, idx) => {
    const nextEpisodeIndex = idx < timelineTableBlocks.length - 1 ? idx + 1 : null;

    if (idx === 0) {
      acc.push(generateNoEffectiveTimeClip(episode.id, rowId, 0, episode.startTime));
    }

    acc.push({
      id: clipIdPrefix ? `${clipIdPrefix}-${episode.id}` : episode.id,
      type: clipType,
      startTime: episode.startTime,
      endTime: episode.endTime,
      rowId: rowId,
      // TODO add translation or use a translation key
      title: `timeline:episode-name`,
      elementId: episode.id,
      name: episode.name,
      clips: [],
    });

    if (nextEpisodeIndex) {
      const nextEpisode = timelineTableBlocks[nextEpisodeIndex];

      const isHalfTime =
        episode &&
        nextEpisode &&
        nextEpisode.matchSegment === MatchSegmentTypes.SECOND &&
        episode.matchSegment === MatchSegmentTypes.FIRST;

      acc.push(
        generateNoEffectiveTimeClip(
          episode.id,
          episode.id,
          episode.endTime,
          nextEpisode.startTime,
          isHalfTime ? 'HT' : '',
          acc.length,
        ),
      );
    }

    return acc;
  }, clips);
};

export interface TimelineTableBlock {
  id: string;
  name: string;
  startTime: number;
  endTime: number;
  matchSegment: MatchSegment;
}

type GenerateEpisodeContainerClipOptions = {
  id: string;
  title: string;
  timelineTableBlocks: TimelineTableBlock[];
  rowId: string;
  rowType: RowType;
  clipIdPrefix: string;
  teamId?: string;
  entityId?: string;
};

export const generateBlockContainerClip = ({
  id,
  title,
  timelineTableBlocks,
  rowId,
  rowType,
  clipIdPrefix,
  teamId,
  entityId,
}: GenerateEpisodeContainerClipOptions): Row => {
  return {
    id: id,
    type: rowType,
    title,
    entityId,
    teamId,
    isHidden: false,
    clips: generateEpisodeClips({
      timelineTableBlocks,
      clipType: 'block-container',
      rowId: rowId,
      clipIdPrefix: clipIdPrefix,
    }),
  };
};

const SCENARIOS_ORDER: { [key in string]: number } = {
  [Scenarios.POSSESION_A]: 0,
  [Scenarios.POSSESION_BC]: 1,
  [Scenarios.POSSESION_D]: 2,
  [Scenarios.CHANGE_OF_POSSESSION]: 3,
};

export const sortScenarios = (prefix: string) => (a: Row, b: Row) => {
  const ida = a.id.replace(prefix, '');
  const idb = b.id.replace(prefix, '');

  const first = ida in SCENARIOS_ORDER ? SCENARIOS_ORDER[ida] : Number.MAX_SAFE_INTEGER;
  const second = idb in SCENARIOS_ORDER ? SCENARIOS_ORDER[idb] : Number.MAX_SAFE_INTEGER;

  let result = 0;
  if (first < second) result = -1;
  else if (first > second) result = 1;
  return result;
};

export const sortByTitle = (row1: Row, row2: Row) => {
  if (row1.title < row2.title) {
    return -1;
  }
  if (row1.title > row2.title) {
    return 1;
  }
  return 0;
};
