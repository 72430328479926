import { z } from 'zod';

import { TeamFormFieldsNames } from './types';

export enum TeamTypes {
  club = 'club',
  nationalTeam = 'national_team',
}

export const teamFormSchema = z.object({
  [TeamFormFieldsNames.name]: z.string().trim().min(2),
  [TeamFormFieldsNames.teamImage]: z.instanceof(FileList).optional(),
  [TeamFormFieldsNames.teamType]: z.nativeEnum(TeamTypes),
  [TeamFormFieldsNames.abbreviation]: z.string().min(2).or(z.literal('')),
  [TeamFormFieldsNames.countryCode]: z.string().min(2, { message: 'Country is required' }),
});

export const createTeamFormSchema = z.object(teamFormSchema.shape);
export const editTeamFormSchema = z.object(teamFormSchema.shape);
