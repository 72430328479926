import { useQueryClient } from '@tanstack/react-query';

import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { SortDirection } from 'shared/types/filters/types';

import { MetadataTeamsApiResponse } from './types';
import { MetadataTeamsFilters, MetadataTeamWithFixtures } from '../../../types/teams';
import { teamsWithFiltersUrl } from '../../routes';
import { useAllowedBackofficeOrigin } from '../../use-allowed-backoffice-origin';
import { transformTeams } from '../transformers';

const TEAMS_QUERY_KEY = 'teams';

export const useTeams = (): InfinityQueryResult<MetadataTeamWithFixtures, MetadataTeamsFilters> => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();

  return useInfinityQuery<MetadataTeamWithFixtures, MetadataTeamsFilters, MetadataTeamsApiResponse>({
    generateUrl: teamsWithFiltersUrl,
    key: TEAMS_QUERY_KEY,
    options: {
      size: 10,
      sort: 'name',
      sortDirection: SortDirection.DESC,
    },
    transformer: transformTeams,
    queryOptions: {
      enabled: getIsAllowedBackofficeOrigin(),
    },
  });
};

export const useInvalidateTeams = () => {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries([TEAMS_QUERY_KEY]);
};
