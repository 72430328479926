import { Grid, Skeleton } from '@mui/material';

import {
  playlistDetailPageHeaderHeight,
  playlistDetailPageMainHeight,
  playlistDetailPageTimelineHeight,
} from '../../styled';

const PlaylistDetailSkeleton = () => {
  return (
    <Grid container height='100%' paddingX={2}>
      <Grid item xs={12} paddingTop={2} height={playlistDetailPageHeaderHeight}>
        <Skeleton variant='rectangular' width='100%' height='100%' />
      </Grid>
      <Grid item xs={12} paddingY={2} height={playlistDetailPageMainHeight}>
        <Skeleton variant='rectangular' width='100%' height='100%' />
      </Grid>
      <Grid item xs={12} paddingBottom={2} height={playlistDetailPageTimelineHeight}>
        <Skeleton variant='rectangular' width='100%' height='100%' />
      </Grid>
    </Grid>
  );
};

export default PlaylistDetailSkeleton;
