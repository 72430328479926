import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';

import { getQueryParams } from '../../../utils/get-query-params';
import { annotationApiUrls } from '../../routes';
import { CreateTaskApiParams } from '../../types';
import { useAllowedBackofficeOrigin } from '../../use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from '../../utils';

interface CreateTaskParams {
  data: CreateTaskApiParams;
}

export const useCreateTask = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Create task success',
    errorMessage: (data) => metadataApiErrorParser(data, 'Create task error'),
  });

  const createTask = ({ data }: CreateTaskParams) => {
    const queryParams = getQueryParams<CreateTaskApiParams>(data);
    if (!queryParams) return;
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate({
      url: `${annotationApiUrls.CREATE_TASK}?${queryParams}`,
    });
  };

  return { ...mutationRequest, createTask };
};
