import { TextField } from '@mui/material';
import { StandardTextFieldProps } from '@mui/material/TextField/TextField';
import React from 'react';

import { FormField } from '../form-field';

export interface Props extends StandardTextFieldProps {
  id: string;
  label: string;
}

export const FormInputField = React.forwardRef(({ value, id, label, required, ...rest }: Props, ref: any) => {
  return (
    <FormField id={id} label={label} required={required}>
      <TextField id={id} size='small' value={value} required={required} {...rest} ref={ref} />
    </FormField>
  );
});

FormInputField.displayName = 'FormInputField';
