import { List } from '@mui/material';

import { MetadataTeamWithFixtures } from '../../../../types/teams';
import { TeamItem } from '../team-item';

interface Props {
  teams: MetadataTeamWithFixtures[];
}

export const TeamsList = ({ teams }: Props) => {
  const teamsItems = teams.map((team) => {
    return <TeamItem key={team.id} team={team} />;
  });

  return <List>{teamsItems}</List>;
};
