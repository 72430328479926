import { PlayingMode, PlayingModes } from './types';

export const TACTICAL_CAMERA_WITH_OVERLAYS_PLAYING_MODE: PlayingMode = {
  mode: PlayingModes.TACTICAL_CAMERA,
  showOverlays: true,
  useEffectiveTime: true,
};

export const TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE: PlayingMode = {
  mode: PlayingModes.TACTICAL_CAMERA,
  showOverlays: false,
  useEffectiveTime: false,
};

export const PANORAMIC_PLAYING_MODE: PlayingMode = {
  mode: PlayingModes.PANORAMIC,
  showOverlays: false,
  useEffectiveTime: false,
};

export const EPISODES_PLAYING_MODE: PlayingMode = {
  mode: PlayingModes.EPISODES,
  showOverlays: true,
  useEffectiveTime: true,
};

export const PLAYLIST_WITH_OVERLAYS_PLAYING_MODE: PlayingMode = {
  mode: PlayingModes.PLAYLIST,
  showOverlays: true,
  useEffectiveTime: false,
};

export const PLAYLIST_WITHOUT_OVERLAYS_PLAYING_MODE: PlayingMode = {
  mode: PlayingModes.PLAYLIST,
  showOverlays: false,
  useEffectiveTime: false,
};

export const getPlayingMode = ({
  playingMode,
  mode,
  showOverlays,
  useEffectiveTime,
}: {
  playingMode: PlayingMode;
  mode?: PlayingModes;
  showOverlays?: boolean;
  useEffectiveTime?: boolean;
}): PlayingMode => {
  return {
    ...playingMode,
    mode: mode ?? playingMode.mode,
    showOverlays: showOverlays ?? playingMode.showOverlays,
    useEffectiveTime: useEffectiveTime ?? playingMode.useEffectiveTime,
  };
};

export const DEFAULT_TACTICAL_CAMERA_PLAYING_MODE = TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE;
export const DEFAULT_EFFECTIVE_TIME_PLAYING_MODE = EPISODES_PLAYING_MODE;
