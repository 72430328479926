import { SortDirection } from 'shared/types/filters/types';

import { KogniaUser, KogniaUsersFilters, UsersApiResponse } from './types';
import { useInfinityQuery } from '../../../hooks/useInfinityQuery';
import { InfinityQueryResult } from '../../../hooks/useInfinityQuery/types';
import { usersWithFiltersUrl } from '../../../routes';
import { transformUsers } from '../transformer';

const PAGE_SIZE = 10;

interface Options {
  initialFilters?: KogniaUsersFilters;
}

export const INITIAL_USERS_FILTERS: KogniaUsersFilters = {
  firstName: '',
  email: '',
};

export const useKogniaUsers = (
  options: Options = {
    initialFilters: INITIAL_USERS_FILTERS,
  },
): InfinityQueryResult<KogniaUser, KogniaUsersFilters> => {
  return useInfinityQuery<KogniaUser, KogniaUsersFilters, UsersApiResponse>({
    generateUrl: usersWithFiltersUrl,
    initialFilters: options.initialFilters,
    key: 'users',
    options: {
      size: PAGE_SIZE,
      sort: 'name',
      sortDirection: SortDirection.DESC,
    },
    transformer: transformUsers,
  });
};
