import { Stack, styled, Typography } from '@mui/material';
import { fontSizes, fontWeight } from 'kognia-ui';
import React from 'react';

import IconOrder from '../../icons/icon-order';
import { IconSizes } from '../../icons/svg-icon';

interface Props {
  title: string;
  showOrder?: boolean;
  order?: 'asc' | 'desc';
  onClick?: () => void;
}

export const ListHeaderOptionTitle = styled(Typography)(() => ({
  fontSize: fontSizes.default,
  lineHeight: 3,
  fontWeight: fontWeight[500],
}));

export const ListHeaderOption = ({ title, showOrder, order, onClick }: Props) => {
  return (
    <Stack direction={'row'} alignItems={'center'} onClick={onClick} sx={{ cursor: onClick ? 'pointer' : 'default' }}>
      <ListHeaderOptionTitle>{title}</ListHeaderOptionTitle>
      {showOrder ? <IconOrder size={IconSizes.small} order={order} /> : null}
    </Stack>
  );
};
