import { useMachine } from '@xstate/react';
import { useEffect } from 'react';
import { AnyEventObject, createMachine, State } from 'xstate';

import { fetchQueryResponse } from 'api/types';

export enum PAGE_STATES {
  IDLE = 'IDLE',
  READY = 'READY',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  NOT_FOUND = 'NOT_FOUND',
  MISSING_DATA = 'MISSING_DATA',
  INVALID_CLIENT = 'INVALID_CLIENT',
}

export enum PAGE_ACTIONS {
  READY = 'READY',
  LOAD = 'LOAD',
  ERROR = 'ERROR',
  NOT_FOUND = 'NOT_FOUND',
  MISSING_DATA = 'MISSING_DATA',
  SET_INVALID_CLIENT = 'SET_INVALID_CLIENT',
}

export const pageStateMachine = createMachine({
  predictableActionArguments: true,
  id: 'recording-list',
  initial: PAGE_STATES.IDLE,
  states: {
    [PAGE_STATES.IDLE]: {
      on: {
        [PAGE_ACTIONS.LOAD]: PAGE_STATES.LOADING,
      },
    },
    [PAGE_STATES.LOADING]: {
      on: {
        [PAGE_ACTIONS.READY]: PAGE_STATES.READY,
        [PAGE_ACTIONS.ERROR]: PAGE_STATES.ERROR,
        [PAGE_ACTIONS.NOT_FOUND]: PAGE_STATES.NOT_FOUND,
        [PAGE_ACTIONS.MISSING_DATA]: PAGE_STATES.MISSING_DATA,
        [PAGE_ACTIONS.SET_INVALID_CLIENT]: PAGE_STATES.INVALID_CLIENT,
      },
    },
    [PAGE_STATES.ERROR]: {
      on: {
        [PAGE_ACTIONS.LOAD]: PAGE_STATES.LOADING,
      },
    },
    [PAGE_STATES.READY]: {},
    [PAGE_STATES.NOT_FOUND]: {
      on: {
        [PAGE_ACTIONS.LOAD]: PAGE_STATES.LOADING,
      },
    },
    [PAGE_STATES.MISSING_DATA]: {
      on: {
        [PAGE_ACTIONS.LOAD]: PAGE_STATES.LOADING,
      },
    },
    [PAGE_STATES.INVALID_CLIENT]: {
      on: {
        [PAGE_ACTIONS.LOAD]: PAGE_STATES.LOADING,
        [PAGE_ACTIONS.READY]: PAGE_STATES.READY,
        [PAGE_ACTIONS.ERROR]: PAGE_STATES.ERROR,
        [PAGE_ACTIONS.NOT_FOUND]: PAGE_STATES.NOT_FOUND,
        [PAGE_ACTIONS.MISSING_DATA]: PAGE_STATES.MISSING_DATA,
        [PAGE_ACTIONS.SET_INVALID_CLIENT]: PAGE_STATES.INVALID_CLIENT,
      },
    },
  },
});

type Response<T> = {
  PAGE_STATES: typeof PAGE_STATES;
  current: State<unknown, AnyEventObject, any, { value: any; context: unknown }>;
  data: T | undefined;
  isPageLoading: boolean;
  isPageError: boolean;
  isPageNotFound: boolean;
  isPageMissingData: boolean;
  isPageReady: boolean;
  isInvalidClient: boolean;
  refetch?: () => void;
  invalidateQuery?: () => Promise<void>;
};

export type DataFetchingFields = {
  isInvalidClient?: boolean;
};

export type DataFetching<T> = fetchQueryResponse<T> & DataFetchingFields;

const usePageStateMachine = <T>(dataFetching: () => DataFetching<T | undefined>): Response<T | undefined> => {
  const {
    isError,
    isFetching,
    isSuccess,
    data,
    isMissingData = false,
    refetch,
    isInvalidClient: isInvalidDataClient,
  } = dataFetching();

  const [current, send] = useMachine(pageStateMachine);

  useEffect(() => {
    if (isFetching) {
      send(PAGE_ACTIONS.LOAD);
    }

    if (isError) {
      send(PAGE_ACTIONS.ERROR);
    }

    if (isSuccess && !isFetching) {
      if (!data) {
        send(PAGE_ACTIONS.NOT_FOUND);
      } else if (isMissingData) {
        send(PAGE_ACTIONS.MISSING_DATA);
      } else if (isInvalidDataClient) {
        send(PAGE_ACTIONS.SET_INVALID_CLIENT);
      } else {
        send(PAGE_ACTIONS.READY);
      }
    }
  }, [isFetching, isSuccess, isError, data, isInvalidDataClient]);

  const isPageReady = current.matches(PAGE_STATES.READY);
  const isPageLoading = current.matches(PAGE_STATES.LOADING);
  const isPageError = current.matches(PAGE_STATES.ERROR);
  const isPageNotFound = current.matches(PAGE_STATES.NOT_FOUND);
  const isPageMissingData = current.matches(PAGE_STATES.MISSING_DATA);
  const isInvalidClient = current.matches(PAGE_STATES.INVALID_CLIENT);

  return {
    PAGE_STATES,
    current,
    data,
    isPageReady,
    isPageLoading,
    isPageError,
    isPageNotFound,
    isPageMissingData,
    isInvalidClient,
    refetch,
  };
};

export default usePageStateMachine;
