import clsx from 'clsx';
import React, { ReactNode } from 'react';

import styles from './SvgIcon.module.scss';

export enum IconSizes {
  xsmall = 'xsmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge',
}

export enum IconColors {
  default = 'default',
  primary = 'primary',
  white = 'white',
  storm = 'storm',
  iron = 'iron',
  aqua = 'aqua',
  blue = 'blue',
  night = 'night',
  red = 'red',
  peach = 'peach',
  green = 'green',
  ghost = 'ghost',
  shark = 'shark',
  seal = 'seal',
  bluePastel = 'blue-pastel',
  cloud = 'cloud',
  fresco = 'fresco',
  fountain = 'fountain',
}

export interface SvgIconProps {
  className?: string;
  color?: IconColors;
  disabled?: boolean;
  isButton?: boolean;
  size?: IconSizes;
  onClick?: (event: React.MouseEvent<SVGSVGElement>) => void;
}

const SvgIcon = (props: SvgIconProps & { children: ReactNode }): JSX.Element => {
  return (
    <svg
      className={clsx(
        props.className && props.className,
        styles.Icon,
        props.isButton && styles.button,
        styles[`Icon__color-${props.color}`],
        styles[`Icon__size-${props.size}`],
        props.disabled && styles['Icon--disabled'],
      )}
      focusable={'false'}
      onClick={props.onClick}
      role={'img'}
      viewBox={'0 0 24 24'}
    >
      {props.children}
    </svg>
  );
};

SvgIcon.defaultProps = {
  color: IconColors.default,
  size: IconSizes.medium,
};

export default SvgIcon;
