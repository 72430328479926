import { UseFormRegister, FieldErrors } from 'react-hook-form';

import { FormInputField } from 'shared/components/form/form-input-field';

import { FilesSignalityFieldsNames, ProcessGameFormSchema } from '../form';

interface Props {
  register: UseFormRegister<ProcessGameFormSchema>;
  errors: FieldErrors<ProcessGameFormSchema>;
}

export function SignalityForm({ errors, register }: Props) {
  const textFieldsList = [
    {
      id: FilesSignalityFieldsNames.metadataJson,
      label: 'Metadata (.json)',
    },
    {
      id: FilesSignalityFieldsNames.tracking1stJson,
      label: 'Tracking 1st (.json)',
    },
    {
      id: FilesSignalityFieldsNames.tracking2ndJson,
      label: 'Tracking 2nd (.json)',
    },
  ];

  const textFields = textFieldsList.map((textField) => {
    return (
      <FormInputField
        key={textField.id}
        id={textField.id}
        label={textField.label}
        error={!!errors[textField.id]}
        fullWidth
        helperText={errors[textField.id] && <>{errors[textField.id]?.message}</>}
        {...register(textField.id)}
      />
    );
  });

  return <div>{textFields}</div>;
}
