import SvgIcon, { SvgIconProps } from '../svg-icon';

const IconSync = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M5.7 12c.42 0 .7-.28.7-.7V9.2c0-.42.28-.7.7-.7h7.7v2.1L19 7.8 14.8 5v2.1H7.1C5.91 7.1 5 8.01 5 9.2v2.1c0 .42.28.7.7.7zm12.6 0c-.42 0-.7.28-.7.7v2.1c0 .42-.28.7-.7.7H9.2v-2.1L5 16.2 9.2 19v-2.1h7.7c1.19 0 2.1-.91 2.1-2.1v-2.1c0-.42-.28-.7-.7-.7z' />
    </SvgIcon>
  );
};

export default IconSync;
