import { useQueryClient } from '@tanstack/react-query';

import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { SortDirection } from 'shared/types/filters/types';

import { transformCoaches } from './transformer';
import { CoachesApiResponse, CoachesFilters } from './types';
import { Coach } from '../../../types/coaches';
import { getCoachesUrl } from '../../routes';
import { useAllowedBackofficeOrigin } from '../../use-allowed-backoffice-origin';

const QUERY_KEY = 'coaches';

export const useCoaches = (): InfinityQueryResult<Coach, CoachesFilters> => {
  const { isAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();

  return useInfinityQuery<Coach, CoachesFilters, CoachesApiResponse>({
    generateUrl: getCoachesUrl,
    key: QUERY_KEY,
    options: {
      size: 10,
      sort: 'name',
      sortDirection: SortDirection.DESC,
    },
    transformer: transformCoaches,
    queryOptions: {
      enabled: isAllowedBackofficeOrigin,
    },
  });
};

export const useInvalidateCoaches = () => {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
};
