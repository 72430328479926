import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Episode } from 'shared/types';
import { RecordingsFilters } from 'shared/types/recording/types';

import { TacticalAnalysisWithFiltersResponse } from './types';
import { useMutationRequest } from '../../hooks/useMutationRequest';
import { tacticalAnalysisWithFilters } from '../../routes';
import { HTTPMethod } from '../../types';
import { transformFiltersForRequest } from '../utils';

const placeHolderData = [] as Episode[];

export const useTacticalAnalysisFilteredItems = (tacticalAnalysisId: string): TacticalAnalysisWithFiltersResponse => {
  const { t } = useTranslation();
  const { mutate, isLoading, isError, isSuccess, data } = useMutationRequest<Episode[], Episode[]>({
    type: HTTPMethod.POST,
    errorMessage: t('api:use-tactical-analysis-filter.error'),
    transformer: (data: Episode[]): Episode[] => data,
  });

  const getFilters = useCallback(
    (
      appliedFilters: RecordingsFilters,
      onSuccess?: (episodes: Episode[], appliedFilters: RecordingsFilters) => void,
    ) => {
      mutate(
        {
          url: tacticalAnalysisWithFilters(tacticalAnalysisId, false),
          data: transformFiltersForRequest(appliedFilters),
        },
        { onSuccess: (data: Episode[]) => onSuccess && onSuccess(data, appliedFilters) },
      );
    },
    [tacticalAnalysisId, mutate],
  );

  return { getFilters, isLoading, isError, isSuccess, data: !data ? placeHolderData : data };
};
