export enum Locales {
  'en-US' = 'en-US',
  'es-ES' = 'es-ES',
  'de-DE' = 'de-DE',
}

export type Translations = {
  [key in Namespaces]: any;
};

export enum Namespaces {
  'ACCOUNT' = 'account',
  'API' = 'api',
  'CAMERA-SYSTEMS' = 'camera-systems',
  'COMMON' = 'common',
  'CREATE-TEAM' = 'create-team',
  'DESCRIPTIONS' = 'descriptions',
  'FORMS' = 'forms',
  'FUNDAMENTALS' = 'fundamentals',
  'HOME' = 'home',
  'LANGUAGES' = 'languages',
  'PLAYLISTS' = 'playlists',
  'LOGIN' = 'login',
  'PLAYLIST-DETAIL' = 'playlist-detail',
  'RECORDINGS' = 'recordings',
  'RECORDING' = 'recording',
  'RECORDING-EDIT' = 'recording-edit',
  'RECORDING-LIST' = 'recordings-list',
  'RECORDING-PLAYLISTS' = 'recording-playlists',
  'SHARE-PLAYLIST-MODAL' = 'share-playlist-modal',
  'SWITCH_ENVIRONMENT' = 'switch-environment',
  'TAGGING-TOOL' = 'tagging-tool',
  'TIMELINE' = 'timeline',
  'TUTORIALS' = 'tutorials',
  'VIDEO-PLAYER' = 'video-player',
  'FAQS' = 'faqs',
  'FILTERS' = 'filters',
}
