import * as Sentry from '@sentry/react';

import { User } from 'shared/types/user/types';

export const registerTrackedUser = (user: User) => {
  Sentry.setTag('locale', user.locale);
  Sentry.setUser({
    id: user.id,
    username: `${user.firstName} ${user.lastName}`,
    email: user.email,
  });
};

export const unregisterTrackedUser = () => {
  Sentry.configureScope((scope) => scope.setUser(null));
};
