import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';

import { getQueryParams } from '../../../utils/get-query-params';
import { annotationApiUrls } from '../../routes';
import { AnnotationsApiParams } from '../../types';
import { useAllowedBackofficeOrigin } from '../../use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from '../../utils';

interface AnnotationTaskParams {
  data: AnnotationsApiParams;
}

export const useAnnotationTask = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Annotations task success',
    errorMessage: (data) => metadataApiErrorParser(data, 'Annotations task error'),
  });

  const annotateTask = ({ data }: AnnotationTaskParams) => {
    const queryParams = getQueryParams<AnnotationsApiParams>(data);
    if (!queryParams) return;
    if (!getIsAllowedBackofficeOrigin()) return;
    mutationRequest.mutate({ url: `${annotationApiUrls.ANNOTATIONS_TASK}?${queryParams}` });
  };

  return { ...mutationRequest, annotateTask };
};
