import { Box, styled } from '@mui/material';

export const AlignmentSourceRecordingList = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  listStyle: 'none',
  gap: theme.spacing(1),
  '& > li': {
    flex: 1,
  },
}));

export const AlignmentSourceRecordingListItem = styled('li')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

export const AlignmentSourceRecordingCard = styled(Box)(({ theme }) => ({
  flex: '1',
}));

export const AlignmentSourceActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
}));
