import classNames from 'classnames';
import { forwardRef, PropsWithChildren } from 'react';

import styles from './PlayHead.module.scss';

export const PlayHeadContent = forwardRef(
  (
    { isShadow, ...rest }: PropsWithChildren<React.HTMLAttributes<HTMLDivElement> & { isShadow?: boolean }>,
    ref: any,
  ) => <div ref={ref} className={classNames(styles.playHeadContent, { [styles.isShadow]: isShadow })} {...rest} />,
);
PlayHeadContent.displayName = 'PlayHeadContent';

export const PlayHeadHeader = ({ children }: PropsWithChildren) => (
  <div className={classNames(styles.playHeadHeader)}>{children}</div>
);
export const PlayHeadArrowDown = ({ isShadow = false }: { isShadow?: boolean }) => (
  <div className={classNames(styles.playHeadArrowDown, { [styles.isShadow]: isShadow })} />
);

export const PlayHeadTime = ({ children, isShadow = false }: PropsWithChildren<{ isShadow?: boolean }>) => (
  <div className={classNames(styles.playHeadTime, { [styles.isShadow]: isShadow })}>{children}</div>
);
