import { useClientId } from 'shared/contexts/app-state';
import { DataFetching } from 'shared/hooks/use-page-state-machine';

import { useTacticalAnalysisData } from '../../api/use-tactical-analysis-data';
import { UseTimelineMatchTaggingData } from '../../api/use-tactical-analysis-data/types';

export const useTacticalAnalysisPage = (tacticalAnalysisId: string): DataFetching<UseTimelineMatchTaggingData> => {
  const { clientId } = useClientId();
  const tacticalAnalysisData = useTacticalAnalysisData(tacticalAnalysisId);

  const isInvalidClient = !tacticalAnalysisData?.data?.matchWithEpisodes?.match?.clientIds.includes(clientId);

  return {
    ...tacticalAnalysisData,
    isInvalidClient,
  };
};
