import { Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import Spinner from 'shared/components/spinner';
import { useFeatureFlag } from 'shared/contexts/app-state';
import { ENVIRONMENT } from 'shared/utils/env-variables';

import { BackofficeLoadingContainer, BackofficeUnauthorizedContainer } from './index.styled';
import { useIsGoogleAuthorized } from '../../api/use-is-google-authorized';
import { redirectToGoogleLogin } from '../../utils/redirect-to-google-login';

type ProtectedRouteProps = RouteProps & {
  component: any;
  featureFlag?: FEATURE_FLAG;
};

const isLocalhost = ENVIRONMENT !== 'production';

export const BackofficeProtectedRoute = ({
  component: Component,
  featureFlag: featureFlag,
  ...rest
}: ProtectedRouteProps) => {
  const history = useHistory();
  const hasFeatureEnabled = useFeatureFlag(featureFlag);
  const { isBackofficeAuthorized, isLoading } = useIsGoogleAuthorized({ enabled: !isLocalhost });

  useEffect(() => {
    if (featureFlag && !hasFeatureEnabled) {
      history.push('/');
    }
  }, [featureFlag, hasFeatureEnabled, history]);

  if (featureFlag && !hasFeatureEnabled) return null;

  if (isLocalhost ? false : isLoading)
    return (
      <BackofficeLoadingContainer>
        <Spinner />
      </BackofficeLoadingContainer>
    );

  if (!isBackofficeAuthorized && !isLocalhost)
    return (
      <BackofficeUnauthorizedContainer>
        <Typography variant='h6'>Unauthorized</Typography>
        <Typography variant='body1' textAlign='center' marginBottom={2}>
          You can authorize with Google by clicking button below
        </Typography>
        <div>
          <Button variant='contained' onClick={() => redirectToGoogleLogin({ callbackUrl: history.location.pathname })}>
            Authorize with Google
          </Button>
        </div>
      </BackofficeUnauthorizedContainer>
    );

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Component {...props} />
        </>
      )}
    />
  );
};
