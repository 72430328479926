import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import Button, { ButtonTypes, ButtonBorderRadius } from 'shared/components/button';
import IconPlaylist from 'shared/components/icons/icon-playlist';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';

import styles from './PlaylistsButton.module.scss';

type PlaylistsButtonProps = {
  matchId?: string;
};

const PlaylistsButton = ({ matchId }: PlaylistsButtonProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const hasMatchId = !!matchId;
  const buttonColor = hasMatchId ? IconColors.storm : IconColors.iron;
  const playlistsRoute = generatePath(routes.RECORDING_PLAYLISTS, { id: matchId });

  const handleClick = () => {
    if (!hasMatchId) return;

    history.push(playlistsRoute);
  };

  return (
    <Button
      onClick={handleClick}
      type={ButtonTypes.WhiteStormOutlined}
      radius={ButtonBorderRadius.R48}
      disabled={!hasMatchId}
    >
      <div className={styles.tagButtonContent}>
        <IconPlaylist size={IconSizes.small} color={buttonColor} />
        {t('timeline:actions.playlists')}
      </div>
    </Button>
  );
};

export default PlaylistsButton;
