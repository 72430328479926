import { useRecoilValue, useSetRecoilState } from 'recoil';

import { timelineMatchAtoms } from '../../atoms';

export const useTacticalAnalysisAvailableFilters = (recordingId: string) => {
  return useRecoilValue(timelineMatchAtoms.filters(recordingId));
};

export const useSetTacticalAnalysisAvailableFilters = (recordingId: string) => {
  return useSetRecoilState(timelineMatchAtoms.filters(recordingId));
};
