import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';

import Input from 'shared/components/input';
import { useDates } from 'shared/hooks/use-dates';

import styles from './DateSelector.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

type InputProps = {
  onClick?: () => void;
  displayDate?: Date;
  title?: string;
};

type RefType = number;

const dateFormat = 'yyyy-MM-dd';

const InputBar = forwardRef<RefType, InputProps>(({ onClick, displayDate, title }, ref) => {
  const { dateToString } = useDates();
  const displayValue = displayDate ? dateToString(displayDate) : 'Input Date';
  return (
    <Input
      data-testid={'date'}
      id='date'
      label={title}
      placeholder={dateFormat}
      value={displayValue}
      onClick={onClick}
      readOnly
    />
  );
});

type DateSelectorProps = {
  title?: string;
  onChangeDate: (date: Date) => any;
  date?: Date;
};

const DateSelector = ({ onChangeDate, date, title }: DateSelectorProps) => {
  return (
    <DatePicker
      dateFormat={dateFormat}
      selected={date}
      title={title}
      onChange={(date: Date) => onChangeDate(date)}
      customInput={<InputBar displayDate={date} title={title} />}
      wrapperClassName={styles.dateSelector}
      popperPlacement='bottom'
      popperClassName={styles.datePicker}
    />
  );
};

InputBar.displayName = 'InputBar';

export default DateSelector;
