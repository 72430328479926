import styles from './ListItemTitle.module.scss';

interface Props {
  children: React.ReactNode;
}

export const ListItemTitle = ({ children }: Props) => {
  return (
    <div data-testid={'list-item-title'} className={styles.title}>
      {children}
    </div>
  );
};
