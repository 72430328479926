import { useTranslation } from 'react-i18next';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { playlistsUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { Playlist } from 'shared/types/playlist/types';

import { transformPlaylist } from '../transformers';
import { PlaylistApiResponse, PostNewPlaylist } from '../types';

export const generateFetchPlaylistsQueryRef = (recordingId: string) => [`fetchPlaylists-recordingId:${recordingId}`];

export const useCreatePlaylist = () => {
  const { t } = useTranslation();

  const { mutate, isLoading, isError, isSuccess } = useMutationRequest<PlaylistApiResponse, Playlist>({
    type: HTTPMethod.POST,
    errorMessage: t('api:use-add-playlist.error'),
    successMessage: t('api:use-add-playlist.success'),
    transformer: transformPlaylist,
  });

  const createPlaylist = ({
    data,
    onSuccess = () => {},
  }: {
    data: PostNewPlaylist;
    onSuccess?: (res: Playlist) => void;
  }) => {
    mutate({ url: playlistsUrl, data }, { onSuccess: (res: Playlist) => onSuccess && onSuccess(res) });
  };

  return { createPlaylist, isLoading, isError, isSuccess };
};
