import { List } from '@mui/material';

import { Stage } from '../../../../types/stages';
import { StageItem } from '../stage-item';

interface Props {
  stages: Stage[];
}

export const StagesList = ({ stages }: Props) => {
  const stagesItems = stages.map((stage) => {
    return <StageItem key={stage.id} stage={stage} />;
  });

  return <List>{stagesItems}</List>;
};
