import React, { forwardRef, PropsWithChildren } from 'react';

import styles from './RowsContentInner.module.scss';
import { CSS_TIMELINE_INNER_WIDTH } from '../timeline-css-variables';

export const RowsContentInner = forwardRef(
  ({ children, ...rest }: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>, ref: any) => (
    <div {...rest} ref={ref} className={styles.rowsContentInner} style={{ width: `${CSS_TIMELINE_INNER_WIDTH}` }}>
      {children}
    </div>
  ),
);

RowsContentInner.displayName = 'RowsContentInner';
