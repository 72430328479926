import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Colors } from 'kognia-ui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonTypes } from 'shared/components/button';
import IconEdit from 'shared/components/icons/icon-edit';
import IconPlay from 'shared/components/icons/icon-play';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import Input from 'shared/components/input';
import { PlaylistItemType } from 'shared/components/video-player/types';
import { secondsAsDuration } from 'shared/utils/seconds-as-duration';

import styles from './RenameDialog.module.scss';
import { CounterBadge } from '../styled';

interface Props {
  videoIndex: number;
  isOpen: boolean;
  playlistItem: PlaylistItemType;
  setIsOpen: (isOpen: boolean) => void;
  onClose: () => void;
  updatePlaylistItem: (playlistItemId: string, data: { [key: string]: any }) => void;
}

const RenameDialog = ({ isOpen = false, onClose, setIsOpen, playlistItem, updatePlaylistItem, videoIndex }: Props) => {
  const { t } = useTranslation();
  const [inputName, setInputName] = useState(playlistItem.name);

  useEffect(() => {
    setInputName(playlistItem.name);
  }, [playlistItem.name]);

  const handleNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    setInputName(event.currentTarget.value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (inputName === '') {
      return false;
    }
    updatePlaylistItem(playlistItem.id, { name: inputName });
    onClose();
    setIsOpen(false);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setInputName(playlistItem.name);
    setIsOpen(false);
    onClose();
  };

  return (
    <Dialog
      keepMounted={false}
      className={styles.dialog}
      open={isOpen}
      onClose={handleClose}
      onClick={(event) => event.stopPropagation()}
      aria-labelledby='confirm-dialog-title'
      aria-describedby='confirm-dialog-description'
    >
      <div className={styles.dialog}>
        <form onSubmit={handleSubmit}>
          <DialogTitle className={styles.title}>
            <div className={styles.iconContainer}>
              <IconEdit size={IconSizes.small} color={IconColors.night} />
            </div>
            {t('playlist-detail:rename-dialog.title')}
          </DialogTitle>
          <DialogContent className={styles.content}>
            <CounterBadge data-element-name='counter' sx={{ background: Colors.primary, color: Colors.white }}>
              {(videoIndex + 1).toString().padStart(3, '0')}
            </CounterBadge>
            <IconPlay size={IconSizes.small} color={IconColors.primary} />
            <Input
              autoFocus
              autoSelect
              id={`edit-playlist-item-name-${playlistItem.id}`}
              type={'text'}
              value={inputName}
              onChange={handleNameChange}
            />
            <div className={styles.duration}>{secondsAsDuration(playlistItem.duration, false)}</div>
          </DialogContent>
          <DialogActions className={styles.actions}>
            <Button buttonType={'button'} onClick={handleClose} type={ButtonTypes.Tertiary}>
              {t('common:actions.cancel')}
            </Button>
            <Button>{t('common:actions.save')}</Button>
          </DialogActions>
        </form>
      </div>
    </Dialog>
  );
};

export default RenameDialog;
