import { zodResolver } from '@hookform/resolvers/zod';
import { AccordionActions, AccordionDetails } from '@mui/material';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useValidateTask } from '../../../../api/operations/use-validate-task';
import { AnnotationFormLabels, validationTaskFormSchema } from '../../utils/form-fields-validation';
import {
  AnnotationFormFieldsNames,
  ValidateTaskForm,
  ValidationTaskFormSchema,
} from '../../utils/form-fields-validation/types';
import { AnnotationFormActions } from '../annotation-form-actions';
import { AnnotationToolField } from '../form-fields/annotation-tool-field';
import { DryRunField } from '../form-fields/dry-run-field';
import { PipelineTypeField } from '../form-fields/pipeline-type-field';
import { RecordingIdField } from '../form-fields/recording-id-field';

export const ValidateTask = () => {
  const { validateTask } = useValidateTask();

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit: handleFormSubmit,
  } = useForm<ValidationTaskFormSchema>({
    resolver: zodResolver(validationTaskFormSchema),
  });

  const handleSubmit = useCallback(
    (data: ValidateTaskForm) => {
      validateTask({ data });
    },
    [validateTask],
  );

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)}>
      <AccordionDetails>
        <RecordingIdField errors={errors} register={register} />
        <PipelineTypeField errors={errors} register={register} />
        <AnnotationToolField errors={errors} register={register} />
        <DryRunField register={register} />
      </AccordionDetails>
      <AccordionActions>
        <AnnotationFormActions reset={() => reset()} />
      </AccordionActions>
    </form>
  );
};
