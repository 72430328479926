import SvgIcon, { SvgIconProps } from '../svg-icon';

export const IconNoBall = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M12 3.81a8.34 8.34 0 0 0-8.333 8.333c0 2.897 1.48 5.45 3.725 6.943L17.4 5.797A8.3 8.3 0 0 0 12 3.81zm.833 2.75l1.125-.792a6.64 6.64 0 0 1 2.435 1.366L14.87 9.151l-2.037-1.424V6.56zm-2.792-.792l1.125.792v1.167l-3.325 2.325-1.125-.383-.325-1.117c.842-1.308 2.133-2.308 3.65-2.783zM7.9 16.401l-.95.083c-1.008-1.167-1.617-2.683-1.617-4.342 0-.1.008-.192.017-.292l.833-.608 1.15.4L8.55 15.26l-.65 1.142zm2.837-1.758h-.629l-1.125-3.35L12 9.176l1.865 1.305-3.128 4.162zm-1.032 5.513c.729.209 1.499.32 2.295.32a8.34 8.34 0 0 0 8.333-8.333 8.29 8.29 0 0 0-1.27-4.423L9.705 20.156zm4.378-1.688c-.658.217-1.358.342-2.083.342-.408 0-.807-.04-1.196-.113l1.798-2.387h1.531l.533.925-.583 1.233zm2.967-1.983l-.95-.083-.658-1.142 1.217-3.617 1.158-.392.833.608.017.283c0 1.658-.608 3.175-1.617 4.342z' />
      <path d='M17.539 2.847L18.87 3.85 5.831 21.153 4.5 20.15 17.539 2.847z' />
    </SvgIcon>
  );
};
