import { Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import React, { useCallback, useState } from 'react';

import ConfirmDialog from 'shared/components/confirm-dialog';
import IconDelete from 'shared/components/icons/icon-delete';
import IconEdit from 'shared/components/icons/icon-edit';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';

import { StagesListItem } from './styled';
import { useDeleteStage } from '../../../../api/stages/use-delete-stage';
import { StageModal } from '../../../../components/stage-modal';
import { Stage } from '../../../../types/stages';
import { printSummarySeasonName } from '../../../../utils/print-season-name';
import { adjustOrderIndexForDisplay } from '../../utils';
import { StageFormFieldsNames } from '../stage-form/types';

interface Props {
  stage: Stage;
}

export const StageItem = ({ stage }: Props) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
  const { deleteStage } = useDeleteStage();

  const handleEditModalOpen = () => setIsEditModalOpen(true);
  const handleEditModalClose = () => setIsEditModalOpen(false);

  const handleDeleteStage = useCallback(() => {
    if (!stage.season.id) return;

    deleteStage({ stageId: stage.id, seasonId: stage.season.id });
  }, [stage.id, stage.season.id, deleteStage]);

  const options: MenuListOption[] = [
    {
      displayText: 'Edit',
      icon: <IconEdit size={IconSizes.small} color={IconColors.storm} />,
      onClick: handleEditModalOpen,
    },
    {
      displayText: 'Delete',
      icon: <IconDelete size={IconSizes.small} color={IconColors.storm} />,
      onClick: () => setIsConfirmDeleteDialogOpen(true),
    },
  ];

  const stageValues = {
    [StageFormFieldsNames.id]: stage.id,
    [StageFormFieldsNames.name]: stage.name,
    [StageFormFieldsNames.orderIndex]: stage.orderIndex ? stage.orderIndex : 0,
    [StageFormFieldsNames.season]: stage.season ? stage.season : undefined,
    [StageFormFieldsNames.parentStageId]: stage.parentStage?.id ? stage.parentStage.id : undefined,
  };

  return (
    <>
      <StagesListItem key={stage.id}>
        <Typography fontWeight={fontWeight['500']}>{stage.name}</Typography>
        <Typography>{adjustOrderIndexForDisplay(stage.orderIndex)}</Typography>
        <Typography>{printSummarySeasonName(stage.season)}</Typography>
        <Typography>{stage.parentStage?.name}</Typography>

        <KebabMenu options={options} />
      </StagesListItem>

      {isEditModalOpen ? (
        <StageModal open={isEditModalOpen} onClose={handleEditModalClose} defaultValues={stageValues} />
      ) : null}

      <ConfirmDialog
        type={'warning'}
        title={`You sure you want delete "${stage.name}" stage?`}
        cancelLabel={'Cancel'}
        confirmLabel={'Delete'}
        onConfirm={handleDeleteStage}
        isOpen={isConfirmDeleteDialogOpen}
        setIsOpen={setIsConfirmDeleteDialogOpen}
      />
    </>
  );
};
