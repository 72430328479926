import { useTranslation } from 'react-i18next';

import { queryClient } from 'api/config';
import { deletePlaylistItemsUrl } from 'api/routes';

import { useMutationRequest } from '../../hooks/useMutationRequest';
import { HTTPMethod } from '../../types';
import { generateFetchPlaylistQueryRef } from '../usePlaylist';

export const useDeletePlaylistItems = (playlistId: string) => {
  const { t } = useTranslation();
  const fetchQueryRef = generateFetchPlaylistQueryRef(playlistId);
  const { mutate, isLoading, isError } = useMutationRequest({
    type: HTTPMethod.DELETE,
    errorMessage: t('api:use-delete-playlist-items.error'),
    successMessage: t('api:use-delete-playlist-items.success'),
    onSuccess: async () => {
      if (fetchQueryRef) await queryClient.invalidateQueries([fetchQueryRef]);
    },
  });

  const deletePlaylistItems = (playlistItemsIds: string[], onSuccess?: (data: unknown) => void) => {
    mutate({ url: deletePlaylistItemsUrl(playlistId), data: { data: playlistItemsIds } }, { onSuccess });
  };

  return { deletePlaylistItems, isLoading, isError };
};
