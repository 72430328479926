import SvgIcon, { SvgIconProps } from '../svg-icon';

const IconExport = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M18.4706 12.2603h-.1v.1 4.6323c0 .4706-.3854.8559-.8559.8559H6.48529c-.4705 0-.85588-.3853-.85588-.8559v-4.6323-.1h-.1H4.5h-.1v.1 4.6323c0 1.1509.93448 2.0853 2.08529 2.0853H17.5147c1.1509 0 2.0853-.9382 2.0853-2.0853v-4.6323-.1h-.1-1.0294zM8.96404 9.28771l2.27056-2.2671v6.84709.1h.1 1.033.1v-.1-6.84631l2.2594 2.26616.0709.07111.0708-.07118.7242-.72794.0704-.07071-.0705-.07053-3.3162-3.31618c-.1143-.11429-.2721-.18003-.4347-.18003s-.3204.06574-.4347.18003L8.09106 8.4183l-.07089.07088.07106.07071.73162.72795.07066.0703.07053-.07043z' />
    </SvgIcon>
  );
};

export default IconExport;
