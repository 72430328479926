import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useVideoPlayerIsPlaying } from 'shared/components/video-player/hooks';

import styles from './VideoControls.module.scss';

interface Props {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const VideoControls = ({ children, disabled = false, onClick }: Props) => {
  const controlsRef = useRef<HTMLDivElement>(null);
  const isPlaying = useVideoPlayerIsPlaying();
  const [isVisible, setIsVisible] = useState(!isPlaying);

  const handleOnClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.target === controlsRef.current && onClick?.(event);
    },
    [onClick, controlsRef],
  );

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  const handleMouseOver = () => {
    setIsVisible(true);
  };

  useEffect(() => {
    const controls = controlsRef?.current;

    controls?.addEventListener('mouseover', () => {
      handleMouseOver();
    });
    controls?.addEventListener('mouseleave', () => {
      handleMouseLeave();
    });

    return () => {
      controls?.replaceWith(controls?.cloneNode(true));
    };
  }, [controlsRef]);

  const isHidden = !isVisible && isPlaying;
  return (
    <div
      ref={controlsRef}
      className={classNames(styles.controls, { [styles.isHidden]: isHidden, [styles.controlsDisabled]: disabled })}
      onClick={handleOnClick}
    >
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default VideoControls;
