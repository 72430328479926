import { Typography } from '@mui/material';
import { Colors, fontSizes, fontWeight } from 'kognia-ui';
import { useCallback } from 'react';

import { TaggingEventsFilters, TaggingEventsFilterType } from 'api/tagging-tool/types';
import { TaggingEventFilter } from 'api/tagging-tool/use-fetch-tagging-events/utils';
import { CheckboxWithColor } from 'shared/components/checkbox-with-color';
import { IconColors } from 'shared/components/icons/svg-icon';
import { sortArray } from 'tagging-tool/utility/arrays';

import { TagFiltersColumn, TagFiltersItem } from '../styled';

interface Props {
  filterOptions: TaggingEventFilter[];
  filterType: keyof TaggingEventsFilters;
  onChange: (filterType: keyof TaggingEventsFilters, value: TaggingEventsFilterType) => void;
  selectedOptions: TaggingEventsFilterType;
  title: string;
}

export const TagFilterType = ({ title, filterOptions, selectedOptions, onChange, filterType }: Props) => {
  const handleToggleAll = useCallback(() => {
    onChange(filterType, { options: [], all: !selectedOptions.all });
  }, [selectedOptions.all, filterType, onChange]);

  const handleCheckFilterOption = useCallback(
    (value: string) => {
      const isChecked = selectedOptions.options.includes(value);

      const options = isChecked
        ? selectedOptions.options.filter((option) => option !== value)
        : [...selectedOptions.options, value];

      onChange(filterType, {
        options: options ?? [],
        all: Boolean(options && options.length === 0),
      });
    },
    [filterType, selectedOptions.options, onChange],
  );

  const isDisabled = filterOptions.length === 0;

  return (
    <TagFiltersColumn>
      <Typography fontWeight={fontWeight['500']} fontSize={fontSizes.default}>
        {title}
      </Typography>
      <TagFiltersItem>
        <CheckboxWithColor
          checked={selectedOptions.all && !isDisabled}
          customColor={IconColors.primary}
          disabled={isDisabled}
          id={`cb-${filterType}$all`}
          onChange={handleToggleAll}
        />

        <label htmlFor={`cb-${filterType}$all`}>
          <Typography
            fontSize={fontSizes.default}
            color={isDisabled ? Colors.ghost : !selectedOptions.all ? Colors.storm : Colors.night}
          >
            All
          </Typography>
        </label>
      </TagFiltersItem>
      {sortArray({
        data: filterOptions.map((filterOption) => ({
          title: filterOption.title,
          value: filterOption.value,
        })),
        key: 'title',
        asc: true,
      }).map((m, idx) => {
        const key = `cb-${filterType}$${idx}}`;
        const checked = selectedOptions.options.includes(m.value);
        return (
          <TagFiltersItem key={key}>
            <CheckboxWithColor
              id={key}
              customColor={IconColors.primary}
              checked={Boolean(checked)}
              onChange={() => handleCheckFilterOption(m.value)}
            />

            <label htmlFor={key}>
              <Typography fontSize={fontSizes.default} color={!checked ? Colors.storm : Colors.night}>
                {m.title}
              </Typography>
            </label>
          </TagFiltersItem>
        );
      })}
    </TagFiltersColumn>
  );
};
