import { RefObject, useEffect } from 'react';

import { useTimelineZoomLevel } from '../use-timeline-zoom-level';

export const useTimelineWheelZoom = (scrollableContentRef: RefObject<HTMLDivElement>) => {
  const { lessZoomLevel, moreZoomLevel } = useTimelineZoomLevel();

  useEffect(() => {
    if (!scrollableContentRef.current) return;
    const content = scrollableContentRef.current;

    const handleWheel = (e: WheelEvent) => {
      if (!e.ctrlKey) return;

      e.deltaY > 0 ? lessZoomLevel() : moreZoomLevel();
      e.preventDefault();
      e.stopPropagation();
    };

    content.addEventListener('wheel', handleWheel);
    return () => {
      content.removeEventListener('wheel', handleWheel);
    };
  }, [scrollableContentRef, lessZoomLevel, moreZoomLevel]);
};
