import React from 'react';

import { Row } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows';
import { RowHeaderScenario } from '../row-header-scenario';
import { RowHeaderTactic } from '../row-header-tactic';

type Props = { row: Row; recordingId: string };

export const RowHeader = ({ row, recordingId }: Props) => {
  if (row.type === 'scenarios') {
    return <RowHeaderScenario recordingId={recordingId} row={row} />;
  }

  if (row.type === 'tactics' || row.type === 'events' || row.type === 'manual-tags' || row.type === 'filters') {
    return (
      <RowHeaderTactic
        recordingId={recordingId}
        rowId={row.id}
        title={row.title}
        totalNumberOfClips={row.clips.reduce((total, clip) => total + (clip.clips?.length ?? 0), 0)}
      />
    );
  }

  return null;
};
