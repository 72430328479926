import { CheckboxWithColor } from 'shared/components/checkbox-with-color';
import { IconColors } from 'shared/components/icons/svg-icon';

import { PlaylistMenuItem } from '../../add-to-playlist-menu-state/atoms';
import { useRemovePlaylistMenuItem } from '../../add-to-playlist-menu-state/hooks';
import styles from '../PlaylistItemsSelectorMenu.module.scss';

interface Props {
  item: PlaylistMenuItem;
}

const SelectedPlaylistItem = ({ item }: Props) => {
  const removePlaylistMenuItem = useRemovePlaylistMenuItem(item.id);

  return (
    <div key={item.id} className={styles.selectedItem}>
      <CheckboxWithColor customColor={IconColors.white} checked={true} onChange={() => removePlaylistMenuItem()} />
      {item.name}
    </div>
  );
};

export default SelectedPlaylistItem;
