import { List } from '@mui/material';

import { Season } from '../../../../types/seasons';
import { SeasonCompetitionItem } from '../season-competition-item';

interface Props {
  seasons: Season[];
}

export const SeasonCompetitionsList = ({ seasons }: Props) => {
  const seasonsItems = seasons.map((season) => {
    return <SeasonCompetitionItem key={season.id} season={season} />;
  });

  return <List>{seasonsItems}</List>;
};
