import { zodResolver } from '@hookform/resolvers/zod';
import { AccordionActions, AccordionDetails } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useReprocessSync } from '../../../../api/operations/use-reprocess-sync';
import { reprocessSyncFormSchema } from '../../utils/form-fields-validation';
import { ReprocessSyncForm, ReprocessSyncFormSchema } from '../../utils/form-fields-validation/types';
import { AnnotationFormActions } from '../annotation-form-actions';
import { RecordingIdField } from '../form-fields/recording-id-field';

export const ReprocessSync = () => {
  const { reprocessSync } = useReprocessSync();

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit: handleFormSubmit,
  } = useForm<ReprocessSyncFormSchema>({
    resolver: zodResolver(reprocessSyncFormSchema),
  });

  const handleSubmit = useCallback(
    (data: ReprocessSyncForm) => {
      reprocessSync({ data });
    },
    [reprocessSync],
  );

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)}>
      <AccordionDetails>
        <RecordingIdField errors={errors} register={register} />
      </AccordionDetails>
      <AccordionActions>
        <AnnotationFormActions reset={() => reset()} />
      </AccordionActions>
    </form>
  );
};
