import { Stack, Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import { useState } from 'react';

import { IconTimeline } from 'shared/components/icons';
import IconEdit from 'shared/components/icons/icon-edit';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';
import { TeamLogo } from 'shared/components/team-logo';

import { TeamListItem } from './styled';
import { MetadataTeamWithFixtures } from '../../../../types/teams';
import { Countries } from '../../../../utils/countries';
import { EditTeamModal } from '../edit-team-modal';
import { TeamDetailsModal } from '../team-details-modal';

interface Props {
  team: MetadataTeamWithFixtures;
}

export const TeamItem = ({ team }: Props) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const handleEditModalOpen = () => setIsEditModalOpen(true);
  const handleEditModalClose = () => setIsEditModalOpen(false);
  const handleDetailsModalOpen = () => setIsDetailsModalOpen(true);
  const handleDetailsModalClose = () => setIsDetailsModalOpen(false);

  const options: MenuListOption[] = [
    {
      displayText: 'Edit',
      icon: <IconEdit size={IconSizes.small} color={IconColors.storm} />,
      onClick: handleEditModalOpen,
    },
    {
      displayText: 'Details',
      icon: <IconTimeline size={IconSizes.small} color={IconColors.storm} />,
      onClick: handleDetailsModalOpen,
    },
  ];

  return (
    <>
      <TeamListItem key={team.id}>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <TeamLogo src={team.logoUrl ?? undefined} name={team.name} />
          <Typography fontWeight={fontWeight['500']}>{team.name}</Typography>
        </Stack>
        <Typography>{team.abbreviation}</Typography>
        <Typography>
          {Countries.find((item) => item.code.toUpperCase() === team.countryCode?.toUpperCase())?.name || ''}
        </Typography>
        <KebabMenu options={options} />
      </TeamListItem>
      {isEditModalOpen ? (
        <EditTeamModal open={isEditModalOpen} onClose={handleEditModalClose} team={team} teamId={team.id} />
      ) : null}
      {isDetailsModalOpen && team?.fixtures ? (
        <TeamDetailsModal teamDetails={team.fixtures} onClose={handleDetailsModalClose} />
      ) : null}
    </>
  );
};
