import React from 'react';

export const CBChecked = props => {
  return (
    <svg
      stroke='currentColor'
      fill='currentColor'
      strokeWidth='0'
      viewBox='0 0 24 24'
      height='1em'
      width='1em'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M5.8458278,2 L18.1541722,2 C19.4914503,2 19.9763797,2.13923842 20.4652686,2.40069906 C20.9541574,2.66215969 21.3378403,3.04584256 21.5993009,3.53473144 C21.8607616,4.02362033 22,4.50854969 22,5.8458278 L22,18.1541722 C22,19.4914503 21.8607616,19.9763797 21.5993009,20.4652686 C21.3378403,20.9541574 20.9541574,21.3378403 20.4652686,21.5993009 C19.9763797,21.8607616 19.4914503,22 18.1541722,22 L5.8458278,22 C4.50854969,22 4.02362033,21.8607616 3.53473144,21.5993009 C3.04584256,21.3378403 2.66215969,20.9541574 2.40069906,20.4652686 C2.13923842,19.9763797 2,19.4914503 2,18.1541722 L2,5.8458278 C2,4.50854969 2.13923842,4.02362033 2.40069906,3.53473144 C2.66215969,3.04584256 3.04584256,2.66215969 3.53473144,2.40069906 C4.02362033,2.13923842 4.50854969,2 5.8458278,2 Z M15.3141427,8.79289322 L10.0212495,14.0857864 L8.20710678,12.2716438 C7.81658249,11.8811195 7.18341751,11.8811195 6.79289322,12.2716438 C6.40236893,12.6621681 6.40236893,13.295333 6.79289322,13.6858573 L9.31414268,16.2071068 C9.70466697,16.5976311 10.3378319,16.5976311 10.7283562,16.2071068 L16.7283562,10.2071068 C17.1188805,9.81658249 17.1188805,9.18341751 16.7283562,8.79289322 C16.3378319,8.40236893 15.704667,8.40236893 15.3141427,8.79289322 Z' />
    </svg>
  );
};

export const CBDisabled = props => {
  return (
    <svg
      stroke='currentColor'
      fill='currentColor'
      strokeWidth='0'
      viewBox='0 0 24 24'
      height='1em'
      width='1em'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        d='M5.8458278,4 C4.92853228,4 4.70418221,4.043324 4.47792916,4.16432545 C4.33757722,4.23938649 4.23938649,4.33757722 4.16432545,4.47792916 C4.043324,4.70418221 4,4.92853228 4,5.8458278 L4,18.1541722 C4,19.0714677 4.043324,19.2958178 4.16432545,19.5220708 C4.23938649,19.6624228 4.33757722,19.7606135 4.47792916,19.8356745 C4.70418221,19.956676 4.92853228,20 5.8458278,20 L18.1541722,20 C19.0714677,20 19.2958178,19.956676 19.5220708,19.8356745 C19.6624228,19.7606135 19.7606135,19.6624228 19.8356745,19.5220708 C19.956676,19.2958178 20,19.0714677 20,18.1541722 L20,5.8458278 C20,4.92853228 19.956676,4.70418221 19.8356745,4.47792916 C19.7606135,4.33757722 19.6624228,4.23938649 19.5220708,4.16432545 C19.2958178,4.043324 19.0714677,4 18.1541722,4 L5.8458278,4 Z M5.8458278,2 L18.1541722,2 C19.4914503,2 19.9763797,2.13923842 20.4652686,2.40069906 C20.9541574,2.66215969 21.3378403,3.04584256 21.5993009,3.53473144 C21.8607616,4.02362033 22,4.50854969 22,5.8458278 L22,18.1541722 C22,19.4914503 21.8607616,19.9763797 21.5993009,20.4652686 C21.3378403,20.9541574 20.9541574,21.3378403 20.4652686,21.5993009 C19.9763797,21.8607616 19.4914503,22 18.1541722,22 L5.8458278,22 C4.50854969,22 4.02362033,21.8607616 3.53473144,21.5993009 C3.04584256,21.3378403 2.66215969,20.9541574 2.40069906,20.4652686 C2.13923842,19.9763797 2,19.4914503 2,18.1541722 L2,5.8458278 C2,4.50854969 2.13923842,4.02362033 2.40069906,3.53473144 C2.66215969,3.04584256 3.04584256,2.66215969 3.53473144,2.40069906 C4.02362033,2.13923842 4.50854969,2 5.8458278,2 Z M8,13 C7.44771525,13 7,12.5522847 7,12 C7,11.4477153 7.44771525,11 8,11 L16,11 C16.5522847,11 17,11.4477153 17,12 C17,12.5522847 16.5522847,13 16,13 L8,13 Z'
      />
    </svg>
  );
};

export const CBIndetermine = props => {
  return (
    <svg
      stroke='currentColor'
      fill='currentColor'
      strokeWidth='0'
      viewBox='0 0 24 24'
      height='1em'
      width='1em'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        d='M5.8458278,2 L18.1541722,2 C19.4914503,2 19.9763797,2.13923842 20.4652686,2.40069906 C20.9541574,2.66215969 21.3378403,3.04584256 21.5993009,3.53473144 C21.8607616,4.02362033 22,4.50854969 22,5.8458278 L22,18.1541722 C22,19.4914503 21.8607616,19.9763797 21.5993009,20.4652686 C21.3378403,20.9541574 20.9541574,21.3378403 20.4652686,21.5993009 C19.9763797,21.8607616 19.4914503,22 18.1541722,22 L5.8458278,22 C4.50854969,22 4.02362033,21.8607616 3.53473144,21.5993009 C3.04584256,21.3378403 2.66215969,20.9541574 2.40069906,20.4652686 C2.13923842,19.9763797 2,19.4914503 2,18.1541722 L2,5.8458278 C2,4.50854969 2.13923842,4.02362033 2.40069906,3.53473144 C2.66215969,3.04584256 3.04584256,2.66215969 3.53473144,2.40069906 C4.02362033,2.13923842 4.50854969,2 5.8458278,2 Z M8,13 L16,13 C16.5522847,13 17,12.5522847 17,12 C17,11.4477153 16.5522847,11 16,11 L8,11 C7.44771525,11 7,11.4477153 7,12 C7,12.5522847 7.44771525,13 8,13 Z'
      />
    </svg>
  );
};

export const CBUnchecked = props => {
  return (
    <svg
      stroke='currentColor'
      fill='currentColor'
      strokeWidth='0'
      viewBox='0 0 24 24'
      height='1em'
      width='1em'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        d='M5.8458278,4 C4.92853228,4 4.70418221,4.043324 4.47792916,4.16432545 C4.33757722,4.23938649 4.23938649,4.33757722 4.16432545,4.47792916 C4.043324,4.70418221 4,4.92853228 4,5.8458278 L4,18.1541722 C4,19.0714677 4.043324,19.2958178 4.16432545,19.5220708 C4.23938649,19.6624228 4.33757722,19.7606135 4.47792916,19.8356745 C4.70418221,19.956676 4.92853228,20 5.8458278,20 L18.1541722,20 C19.0714677,20 19.2958178,19.956676 19.5220708,19.8356745 C19.6624228,19.7606135 19.7606135,19.6624228 19.8356745,19.5220708 C19.956676,19.2958178 20,19.0714677 20,18.1541722 L20,5.8458278 C20,4.92853228 19.956676,4.70418221 19.8356745,4.47792916 C19.7606135,4.33757722 19.6624228,4.23938649 19.5220708,4.16432545 C19.2958178,4.043324 19.0714677,4 18.1541722,4 L5.8458278,4 Z M5.8458278,2 L18.1541722,2 C19.4914503,2 19.9763797,2.13923842 20.4652686,2.40069906 C20.9541574,2.66215969 21.3378403,3.04584256 21.5993009,3.53473144 C21.8607616,4.02362033 22,4.50854969 22,5.8458278 L22,18.1541722 C22,19.4914503 21.8607616,19.9763797 21.5993009,20.4652686 C21.3378403,20.9541574 20.9541574,21.3378403 20.4652686,21.5993009 C19.9763797,21.8607616 19.4914503,22 18.1541722,22 L5.8458278,22 C4.50854969,22 4.02362033,21.8607616 3.53473144,21.5993009 C3.04584256,21.3378403 2.66215969,20.9541574 2.40069906,20.4652686 C2.13923842,19.9763797 2,19.4914503 2,18.1541722 L2,5.8458278 C2,4.50854969 2.13923842,4.02362033 2.40069906,3.53473144 C2.66215969,3.04584256 3.04584256,2.66215969 3.53473144,2.40069906 C4.02362033,2.13923842 4.50854969,2 5.8458278,2 Z'
      />
    </svg>
  );
};

export const RBChecked = props => {
  return (
    <svg
      stroke='currentColor'
      fill='currentColor'
      strokeWidth='0'
      viewBox='0 0 24 24'
      height='1em'
      width='1em'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        d='M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 Z M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M12,17 C14.7614237,17 17,14.7614237 17,12 C17,9.23857625 14.7614237,7 12,7 C9.23857625,7 7,9.23857625 7,12 C7,14.7614237 9.23857625,17 12,17 Z'
      />
    </svg>
  );
};

export const RBUnchecked = props => {
  return (
    <svg
      stroke='currentColor'
      fill='currentColor'
      strokeWidth='0'
      viewBox='0 0 24 24'
      height='1em'
      width='1em'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 Z M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z' />
    </svg>
  );
};

export const RBDisabled = props => {
  return (
    <svg
      stroke='currentColor'
      fill='currentColor'
      strokeWidth='0'
      viewBox='0 0 32 32'
      height='1em'
      width='1em'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M14 24.667c5.891 0 10.667-4.776 10.667-10.667S19.89 3.333 14 3.333 3.333 8.11 3.333 14 8.11 24.667 14 24.667zm0 2.666C6.636 27.333.667 21.363.667 14 .667 6.636 6.637.667 14 .667c7.364 0 13.333 5.97 13.333 13.333 0 7.364-5.97 13.333-13.333 13.333zm-5.333-12a1.333 1.333 0 1 1 0-2.666h10.666a1.333 1.333 0 1 1 0 2.666H8.667z'
      />
    </svg>
  );
};

export const CrossedEye = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h20v20H0z' />
        <path
          fill='currentColor'
          d='M10 6.103a3.638 3.638 0 0 1 3.63 3.63c0 .129 0 .275-.018.404l2.621 2.621a15.522 15.522 0 0 0 2.842-3.025C16.582 6.323 13.428 4.288 10 4.288c-.678 0-1.338.092-1.998.239l1.595 1.595c.128-.019.275-.019.403-.019zM2.098 3.115l2.769 2.768c-1.43.935-2.769 2.237-3.942 3.85 2.493 3.41 5.647 5.445 9.075 5.445 1.192 0 2.347-.256 3.447-.715l3.171 3.172 1.284-1.283-14.52-14.52-1.284 1.283zM6.37 9.733c0-.678.183-1.301.513-1.833l1.375 1.375a1.99 1.99 0 0 0-.073.458c0 1.009.807 1.815 1.815 1.815.165 0 .312-.036.458-.073l.715.715.66.66a3.671 3.671 0 0 1-1.833.513 3.638 3.638 0 0 1-3.63-3.63z'
        />
      </g>
    </svg>
  );
};

export const Eye = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h20v20H0z' />
        <path
          fill='currentColor'
          d='M10 3.75C5.833 3.75 2.275 6.342.833 10c1.442 3.658 5 6.25 9.167 6.25s7.725-2.592 9.167-6.25c-1.442-3.658-5-6.25-9.167-6.25zm0 10.417A4.168 4.168 0 0 1 5.833 10C5.833 7.7 7.7 5.833 10 5.833S14.167 7.7 14.167 10 12.3 14.167 10 14.167zM10 7.5A2.497 2.497 0 0 0 7.5 10c0 1.383 1.117 2.5 2.5 2.5s2.5-1.117 2.5-2.5-1.117-2.5-2.5-2.5z'
        />
      </g>
    </svg>
  );
};
