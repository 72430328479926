import classNames from 'classnames';
import { ReactNode } from 'react';

import styles from './Card.module.scss';

interface Props {
  children: ReactNode;
  onClick?: () => void;
  className?: string;
}

const Card = ({ children, onClick = undefined, className = '' }: Props) => {
  return (
    <div onClick={onClick} className={classNames(styles.card, className, { [styles.hover]: onClick })}>
      {children}
    </div>
  );
};

export default Card;
