import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';

import { Venue, VenueApi, VenuesApiResponse } from './types';

const transformData = (items: VenueApi[]): Venue[] => {
  return items.map((item) => ({
    id: item.uuid,
    name: item.name,
    city: item.city,
    countryCode: item.country_code,
  }));
};

export const transformVenues = (response: VenuesApiResponse): InfinityQueryDataResult<Venue> => {
  return {
    data: {
      items: transformData(response.data),
      page: response.page,
    },
    nextCursor: response.page.totalPages > response.page.number ? response.page.number + 1 : undefined,
  };
};
