import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';

import { annotationApiUrls } from '../../routes';
import { useAllowedBackofficeOrigin } from '../../use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from '../../utils';
import { MetadataPlayerApiResponse } from '../use-players/types';

type UpdatePlayerParams = Pick<MetadataPlayerApiResponse, 'name' | 'uuid'> & { image?: File };

interface RequestData {
  data: UpdatePlayerParams;
  onSuccess?: () => void;
}

export const useEditPlayer = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.PATCH,
    successMessage: 'Player updated',
    errorMessage: (data) => metadataApiErrorParser(data, 'Error updating player'),
  });

  const editPlayer = ({ data, onSuccess }: RequestData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    const formData = new FormData();
    formData.append('player', JSON.stringify({ name: data.name }));
    data.image && formData.append('player_image', data.image);

    mutationRequest.mutate(
      {
        url: `${annotationApiUrls.PLAYERS}/${data.uuid}`,
        data: formData,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, editPlayer };
};
