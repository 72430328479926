import { ClickAwayListener } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import Button, { ButtonTypes, ButtonPadding } from 'shared/components/button';
import IconEdit from 'shared/components/icons/icon-edit';
import { IconSizes, IconColors } from 'shared/components/icons/svg-icon';
import Spinner from 'shared/components/spinner';

import InputTextField from './input-text-field';
import { PlainText } from './plain-text';
import styles from './UpdateText.module.scss';

type UpdateTextProps = {
  canEdit?: boolean;
  isSubmitting?: boolean;
  onSubmit: (newText: string) => void;
  originalText: string;
};

export const UpdateText = ({ canEdit = true, isSubmitting = false, onSubmit, originalText }: UpdateTextProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [newText, setNewText] = useState<string>(originalText);

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.code === 'Escape') {
      setNewText(originalText);
      setIsEditing(false);
    }

    return false;
  };

  useEffect(() => {
    // TODO add tests to cover the space play/pause functionality
    document.body.addEventListener('keyup', handleKeyUp);

    return () => {
      document.body.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const handleOnSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmit(newText);
    setIsEditing(false);
  };

  const onClickOutside = () => {
    if (!isEditing) return;

    setIsEditing(false);

    if (newText === originalText) return;

    if (isEditing) {
      onSubmit(newText);
    }
  };

  const handleIconClick = () => {
    if (!isEditing) {
      setIsEditing(true);
    } else {
      onClickOutside();
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewText(event.target.value);
  };

  if (!canEdit) return <PlainText originalText={originalText} />;

  return (
    <ClickAwayListener onClickAway={onClickOutside}>
      <div className={styles.container}>
        <form onSubmit={handleOnSubmit}>
          <InputTextField
            ref={inputRef}
            isEditing={isEditing}
            originalText={originalText}
            handleNameChange={handleNameChange}
          />
          {isSubmitting && <Spinner />}
          {!isSubmitting && (
            <Button buttonType={'button'} onClick={handleIconClick} padding={ButtonPadding.P0} type={ButtonTypes.Clear}>
              <IconEdit size={IconSizes.small} color={IconColors.storm} />
            </Button>
          )}
        </form>
      </div>
    </ClickAwayListener>
  );
};
