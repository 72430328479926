import { deleteKeypadTagsUrl, keypadCopyUrl, keypadsUrl, keypadTagsUrl, keypadUrl } from 'api/routes';
import { KeypadApiResponse } from 'api/tagging-tool/types';

import { fetchRequest } from './base';
import {
  FetchCopyKeypadParams,
  FetchCreateKeypadBody,
  FetchCreateKeypadTagBody,
  FetchCreateKeypadTagResponse,
  FetchDeleteKeypadParams,
  FetchDeleteKeypadResponse,
  FetchDeleteKeypadTagParams,
  FetchDeleteKeypadTagResponse,
  FetchKeypadParams,
  FetchKeypadResponse,
  FetchKeypadsResponse,
  FetchUpdateKeypadBody,
  FetchUpdateKeypadResponse,
  FetchUpdateKeypadTagBody,
  FetchUpdateKeypadTagResponse,
} from './keypad';

// GET /keypad

export const fetchKeypads = () => {
  return fetchRequest<FetchKeypadsResponse>({
    url: keypadsUrl(),
  });
};

// POST /keypad

export const fetchCreateKeypad = (body: FetchCreateKeypadBody) => {
  return fetchRequest<FetchKeypadResponse>({
    url: keypadsUrl(),
    body: { name: body.name },
  });
};

export const fetchDuplicateKeypad = (params: FetchCopyKeypadParams) => {
  return fetchRequest<FetchKeypadResponse>({
    url: keypadCopyUrl(params.id),
    body: { name: params.name },
  });
};

// PUT /keypad/${keypadId}

export const fetchUpdateKeypad = (body: FetchUpdateKeypadBody) => {
  return fetchRequest<FetchUpdateKeypadResponse>({
    url: keypadUrl(body.id),
    body: {
      id: body.id,
      isDefault: body.isDefault,
      name: body.name,
    },
    method: 'put',
  });
};

// DELETE /keypad/${keypadId}

export const fetchDeleteKeypad = (params: FetchDeleteKeypadParams) => {
  return fetchRequest<FetchDeleteKeypadResponse>({
    url: keypadUrl(params.id),
    method: 'delete',
  });
};

// GET /keypad/${keypadId}

export const fetchKeypad = (params: FetchKeypadParams) => {
  return fetchRequest<KeypadApiResponse>({
    url: keypadUrl(params.id),
  });
};

// POST /keypad/${keypadId}/tag

export const fetchCreateKeypadTag = (body: FetchCreateKeypadTagBody) => {
  return fetchRequest<FetchCreateKeypadTagResponse>({
    url: keypadTagsUrl(body.keypadId!),
    body: {
      keypadTagId: body.keypadTagId,
      name: body.name,
      typeOfPlay: body.typeOfPlay,
      timeBefore: body.timeBefore,
      timeAfter: body.timeAfter,
      hotKey: body.hotKey,
      description: body.description,
      typeOfPlaySource: body.typeOfPlaySource,
      isEnabled: body.isEnabled,
      isCustom: body.isCustom,
    },
  });
};

// PUT /keypad/${keypadId}/tag/${tagId}

export const fetchUpdateKeypadTag = (body: FetchUpdateKeypadTagBody) => {
  return fetchRequest<FetchUpdateKeypadTagResponse>({
    url: keypadTagsUrl(body.keypadId!),
    body: {
      keypadTagId: body.keypadTagId,
      name: body.name,
      typeOfPlay: body.typeOfPlay,
      timeBefore: body.timeBefore,
      timeAfter: body.timeAfter,
      hotKey: body.hotKey,
      description: body.description,
      typeOfPlaySource: body.typeOfPlaySource,
      isEnabled: body.isEnabled,
      isCustom: body.isCustom,
    },
    method: 'put',
  });
};

// DELETE /keypad/${keypadId}/tag/${tagId}

export const fetchDeleteKeypadTag = (params: FetchDeleteKeypadTagParams) => {
  return fetchRequest<FetchDeleteKeypadTagResponse>({
    url: deleteKeypadTagsUrl(params.keypadId!, params.keypadTagId),
    method: 'delete',
  });
};
