import { Box } from '@mui/material';
import { PopoverOrigin } from '@mui/material/Popover/Popover';
import classNames from 'classnames';
import React, { forwardRef, useCallback, useState } from 'react';

import IconVerticalMenu from 'shared/components/icons/icon-vertical-menu';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';

import styles from './KebabMenu.module.scss';
import { KebabMenuButton } from './styled';
import { MenuList } from '../menu-list';

export type MenuListOption = {
  avoidCloseOnClick?: boolean;
  displayText?: string | JSX.Element;
  icon?: JSX.Element;
  isHidden?: boolean | undefined;
  onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  selected?: boolean;
};

type Props = {
  className?: string;
  id?: string;
  options?: MenuListOption[];
  triggerComponent?: JSX.Element;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  disableButtonPadding?: boolean;
};

export type Ref = HTMLDivElement;

const defaultTriggerComponent = <IconVerticalMenu size={IconSizes.small} color={IconColors.storm} />;

const KebabMenu = forwardRef<Ref, Props>(
  (
    {
      anchorOrigin,
      transformOrigin,
      options = [],
      triggerComponent = defaultTriggerComponent,
      id,
      className = '',
      disableButtonPadding,
    },
    ref,
  ) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuId = id ? `kebab-menu-${id}` : 'kebab-menu';

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = useCallback(
      (event: React.SyntheticEvent | React.MouseEvent<Document, MouseEvent> | MouseEvent | TouchEvent) => {
        event && event.stopPropagation();
        if (!anchorEl) return;

        setAnchorEl(null);
      },
      [anchorEl],
    );

    const menuOptions = options.filter((option) => !option.isHidden);

    return (
      <div ref={ref} className={classNames({ [className]: className })}>
        <Box className={styles.iconContainer}>
          <KebabMenuButton
            aria-controls={menuId}
            aria-haspopup='true'
            onClick={handleClick}
            disableButtonPadding={disableButtonPadding}
          >
            {triggerComponent}
          </KebabMenuButton>
        </Box>
        <MenuList
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          id={menuId}
          isOpen={!!anchorEl}
          onClose={handleClose}
          options={menuOptions}
          transformOrigin={transformOrigin}
        />
      </div>
    );
  },
);

KebabMenu.displayName = 'KebabMenu';

export default KebabMenu;
