import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';

import { annotationApiUrls } from '../../routes';
import { useAllowedBackofficeOrigin } from '../../use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from '../../utils';
import { TeamApi } from '../types';

type UpdateTeamParams = Pick<TeamApi, 'name' | 'abbreviation' | 'country_code' | 'team_type'> & { image?: File };

export const useUpdateTeam = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.PATCH,
    successMessage: 'Team updated',
    errorMessage: (data) => metadataApiErrorParser(data, 'Team update error'),
  });

  const editTeam = ({ data, id, onSuccess }: { data: UpdateTeamParams; id: string; onSuccess?: () => void }) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    const formData = new FormData();
    formData.append(
      'team',
      JSON.stringify({
        name: data.name,
        abbreviation: data.abbreviation,
        country_code: data.country_code,
        team_type: data.team_type,
      }),
    );
    data.image && formData.append('team_image', data.image);

    mutationRequest.mutate(
      {
        url: `${annotationApiUrls.TEAMS}/${id}`,
        data: formData,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, editTeam };
};
