import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';

import { CoachesApiResponse } from './types';
import { Coach } from '../../../types/coaches';
import { CoachApi } from '../types';

const transformData = (items: CoachApi[]): Coach[] => {
  return items.map((item) => ({
    id: item.uuid,
    name: item.name,
    photoUrl: item.photo_url,
  }));
};

export const transformCoaches = (response: CoachesApiResponse): InfinityQueryDataResult<Coach> => {
  return {
    data: {
      items: transformData(response.data),
      page: response.page,
    },
    nextCursor: response.page.totalPages > response.page.number ? response.page.number + 1 : undefined,
  };
};
