import { Box } from '@mui/material';
import React, { PropsWithChildren, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useMetrics } from 'kognia/metrics/hooks/use-metrics';
import IconMinus from 'shared/components/icons/icon-minus';
import IconPlus from 'shared/components/icons/icon-plus';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import RangeSlider from 'shared/components/range-slider';
import { MetricsData, MetricsNames } from 'shared/types/metrics';
import { getIsFormTag } from 'shared/utils/is-form-tag';

import styles from './Zoom.module.scss';
import { useTimelineZoomLevel } from '../../../hooks/use-timeline-zoom-level';

export const ZOOM_LEVELS = {
  EXTRA_LARGE: 0,
  LARGE: 1,
  MEDIUM_LARGE: 2,
  MEDIUM: 3,
  MEDIUM_SMALL: 4,
  SMALL: 5,
  EXTRA_SMALL: 6,
};

export const ZOOM_LEVELS_VALUES = Object.values(ZOOM_LEVELS);
export type ZOOM_LEVELS_TYPE = (typeof ZOOM_LEVELS_VALUES)[number];

const MIN_ZOOM = ZOOM_LEVELS_VALUES[ZOOM_LEVELS.EXTRA_LARGE];
const MAX_ZOOM = ZOOM_LEVELS_VALUES[ZOOM_LEVELS.EXTRA_SMALL];
const STEP_ZOOM = 1;
const isNextStepEnabled = (value: number) => value < MAX_ZOOM;
const isPreviousStepEnabled = (value: number) => value > MIN_ZOOM;

const Separator = ({ left }: { left: number }) => <div className={styles.separator} style={{ left: `${left}%` }} />;
const SeparatorsList = () => {
  return (
    <>
      {Array(MAX_ZOOM - 1)
        .fill('')
        .map((_, idx) => (
          <Separator key={idx} left={(idx + 1) * (100 / ZOOM_LEVELS_VALUES.length + 2)} />
        ))}
    </>
  );
};
const ZoomContainer = ({ children }: PropsWithChildren) => <div className={styles.zoomRangeContainer}>{children}</div>;

const ZoomRange = (): JSX.Element => {
  const { t } = useTranslation();
  const { zoomLevel, setZoomLevel } = useTimelineZoomLevel();
  const { pushEvent } = useMetrics();

  const handleOnChange = useCallback(
    (value: ZOOM_LEVELS_TYPE) => {
      pushEvent<MetricsData[MetricsNames.TACTICAL_ANALYSIS_CHANGE_ZOOM]>(MetricsNames.TACTICAL_ANALYSIS_CHANGE_ZOOM, {
        zoom_level: value.toString(),
      });
      setZoomLevel(value);
    },
    [pushEvent, setZoomLevel],
  );

  const getPreviousStep = useCallback(() => {
    if (isPreviousStepEnabled(zoomLevel)) {
      handleOnChange(zoomLevel - 1);
    }
  }, [zoomLevel, handleOnChange]);

  const getNextStep = useCallback(() => {
    if (isNextStepEnabled(zoomLevel)) {
      handleOnChange(zoomLevel + 1);
    }
  }, [zoomLevel, handleOnChange]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const isFormTag = getIsFormTag((event.target as HTMLElement).tagName);
      if (isFormTag || event.ctrlKey || event.metaKey) return;
      if (event.key === '+') {
        getNextStep();
      } else if (event.key === '-') {
        getPreviousStep();
      }
    },
    [getNextStep, getPreviousStep],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <ZoomContainer>
      <label>{t('timeline:zoom-range.title')}</label>
      <div role='button' onClick={getPreviousStep}>
        <IconMinus
          color={IconColors.default}
          disabled={!isPreviousStepEnabled(zoomLevel)}
          isButton
          size={IconSizes.small}
        />
      </div>
      <Box sx={{ position: 'relative', alignItems: 'center', display: 'flex', width: '142px   ' }}>
        <SeparatorsList />
        <RangeSlider max={MAX_ZOOM} min={MIN_ZOOM} onChange={handleOnChange} step={STEP_ZOOM} value={zoomLevel} />
      </Box>
      <div role='button' onClick={getNextStep}>
        <IconPlus color={IconColors.default} disabled={!isNextStepEnabled(zoomLevel)} isButton size={IconSizes.small} />
      </div>
    </ZoomContainer>
  );
};

export default ZoomRange;
