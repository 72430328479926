import { useTranslation } from 'react-i18next';

import NotFound from 'shared/components/not-found';
import { Playlist } from 'shared/types';

import styles from './RercordingPlaylistsPage.module.scss';
import PlaylistsGrid from '../playlists-grid';

interface Props {
  playlists: Playlist[];
  matchId: string;
}
const RecordingPlaylistsPage = ({ playlists, matchId }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.heading}>
        <h1 className={styles.title}>{t('recording-playlists:title')}</h1>
      </div>
      {playlists.length === 0 && (
        <NotFound
          header={t('recording-playlists:not-found.header')}
          description={t('recording-playlists:not-found.description')}
        />
      )}
      {playlists.length > 0 && <PlaylistsGrid recordingId={matchId} playlists={playlists} />}
    </>
  );
};

export default RecordingPlaylistsPage;
