import SvgIcon, { SvgIconProps } from '../svg-icon';

const IconBackward = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M6 6H8V18H6V6ZM18 18V6L9.5 12L18 18Z' />
    </SvgIcon>
  );
};

export default IconBackward;
