import { List } from '@mui/material';

import { MetadataGame } from '../../../../types/games';
import { GameItem } from '../metadata-game-item';

interface Props {
  games: MetadataGame[];
}

export const GamesList = ({ games }: Props) => {
  const gamesItems = games.map((game) => {
    return <GameItem key={game.id} game={game} />;
  });

  return <List>{gamesItems}</List>;
};
