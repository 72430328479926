import { UseQueryResult } from '@tanstack/react-query';
import orderBy from 'lodash/orderBy';
import some from 'lodash/some';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { matchOrLiveTaggingEventsUrl } from 'api/routes';
import { useUser } from 'shared/contexts/app-state';
import { SortDirection, TaggingEvents } from 'shared/types';
import { TypeOfPlay } from 'shared/types/type-of-play/types';

import {
  applyTypeOfPlayFilter,
  applyUserFilter,
  generateTaggingEventsFilters,
  TaggingEventsFilterOptions,
} from './utils';
import { queryClient } from '../../config';
import { transformTaggingEvents } from '../transformers/tagging-events';
import { TaggingEvent, TaggingEventsFilters, TaggingEventsOptions } from '../types';

interface useFetchTaggingToolEventsInterface {
  (
    recordingId: string,
    options?: TaggingEventsOptions,
  ): UseQueryResult<TaggingEvents> & {
    addTaggingEvent: (data: TaggingEvent) => void;
    addMultipleTaggingEvents: (data: TaggingEvent[]) => void;
    appliedFilters: TaggingEventsFilters;
    filterOptions: TaggingEventsFilterOptions;
    hasFiltersApplied: boolean;
    removeTaggingEvent: (id: string) => void;
    resetFilters: () => TaggingEventsFilters;
    setFilters: (value: TaggingEventsFilters) => void;
    setQueryData: (data: TaggingEvents) => void;
    taggingEvents: TaggingEvent[];
    updateTaggingEvent: (taggingEvent: TaggingEvent) => void;
  };
}

export const DEFAULT_TAGGING_EVENTS_FILTERS: TaggingEventsFilters = {
  [TypeOfPlay.Offense]: { options: [], all: true },
  [TypeOfPlay.Defense]: { options: [], all: true },
  [TypeOfPlay.Transition]: { options: [], all: true },
  [TypeOfPlay.imported]: { options: [], all: true },
  user: { options: [], all: false },
};

export const useFetchTaggingEvents: useFetchTaggingToolEventsInterface = (
  recordingId,
  options = {
    isLive: false,
    sortOrder: SortDirection.DESC,
    filters: DEFAULT_TAGGING_EVENTS_FILTERS,
  },
) => {
  const user = useUser();
  const { t } = useTranslation();
  const initialFilters = useMemo(
    () => ({
      ...DEFAULT_TAGGING_EVENTS_FILTERS,
      user: { options: [user.id], all: false },
    }),
    [user.id],
  );
  const queryRef = useMemo(() => [`tagging-events-${recordingId}`, 'tagging-events'], [recordingId]);
  const [appliedFilters, setAppliedFilters] = useState<TaggingEventsFilters>(options.filters ?? initialFilters);
  const request = useFetchRequest<TaggingEvents>({
    queryRef,
    url: matchOrLiveTaggingEventsUrl(recordingId, options.isLive),
    transformer: transformTaggingEvents,
    options: { staleTime: 100 },
  });

  const taggingEvents: TaggingEvent[] = useMemo(() => {
    if (!request?.data) return [];

    const filteredTags = request.data.tags
      .filter(applyTypeOfPlayFilter(appliedFilters, TypeOfPlay.Offense))
      .filter(applyTypeOfPlayFilter(appliedFilters, TypeOfPlay.Defense))
      .filter(applyTypeOfPlayFilter(appliedFilters, TypeOfPlay.Transition))
      .filter(applyTypeOfPlayFilter(appliedFilters, TypeOfPlay.imported))
      .filter(applyUserFilter(appliedFilters));

    return orderBy(filteredTags, ['time'], [options.sortOrder ?? SortDirection.ASC]);
  }, [appliedFilters, request.data, options]);

  const setQueryData = useCallback(
    (data: TaggingEvents) => {
      queryClient.setQueryData(queryRef, data);
    },
    [queryRef],
  );

  const addTaggingEvent = useCallback(
    (event: TaggingEvent) => {
      if (request.data) {
        setQueryData({ ...request.data, tags: [...(request.data?.tags ?? []), event] });
      }
    },
    [setQueryData, request.data],
  );

  const addMultipleTaggingEvents = useCallback(
    (events: TaggingEvent[]) => {
      if (request.data) {
        setQueryData({ ...request.data, tags: [...(request.data?.tags ?? []), ...events] });
      }
    },
    [setQueryData, request.data],
  );

  const removeTaggingEvent = useCallback(
    (id: string) => {
      if (request.data) {
        setQueryData({ ...request.data, tags: (request.data?.tags ?? []).filter((tag) => tag.id !== id) });
      }
    },
    [setQueryData, request.data],
  );

  const updateTaggingEvent = useCallback(
    (updatedTaggingEvent: TaggingEvent) => {
      if (request.data?.tags) {
        setQueryData({
          ...request.data,
          tags: (request.data.tags ?? []).map((taggingEvent) =>
            taggingEvent.id === updatedTaggingEvent.id ? updatedTaggingEvent : taggingEvent,
          ),
        });
      }
    },
    [setQueryData, request.data],
  );

  const filterOptions = useMemo(() => {
    return generateTaggingEventsFilters(request?.data?.tags ?? [], request.data?.usersInfo ?? [], t);
  }, [t, request?.data]);

  const setFilters = useCallback(
    (value: TaggingEventsFilters) => {
      setAppliedFilters(value);
    },
    [setAppliedFilters],
  );

  const resetFilters = useCallback(() => {
    setAppliedFilters(initialFilters);
    return initialFilters;
  }, [initialFilters]);

  const hasFiltersApplied = useMemo(() => {
    return some(appliedFilters, (filterType) => filterType.options.length > 0);
  }, [appliedFilters]);

  return {
    ...request,
    addMultipleTaggingEvents,
    addTaggingEvent,
    appliedFilters,
    filterOptions,
    hasFiltersApplied,
    removeTaggingEvent,
    resetFilters,
    setFilters,
    setQueryData,
    taggingEvents,
    updateTaggingEvent,
  };
};
