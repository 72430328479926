import { Colors } from 'kognia-ui';
import { ReactNode, SVGProps } from 'react';

import { TagEventsSkeletonContainer, TagEventsSkeletonContent } from './styled';

interface Props {
  children: ReactNode;
}

const Stripes = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='100%' height={'504px'} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect width='100%' height={'114px'} rx={4} fill={Colors.athens} />
      <rect y={130} width='100%' height={'114px'} rx={4} fill={Colors.athens} />
      <rect y={260} width='100%' height={'114px'} rx={4} fill={Colors.athens} />
      <rect y={390} width='100%' height={'114px'} rx={4} fill={Colors.athens} />
    </svg>
  );
};

export const TagEventsListSkeleton = ({ children }: Props) => {
  return (
    <TagEventsSkeletonContainer>
      <Stripes />
      <TagEventsSkeletonContent>{children}</TagEventsSkeletonContent>
    </TagEventsSkeletonContainer>
  );
};
