import reduce from 'lodash/reduce';

import { FilterOptions, FiltersList, Playlist, PlaylistItemWithoutVideoSources, TacticIdOrAll } from 'shared/types';
import { Pagination } from 'shared/types/pagination/types';

import { transformRecordingByName } from '../../recording/transformers';
import {
  PlaylistApiResponse,
  PlaylistFiltersAPIResponse,
  PlaylistItemApiResponse,
  PlaylistsWithFiltersAPIResponse,
} from '../types';

export const transformPlaylistItem = (
  playlistItem: PlaylistItemApiResponse,
  recordingHasHomographies = false,
): PlaylistItemWithoutVideoSources => {
  return {
    id: playlistItem.id,
    endTime: playlistItem.endTime,
    name: playlistItem.name,
    startTime: playlistItem.startTime,
    index: playlistItem.index,
    origin: playlistItem.origin,
    recordingId: playlistItem.recordingId,
    hasHomographies: recordingHasHomographies,
    fundamentalsSelected: {
      tacticalAnalysisId: playlistItem.fundamentalsSelected.tacticalAnalysisId,
      fundamentalsSelected: playlistItem.fundamentalsSelected.fundamentalsSelected as TacticIdOrAll[],
    },
    episodesVideos: playlistItem.episodesVideos.map((episodeVideo) => ({
      startTime: episodeVideo.startTime,
      endTime: episodeVideo.endTime,
      videoSrc: {
        endTime: episodeVideo.endTime,
        endTimeInMatch: episodeVideo.endTime,
        poster: episodeVideo.videoSrc.poster,
        src: episodeVideo.videoSrc.src,
        srcDownload: episodeVideo.videoSrc.srcDownload,
        startTime: episodeVideo.startTime,
        startTimeInMatch: episodeVideo.startTime,
        type: episodeVideo.videoSrc.playBackType,
        id: episodeVideo.videoSrc.id,
      },
    })),
    videoSourceRef: playlistItem.videoSourceRef,
  };
};

export const transformPlaylist = (data: PlaylistApiResponse): Playlist => {
  const recordingsByName = data.recordings.map((recordingItem) => transformRecordingByName(recordingItem));
  return {
    createdAt: data.createdAt,
    clientId: data.clientId,
    description: data.description,
    id: data.id,
    name: data.name,
    playlistItems: data.playlistItems.map((playlistItem) =>
      transformPlaylistItem(
        playlistItem,
        recordingsByName.find((recording) => recording.id === playlistItem.recordingId)?.hasHomographies || false,
      ),
    ),
    poster: data.poster,
    duration: data.duration,
    user: {
      userId: data.user.userId,
      firstName: data.user.firstName,
      lastName: data.user.lastName,
    },
    updatedAt: data.updatedAt,
    recordings: recordingsByName,
    teams: data.teams,
  };
};

interface PlaylistsResponseData {
  filters: FiltersList;
  page: Pagination;
  playlists: Playlist[];
}

interface PlaylistsResponse {
  data: PlaylistsResponseData;
  nextCursor?: number;
}

const transformFilters = (filters: PlaylistFiltersAPIResponse) => {
  return reduce(
    filters,
    (acc, filter, filterKey) => {
      return {
        ...acc,
        [filterKey]: {
          title: filter.title,
          options: reduce(
            filter.options,
            (acc, option, optionKey) => {
              return {
                ...acc,
                [optionKey]: { title: option.title },
              };
            },
            {} as FilterOptions,
          ),
        },
      };
    },
    {} as FiltersList,
  );
};

export const transformPlaylists = (response: PlaylistsWithFiltersAPIResponse): PlaylistsResponse => {
  return {
    data: {
      playlists: response.playlists.content.map(transformPlaylist),
      page: response.playlists.page,
      filters: transformFilters(response.filters),
    },
    nextCursor:
      response.playlists.page.totalPages > response.playlists.page.number
        ? response.playlists.page.number + 1
        : undefined,
  };
};
