import React from 'react';

import AddToPlaylistMenu from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu';

import { useTimelineIsMultiselectModeActive } from '../../../hooks/use-timeline-is-multiselect-mode-active';

interface Props {
  recordingId: string;
}

const AddToPlaylistMenuTimeline = ({ recordingId }: Props) => {
  const { isMultiselectModeActive, setIsMultiselectModeActive } = useTimelineIsMultiselectModeActive(recordingId);

  return (
    <AddToPlaylistMenu
      isOpen={isMultiselectModeActive}
      onClose={() => setIsMultiselectModeActive(false)}
      recordingId={recordingId}
    />
  );
};

export default React.memo(AddToPlaylistMenuTimeline);
