import { QueryClient } from '@tanstack/react-query';

export const apiHost = '/api';

export const DEFAULT_VIDEO_TYPE = 'application/dash+xml';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
