import classNames from 'classnames';
import React from 'react';

import styles from './ActionIcon.module.scss';

const ActionIcon = (props: { children: React.ReactElement; text: string; onClick?: () => void; active?: boolean }) => {
  const { children, text, onClick } = props;

  return (
    <div className={styles.container} onClick={onClick}>
      {children}
      <div className={classNames(styles.text, { [styles.active]: props.active })}>{text}</div>
    </div>
  );
};

export default ActionIcon;
