import { Box, CSSObject, Divider, styled, Theme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import classNames from 'classnames';
import { Colors } from 'kognia-ui';
import { ReactNode } from 'react';

import styles from './SidebarLayout.module.scss';

export const openSidebarWidth = 256;
export const collapsedSidebarWidth = 73;

const openedMixin = (theme: Theme): CSSObject => ({
  width: openSidebarWidth,
  padding: `${theme.spacing(5)} ${theme.spacing(2)}`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: collapsedSidebarWidth,
  padding: `${theme.spacing(5)} ${theme.spacing(2)}`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
});

export const SidebarLayoutSidebar = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: openSidebarWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export const SidebarLayoutWrapper = ({ children }: { children: ReactNode }) => (
  <div className={styles.sidebarLayoutWrapper}>{children}</div>
);

export const SidebarLayoutContent = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })<{ open: boolean }>(
  ({ open }) => ({
    backgroundColor: Colors.background,
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100%',
    maxWidth: open ? `calc(100% - ${openSidebarWidth}px)` : `calc(100% - ${collapsedSidebarWidth}px)`,
    flexGrow: 1,
  }),
);

export const SidebarUserInfo = ({ children }: { children: ReactNode }) => (
  <div className={styles.sidebarUserInfo}>{children}</div>
);

export const UserAvatar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '24px',
  minWidth: '24px',
  height: '24px',
  overflow: 'hidden',
  borderRadius: '50%',
  marginRight: theme.spacing(0.25),
  '& img': {
    maxWidth: '100%',
  },
}));

export const SidebarDivider = () => <Divider className={styles.sidebarDivider} />;
export const SidebarContent = ({ children }: { children: ReactNode }) => (
  <div className={styles.sidebarContent}>{children}</div>
);

export const SidebarLogoWrapper = ({ children, spacingBottom }: { children: ReactNode; spacingBottom: boolean }) => (
  <div className={classNames({ [styles.hasSpacingBottom]: spacingBottom })}>{children}</div>
);

export const SidebarHeader = ({ children, open }: { children: ReactNode; open: boolean }) => (
  <div className={classNames(styles.sidebarHeader, { [styles.isOpen]: open })}>{children}</div>
);
