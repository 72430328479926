import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { useCurrentPlaylistItem } from 'shared/components/video-player';
import { PlayingMode } from 'shared/components/video-player/types';
import { useFeatureFlag } from 'shared/contexts/app-state';

import { timelineMatchAtoms } from '../../atoms';
import { generatePlaylistItemFromEpisodes } from '../../components/tactical-analysis/utils/generate-timeline-playlist';
import { useTacticalAnalysisEpisodes } from '../use-tactical-analysis-episodes';
import { useTacticalAnalysisMatch } from '../use-tactical-analysis-match';
import { useTimelineTactics } from '../use-timeline-tactics';

export const useGenerateTimelinePlaylist = (recordingId: string) => {
  const episodes = useTacticalAnalysisEpisodes(recordingId);
  const match = useTacticalAnalysisMatch(recordingId);
  const timelineVideoSource = useRecoilValue(timelineMatchAtoms.matchVideoSource(recordingId));
  const { selectedTactics: fundamentalsSelected } = useTimelineTactics(recordingId);
  const timelineEpisodesOverlaysFeatureFlag = useFeatureFlag(FEATURE_FLAG.EPISODES_OVERLAYS_TIMELINE);
  const currentPlaylistItem = useCurrentPlaylistItem();

  return useCallback(
    (playingMode: PlayingMode) =>
      generatePlaylistItemFromEpisodes({
        episodes,
        fullVideoSourceDuration: match.defaultVideoSource.duration,
        tacticalCameraVideo: timelineVideoSource,
        playingMode,
        recordingId,
        fundamentalsSelected,
        hasHomographies: !timelineEpisodesOverlaysFeatureFlag ? currentPlaylistItem.hasHomographies : false,
      }),
    [
      timelineVideoSource,
      currentPlaylistItem.hasHomographies,
      match,
      episodes,
      recordingId,
      fundamentalsSelected,
      timelineEpisodesOverlaysFeatureFlag,
    ],
  );
};
