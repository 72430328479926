import { useQueryClient } from '@tanstack/react-query';

import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { SortDirection } from 'shared/types/filters/types';

import { transformPlayersResponse } from './transformer';
import { PlayerApiResponse } from './types';
import { MetadataPlayersApiFilters, MetadataPlayerSummary } from '../../../types/players';
import { playersWithFiltersUrl } from '../../routes';
import { useAllowedBackofficeOrigin } from '../../use-allowed-backoffice-origin';

const PLAYERS_QUERY_KEY = 'players';

export const usePlayers = (): InfinityQueryResult<MetadataPlayerSummary, MetadataPlayersApiFilters> => {
  const { isAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();

  return useInfinityQuery<MetadataPlayerSummary, MetadataPlayersApiFilters, PlayerApiResponse>({
    generateUrl: playersWithFiltersUrl,
    key: PLAYERS_QUERY_KEY,
    options: {
      size: 10,
      sort: 'name',
      sortDirection: SortDirection.DESC,
    },
    transformer: transformPlayersResponse,
    queryOptions: {
      enabled: isAllowedBackofficeOrigin,
    },
  });
};

export const useInvalidatePlayers = () => {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries({ queryKey: [PLAYERS_QUERY_KEY] });
};
