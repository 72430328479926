import queryString from 'query-string';
import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { AddToPlaylistMenuStateProvider } from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/add-to-playlist-menu-state';
import Container from 'shared/components/container';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import Spinner from 'shared/components/spinner';
import { SwitchEnvironment } from 'shared/components/switch-environment';
import { VideoPlayerStateProvider } from 'shared/components/video-player';
import { PANORAMIC_PLAYING_MODE } from 'shared/components/video-player/defaultPlayingModes';
import { PlaylistItemType } from 'shared/components/video-player/types';
import usePageStateMachine from 'shared/hooks/use-page-state-machine';

import { TagRecordingScreen } from './components/tag-recording-screen';
import { UseTaggingToolTagRecordingDataType } from './hooks/use-tagging-tool-tag-recording-data';
import { useTaggingToolTagRecordingPage } from './hooks/use-tagging-tool-tag-recording-page';
import { generateTaggingVideoTypes } from './utils';

export const TaggingToolTagContainer = () => {
  const { recordingId } = useParams<{ recordingId: string }>();
  const { search } = useLocation();
  const { isLive } = queryString.parse(search);
  const { data, isPageLoading, isPageReady, refetch, isInvalidClient } =
    usePageStateMachine<UseTaggingToolTagRecordingDataType>(() =>
      useTaggingToolTagRecordingPage(recordingId, isLive === 'true'),
    );

  const videoTypes = useMemo(
    () =>
      !data?.matchWithEpisodes?.match?.videoSources
        ? []
        : generateTaggingVideoTypes({
            videoSources: data.matchWithEpisodes.match.videoSources,
          }),
    [data],
  );

  const playlistItems: PlaylistItemType[] = useMemo(
    () =>
      !data?.matchWithEpisodes?.match?.id
        ? []
        : [
            {
              id: 'tagging-tool-item',
              duration: 0,
              videoTypes,
              name: '',
              index: 0,
              fundamentalsSelected:
                data?.matchWithEpisodes.episodes.length > 0
                  ? {
                      tacticalAnalysisId: data?.matchWithEpisodes.episodes[0].tacticalAnalysisId,
                      fundamentalsSelected: ['all'],
                    }
                  : { tacticalAnalysisId: undefined, fundamentalsSelected: [] },
              hasHomographies: false,
              recordingId: data?.matchWithEpisodes.match.id,
            },
          ],
    [videoTypes, data],
  );

  if (isInvalidClient && data?.matchWithEpisodes?.match.clientIds) {
    return <SwitchEnvironment resourceClientsIds={data.matchWithEpisodes.match.clientIds} />;
  }

  return (
    <SidebarLayout>
      <Container>
        {isPageLoading && <Spinner isFullPage />}
        {isPageReady && data?.taggingEvents && (
          <AddToPlaylistMenuStateProvider id={'tagging-tool-playlist-menu'}>
            <VideoPlayerStateProvider
              refreshData={refetch}
              playerId={`tagging-tool-${data.matchWithEpisodes?.match.id || data.taggingEvents.name}`}
              playlistItems={playlistItems}
              playingMode={videoTypes[0]?.playingMode || PANORAMIC_PLAYING_MODE}
            >
              <TagRecordingScreen isLive={isLive === 'true'} key={recordingId} recordingId={recordingId} />
            </VideoPlayerStateProvider>
          </AddToPlaylistMenuStateProvider>
        )}
      </Container>
    </SidebarLayout>
  );
};
