import { atomFamily, useRecoilValue, useSetRecoilState } from 'recoil';

import { Row } from '../../../../api/use-tactical-analysis-data/generate-timeline-rows';

export const rowAtomFamily = atomFamily<Row, string>({
  key: 'table-row',
  default: {
    id: '',
    type: 'episodes',
    isHidden: false,
    teamId: '',
    clips: [],
    title: '',
    isSelected: false,
    scenarioSubRowDisabled: undefined,
  },
});

export const rowGroupIsCollapsedFamily = atomFamily<boolean, string>({
  key: 'table-row-group-collapsed',
  default: false,
});

export const rowGroupHighlighted = atomFamily<boolean, string>({
  key: 'table-row-group-highlighted',
  default: false,
});

export const useRow = (rowId: string) => {
  return useRecoilValue(rowAtomFamily(rowId));
};

export const useSetRowGroupIsCollapsed = (rowId: string) => {
  return useSetRecoilState(rowGroupIsCollapsedFamily(rowId));
};

export const useRowGroupIsCollapsed = (rowId: string) => {
  return useRecoilValue(rowGroupIsCollapsedFamily(rowId));
};

export const useSetRowIsHighlighted = (rowId: string) => {
  return useSetRecoilState(rowGroupHighlighted(rowId));
};

export const useRowIsHighlighted = (rowId: string) => {
  return useRecoilValue(rowGroupHighlighted(rowId));
};
