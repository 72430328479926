import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { DialogNew } from 'shared/components/dialog-new';
import { IconUser } from 'shared/components/icons/icon-user';
import { useDates } from 'shared/hooks/use-dates';

import { TeamFixture } from '../../../../types/teams';

interface Props {
  onClose: () => void;
  teamDetails: TeamFixture[];
}

function createData(name: string, value: string) {
  return { name, value };
}

const TableData = ({ teamFixture }: { teamFixture: TeamFixture }) => {
  const { dateToString } = useDates();
  const rows = [
    createData('name', teamFixture.name.toString()),
    createData('duration', teamFixture.duration.toString()),
    createData('date', dateToString(teamFixture.date)),
    createData('team_away_score', teamFixture.homeTeamScore.toString()),
    createData('away_team_score', teamFixture.awayTeamScore.toString()),
    createData('pitch_length', teamFixture.pitchLength.toString()),
    createData('pitch_width', teamFixture.pitchWidth.toString()),
  ];

  return (
    <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
      <Table sx={{ minWidth: 650 }} aria-label='team details table'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align='right'>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component='th' scope='row'>
                {row.name}
              </TableCell>
              <TableCell align='right'>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const TeamDetailsModal = ({ onClose, teamDetails }: Props) => {
  return (
    <DialogNew
      fullWidth
      maxWidth='md'
      title={'Team details'}
      icon={<IconUser />}
      onCancel={onClose}
      onClose={onClose}
      open={true}
    >
      {teamDetails.map((fixture) => (
        <TableData key={fixture.id} teamFixture={fixture} />
      ))}
    </DialogNew>
  );
};
