import { Dispatch as ReactDispatch, useCallback, useEffect, useReducer } from 'react';

import { KeypadApiResponse, KeypadTag } from 'api/tagging-tool/types';
import { TypeOfPlay } from 'shared/types/type-of-play/types';

import { KeypadIndex, makeCustomKeypadTag } from '../../../../tagging-tool/model/Keypad';
import {
  fetchKeypad,
  fetchUpdateKeypadTag,
  fetchCreateKeypadTag,
  fetchDeleteKeypadTag,
} from '../../../../tagging-tool/service/keypad.service';
import { KeypadTagHotKeyResolver, makeHotKeyResolver } from '../../../../tagging-tool/utility/hotkeys';

export type CustomButtonsData = {
  offense: Array<KeypadTag>;
  transition: Array<KeypadTag>;
  defense: Array<KeypadTag>;
};

export type State = {
  keypadId?: string;
  fetching?: boolean;
  editModalShown?: boolean;
  removeModalShown?: boolean;
  creating?: boolean;
  data?: KeypadIndex;
  targetTagData?: Partial<KeypadTag>;
  customButtonsData?: CustomButtonsData;
  hotKeyResolver?: KeypadTagHotKeyResolver;
};

export enum ActionType {
  SET_KEYPAD,
  SET_ENABLED,
  EDIT_MODAL_SHOW,
  ON_ADD_NEW,
  EDIT_MODAL_CANCEL,
  EDIT_MODAL_CLOSED,
  SET_FETCHING,
  ON_UPSERT_FETCH_END,
  REMOVE_MODAL_SHOW,
  REMOVE_MODAL_CANCEL,
  REMOVE_MODAL_CLOSED,
  ON_REMOVE_FETCH_END,
}

export type Action =
  | { type: ActionType.SET_KEYPAD; payload: KeypadApiResponse }
  | { type: ActionType.SET_ENABLED; payload: KeypadTag }
  | { type: ActionType.EDIT_MODAL_SHOW; payload: KeypadTag }
  | { type: ActionType.EDIT_MODAL_CANCEL }
  | { type: ActionType.EDIT_MODAL_CLOSED }
  | { type: ActionType.SET_FETCHING }
  | { type: ActionType.ON_UPSERT_FETCH_END; payload?: KeypadTag }
  | { type: ActionType.ON_ADD_NEW }
  | { type: ActionType.REMOVE_MODAL_SHOW; payload: KeypadTag }
  | { type: ActionType.REMOVE_MODAL_CANCEL }
  | { type: ActionType.REMOVE_MODAL_CLOSED }
  | { type: ActionType.ON_REMOVE_FETCH_END; payload: boolean };

export type Dispatch = { dispatch: ReactDispatch<Action> } & {
  handleEnabledChange: (tag: KeypadTag, isEnabled: boolean) => void;
  handleUpdateKeypadTag: (item: KeypadTag) => void;
  handleCreateKeypadTag: (item: KeypadTag) => void;
  handleRemoveKeypadTag: (item: KeypadTag) => void;
};

const updateData = (data: KeypadIndex): Pick<State, 'data' | 'customButtonsData' | 'hotKeyResolver'> => {
  const customButtonsData = Object.values(data).reduce(
    (acc, item) => {
      if (!item.isCustom) {
        return acc;
      } else if (item.typeOfPlay === TypeOfPlay.Offense) {
        return { ...acc, offense: [...acc.offense, item] };
      } else if (item.typeOfPlay === TypeOfPlay.Transition) {
        return { ...acc, transition: [...acc.transition, item] };
      } else if (item.typeOfPlay === TypeOfPlay.Defense) {
        return { ...acc, defense: [...acc.defense, item] };
      }
      return acc;
    },
    { offense: [], transition: [], defense: [] } as CustomButtonsData,
  );

  return {
    data,
    customButtonsData,
    hotKeyResolver: makeHotKeyResolver(data),
  };
};

const reducer = (state: State, action: Action): State => {
  if (action.type === ActionType.SET_KEYPAD) {
    const data = action.payload.tags.reduce((acc, keypad) => {
      return {
        ...acc,
        [keypad.keypadTagId]: {
          keypadId: keypad.keypadId,
          keypadTagId: keypad.keypadTagId,
          name: keypad.name,
          typeOfPlay: keypad.typeOfPlay,
          timeBefore: keypad.timeBefore,
          timeAfter: keypad.timeAfter,
          hotKey: keypad.hotKey,
          description: keypad.description,
          typeOfPlaySource: keypad.typeOfPlaySource,
          isEnabled: keypad.isEnabled,
          isCustom: keypad.isCustom,
        },
      };
    }, {} as KeypadIndex);

    return {
      ...state,
      keypadId: action.payload.id,
      ...updateData(data),
    };
  } else if (action.type === ActionType.SET_ENABLED) {
    const data: KeypadIndex = {
      ...state.data!,
      [action.payload.keypadTagId]: action.payload,
    };
    return {
      ...state,
      ...updateData(data),
    };
  } else if (action.type === ActionType.EDIT_MODAL_SHOW) {
    return {
      ...state,
      targetTagData: action.payload,
      creating: false,
      editModalShown: true,
    };
  } else if (action.type === ActionType.EDIT_MODAL_CANCEL) {
    return {
      ...state,
      editModalShown: false,
    };
  } else if (action.type === ActionType.EDIT_MODAL_CLOSED) {
    return {
      ...state,
      editModalShown: false,
      targetTagData: undefined,
    };
  } else if (action.type === ActionType.SET_FETCHING) {
    return {
      ...state,
      fetching: true,
    };
  } else if (action.type === ActionType.ON_UPSERT_FETCH_END) {
    if (!action.payload) {
      return { ...state, editModalShown: false, fetching: false };
    }

    const data: KeypadIndex = {
      ...state.data!,
      [action.payload.keypadTagId]: action.payload,
    };

    return {
      ...state,
      ...updateData(data),
      editModalShown: false,
      fetching: false,
    };
  } else if (action.type === ActionType.ON_ADD_NEW) {
    return {
      ...state,
      targetTagData: makeCustomKeypadTag({ keypadId: state.keypadId! }),
      creating: true,
      editModalShown: true,
    };
  } else if (action.type === ActionType.REMOVE_MODAL_SHOW) {
    return {
      ...state,
      targetTagData: action.payload,
      removeModalShown: true,
    };
  } else if (action.type === ActionType.REMOVE_MODAL_CANCEL) {
    return {
      ...state,
      removeModalShown: false,
    };
  } else if (action.type === ActionType.REMOVE_MODAL_CLOSED) {
    return {
      ...state,
      targetTagData: undefined,
    };
  } else if (action.type === ActionType.ON_REMOVE_FETCH_END) {
    if (!action.payload) {
      const data: KeypadIndex = {
        ...state.data!,
      };
      delete data[state.targetTagData!.keypadTagId!];
      return {
        ...state,
        ...updateData(data),
        fetching: false,
        removeModalShown: false,
      };
    }

    return {
      ...state,
      fetching: false,
      removeModalShown: false,
    };
  }
  return state;
};

export const useKeypadEditScreenState = (params: { id: string }): [State, Dispatch] => {
  const [state, dispatch] = useReducer(reducer, {});

  // effects

  useEffect(() => {
    fetchKeypad({ id: params.id }).then((res) => {
      if (res.error) {
        return;
      }

      dispatch({ type: ActionType.SET_KEYPAD, payload: res.data });
    });
  }, [params.id]);

  // handlers

  const handleEnabledChange = useCallback((tag: KeypadTag, isEnabled: boolean) => {
    const prevItem = { ...tag };
    const nextItem: KeypadTag = { ...tag, isEnabled };

    fetchUpdateKeypadTag(nextItem).then((res) => {
      if (res.error) {
        dispatch({ type: ActionType.SET_ENABLED, payload: prevItem });
      }
    });

    dispatch({ type: ActionType.SET_ENABLED, payload: nextItem });
  }, []);

  const handleUpdateKeypadTag = useCallback((item: KeypadTag) => {
    dispatch({ type: ActionType.SET_FETCHING });

    fetchUpdateKeypadTag(item).then((res) => {
      dispatch({
        type: ActionType.ON_UPSERT_FETCH_END,
        payload: res.error ? undefined : item,
      });
    });
  }, []);

  const handleCreateKeypadTag = useCallback((item: KeypadTag) => {
    dispatch({ type: ActionType.SET_FETCHING });

    fetchCreateKeypadTag(item).then((res) => {
      dispatch({
        type: ActionType.ON_UPSERT_FETCH_END,
        payload: res.error ? undefined : item,
      });
    });
  }, []);

  const handleRemoveKeypadTag = useCallback((item: KeypadTag) => {
    dispatch({ type: ActionType.SET_FETCHING });

    fetchDeleteKeypadTag(item).then((res) => {
      dispatch({
        type: ActionType.ON_REMOVE_FETCH_END,
        payload: res.error,
      });
    });
  }, []);

  return [
    state,
    {
      dispatch,
      handleEnabledChange,
      handleUpdateKeypadTag,
      handleCreateKeypadTag,
      handleRemoveKeypadTag,
    },
  ];
};
