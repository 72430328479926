import { isArray } from 'lodash';
import { useEffect } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { useSetUserAndClientIdForMetrics } from 'kognia/metrics/hooks/use-set-user-and-client-id-for-metrics';
import { APP_COLLECT_METRICS } from 'kognia/metrics/utils';

import { isUserAdmin, useClientIdValue, useFeatureFlag, useIsUserAuthorized, useUser } from '../../contexts/app-state';
import { ActionTypes } from '../../streams/actionTypes';
import { publishEvent } from '../../streams/eventEmitter';
import { MetricsNames } from '../../types/metrics';
import redirectToExternalLogin from '../../utils/redirect-to-external-login';

type ProtectedRouteProps = RouteProps & {
  component: any;
  onlyAdminAccess?: boolean;
  featureFlag?: FEATURE_FLAG;
};

export const ProtectedRoute = ({
  component: Component,
  featureFlag: featureFlag,
  onlyAdminAccess = false,
  ...rest
}: ProtectedRouteProps) => {
  const history = useHistory();
  const isAdmin = isUserAdmin();
  const hasFeatureEnabled = useFeatureFlag(featureFlag);
  const isUserAuthorized = useIsUserAuthorized();
  const historyLocation = useHistory().location;
  const user = useUser();
  const clientId = useClientIdValue();
  const setUserAndClientIdForMetrics = useSetUserAndClientIdForMetrics();

  useEffect(() => {
    APP_COLLECT_METRICS && setUserAndClientIdForMetrics(clientId, user);
  }, [setUserAndClientIdForMetrics, user, clientId]);

  useEffect(() => {
    if (rest.path) {
      publishEvent({
        type: ActionTypes.VIEW_PAGE,
        payload: {
          name: MetricsNames.PAGE_VIEW,
          data: { generic_url: isArray(rest.path) ? rest.path[0] : rest.path, url: history.location.pathname },
        },
      });
    }
  }, [clientId, history.location, history.location.pathname, rest.path, user.id]);

  useEffect(() => {
    if (!isAdmin && onlyAdminAccess) {
      return history.push('/');
    }
  }, [history, isAdmin, onlyAdminAccess]);

  useEffect(() => {
    if (!isUserAuthorized) {
      return redirectToExternalLogin(`${historyLocation.pathname}${historyLocation.search}`);
    }
  }, [historyLocation.pathname, historyLocation.search, isUserAuthorized]);

  useEffect(() => {
    if (featureFlag && !hasFeatureEnabled) {
      history.push('/');
    }
  }, [featureFlag, hasFeatureEnabled, history]);

  if (!isUserAuthorized) return null;
  if (featureFlag && !hasFeatureEnabled) return null;
  if (!isAdmin && onlyAdminAccess) return null;

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Component {...props} />
        </>
      )}
    />
  );
};
