import { useLocation, useParams } from 'react-router-dom';

import { useMetadataGame } from '../../../../../api/games/use-metadata-game';
import { GameInfoForm } from '../../components/game-info-form';
import { getCurrentStepFromPathName, useCheckValidateStep } from '../../config';
import { GameFormRouteParams } from '../../types';

export const GameFormInfo = () => {
  const location = useLocation();
  const { id } = useParams<GameFormRouteParams>();
  const { data: game, isLoading } = useMetadataGame({ gameId: id });
  const step = getCurrentStepFromPathName(location.pathname);

  useCheckValidateStep(step);

  return <>{isLoading && id ? null : <GameInfoForm game={game} step={step} />}</>;
};
