import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';

import { getQueryParams } from '../../../utils/get-query-params';
import { annotationApiUrls } from '../../routes';
import { LabelsApiParams } from '../../types';
import { useAllowedBackofficeOrigin } from '../../use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from '../../utils';

interface LabelsTaskParams {
  data: LabelsApiParams;
}

export const useLabelsTask = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Labels task success',
    errorMessage: (data) => metadataApiErrorParser(data, 'Labels task error'),
  });

  const labelsTask = ({ data }: LabelsTaskParams) => {
    const queryParams = getQueryParams<LabelsApiParams>(data);
    if (!queryParams) return;
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate({ url: `${annotationApiUrls.LABELS_TASK}?${queryParams}` });
  };

  return { ...mutationRequest, labelsTask };
};
