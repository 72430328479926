import React, { RefObject, useCallback } from 'react';

import { useSaveUserPreset } from 'api/user-presets/use-save-user-preset';
import { TimelineHeadersWidthPreset } from 'shared/types/user-preset/types';

import styles from './RowHeadersResizeBar.module.scss';
import { HEADER_MIN_WIDTH } from '../../../../../atoms';
import { useSetTimelineHeadersWidth, useTimelineHeadersWidth } from '../../../../../hooks/use-timeline-headers-width';
import ResizeBar from '../../../../tactical-analysis/resize-bar';

type Props = {
  headerContentDiv: RefObject<HTMLDivElement>;
  contentDiv: RefObject<HTMLDivElement>;
};

const TIMELINE_TABLE_MIN_WIDTH = 224;

export const RowHeadersResizeBar = ({ headerContentDiv, contentDiv }: Props) => {
  const { savePreset } = useSaveUserPreset();
  const headersWidth = useTimelineHeadersWidth();
  const setTimelineHeadersWidth = useSetTimelineHeadersWidth();

  const handleSaveHeaderWidth = useCallback(
    (width: number) => {
      if (headersWidth === width) {
        return;
      }
      const headerWidthPreset = new TimelineHeadersWidthPreset(width);
      setTimelineHeadersWidth(width);
      savePreset({ data: headerWidthPreset });
    },
    [savePreset, setTimelineHeadersWidth, headersWidth],
  );

  return (
    <div className={styles.container}>
      <ResizeBar
        direction={'horizontal'}
        mainPanelElement={headerContentDiv}
        secondaryPanelElement={contentDiv}
        mainPanelMinSize={HEADER_MIN_WIDTH}
        secondaryPanelMinSize={TIMELINE_TABLE_MIN_WIDTH}
        onChange={handleSaveHeaderWidth}
      />
    </div>
  );
};
