import { Tab, Tabs, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import styles from './FilterTabs.module.scss';

interface FilterTabsProps {
  value: string;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<any>, newValue: string) => void;
  children: React.ReactNode;
}

export const FilterTabs = withStyles(
  createStyles({
    root: {
      width: '100%',
    },
  }),
)((props: FilterTabsProps) => <Tabs {...props} className={styles.tabs} TabIndicatorProps={{ children: <div /> }} />);

interface FilterTabProps {
  active?: boolean;
  disabled?: boolean;
  label: string;
  value: string;
}

const TabSelectedBadge = () => {
  return <div className={styles.tabSelectedBadge} />;
};

const TabUnSelectedBadge = () => {
  return <div className={styles.tabUnselectedBadge} />;
};

export const FilterTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      maxWidth: '100%',
      marginRight: theme.spacing(1),
      '&:focus': {
        opacity: 1,
      },
    },
  }),
)((props: FilterTabProps) => {
  const { active, ...tabProps } = props;
  return (
    <Tab
      icon={active ? <TabSelectedBadge /> : <TabUnSelectedBadge />}
      className={styles.tab}
      disableRipple
      {...tabProps}
    />
  );
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export const FilterTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      className={styles.tabPanel}
      role='tabpanel'
      hidden={value !== index}
      id={`filters-tabpanel-${index}`}
      aria-labelledby={`filters-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
};
