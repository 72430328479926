import IconTime from 'shared/components/icons/icon-time';
import { secondsAsDuration } from 'shared/utils/seconds-as-duration';

import styles from './PlaylistsDuration.module.scss';

interface Props {
  duration: number;
}

const PlayListDuration = ({ duration }: Props) => {
  return (
    <div className={styles.duration}>
      <IconTime /> {secondsAsDuration(duration, false)}
    </div>
  );
};

export default PlayListDuration;
