import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';

import { MetadataPlayerSummary } from '../../../types/players';
import { annotationApiUrls } from '../../routes';
import { useAllowedBackofficeOrigin } from '../../use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from '../../utils';
import { transformPlayer } from '../use-players/transformer';
import { MetadataPlayerApiResponse } from '../use-players/types';

type CreatePlayerParams = Pick<MetadataPlayerApiResponse, 'name'> & { image?: File };

interface CreatePlayerData {
  data: CreatePlayerParams;
  onSuccess?: (player: MetadataPlayerSummary) => void;
}

export const useCreatePlayer = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<
    MetadataPlayerApiResponse,
    MetadataPlayerSummary,
    MetadataApiErrorResponse
  >({
    type: HTTPMethod.POST,
    successMessage: 'Player created',
    transformer: transformPlayer,
    errorMessage: (data) => metadataApiErrorParser(data, 'Player creation error'),
  });

  const createPlayer = ({ data, onSuccess }: CreatePlayerData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    const formData = new FormData();
    formData.append('player', JSON.stringify({ name: data.name }));
    data.image && formData.append('player_image', data.image);

    mutationRequest.mutate(
      {
        url: annotationApiUrls.PLAYERS,
        data: formData,
      },
      { onSuccess },
    );
  };

  return { ...mutationRequest, createPlayer };
};
