import { MutateOptions } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { postManyPlaylistItem } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { Playlist } from 'shared/types';

import { transformPlaylist } from '../transformers';
import { PlaylistApiResponse, PostNewPlaylistItem } from '../types';

interface Params {
  successMessage?: string;
}

export const useAddManyToPlaylist = ({ successMessage }: Params = {}) => {
  const { t } = useTranslation();

  const { mutate, isLoading, isError, isSuccess } = useMutationRequest<PlaylistApiResponse, Playlist>({
    type: HTTPMethod.POST,
    transformer: transformPlaylist,
    errorMessage: t('api:use-add-many-to-playlist.error'),
    successMessage: successMessage !== undefined ? successMessage : t('api:use-add-many-to-playlist.success'),
  });

  const addManyToPlaylist = useCallback(
    ({ items, options }: { items: PostNewPlaylistItem[]; options?: MutateOptions<Playlist, unknown, unknown> }) => {
      const { playlistId } = items[0];
      const url = postManyPlaylistItem(playlistId);

      return mutate({ url, data: items }, options);
    },
    [mutate],
  );

  return { addManyToPlaylist, isLoading, isError, isSuccess };
};
