import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import {
  EPISODES_PLAYING_MODE,
  PANORAMIC_PLAYING_MODE,
  TACTICAL_CAMERA_WITH_OVERLAYS_PLAYING_MODE,
  TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE,
} from 'shared/components/video-player/defaultPlayingModes';
import { PlaylistItemType, VideoSourceType, VideoSourceWithTimes } from 'shared/components/video-player/types';
import { useFeatureFlag } from 'shared/contexts/app-state';
import { EpisodeClip, Playlist, VideoSourceRef } from 'shared/types';
import { VideoSource } from 'shared/types/recording/types';
import { getVideoSources, GetVideoSourcesResponse } from 'shared/utils/get-video-sources';

const findEpisodeForPlaylistItem = (startTime: number, endTime: number, episodeClips: EpisodeClip[]) => {
  return episodeClips.find((episodeClip) => startTime >= episodeClip.startTime && endTime <= episodeClip.endTime);
};

const generatePlaylistVideoSource = (
  startTime: number,
  endTime: number,
  episodeClip: EpisodeClip,
): VideoSourceWithTimes => {
  return {
    startTime: startTime - episodeClip.startTime,
    startTimeInMatch: episodeClip.startTime,
    endTimeInMatch: endTime,
    endTime: endTime - episodeClip.startTime,
    src: episodeClip.videoSrc.src,
    srcDownload: episodeClip.videoSrc.srcDownload,
    id: episodeClip.videoSrc.id,
  };
};

const generateFullMatchVideoSource = (
  startTime: number,
  endTime: number,
  fullMatchVideo: VideoSource,
): VideoSourceWithTimes => {
  return {
    startTime,
    endTime,
    src: fullMatchVideo.src,
    srcDownload: fullMatchVideo.srcDownload,
    id: fullMatchVideo.id,
  };
};

interface GenerateVideoSourcesFromVideoTypes {
  startTime: number;
  endTime: number;
  episodesVideos: EpisodeClip[];
  videoSources?: GetVideoSourcesResponse;
  videoSourceRef: VideoSourceRef;
  useCustomOverlays: boolean;
}

export const generateVideoSourcesFromVideoTypes = ({
  startTime,
  endTime,
  episodesVideos,
  videoSources,
  videoSourceRef,
  useCustomOverlays = false,
}: GenerateVideoSourcesFromVideoTypes): VideoSourceType[] => {
  const episodeClip = findEpisodeForPlaylistItem(startTime, endTime, episodesVideos);
  const hasEpisodes = !isEmpty(episodesVideos) && !!episodeClip;
  const hasTacticalCameraVideo = videoSources?.tacticalCameraVideo;
  const hasUploadedVideo = videoSources?.uploadedVideo;
  const hasPanoramaVideo = videoSources?.panoramicVideo;

  return [
    ...(hasEpisodes && !useCustomOverlays
      ? [
          {
            playingMode: EPISODES_PLAYING_MODE,
            videoSources: [generatePlaylistVideoSource(startTime, endTime, episodeClip)],
          },
        ]
      : []),
    ...(useCustomOverlays && hasTacticalCameraVideo
      ? [
          {
            playingMode: TACTICAL_CAMERA_WITH_OVERLAYS_PLAYING_MODE,
            videoSources: [generateFullMatchVideoSource(startTime, endTime, videoSources.tacticalCameraVideo)],
          },
        ]
      : []),
    ...(hasTacticalCameraVideo && (!useCustomOverlays || !hasEpisodes)
      ? [
          {
            playingMode: TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE,
            videoSources: [generateFullMatchVideoSource(startTime, endTime, videoSources.tacticalCameraVideo)],
          },
        ]
      : []),
    ...(hasUploadedVideo
      ? [
          {
            playingMode: TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE,
            videoSources: [generateFullMatchVideoSource(startTime, endTime, videoSources.uploadedVideo)],
          },
        ]
      : []),
    ...(hasPanoramaVideo
      ? [
          {
            playingMode: PANORAMIC_PLAYING_MODE,
            videoSources: [generateFullMatchVideoSource(startTime, endTime, videoSources.panoramicVideo)],
            videoSourceRef,
          },
        ]
      : []),
  ];
};

export const useMapVideos = () => {
  const customOverlaysFeatureFlag = useFeatureFlag(FEATURE_FLAG.CUSTOM_OVERLAYS);

  return useCallback(
    (playlist: Playlist): PlaylistItemType[] => {
      return playlist.playlistItems.map((playlistItem) => {
        const playlistItemRecording = playlist.recordings.find(
          (recording) => recording.id === playlistItem.recordingId,
        );
        const videoSources = getVideoSources(playlistItemRecording?.videoSources || []);
        const videoTypes: VideoSourceType[] = generateVideoSourcesFromVideoTypes({
          startTime: playlistItem.startTime,
          endTime: playlistItem.endTime,
          episodesVideos: playlistItem.episodesVideos,
          videoSources,
          videoSourceRef: playlistItem.videoSourceRef,
          useCustomOverlays: customOverlaysFeatureFlag && playlistItem.hasHomographies,
        });

        return {
          id: playlistItem.id,
          name: playlistItem.name,
          index: playlistItem.index,
          duration: playlistItem.endTime - playlistItem.startTime,
          videoTypes,
          recordingName: playlistItemRecording?.name,
          recordingMatchday: playlistItemRecording?.matchDay,
          recordingId: playlistItem.recordingId,
          hasHomographies: playlistItem.hasHomographies,
          fundamentalsSelected: playlistItem.fundamentalsSelected,
          videoSourceRef: playlistItem.videoSourceRef,
        };
      });
    },
    [customOverlaysFeatureFlag],
  );
};
