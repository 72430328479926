import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { useDeleteLiveRecording } from 'api/recording/useDeleteLiveRecording';
import { routes } from 'kognia/router/routes';
import RecordingCard from 'pages/recordings-list/components/recording-card';
import IconCamera from 'shared/components/icons/icon-camera';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import { MenuListOption } from 'shared/components/kebab-menu';
import { ListInfo } from 'shared/components/list/list-info';
import { ListItemTitle } from 'shared/components/list/list-item-title';
import { Recording } from 'shared/types/recording/types';
import { makeRecordingAlignmentSourcesRoute, makeRecordingDetailRoute } from 'tagging-tool/utility/navigation';

import CompetitionInfo from './competition-info';
import DateInfo from './date-info';
import LiveRecordingInfo from './live-recording-info';
import StartTime from './match-day-info';
import stylesCard from '../vod-recording-card/RecordingCard.module.scss';

interface Props {
  onDeleteSuccess?: (recordingId: string) => void;
  recording: Recording;
}

const LiveRecordingCard = React.memo(({ recording, onDeleteSuccess }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isDeleted, setIsDeleted] = useState(false);
  const { deleteRecording, isError, isLoading } = useDeleteLiveRecording(recording.id, onDeleteSuccess);

  const { id, type, name, date, competitionName } = recording;

  useEffect(() => {
    if (isError) {
      setIsDeleted(false);
    }
  }, [isError]);

  const handleConfirmDelete = () => {
    deleteRecording();
    setIsDeleted(true);
  };

  const menuOptions = [
    {
      displayText: '',
    },
    {
      displayText: t('recording:actions.align-with-video'),
      icon: <IconCamera size={IconSizes.small} color={IconColors.storm} />,
      onClick: (event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        event && event.stopPropagation();
        history.push(
          makeRecordingAlignmentSourcesRoute({
            recordingId: recording.id,
            isLive: recording.isLive,
          }),
        );
      },
    },
  ] as MenuListOption[];

  const handleClickToTaggingTool = useCallback(() => {
    history.push(makeRecordingDetailRoute({ recordingId: recording.id, isLive: true, showsKeypad: true }));
  }, []);

  return (
    <RecordingCard
      editPath={generatePath(routes.LIVE_TAGGING_SESSION_EDIT, { recordingId: id })}
      hasVideoSource
      isDeleted={isDeleted}
      isLoading={isLoading}
      menuOptions={menuOptions}
      onClick={handleClickToTaggingTool}
      onDelete={handleConfirmDelete}
      recording={recording}
      title={
        <ListInfo>
          <LiveRecordingInfo />
          <ListItemTitle>
            <h2>{name}</h2>
            <CompetitionInfo type={type} competitionName={competitionName} t={t} />
          </ListItemTitle>
        </ListInfo>
      }
    >
      <div />
      <div className={stylesCard.recordingDate}>
        <DateInfo date={date} />
        <StartTime date={date} />
      </div>
      <div />
    </RecordingCard>
  );
});

LiveRecordingCard.displayName = 'LiveRecordingCard';

export default LiveRecordingCard;
