import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';

import Button, { ButtonTypes } from 'shared/components/button';

import styles from './ConfirmDialog.module.scss';

export type ConfirmDialogTypes = 'warning' | 'default' | 'primary';

interface Props {
  title?: string;
  cancelLabel: string;
  confirmLabel: string;
  description?: string;
  onCancel?: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  type?: ConfirmDialogTypes;
  setIsOpen: (isOpen: boolean) => void;
}

const getButtonTypeFromConfirmDialogType = (type: ConfirmDialogTypes) => {
  const buttonTypesMapping = {
    warning: ButtonTypes.PrimaryDanger,
    default: ButtonTypes.Tertiary,
    primary: ButtonTypes.Primary,
  };

  return buttonTypesMapping[type];
};

const ConfirmDialog = ({
  title,
  cancelLabel = 'Cancel',
  confirmLabel = 'Confirm',
  description,
  onCancel,
  onConfirm,
  isOpen,
  type = 'default',
  setIsOpen,
}: Props) => {
  const handleCancel = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (onCancel) onCancel();
    setIsOpen(false);
  };

  const handleConfirm = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onConfirm();
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      aria-labelledby='confirm-dialog-title'
      aria-describedby='confirm-dialog-description'
    >
      <div className={styles.dialog}>
        {title && <DialogTitle id='confirm-dialog-title'>{title}</DialogTitle>}
        {description && (
          <DialogContent>
            <DialogContentText className={styles.description} id='confirm-dialog-description'>
              {description}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions className={styles.dialogActions}>
          <Button onClick={handleCancel} type={ButtonTypes.Tertiary}>
            {cancelLabel}
          </Button>
          <Button onClick={handleConfirm} autoFocus type={getButtonTypeFromConfirmDialogType(type)}>
            {confirmLabel}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ConfirmDialog;
