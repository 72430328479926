import { Box, OutlinedInput, styled, TextField } from '@mui/material';
import { Colors } from 'kognia-ui';

export const UploadAutocompleteTextField = styled(TextField)(() => ({
  background: Colors.white,
}));

export const UploadColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));

export const UploadInput = styled(TextField)(() => ({
  background: Colors.white,
}));

export const UploadLabelWithLink = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const UploadOutlinedInput = styled(OutlinedInput)(() => ({
  background: Colors.white,
}));
