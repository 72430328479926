import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Button, Card, CardActions, CardContent, Container, Stack, Typography } from '@mui/material';
import { Colors, fontWeight } from 'kognia-ui';
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { useCreateClient } from 'api/backoffice/client/use-create-client';
import { FormInputField } from 'shared/components/form/form-input-field';
import { FormSelectField } from 'shared/components/form/form-select-field';
import { getTypeOptions } from 'shared/components/form/get-type-options';
import { IconChevronRight } from 'shared/components/icons/icon-chevron-right';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import { Client } from 'shared/types/index';

import { CreateClientFormFieldsNames, createClientFormSchema } from './utils/create-client-form';
import { BackofficeContentContainer } from '../../components/backoffice-content-container';
import { Countries } from '../../utils/countries';

export const CreateClientFormLabels = {
  [CreateClientFormFieldsNames.ID]: 'Environment ID',
  [CreateClientFormFieldsNames.CITY]: 'City',
  [CreateClientFormFieldsNames.COUNTRY]: 'Country',
  [CreateClientFormFieldsNames.NAME]: 'Environment Name',
};

export const CreateEnvironmentContainer = () => {
  const [createdEnvironment, setCreatedEnvironment] = React.useState<Client | null>(null);
  const { createClient, isLoading } = useCreateClient();
  const {
    reset,
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
  } = useForm<Client>({
    resolver: zodResolver(createClientFormSchema),
  });

  const handleAddOnSuccess = useCallback(
    (data: Client) => {
      setCreatedEnvironment(data);
      reset();
    },
    [reset],
  );

  const handleCreateClient = useCallback(
    (data: Client) => {
      createClient({ data, onSuccess: handleAddOnSuccess });
    },
    [createClient, handleAddOnSuccess],
  );

  const inputFormFields = useMemo(
    () => [CreateClientFormFieldsNames.ID, CreateClientFormFieldsNames.NAME, CreateClientFormFieldsNames.CITY],
    [],
  );

  return (
    <BackofficeContentContainer>
      <Container fixed maxWidth='sm'>
        <Stack direction={'column'} spacing={2}>
          <Typography
            variant={'h6'}
            sx={{ fontWeight: fontWeight['400'] }}
            display='flex'
            alignItems='center'
            gap={0.5}
          >
            <span>Environments</span>
            <IconChevronRight size={IconSizes.small} color={IconColors.storm} />
            <span>Add environment</span>
          </Typography>
          <Card>
            <CardContent>
              <form onSubmit={handleFormSubmit(handleCreateClient)}>
                <Stack direction='column' spacing={2}>
                  {inputFormFields.map((field) => (
                    <FormInputField
                      key={field}
                      id={field}
                      label={CreateClientFormLabels[field]}
                      error={!!errors[field]}
                      fullWidth
                      helperText={errors[field] && <>{errors[field]?.message}</>}
                      {...register(field)}
                    />
                  ))}

                  <FormSelectField
                    id={CreateClientFormFieldsNames.COUNTRY}
                    label={CreateClientFormLabels[CreateClientFormFieldsNames.COUNTRY]}
                    options={getTypeOptions({
                      options: Countries.map((country) => ({
                        value: country.code,
                        label: country.name,
                      })),
                    })}
                    error={!!errors[CreateClientFormFieldsNames.COUNTRY]}
                    helperText={
                      errors[CreateClientFormFieldsNames.COUNTRY] && (
                        <>{errors[CreateClientFormFieldsNames.COUNTRY]?.message}</>
                      )
                    }
                    defaultValue={''}
                    {...register(CreateClientFormFieldsNames.COUNTRY)}
                  />

                  <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Button onClick={() => reset()}>Reset</Button>
                    <LoadingButton
                      color={'primary'}
                      loading={isLoading}
                      size={'large'}
                      type={'submit'}
                      variant={'contained'}
                    >
                      Send
                    </LoadingButton>
                  </CardActions>
                </Stack>
              </form>
            </CardContent>
          </Card>
          {createdEnvironment ? (
            <Card key={createdEnvironment.id} sx={{ background: Colors.twilight }}>
              <CardContent>
                <Typography variant={'h5'} marginBottom={2}>
                  {createdEnvironment.name}
                  <Typography variant={'body1'} color={Colors.green} marginBottom={2}>
                    (id: {createdEnvironment.id})
                  </Typography>
                </Typography>
                <Stack direction={'column'} spacing={1}>
                  <Typography sx={{ fontWeight: fontWeight['500'] }}>
                    <strong>City:</strong> {createdEnvironment.city}
                  </Typography>
                  <Typography sx={{ fontWeight: fontWeight['500'] }}>
                    <strong>Country:</strong>{' '}
                    {Countries.find((country) => country.code === createdEnvironment.country)?.name}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          ) : null}
        </Stack>
      </Container>
    </BackofficeContentContainer>
  );
};
