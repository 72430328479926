import { TypeOf } from 'zod';

import { trimFormSchema } from './trimFormSchema';

export const trimFormId = 'trim-form';

export enum TrimFormFieldsNames {
  startTime = 'startTime',
  endTime = 'endTime',
}

export interface TrimForm {
  [TrimFormFieldsNames.startTime]?: number;
  [TrimFormFieldsNames.endTime]?: number;
}

export type TrimFormSchema = TypeOf<typeof trimFormSchema>;
