import { last } from 'lodash';
import { atom, atomFamily, CallbackInterface, useRecoilCallback, useRecoilValue } from 'recoil';

import { Clip, Row } from './index';
import { rowAtomFamily } from '../../../components/timeline/timeline-table/store/atoms';

export interface ClipWithSelection extends Clip {
  isSelected: boolean;
  isSelectedForPlaying: boolean;
}

export const clipAtomFamily = atomFamily<ClipWithSelection, string>({
  key: 'clip-with-selection',
  default: {
    id: '',
    startTime: 0,
    endTime: 0,
    type: 'episode',
    rowId: '',
    elementId: '',
    isSelected: false,
    isSelectedForPlaying: false,
    title: '',
  },
});

const clipIdsAtom = atom<string[]>({
  key: 'clip-ids-list',
  default: [],
});

const rowIdsAtom = atom<string[]>({
  key: 'tracks-ids-list',
  default: [],
});

const lastSelectedClipIdAtom = atom<string>({
  key: 'last-selected-clip',
  default: '',
});

const selectedClipIdsAtom = atom<string[]>({
  key: 'clip-ids-selected-list',
  default: [],
});

export const useClipsIdsSelection = () => {
  return useRecoilValue(selectedClipIdsAtom);
};

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const selectionActionsCreator = (set: CallbackInterface['set'], snapshot: CallbackInterface['snapshot']) => {
  const setLastSelectedClip = (clipId: string) => set(lastSelectedClipIdAtom, clipId);
  const getSelectedClipIds = () => snapshot.getLoadable(selectedClipIdsAtom).valueMaybe() ?? [];
  const replaceSelectedClipIds = (updatedClipsSelected: string[]) =>
    set(selectedClipIdsAtom, () => updatedClipsSelected);
  const getListOfClipIds = () => snapshot.getLoadable(clipIdsAtom).valueMaybe() ?? [];
  const getListOfRowIds = () => snapshot.getLoadable(rowIdsAtom).valueMaybe() ?? [];
  const getLastSelectedClipId = () => snapshot.getLoadable(lastSelectedClipIdAtom).valueMaybe() ?? '';
  const getClip = (clipId: string) => snapshot.getLoadable(clipAtomFamily(clipId)).valueMaybe();
  const getClipList = (clipIds: string[]): Clip[] => clipIds.map((clipId) => getClip(clipId)).filter(notEmpty);
  const getRow = (rowId: string) => snapshot.getLoadable(rowAtomFamily(rowId)).valueMaybe();
  const getRowList = (rowIds: string[]): Row[] => rowIds.map((rowId) => getRow(rowId)).filter(notEmpty);
  const getFiltersClips = (): Clip[] => {
    const clipIds = getListOfClipIds();
    const clips = getClipList(clipIds);

    return clips.filter((clip) => clip.type === 'filter');
  };
  const getRowClips = (rowId: string): Clip[] => {
    const row = getRow(rowId);

    if (row?.type === 'filters') {
      return getFiltersClips();
    }

    return row?.clips.reduce((clips, clip) => [...clips, ...(clip.clips ?? [])], [] as Clip[]) ?? [];
  };

  const initClip = (clip: Clip) =>
    set(clipAtomFamily(clip.id), {
      ...clip,
      isSelected: false,
      isSelectedForPlaying: false,
    });

  const changeClipSelection = (clipId: string, value: boolean) => {
    set(clipAtomFamily(clipId), (clip) => ({
      ...clip,
      isSelected: value,
    }));
  };

  const selectClip = (clipId: string) => {
    set(clipAtomFamily(clipId), (clip) => ({ ...clip, isSelected: true }));
  };

  const unSelectClip = (clipId: string) => {
    set(clipAtomFamily(clipId), (clip) => ({ ...clip, isSelected: false }));
  };

  const toggleClip = (clipId: string) => {
    set(clipAtomFamily(clipId), (clip) => ({
      ...clip,
      isSelected: !clip.isSelected,
    }));
    setLastSelectedClip(clipId);
  };

  const resetSelection = () => {
    const clipIds = getListOfClipIds();
    const rowIds = getListOfRowIds();

    set(lastSelectedClipIdAtom, () => '');
    set(selectedClipIdsAtom, () => []);
    clipIds.forEach((clipId) =>
      set(clipAtomFamily(clipId), (clip) => ({ ...clip, isSelected: false, isDisabled: false })),
    );
    rowIds.forEach((rowId) => set(rowAtomFamily(rowId), (row) => ({ ...row, isDisabled: false })));
  };

  const clearSelectedClips = () => {
    setLastSelectedClip('');
    getSelectedClipIds().forEach((clipId) => unSelectClip(clipId));
    set(selectedClipIdsAtom, () => []);
  };

  const updateMultipleClips = (clipIds: string[], clearSelection = false) => {
    if (clearSelection) clearSelectedClips();

    const selectedClips = clearSelection ? [] : getSelectedClipIds();

    replaceSelectedClipIds(selectedClips.concat(clipIds.filter((id) => !selectedClips.includes(id))));
    clipIds.forEach(selectClip);
  };

  const removeMultipleClipIds = (clipIds: string[]) => {
    set(selectedClipIdsAtom, (selectedClips) => selectedClips.filter((id) => !clipIds.includes(id)));
    clipIds.forEach(unSelectClip);
  };

  const addClipIdToSelection = (clipId: string) => {
    set(selectedClipIdsAtom, (selectedClips) => [...new Set([...selectedClips, clipId])]);
    selectClip(clipId);
  };

  const selectSingleClip = (clipId: string) => {
    clearSelectedClips();
    selectClip(clipId);
    set(selectedClipIdsAtom, () => [clipId]);
    setLastSelectedClip(clipId);
  };

  const replaceMultipleClips = (clipIds: string[]) => {
    clearSelectedClips();
    clipIds.forEach(selectClip);
    set(selectedClipIdsAtom, () => clipIds);
    setLastSelectedClip(last(clipIds) ?? '');
  };

  const removeClipIdFromSelection = (clipId: string) => {
    unSelectClip(clipId);
    set(selectedClipIdsAtom, (selectedClips) => selectedClips.filter((id) => id !== clipId));
    setLastSelectedClip(clipId);
  };

  const addClipsToSelection = (clips: Clip[]) => {
    set(clipIdsAtom, (currVal) => [...currVal, ...clips.map((clip) => clip.id)]);
    clips.forEach((clip) => initClip(clip));
  };

  const selectPlaylistSelectionClips = (clips: Clip[]) => {
    clips.forEach((clip) => {
      set(clipAtomFamily(clip.id), (clip) => ({ ...clip, isSelectedForPlaying: true }));
    });
  };

  const unselectPlayingSelectionClips = () => {
    getListOfClipIds().forEach((clipId) => {
      const clip = getClip(clipId);
      if (!clip || !clip.isSelectedForPlaying) return;

      set(clipAtomFamily(clipId), (clip) => ({ ...clip, isSelectedForPlaying: false }));
    });
  };

  const selectPlayingSelectionRow = (rowId?: string, team?: 'home' | 'opponent') => {
    if (rowId) {
      set(rowAtomFamily(rowId), (row) => ({ ...row, isSelected: true, scenarioSubRowDisabled: team }));
    }
  };

  const resetSelectedRow = () => {
    getListOfRowIds().forEach((rowId) => {
      const row = getRow(rowId);

      if (row && row.isSelected) {
        set(rowAtomFamily(rowId), (row) => ({
          ...row,
          isSelected: false,
          scenarioSubRowDisabled: undefined,
        }));
      }
    });
  };

  return {
    addClipIdToSelection,
    addClipsToSelection,
    changeClipSelection,
    clearSelectedClips,
    selectPlaylistSelectionClips,
    unselectPlayingSelectionClips,
    selectRow: selectPlayingSelectionRow,
    getClip,
    getClipList,
    getFiltersClips,
    getLastSelectedClipId,
    getListOfClipIds,
    getListOfRowIds,
    getRow,
    getRowList,
    getRowClips,
    getSelectedClips: getSelectedClipIds,
    removeClipIdFromSelection,
    removeMultipleClipIds,
    replaceMultipleClips,
    replaceSelectedClipIds,
    resetSelectedRow,
    resetSelection,
    selectClip,
    selectSingleClip,
    setLastSelectedClip,
    toggleClip,
    unSelectClip,
    updateMultipleClips,
  };
};

export const useResetTimelineSelectionAtoms = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      () => {
        const actions = selectionActionsCreator(set, snapshot);

        return actions.resetSelection();
      },
    [],
  );
};

export const useValidateSelection = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (clipIds: string[]) => {
        const actions = selectionActionsCreator(set, snapshot);

        const selectedClips = actions.getSelectedClips();

        actions.replaceSelectedClipIds(selectedClips.filter((id) => clipIds.includes(id)));
      },
    [],
  );
};

export const useRowClips = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (rowId: string) => {
        const actions = selectionActionsCreator(set, snapshot);

        return actions.getRowClips(rowId);
      },
    [],
  );
};

export const useSelectPlayingSelectionClips = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (clips: Clip[]) => {
        const actions = selectionActionsCreator(set, snapshot);

        actions.selectPlaylistSelectionClips(clips);
      },
    [],
  );
};

export const useUnselectPlayingSelectionClips = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      () => {
        const actions = selectionActionsCreator(set, snapshot);

        actions.unselectPlayingSelectionClips();
      },
    [],
  );
};

export const useSelectRow = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (rowId?: string, team?: 'home' | 'opponent') => {
        const actions = selectionActionsCreator(set, snapshot);

        actions.selectRow(rowId, team);
      },
    [],
  );
};

export const useUnselectRows = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      () => {
        const actions = selectionActionsCreator(set, snapshot);

        actions.resetSelectedRow();
      },
    [],
  );
};

export const useGetRowsList = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      (rowIds: string[]) => {
        const actions = selectionActionsCreator(() => {}, snapshot);

        return actions.getRowList(rowIds);
      },
    [],
  );
};

export const useGetClipsList = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      (clipIds: string[]) => {
        const actions = selectionActionsCreator(() => {}, snapshot);

        return actions.getClipList(clipIds);
      },
    [],
  );
};

export const useGetClip = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      (clipId: string) => {
        const actions = selectionActionsCreator(() => {}, snapshot);

        return actions.getClip(clipId);
      },
    [],
  );
};

export const useAddClipsToSelectionList = () => {
  const validateSelection = useValidateSelection();

  return useRecoilCallback(
    ({ set, snapshot }) =>
      (clips: Clip[]) => {
        const actions = selectionActionsCreator(set, snapshot);

        const filteredClips = clips.filter(
          (clip) => clip.type !== 'not-effective-time' && clip.type !== 'block-container',
        );

        set(clipIdsAtom, () => filteredClips.map((clip) => clip.id));

        filteredClips.forEach((clip) => {
          if (!actions.getClip(clip.id)?.id) {
            set(clipAtomFamily(clip.id), { ...clip, isSelected: false, isSelectedForPlaying: false });
          }
        });

        validateSelection(filteredClips.map((clip) => clip.id));
      },
    [],
  );
};

export const useAddRowsToSelectionList = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (rows: Row[], teamId: string) => {
        const actions = selectionActionsCreator(set, snapshot);

        set(rowIdsAtom, () => rows.map((row) => row.id));
        rows.forEach((row) => {
          if (!actions.getRow(row.id)?.id) {
            set(rowAtomFamily(row.id), { ...row, isHidden: Boolean(row.teamId && row.teamId !== teamId) });
          }
        });
      },
    [],
  );
};

export const useAddRowToSelectionList = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (row: Row) => {
        const actions = selectionActionsCreator(set, snapshot);

        set(rowIdsAtom, (prev) => [...prev, row.id]);
        if (!actions.getRow(row.id)?.id) {
          set(rowAtomFamily(row.id), { ...row, isHidden: false });
        }
      },
    [],
  );
};

export const useAddClipShiftSelection = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (clipId: string) => {
        const actions = selectionActionsCreator(set, snapshot);
        const clipIds = actions.getListOfClipIds();

        const lastSelectedClipId = actions.getLastSelectedClipId();
        const lastClickedClipValue = actions.getClip(lastSelectedClipId ?? '')?.isSelected ?? false;

        if (!lastSelectedClipId) {
          actions.toggleClip(clipId);
          actions.setLastSelectedClip(clipId);
        } else {
          const firstClipIndex = clipIds.findIndex((id) => id === lastSelectedClipId);
          const lastClipIndex = clipIds.findIndex((id) => id === clipId);

          const clipsRangeToSelect = clipIds
            .slice(Math.min(firstClipIndex, lastClipIndex), Math.max(firstClipIndex, lastClipIndex) + 1)
            .filter((id) => id !== lastSelectedClipId);

          lastClickedClipValue
            ? actions.updateMultipleClips(clipsRangeToSelect)
            : actions.removeMultipleClipIds(clipsRangeToSelect);
        }
      },
    [],
  );
};

export const useAddRowClips = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (rowId: string, clearSelection = false) => {
        const actions = selectionActionsCreator(set, snapshot);
        const rowClips = actions.getRowClips(rowId);

        if (!rowClips) return;

        actions.updateMultipleClips(
          rowClips.map((clip) => clip.id),
          Boolean(clearSelection),
        );
      },
    [],
  );
};

export const useAddClipsAction = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (clipIds: string[]) => {
        const actions = selectionActionsCreator(set, snapshot);
        actions.updateMultipleClips(clipIds);
      },
    [],
  );
};

export const useReplaceMultipleClipsAction = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (clipIds: string[]) => {
        const actions = selectionActionsCreator(set, snapshot);
        actions.replaceMultipleClips(clipIds);
      },
    [],
  );
};

export const useToggleClipSelection = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (clipId: string) => {
        const actions = selectionActionsCreator(set, snapshot);
        const clip = actions.getClip(clipId);
        const selectedClipIds = actions.getSelectedClips();

        if (!clip) return;

        if (clip.isSelected && selectedClipIds.length <= 1) {
          actions.removeClipIdFromSelection(clipId);
        } else {
          actions.selectSingleClip(clipId);
        }
      },
    [],
  );
};

export const useToggleCtrlClipSelection = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (clipId: string) => {
        const actions = selectionActionsCreator(set, snapshot);
        const clip = actions.getClip(clipId);

        if (!clip) return;

        if (clip.isSelected) {
          actions.removeClipIdFromSelection(clipId);
        } else {
          actions.addClipIdToSelection(clipId);
        }
      },
    [],
  );
};

export const useRemoveClipFromSelection = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (clipId: string) => {
        const actions = selectionActionsCreator(set, snapshot);
        const clip = actions.getClip(clipId);

        if (!clip) return;

        set(lastSelectedClipIdAtom, clipId);
        set(selectedClipIdsAtom, (currVal) => currVal.filter((id) => clipId !== id));
        set(clipAtomFamily(clipId), (clip) => ({ ...clip, isSelected: false }));
      },
    [],
  );
};

export const useCleanSelection = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      () => {
        const actions = selectionActionsCreator(set, snapshot);
        actions.resetSelectedRow();
        actions.clearSelectedClips();
      },
    [],
  );
};
