import classNames from 'classnames';
import React from 'react';

import styles from './ButtonDropdown.module.scss';
import Button, { ButtonTypes } from '../button';
import IconChevronDown from '../icons/icon-chevron-down';
import { IconColors, IconSizes } from '../icons/svg-icon';

interface Props {
  disabled: boolean;
  isOpen: boolean;
  isSelected: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  title: string;
}
export const ButtonDropdown = ({ disabled, isOpen, isSelected, onClick, title }: Props) => {
  return (
    <Button
      disabled={disabled}
      className={classNames(styles.dropdownButton, {
        [styles.active]: isOpen,
        [styles.selected]: isSelected,
      })}
      type={ButtonTypes.White}
      onClick={onClick}
    >
      <div className={styles.group}>
        <div className={styles.groupContent}>{title}</div>
        <span
          className={classNames(styles.chevron, {
            [styles.chevronUp]: isOpen,
          })}
        >
          <IconChevronDown color={IconColors.iron} size={IconSizes.small} />
        </span>
      </div>
    </Button>
  );
};
