import account from './account.json';
import api from './api.json';
import common from './common.json';
import createTeam from './create-team.json';
import descriptions from './descriptions.json';
import faqs from './faqs.json';
import filters from './filters.json';
import forms from './forms.json';
import fundamentals from './fundamentals.json';
import home from './home.json';
import languages from './languages.json';
import login from './login.json';
import playlistDetail from './playlist-detail.json';
import playlists from './playlists.json';
import recordingEdit from './recording-edit.json';
import recordingPlaylists from './recording-playlists.json';
import recording from './recording.json';
import recordingsList from './recordings-list.json';
import recordings from './recordings.json';
import sharePlaylistModal from './share-playlist-modal.json';
import switchEnvironment from './switch-environment.json';
import taggingTool from './tagging-tool.json';
import timeline from './timeline.json';
import tutorials from './tutorials.json';
import videoPlayer from './video-player.json';
import { Namespaces } from '../../types';

const translations = {
  [Namespaces['ACCOUNT']]: account,
  [Namespaces['API']]: api,
  [Namespaces['COMMON']]: common,
  [Namespaces['CREATE-TEAM']]: createTeam,
  [Namespaces['DESCRIPTIONS']]: descriptions,
  [Namespaces['FORMS']]: forms,
  [Namespaces['FUNDAMENTALS']]: fundamentals,
  [Namespaces['HOME']]: home,
  [Namespaces['LANGUAGES']]: languages,
  [Namespaces['LOGIN']]: login,
  [Namespaces['PLAYLIST-DETAIL']]: playlistDetail,
  [Namespaces['PLAYLISTS']]: playlists,
  [Namespaces['RECORDING']]: recording,
  [Namespaces['RECORDING-EDIT']]: recordingEdit,
  [Namespaces['RECORDING-LIST']]: recordingsList,
  [Namespaces['RECORDING-PLAYLISTS']]: recordingPlaylists,
  [Namespaces['RECORDINGS']]: recordings,
  [Namespaces['SHARE-PLAYLIST-MODAL']]: sharePlaylistModal,
  [Namespaces['SWITCH_ENVIRONMENT']]: switchEnvironment,
  [Namespaces['TAGGING-TOOL']]: taggingTool,
  [Namespaces['TIMELINE']]: timeline,
  [Namespaces['TUTORIALS']]: tutorials,
  [Namespaces['VIDEO-PLAYER']]: videoPlayer,
  [Namespaces['FAQS']]: faqs,
  [Namespaces['FILTERS']]: filters,
};

export default translations;
