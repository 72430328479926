import { array, boolean, number, object, string, TypeOf } from 'zod';

export const SeasonSummarySchema = object({
  id: string(),
  year: number(),
  crossesYear: boolean(),
});
const CompetitionSchema = object({
  id: string(),
  name: string(),
  country: string(),
});
export const StageMiniSummarySchema = object({
  name: string(),
  orderIndex: number(),
  id: string(),
});
export const StageSummarySchema = object({
  name: string(),
  orderIndex: number(),
  id: string(),
  parentStage: StageMiniSummarySchema.optional(),
  childStages: array(StageMiniSummarySchema).optional(),
});
export const SeasonSchema = object({
  id: string(),
  year: number(),
  crossesYear: boolean(),
  competition: CompetitionSchema.optional(),
  stages: array(StageSummarySchema).optional(),
});
export type Season = TypeOf<typeof SeasonSchema>;
export type SeasonSummary = TypeOf<typeof SeasonSummarySchema>;
