import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

import { SortDirection } from 'shared/types';

import { InfinityQueryResult, Options } from './types';
import { getItems, getPage } from './utils';
import { HTTPMethod } from '../../types';
import { useBackendApi } from '../useBackendApi';

const PAGE_SIZE = 10;

export const useInfinityQuery = <TData, TFilters, TApiResponse>({
  generateUrl,
  options = {
    sortDirection: SortDirection.DESC,
    sort: '',
    size: PAGE_SIZE,
  },
  enabled = true,
  initialFilters = {} as TFilters,
  transformer,
  key,
  queryOptions,
}: Options<TData, TFilters, TApiResponse>): InfinityQueryResult<TData, TFilters> => {
  const [filters, setFilters] = useState<TFilters>(initialFilters);
  const queryRef = useMemo(() => [key, options, filters], [key, options, filters]);
  const fetchRequest = useInfiniteQuery<TData[]>(
    queryRef,
    (infinityQueryOptions) => {
      return useBackendApi(
        generateUrl(
          {
            sortDirection: options.sortDirection,
            sort: options.sort,
            size: options.size,
            page: infinityQueryOptions.pageParam,
          },
          filters,
        ),
        HTTPMethod.GET,
        transformer,
      );
    },
    {
      getNextPageParam: (lastPage: any) => {
        return lastPage.nextCursor;
      },
      enabled,
      staleTime: 200,
      ...queryOptions,
    },
  );

  const items = useMemo(() => getItems<TData>(fetchRequest.data?.pages), [fetchRequest.data]);
  const page = useMemo(() => getPage(fetchRequest.data?.pages), [fetchRequest.data]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, ...result } = fetchRequest;

  return { ...result, items, setFilters, page };
};
