import { VITE_REACT_APP_RELEASE_VERSION } from '../env-variables';

const PRODUCTION_HOST = 'app.kogniasports.com';
const STAGING_HOST = 'dev.app.kogniasports.com';
const PREVIEW_HOST = /([a-z0-9][a-z0-9-]+[a-z0-9]\.app.kogniasports\.com)/gi;

export enum Environments {
  Production = 'production',
  Staging = 'staging',
  Preview = 'preview',
  Unknown = 'unknown',
}

export const getEnvironment = (hostname = window.location.hostname): Environments => {
  if (hostname === PRODUCTION_HOST) return Environments.Production;
  if (hostname === STAGING_HOST) return Environments.Staging;
  if (hostname.match(PREVIEW_HOST) !== null) return Environments.Preview;

  return Environments.Unknown;
};

export const getReleaseVersion = (): string => {
  return VITE_REACT_APP_RELEASE_VERSION ?? 'unknown';
};
