import { boolean, literal, nativeEnum, object, string } from 'zod';

import { AnnotationFormFieldsNames } from './types';
import { BackofficeAnnotationToolType, BackofficePipelineType, BackofficeTaskType } from '../../../../api/types';

const optionalEmptyStringTransform = literal('').transform(() => undefined);

export const recordingIdSchema = string().trim().min(2, 'RecordingID is required');
const taskTypeSchema = nativeEnum(BackofficeTaskType);
const startIxSchema = string().trim().optional();
const endIxSchema = string().trim().optional();
export const episodeIndex = string().optional();
export const tacticalAnalysisIdSchema = string().optional();
export const gitCommitSchema = string().optional();
const loadAllSchema = boolean();
const dryRunSchema = boolean();
const pipelineTypeSchema = nativeEnum(BackofficePipelineType).optional().or(optionalEmptyStringTransform);
const annotationToolSchema = nativeEnum(BackofficeAnnotationToolType);

const refineIndex = (input: any) => !(input[AnnotationFormFieldsNames.START_IX] === '');
const customIndexError = {
  message: 'Start index is required',
  path: [AnnotationFormFieldsNames.START_IX],
};
export const createTaskFormSchema = object({
  [AnnotationFormFieldsNames.RECORDING_ID]: recordingIdSchema,
  [AnnotationFormFieldsNames.TASK_TYPE]: taskTypeSchema,
  [AnnotationFormFieldsNames.START_IX]: startIxSchema,
  [AnnotationFormFieldsNames.END_IX]: endIxSchema,
  [AnnotationFormFieldsNames.LOAD_ALL]: loadAllSchema,
  [AnnotationFormFieldsNames.PIPELINE_TYPE]: pipelineTypeSchema,
  [AnnotationFormFieldsNames.ANNOTATION_TOOL]: annotationToolSchema,
}).refine(refineIndex, customIndexError);

export const deleteTaskFormSchema = object({
  [AnnotationFormFieldsNames.RECORDING_ID]: recordingIdSchema,
  [AnnotationFormFieldsNames.TASK_TYPE]: taskTypeSchema,
  [AnnotationFormFieldsNames.START_IX]: startIxSchema,
  [AnnotationFormFieldsNames.END_IX]: endIxSchema,
  [AnnotationFormFieldsNames.ANNOTATION_TOOL]: annotationToolSchema,
}).refine(refineIndex, customIndexError);

export const annotationsTaskFormSchema = object({
  [AnnotationFormFieldsNames.RECORDING_ID]: recordingIdSchema,
  [AnnotationFormFieldsNames.TASK_TYPE]: taskTypeSchema,
  [AnnotationFormFieldsNames.START_IX]: startIxSchema,
  [AnnotationFormFieldsNames.END_IX]: endIxSchema,
  [AnnotationFormFieldsNames.PIPELINE_TYPE]: pipelineTypeSchema,
  [AnnotationFormFieldsNames.ANNOTATION_TOOL]: annotationToolSchema,
}).refine(refineIndex, customIndexError);

export const labelsTaskFormSchema = object({
  [AnnotationFormFieldsNames.RECORDING_ID]: recordingIdSchema,
  [AnnotationFormFieldsNames.TASK_TYPE]: taskTypeSchema,
  [AnnotationFormFieldsNames.START_IX]: startIxSchema,
  [AnnotationFormFieldsNames.END_IX]: endIxSchema,
  [AnnotationFormFieldsNames.PIPELINE_TYPE]: pipelineTypeSchema,
  [AnnotationFormFieldsNames.ANNOTATION_TOOL]: annotationToolSchema,
}).refine(refineIndex, customIndexError);

export const validationTaskFormSchema = object({
  [AnnotationFormFieldsNames.RECORDING_ID]: recordingIdSchema,
  [AnnotationFormFieldsNames.DRY_RUN]: dryRunSchema,
  [AnnotationFormFieldsNames.PIPELINE_TYPE]: pipelineTypeSchema,
  [AnnotationFormFieldsNames.ANNOTATION_TOOL]: annotationToolSchema,
});

export const reprocessSyncFormSchema = object({
  [AnnotationFormFieldsNames.RECORDING_ID]: recordingIdSchema,
});

export const AnnotationFormLabels = {
  [AnnotationFormFieldsNames.RECORDING_ID]: 'Recording ID',
  [AnnotationFormFieldsNames.TASK_TYPE]: 'Task type',
  [AnnotationFormFieldsNames.START_IX]: 'Start index',
  [AnnotationFormFieldsNames.END_IX]: 'End index',
  [AnnotationFormFieldsNames.LOAD_ALL]: 'Load annotations',
  [AnnotationFormFieldsNames.PIPELINE_TYPE]: 'Pipeline type',
  [AnnotationFormFieldsNames.DRY_RUN]: 'Dry run',
  [AnnotationFormFieldsNames.EPISODE_INDEX]: 'Episode index',
  [AnnotationFormFieldsNames.ANNOTATION_TOOL]: 'Annotation tool',
  [AnnotationFormFieldsNames.TACTICAL_ANALYSIS_ID]: 'Tactical Analysis ID',
  [AnnotationFormFieldsNames.GIT_COMMIT]: 'Git commit',
};
