import { Button, styled } from '@mui/material';

interface KebabMenuButtonProps {
  disableButtonPadding?: boolean;
}

export const KebabMenuButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'disableButtonPadding',
})<KebabMenuButtonProps>(({ theme, disableButtonPadding }) => ({
  padding: disableButtonPadding ? 0 : theme.spacing(0.5),
  minWidth: 'auto',
}));
