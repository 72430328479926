import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import IconCamera from 'shared/components/icons/icon-camera';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import Spinner from 'shared/components/spinner';

import styles from '../RecordingStates.module.scss';

interface Props {
  active: boolean;
  isProcessingVideo: boolean;
}

const StateCamera = ({ active, isProcessingVideo }: Props) => {
  const { t } = useTranslation();
  return (
    <span className={classNames(styles.stateBlock, active && !isProcessingVideo ? styles.active : styles.inactive)}>
      {isProcessingVideo ? (
        <Spinner color={IconColors.blue} size={IconSizes.small} />
      ) : (
        <IconCamera size={IconSizes.small} color={active ? IconColors.blue : IconColors.iron} />
      )}
      <div>{t('recording:states.video')}</div>
    </span>
  );
};

export default StateCamera;
