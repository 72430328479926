import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown as Caret } from 'react-icons/md';

import { getFiltersFromUrl } from 'api/recording/useFetchRecordings/util/get-initial-filters';
import { RecordingFilters } from 'pages/recordings-list/types';
import { ListTitle } from 'shared/components/list/list-title';
import { Filter, FilterOptions } from 'shared/types';

import styles from './annotation-type-filter.module.scss';

type AnnotationTypeFilterProps = {
  annotationFilter: Filter;
  applyFilters: Function;
};

const getSelectedAnnotationType = (annotationFilter: FilterOptions): string => {
  const urlFilters = getFiltersFromUrl();

  const firstKey = Object.keys(annotationFilter)[0];
  return reduce(
    annotationFilter,
    (acc, option, type) => {
      return urlFilters.annotationType === type ? type : acc;
    },
    firstKey,
  );
};

const AnnotationTypeFilter = ({ annotationFilter, applyFilters }: AnnotationTypeFilterProps): JSX.Element => {
  const { t } = useTranslation();
  const annotationTypeOptionApplied = getSelectedAnnotationType(annotationFilter.options);

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
      <ListTitle dataTestId='page-title'>{t('filters:recordings')}</ListTitle>

      <div
        className={styles.dropdown}
        tabIndex={0}
        onMouseEnter={() => setDropdownVisible(true)}
        onMouseLeave={() => setDropdownVisible(false)}
        onFocus={() => setDropdownVisible(true)}
        onBlur={() => setDropdownVisible(false)}
      >
        <Typography sx={{ fontSize: 24, fontWeight: 300, display: 'inline' }}>
          {t(`filters:annotation-type.${annotationTypeOptionApplied.toLowerCase()}`)}
        </Typography>
        <Caret
          className={classNames(styles.caret, {
            [styles['caret--down']]: isDropdownVisible,
          })}
        />
        <ul
          className={classNames(styles.list, {
            [styles['list--visible']]: isDropdownVisible,
          })}
        >
          {map(annotationFilter.options, (option, index) => (
            <li
              tabIndex={annotationTypeOptionApplied === index ? -1 : 0}
              className={classNames(styles.type, {
                [styles['type--disabled']]: annotationTypeOptionApplied === index,
              })}
              key={index}
              onClick={() => {
                applyFilters({ key: RecordingFilters.ANNOTATION_TYPE, selectedFilters: [index] });
              }}
            >
              {t(`filters:annotation-type.${index.toLowerCase()}`)}
            </li>
          ))}
        </ul>
      </div>
    </Box>
  );
};

export default AnnotationTypeFilter;
